import React, {useEffect, useState, useRef} from "react";
import { useAuth } from "../contexts/authContext";
import styles from '../styles/scss/AddEmailModal.module.scss';

import Button from "../components/Button";

import {useFetch} from "../hooks/useFetch";
import {useModal} from "../contexts/ModalContext";
import {Controller, useForm} from "react-hook-form";
import Input2 from "../components/Input2";
import {retryableError} from "../util/retryableError";
import Modal from "@mui/material/Modal";
import {screen,track} from "../util/analytics";
import useProgressBar from "../hooks/useProgressBar";
import ProgressBar from "../components/ProgressBar";
import useDetectNetwork from "../hooks/useDetectNetwork";
import ConfirmEmailModal from "../modals/ConfirmEmailModal";
import Slide from '@mui/material/Slide';
import Fade from "@mui/material/Fade";

//If we'ere not in the right state, we'll get stuck with Loading button. need to check and redirect fix
const AddEmailModal = ({homeCallback, callback, backEmail, exitCallback, props, windowWidth}) => {
    const { register, handleSubmit, formState,
        setValue,
        control, reset, getValues} = useForm({mode: "onChange", reValidateMode: "all"});
    const [resetKey, setResetKey] = useState(Date.now());

    const {authState, authService} = useAuth();
    const [buttonText, setButtonText] = useState('Next')
    const [error, setError] = useState("");
    const [email, setEmail] = useState(null);
    const [back, setBack] = useState(backEmail);
    const [submitted, setSubmitted] = useState(false);
    const {state, fetch, isLoading, uiErrorMsg, reset: resetFetch} = useFetch();
    const {toggleModal,setModalType, setShowSettings, hiddenSettings, setHiddenSettings} = useModal();
    const [onlyUpdateEmailOnce, setOnlyUpdateEmailOnce] = useState(false);
    const inputRef = useRef(null);
    const { progressStarted, apiStarted, isComplete, startProgress, handleCompletion,  setLastApiCallStarted, setProgressOverride, progressOverride, resetProgressBar} = useProgressBar();
    const [showNextModal, setShowNextModal] = useState(false);
    const [transitionClose, setTransitionClose] = useState(true);
    const leftbutton = "https://img.mirkat.com/web/icons/leftbutton.svg";


    const delayedClose = () => {
        setTransitionClose(false);
        setTimeout(() => {exitCallback(false)}, 500)
        //exitCallback(false);
    };


    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    });

    const isOnline = useDetectNetwork();
    useEffect(() => {
        if(!isOnline){
            setError("No network connection")
        }
        if(isOnline && error === "No network connection") {
            setError("")
        }
    }, [isOnline])

    useEffect(() => {
        console.log("props: ", props, windowWidth)
        if(props?.fromSettings) {
            screen('Add Email Address: Email', {screenType: 'addEmailAddress', screenSubType: 'email', entryType: 'settings'})
        } else {
            screen('Add Email Address: Email', {screenType: 'addEmailAddress', screenSubType: 'email', entryType: 'card'})

        }

        //on load ensure progressbar is reset (incase we go to confirm and back)


        return(() => {
            setOnlyUpdateEmailOnce(false)
            resetProgressBar()
        })
    }, []);

    useEffect(() => {
        if(!isLoading) {
            setButtonText('Next')
        }
        if(backEmail && !onlyUpdateEmailOnce) {
            console.log("How many times does this run?")
            setValue("email", backEmail, { shouldValidate: true })
            setOnlyUpdateEmailOnce(true)
        }

    }, [isLoading, uiErrorMsg, backEmail]);

    useEffect(() => {
        //Error 39 = user already registered - should we redirect to login?
        if(state && email) {
            if(state.matches('idle') && !submitted) {
                fetch('addEmail', [email])
                setSubmitted(true);
            }

        }
        if(state.matches('success') && email) {
            setProgressOverride(100)
            resetProgressBar()
            //go to confirm modal
            //setEmailBack(email) // passed back to parent component to fire off the confirm modal
            console.log("TARGET CONTEXT VAR: ", state?.context?.data?.secondaryUserEmailId);
            //setModalType({modalType:'confirmEmail', email: email, callback: callback, exitCallback: exitCallback, refreshCallback: props.refreshCallback, secondaryUserEmailId: state?.context?.data?.secondaryUserEmailId, fromFeed: props?.fromFeed || false}) // pass email to confirm modal
            setShowNextModal(true);
            resetFetch()
        }
        if(state.matches('failure')) {
            setProgressOverride(100)
            resetProgressBar()
            setButtonText('Next')
            console.log("state: ", state)
            let {canRetry, errMsg }= retryableError({errorBody: state.context.error})
            setError(errMsg);
            setResetKey(Date.now())
            setTimeout(() => {
                resetFetch()
            }, 3000);

        }

    }, [state, authState, email, submitted]);

    //back button needs to go to.... either settings or the dark web modal..

    const onSubmit = (data) => {
        //#321
        track('Button Tapped', {buttonType: 'next', screenType: 'addEmailAddress', screenSubType: 'email', entryType: props?.fromFeed ? 'card' : 'settings'})
        const email = data.email;
        startProgress()
        setLastApiCallStarted(true)
        setEmail(email);
        setSubmitted(false)
    }

    const goBack = () => {
        track('Button Tapped', {buttonType: 'back', screenType: 'addEmailAddress', screenSubType: 'email'})
        //if(formState.isValid) {
        //    setModalType({modalType: 'introAddEmail', backEmail: getValues("email"), callback: exitCallback, emails: props.emails, refreshCallback: props.refreshCallback})
        //} else {
        //    setModalType({modalType: 'introAddEmail', callback: exitCallback, emails: props.emails, refreshCallback: props.refreshCallback})
        //}
        if (windowWidth > 839) {
            setTransitionClose(false);
            exitCallback(false);
        } else {
            delayedClose();
        }
        if (hiddenSettings) {
            setHiddenSettings(false);
            //setModalType('');
            //toggleModal();
        }
    }

    const validateEmail = (email) => {
        if(error) {
            setError("")
        }
    }

    return (
       <>{progressStarted && <ProgressBar progressOverride={progressOverride} apiStarted={apiStarted} onCompleted={handleCompletion} setProgressOverride={setProgressOverride}/>}
        {showNextModal && <ConfirmEmailModal props={{homeCallback: homeCallback, email: email, callback: callback, exitCallback: setShowNextModal, refreshCallback: props.refreshCallback, secondaryUserEmailId: state?.context?.data?.secondaryUserEmailId, fromFeed: props?.fromFeed || false}} windowWidth={windowWidth} />}
        {windowWidth < 840 && 
        <Modal open={transitionClose} closeAfterTransition>
        <Slide direction="left" in={transitionClose}>
        <div className={styles["container"]}>
            <div className={styles["modal-addEmail-container"]}>
                <form noValidate method="post" onSubmit={handleSubmit(onSubmit)} style={{maxWidth: "600px", minWidth: "360px"}}>
                    <div className={styles["back-button"]}>
                        <img src={leftbutton} alt="back arrow" style={{cursor: "pointer"}} onClick={goBack}/>
                    </div>
                    <div className={styles["email-inner-container"]}>
                        <div className={styles["email-form-header-container"]}>

                            <div className={styles["email-form-header-text"]}>
                                <div className={styles["email-form-header"]}>Enter email address for monitoring on the dark web
                                </div>
                            </div>
                        </div>
                        <div className={styles["inner-div"]}>
                        <Controller
                            key={`email-${resetKey}`}  // Apply the key here
                            control={control}
                            name="email"
                            defaultValue=""
                            rules={{ required: true, maxLength: 100, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i , validate: validateEmail}}
                            render={({ field, fieldState }) => <Input2 field={field} maxLength={100} label="Email address" type="email" id="email" autoComplete="email" inputRef={!showNextModal && inputRef} />}
                        />

                        <div className={styles["error"]} id="error-message">{error}</div>
                    </div>
                    </div>
                    <div className={styles["button-div"]}> <Button isDisabled={!formState.isValid || progressStarted} buttonText="Next" /></div>
                </form>
            </div>
        </div>
    </Slide>
    </Modal>}
    {windowWidth > 839 &&
    <Modal open={transitionClose} closeAfterTransition>
        <Fade in={transitionClose}>
        <div className={showNextModal ? "settings-none" : styles["container"] }>
            <div className={styles["modal-addEmail-container"]}>
                <form noValidate method="post" onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles["back-button"]}>
                        <img src={leftbutton} alt="back arrow" style={{cursor: "pointer"}} onClick={goBack}/>
                    </div>
                    <div className={styles["email-inner-container"]}>
                        <div className={styles["email-form-header-container"]}>

                            <div className={styles["email-form-header-text"]}>
                                <div className={styles["email-form-header"]}>Enter email address for monitoring on the dark web
                                </div>
                            </div>
                        </div>
                        <div className={styles["inner-div"]}>
                        <Controller
                            key={`email-${resetKey}`}  // Apply the key here
                            control={control}
                            name="email"
                            defaultValue=""
                            rules={{ required: true, maxLength: 100, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, validate: validateEmail }}
                            render={({ field, fieldState }) => <Input2 field={field} maxLength={100} label="Email address" type="email" id="email" autoComplete="email" inputRef={inputRef} />}
                        />

                        <div className={styles["error"]} id="error-message">{error}</div>
                    </div>
                    </div>
                    <div className={styles["button-div"]}> <Button isDisabled={!formState.isValid || progressStarted} buttonText="Next" /></div>
                </form>
            </div>
        </div>
        </Fade>
    </Modal>}
           </>
    )
}

export default AddEmailModal;

import React, {useEffect} from "react";
import MainNavigation from "../components/MainNavigation";
import {useNavigate} from "react-router-dom";

const ErrorPage = () => {
    const navigate = useNavigate();
    //Redirect for now. we may want to log errors and have a real 404 page?
    useEffect(() => {
        navigate("/")
    }, [])

    return (
        <>
            <MainNavigation />
            <main>
                <div className="error">
                    <h1>Error</h1>
                    <p>Something went wrong.</p>
                </div>
            </main>
        </>
    );
}

export default ErrorPage;
import {useState} from "react";


// This hook is used to handle form input changes and sets isFormCompleted to true if all fields are filled out.
//pass in an object with fields for it to check
//example: {email: "", password: ""}
const useFormHandler = (formObject) => {

    if (!formObject) {
        throw new Error("formObject is required");
    }
    const [formData, setFormData] = useState(formObject);

    const isFormCompleted = Object.values(formData).every((field) => field !== "");
    const handleInputChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    //validators and errors?
    return({isFormCompleted, handleInputChange, setFormData});

}

export default useFormHandler;
import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/authContext';
import { useFetch } from './useFetch';
import { retryableError } from '../util/retryableError';
import {parseMirkatAuthData} from "../util/mirkatAuth";


const maxInterval = parseInt(process.env.REACT_APP_POLLING_INTERVAL) || 4000; //4 seconds ... otherwise might take too long to respond for the user
const timeout = parseInt(process.env.REACT_APP_MAX_POLLING_TIME) || 900000; //15 minutes
const useConfirmPoll = () => {
    const {authState, authService, stateSteady} = useAuth();
    const {state, fetch, ready, reset} = useFetch();
    const [retryCount, setRetryCount] = useState(0);
    const [backoff, setBackoff] = useState(1000);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [start, setStart] = useState(false);
    const [userEmailId, setUserEmailId] = useState(null);
    const [startTime, setStartTime] = useState(Date.now());

    //userEmailId only required for secondary email confirm
    const startPolling = (userEmailId) => {
        setStartTime(Date.now())
        if (userEmailId) {
            setUserEmailId(userEmailId);
        }
        setRetryCount(0);
        setBackoff(1000);
        reset();
        setStart(true);
    }
    const resetPolling = () => {
        setStartTime(Date.now())
        setStart(false);
        reset();
        setSuccess(false);
        //setError(null);
        setUserEmailId(null);
    }


    //state.context.data
    const handleAuth = (data) => {
        if (data?.ptk && !data?.accessKey && !data?.refreshKey) {
            console.log("Confirm Poll: ", data)
            //new user confirmed
            authService.send('POLLING_NEWUSER_CONFIRM_SUCCESS', {data: data})

        } else if (data?.accessKey && data?.refreshKey) {
            //existing user confirmed
            let authData = parseMirkatAuthData(data)
            console.log("Parsed Auth Data: ", authData)
            console.log("Auth data: ", data)
            authService.send('POLLING_AUTHCONFIRM_SUCCESS', {data: data})

        } else {
            console.log("ERROR in polling success. should not be in here")
            setError("An unexpected error occurred. Please try again.")
        }

    }
    //We're going to need to handle max session time or something if the user wait's in confirm too long.
    //Pre-Password Token __k_tp__ is only good for 1 hour  /v2/users/confirm or  /v2/users/confirmToken set this
    //if this expires -> go back to confirm and resubmit
    useEffect(() => {
        if(stateSteady && authState.matches('confirm') && start) {
            if(state.matches('idle')) {
                if (userEmailId) {
                    fetch('confirmSecondaryPoll', {userEmailId: userEmailId})
                } else {
                    fetch('confirmPoll')
                }
            }
            if(state.matches('success') && !success) { //request was successful
                if(state.context?.data.ptk || (state.context?.data.accessKey && state.context?.data.refreshKey)) { //did the request contain the data we need?
                    console.log("we got data")
                    handleAuth(state.context.data);
                    setSuccess(true); //we successfully got the data we needed
                    setStart(false)
                   // resetPolling()
                } else {
                    if ((startTime + timeout) <= Date.now()) { //polling timed out, show error message to user.
                       // setError("Timed out waiting for confirmation. Please try again.");
                        resetPolling()
                    } else {
                        setTimeout(() => {
                            reset(); //resets fetch state to idle - so it will fetch again
                            //setRetryCount(retryCount + 1); // could exit after retry count or backoff time if we wish
                            setBackoff(backoff + 1000);
                        }, Math.min(backoff, maxInterval)) // goes 1 second, 2 seconds,3 seconds, 4 seconds, 4 seconds ... max
                    }
                }
            }

            if(state.matches('failure')) {
                //Might need to update this
                let {canRetry, errMsg }= retryableError({errorBody: state.context.error})
                console.log("Polling canRetry: ", canRetry)
                console.log("Polling errMsg: ", errMsg)
                if(retryCount < 3) {
                    setRetryCount(retryCount + 1);
                    resetPolling()
                } else {
                    setError(errMsg);
                    console.log("Polling state failure: ", state)
                }
            }
        }
        console.log("READY: ", ready)
        console.log("Backoff: ", backoff)
        console.log("Timeout: ", timeout)
        console.log("Retry Count: ", retryCount)
        console.log("State: ", state)
    }, [authState, stateSteady, state, backoff, start, success])

    return {success, error, startPolling, resetPolling, state, pollRunning: start }
}

export default useConfirmPoll;


//new user always has ptk otherwise, accessToken and refreshToken present means authenticated existing user

/*
{
    "emailId": "g4apbDoj",
    "deviceTypeId": 2,
    "primaryUser": true,
    "emailAddress": "scott+newusertokentest4@mirkat.com",
    "settings": {
        "notifyAndroid": 1,
        "notifyEmail": 1,
        "notifyIOS": 1,
        "notifyWindows": 1
    },
    "ptk": "a458d43f-17a4-4f1d-aa20-aca55bf3c6ed"
}
 */

export function preloadImages(urls) {
    //if single url string, convert to array and continue
    if(typeof urls === "string") {
        urls = [urls];
    }

    urls.forEach(url => {
        const img = new Image();
        img.src = url;
    });
    //done
    return true;
}

//        "https://img.mirkat.com/web/images/home/upgrade-checkmark.svg", needs to happen before welcome screen
//src="https://img.mirkat.com/web/images/home/exposedc.svg"
//https://img.mirkat.com/web/images/home/accountd.svg
//https://img.mirkat.com/web/images/home/privated.svg
//https://img.mirkat.com/web/images/home/creditd.svg
//https://img.mirkat.com/web/images/home/bankingd.svg

export function preloadUpgradeCardImages() {
    const upgradeCardImages = [
        "https://img.mirkat.com/web/images/home/upgrade-checkmark.svg",
        "https://img.mirkat.com/web/images/home/exposedc.svg",
        "https://img.mirkat.com/web/images/home/accountd.svg",
        "https://img.mirkat.com/web/images/home/privated.svg",
        "https://img.mirkat.com/web/images/home/creditd.svg",
        "https://img.mirkat.com/web/images/home/bankingd.svg",
        "https://img.mirkat.com/web/images/home/identityd.svg",
    ];

    preloadImages(upgradeCardImages);
}

export function preloadMainImages() {
    const menuIconSvgs = [
        "https://img.mirkat.com/web/images/menu/headerLogo.png",
        "https://img.mirkat.com/web/images/home/Mirkat.png",
        "https://img.mirkat.com/web/icons/leftbutton.svg",
        "https://img.mirkat.com/web/images/menu/dot.svg",
        "https://img.mirkat.com/web/icons/history.svg",
        "https://img.mirkat.com/web/icons/ChangePlan.svg",
        "https://img.mirkat.com/web/icons/settings.svg",
        "https://img.mirkat.com/web/images/menu/dot.svg",
        "https://img.mirkat.com/web/images/home/Icon.svg",
        "https://img.mirkat.com/web/images/home/add_email_icon_plus.svg",
    ]
    preloadImages(menuIconSvgs);
}


export function preloadTestRiskImages() {
    const testRiskImages = [
        "https://img.mirkat.com/web/images/home/illustration.png",
        "https://img.mirkat.com/cdn-cgi/image/width=1000/web/images/home/illustration.png",
        "https://img.mirkat.com/cdn-cgi/image/width=1000/images/riskbanner_allaccounts_updated1.png",
        "https://img.mirkat.com/cdn-cgi/image/width=1000/images/riskbanner_audiostreaming1.png",
        "https://img.mirkat.com/cdn-cgi/image/width=1000/images/riskbanner_phonehijack1.png",
        "https://img.mirkat.com/cdn-cgi/image/width=1000/images/riskbanner_phonehijack3.png",
        "https://img.mirkat.com/cdn-cgi/image/width=1000/images/riskbanner_bankaccount2.png",
        "https://img.mirkat.com/cdn-cgi/image/width=1000/images/riskbanner_creditcard2.png",
        "https://img.mirkat.com/cdn-cgi/image/width=1000/images/riskbanner_phishingmail3.png",
        "https://img.mirkat.com/cdn-cgi/image/width=1000/images/riskbanner_identity1.png",
        "https://img.mirkat.com/cdn-cgi/image/width=1000/images/riskbanner_generic1.png",
        "https://img.mirkat.com/cdn-cgi/image/width=1000/images/riskbanner_videostreaming1.png",
        "https://img.mirkat.com/cdn-cgi/image/width=1000/images/riskbanner_audiostreaming3.png",
        "https://img.mirkat.com/cdn-cgi/image/width=1000/images/riskbanner_gaming1.png",
        "https://img.mirkat.com/cdn-cgi/image/width=1000/images/riskbanner_sensitive1.png",
        "https://img.mirkat.com/cdn-cgi/image/width=1000/images/riskbanner_phonehijack2.png",
        "https://img.mirkat.com/cdn-cgi/image/width=1000/images/riskbanner_bankaccount3.png",
        "https://img.mirkat.com/cdn-cgi/image/width=1000/images/riskbanner_creditcard3.png",
        "https://img.mirkat.com/cdn-cgi/image/width=1000/images/riskbanner_phishingmail1.png",
        "https://img.mirkat.com/cdn-cgi/image/width=1000/images/riskbanner_phonephishing1.png",




    ];
    preloadImages(testRiskImages);
}



const cacheIconSvgs = [
    "https://img.mirkat.com/web/icons/alert.svg",
    "https://img.mirkat.com/web/icons/ChangePlan.svg",
    "https://img.mirkat.com/web/icons/mask.svg",
    "https://img.mirkat.com/web/images/home/accountc.svg",
    "https://img.mirkat.com/web/images/home/accountd.svg",
    "https://img.mirkat.com/web/images/home/accounte.svg",
    "https://img.mirkat.com/web/images/home/account.svg",
    "https://img.mirkat.com/web/images/home/bankingc.svg",
    "https://img.mirkat.com/web/images/home/bankingd.svg",
    "https://img.mirkat.com/web/images/home/bankinge.svg",
    "https://img.mirkat.com/web/images/home/banking.svg",
    "https://img.mirkat.com/web/images/home/breaches-card-icon.svg",
    "https://img.mirkat.com/web/images/home/breaches-exit-button.svg",
    "https://img.mirkat.com/web/images/home/breaches-icon.svg",
    "https://img.mirkat.com/web/images/home/card.svg",
    "https://img.mirkat.com/web/images/home/creditc.svg",
    "https://img.mirkat.com/web/images/home/creditd.svg",
    "https://img.mirkat.com/web/images/home/credite.svg",
    "https://img.mirkat.com/web/images/home/email-card-icon.svg",
    "https://img.mirkat.com/web/images/home/emailIcon.svg",
    "https://img.mirkat.com/web/images/home/exposedc.svg",
    "https://img.mirkat.com/web/images/home/Icon.svg",
    "https://img.mirkat.com/web/images/home/identityc.svg",
    "https://img.mirkat.com/web/images/home/identityd.svg",
    "https://img.mirkat.com/web/images/home/identitye.svg",
    "https://img.mirkat.com/web/images/home/identity.svg",
    "https://img.mirkat.com/web/images/home/illustrationb.svg",
    "https://img.mirkat.com/web/images/home/passwordb.svg",
    "https://img.mirkat.com/web/images/home/passwords.svg",
    "https://img.mirkat.com/web/images/home/privatec.svg",
    "https://img.mirkat.com/web/images/home/privated.svg",
    "https://img.mirkat.com/web/images/home/privatee.svg",
    "https://img.mirkat.com/web/images/home/private.svg",
    "https://img.mirkat.com/web/images/menu/headerLogo.svg",
    "https://img.mirkat.com/web/images/menu/wordmark.svg",
];

export function preloadCacheIconSvgs() {
    preloadImages(cacheIconSvgs);
}


//cache these after feed loads, look for the icons for the cards THEN cache. we shouldn't need to beforehand
//missed images
/*
https://img.mirkat.com/icons/risk_phishing.png
https://img.mirkat.com/images/action_review_phone.png
https://img.mirkat.com/images/action_scam_blocking.png
https://img.mirkat.com/icons/risk_allaccounts.png
https://img.mirkat.com/images/action_change_password.png
https://img.mirkat.com/icons/risk_entity.png

https://img.mirkat.com/icons/risk_mobilehijack.png
https://img.mirkat.com/images/action_pin.png


 */

//For feed - todo: need/combine with threat history
export const cacheFeedActionImages = (response) => {
    const urls = extractImageUrls(response);
    if(urls.length > 0) {
        preloadImages(urls);
    }
}

function extractImageUrls(response) {
    // Initialize an array to hold all the URLs
    let urls = [];

    // Check if the response has the structure we expect
    if (response?.feed?.existingRisks?.cards) {
        // Iterate over each item in the 'c' array
        response.feed.existingRisks.cards.forEach(item => {
            // If 'iImageUrl' exists, add it to the urls array
            if (item.iconImageUrl) {
                urls.push(item.iconImageUrl);
            }

            // Check if 'a' exists and is an array
            if (Array.isArray(item?.actions)) {
                // Iterate over each item in 'a'
                item.actions.forEach(aItem => {
                    // If 'i2Url' exists, add it to the urls array
                    if (aItem.imageUrl) {
                        urls.push(aItem.imageUrl);
                    }
                });
            }
        });
    }

    return urls;
}




export const cacheThreatHistoryImages = (response) => {
    // { cards: [{iconImageUrl: "https://img.mirkat.com/icons/risk_phishing.png", bannerImageUrl: "https://img.mirkat.com/images/action_review_phone.png"}]}]}
    const urls = extractThreatHistoryImageUrls(response);

    if(urls.length > 0) {
        preloadImages(urls);
    }
}

function extractThreatHistoryImageUrls(response) {
    // Initialize an array to hold all the URLs
    let urls = [];

    // Check if the response has the structure we expect
    if (response?.cards) {
        // Iterate over each item in the 'c' array
        response?.cards.forEach(item => {
            // If 'iImageUrl' exists, add it to the urls array
            if (item?.iconImageUrl) {
                urls.push(item.iconImageUrl);
            }
            if (item?.bannerImageUrl) {
                urls.push(item.bannerImageUrl);
            }
        });
    }
    return urls;
}
import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/authContext';
import { useFetch } from './useFetch';
import { retryableError } from '../util/retryableError';
import {parseMirkatAuthData} from "../util/mirkatAuth";


const maxInterval = parseInt(process.env.REACT_APP_POLLING_INTERVAL) || 4000; //4 seconds ... otherwise might take too long to respond for the user
const timeout = parseInt(process.env.REACT_APP_MAX_POLLING_TIME) || 900000; //15 minutes
const useSecondaryPoll = () => {
    const {authState, authService, stateSteady} = useAuth();
    const {state, fetch, ready, reset} = useFetch();
    const [retryCount, setRetryCount] = useState(0);
    const [backoff, setBackoff] = useState(1000);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [start, setStart] = useState(false);
    const [userEmailId, setUserEmailId] = useState(null);
    const [pollingTimedOut, setPollingTimedOut] = useState(false);
    const [startTime, setStartTime] = useState(Date.now());



    //userEmailId only required for secondary email confirm
    const startPolling = (secondaryUserEmailId) => {
        console.log("SECONDARY USER EMAIL ID: ", secondaryUserEmailId);
        if (secondaryUserEmailId) {
            setUserEmailId(secondaryUserEmailId);
        }
        setRetryCount(0);
        setBackoff(1000);
        reset();
        setStart(true);
    }

    const resetPolling = () => {
        setStart(false);
        reset();
        setSuccess(false);
        //setError(null);
        setUserEmailId(null);
    }

    useEffect(() => {
        if(stateSteady && authState.matches('authenticated') && start) {
            if(state.matches('idle') && !success) {
                    fetch('confirmSecondaryPoll', [userEmailId]);
            }
            if(state.matches('success') && !success) {
                if(state.context?.data?.confirmed_email) {
                    console.log("WE GOT DATA", state)
                    setSuccess(true);
                    setStart(false);
                } else {
                    if ((startTime + timeout) <= Date.now()) {
                        setError("Timed out waiting for confirmation. Please try again.")
                        resetPolling()
                    } else {
                        setTimeout(() => {
                            reset(); //resets fetch state to idle - so it will fetch again
                            //setRetryCount(retryCount + 1); // could exit after retry count or backoff time if we wish
                            setBackoff(backoff + 1000);
                        }, Math.min(backoff, maxInterval)) // goes 1 second, 2 seconds,3 seconds, 4 seconds, 4 seconds ... max
                    }
                }
            }

            if(state.matches('failure')) {
                //Might need to update this
                let {canRetry, errMsg }= retryableError({errorBody: state.context.error})
                if(retryCount < 3) {
                    setRetryCount(retryCount + 1);
                    resetPolling()
                } else {
                    setError(errMsg);
                    console.log("Polling state failure: ", state)
                }
            }
        }
        console.log("READY: ", ready)

    }, [authState, stateSteady, state, backoff, start])

    return {success, error, startPolling, resetPolling, state, pollRunning: start }
}

export default useSecondaryPoll;


//new user always has ptk otherwise, accessToken and refreshToken present means authenticated existing user

/*
{
    "emailId": "g4apbDoj",
    "deviceTypeId": 2,
    "primaryUser": true,
    "emailAddress": "scott+newusertokentest4@mirkat.com",
    "settings": {
        "notifyAndroid": 1,
        "notifyEmail": 1,
        "notifyIOS": 1,
        "notifyWindows": 1
    },
    "ptk": "a458d43f-17a4-4f1d-aa20-aca55bf3c6ed"
}
 */

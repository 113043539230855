import React, {useEffect, useRef, useState} from "react";
import Button from "../components/Button";
import useFormHandler from "../hooks/useFormHandler";
import {useAuth} from "../contexts/authContext";
import {useFetch} from "../hooks/useFetch";
import {useModal} from "../contexts/ModalContext";
import styles from "../styles/scss/ConfirmEmailModal.module.scss";
import CodeResentModal from "./CodeResentModal";
import {Controller, useForm, useWatch} from "react-hook-form";
import Input2 from "../components/Input2";
import {retryableError} from "../util/retryableError";
import Modal from "@mui/material/Modal";
import {screen,track} from "../util/analytics";
import useSecondaryPoll from "../hooks/useSecondaryPoll";
import ProgressBar from "../components/ProgressBar";
import useProgressBar from "../hooks/useProgressBar";
import useDetectNetwork from "../hooks/useDetectNetwork";
import Slide from '@mui/material/Slide';
import Fade from "@mui/material/Fade";

//If successful -> loading/scanning page

const ConfirmEmailModal = ({ props, windowWidth }) => {
    const { register, handleSubmit, formState,
        setValue,
        control, reset,  formState: {errors}} = useForm({mode: "onChange", defaultValues: {code: ""}});
    const fieldState = useWatch({control, name: 'code'});
    const [resetKey, setResetKey] = useState(Date.now());    //back button needs to go to IntroEmmailModal
    const {isFormCompleted, handleInputChange, setFormData} = useFormHandler({code: ""});
    const codeRef = useRef();
    const {state, fetch, isLoading, uiErrorMsg, reset: resetFetch} = useFetch();
    const {state: state2, fetch: fetch2, isLoading: isLoading2, uiErrorMsg: uiErrorMsg2, reset: reset2} = useFetch();
    const {state: state3, fetch: fetch3, isLoading: isLoading3, uiErrorMsg: uiErrorMsg3, reset: reset3} = useFetch();
    const {authState, authService, isReady, stateSteady} = useAuth();
    const [code, setCode] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const {toggleModal,setModalType, setMobileKebab} = useModal();
    const [error, setError] = useState("");
    const [showResendModal, setShowResendModal] = useState(false);
    const stackedModal = true;
    const inputRef = useRef(null);
    const {success, error: pollingError, resetPolling, startPolling, state: pollingState , pollRunning} = useSecondaryPoll()
    const { progressStarted, apiStarted, isComplete, startProgress, handleCompletion,  setLastApiCallStarted, setProgressOverride, progressOverride, resetProgressBar} = useProgressBar();
    const [transitionClose, setTransitionClose] = useState(true);
    const leftbutton = "https://img.mirkat.com/web/icons/leftbutton.svg";

    const delayedClose = () => {
        setTransitionClose(false);
        setTimeout(() => {props.exitCallback(false)}, 500)
        //exitCallback(false);
    };

    //we're going to remove this specific polling error anyways
    //useEffect(() => {
       // if(pollingError && !submitted) {
            //setError(pollingError)
       // }
   // }, [pollingError, submitted])

    const isOnline = useDetectNetwork();
    useEffect(() => {
        if(!isOnline){
            setError("No network connection")
        }
        if(isOnline && error === "No network connection") {
            setError("")
        }
    }, [isOnline])


    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef.current]);

    /* //Disabling polling. we are not using right now anyways. Too much noise.
    useEffect(() => {
        if(authState.matches('authenticated') && stateSteady) {
            if (pollingState.matches('idle') && !pollRunning && !success) {
                console.log("START POLLING in CONFIRM")
                startPolling(props?.secondaryUserEmailId)
            }
            if (pollingState.matches('success') && success) {
                track('User Email Address Added', {eventType: 'emailAddressAdded', entryType: props?.fromFeed ? 'card' : 'settings'})
                fetch3('feedRefresh')
                props.refreshCallback(true);
                //setModalType({modalType: ''});
               if (props.callback) {
                   props.callback(true)
               } 
               if (props.homeCallback) {
                   props.homeCallback(false)
               } 
               if (props.exitCallback) {
                   props.exitCallback(false)
               }
               setMobileKebab(false);
            }

        }
        console.log("Polling state: ", pollingState)
        console.log("State steady: ", stateSteady)
        console.log("AuthState: ", authState)
    }, [authState, stateSteady, pollingState, pollRunning])
*/
    useEffect(() => {
        screen('Add Email Address: Confirmation', {screenType: 'addEmailAddress', screenSubType: 'emailConfirmation', entryType: props?.fromFeed ? 'card' : 'settings'})
    }, []);

    useEffect(() => {
        if(state && code) {
            if(state.matches('idle') && !submitted && props.email) {
                //authService.send({ type: 'CONFIRM', code: code}) // we need to check this state... and display an error if it fails.
                fetch('confirmAddEmail', [props.email, code])
                setSubmitted(true);
                //setStep(2)
            }
            if(state.matches('success') && submitted) {
                setProgressOverride(100)
                if (props.homeCallback) {
                    props.homeCallback(false)
                }
                track('User Email Address Added', {eventType: 'emailAddressAdded', entryType: props?.fromFeed ? 'card' : 'settings'})
                fetch3('feedRefresh')

                props.refreshCallback(true);
                //setModalType({modalType: ''});
                if (props.callback) {
                    props.callback(true)
                }
                if (props.exitCallback) {
                   setTimeout(() => props.exitCallback(false), 500)
                }

                setMobileKebab(false);
                //setModalType({modalType: 'introAddEmail'})
            }
            if(state.matches('failure') && submitted) {
                setProgressOverride(100)
                let {canRetry, errMsg} = retryableError({errorBody: state.context.error})
                setError(errMsg)
                setSubmitted(false)
                setCode("")
                reset({
                    code: '',
                })
                resetFetch()
            }
            console.log(error)
        }
        //console.log("Confirm state output authstate: ", authState, "confirm state: ", state)

    }, [isReady, state, code, submitted, authState, props, error]);


    useEffect(() => {
        if(!authState.matches('authenticated')) {

            //need additional error notification.. "logged out" or something.

        }
        //resend code modal...
        if(state2.matches('success')) {
            setProgressOverride(100)
            //#342
            console.log("success on emails retrieval: ", state2.context.data)
            setShowResendModal(true)
            //show confirm else show error
            resetProgressBar()
            reset2()
        }
        if(state2.matches('failure')) {
            setProgressOverride(100)
            console.log("state2: ", state2)
            let {canRetry, errMsg} = retryableError({errorBody: state2.context.error})
            setError(errMsg);
            reset({
                code: '',
            })
            setResetKey(Date.now())
            setSubmitted(false);
            setCode(null);
            resetProgressBar()
            reset2()
        }

    }, [authState, state2, error]);



    const goBack = () => {
        //setStep(0);
        console.log("going back: ", props.email)
        track('Button Tapped', {buttonType: 'back', screenType: 'addEmailAddress', screenSubType: 'emailConfirmation'})
        //setModalType({modalType: 'addEmail', callback: props.callback, backEmail: props.email, exitCallback: props.exitCallback, refreshCallback: props.refreshCallback});
        if (windowWidth > 839) {
            setTransitionClose(false);
            props.exitCallback(false);
        } else {
            delayedClose();
        }
    }

    const onSubmit = (data) => {
        //#328
        track('Button Tapped', {buttonType: 'verifyEmail', screenType: 'addEmailAddress', screenSubType: 'emailConfirmation', entryType: props?.fromFeed ? 'card' : 'settings'})
        const code = data.code;
        //error code 3 - send back to registration page if registration not complete (needs_confirm)
        // we have 2 scenerios... 1 confirm user, 2 confirm register... -> to passwordform
        startProgress()
        setLastApiCallStarted(true)
        setCode(code);
    }
    const resendOnClick = (event) => {
        //#335
        track('Button Tapped', {buttonType: 'resentCode', screenType: 'addEmailAddress', screenSubType: 'emailConfirmation', entryType: props?.fromFeed ? 'card' : 'settings'})
        event.preventDefault();
        //fetch2('resendConfirm', [authState.context.userId, authState.context.emailId])

        let email = props.email;
        if(!email) {
            email = authState.context.emailLogin
        }
        startProgress()
        fetch2('addEmail', [props.email])
        setLastApiCallStarted(true)
    }

    const validateCode = (code) => {
        if(error) {
            setError("")
        }
        return true;
    }

    return (
        <>{progressStarted && <ProgressBar progressOverride={progressOverride} apiStarted={apiStarted} onCompleted={handleCompletion} setProgressOverride={setProgressOverride}/>}
        {windowWidth < 840 &&
        <Modal open={transitionClose} closeAfterTransition>
        <Slide direction="left" in={transitionClose}>
        <div className={styles["modal-container"]}>
            {showResendModal && <CodeResentModal callback={setShowResendModal} props={props} />}
            <div className={styles["modal-confirm-email-container"]}>
                <form method="post" onSubmit={handleSubmit(onSubmit)} className={styles["modal-confirm-email-form"]}>
                        <div className={styles["back-button"]}>
                            <img src={leftbutton} alt="back arrow" style={{cursor: "pointer"}} onClick={goBack}/>
                        </div>
                        <div className={styles["confirm-inner-container"]}>
                            <div className={styles["confirm-header"]}>
                                <div className={styles["confirm-header-text"]} style={{ overflowWrap: "unset", wordBreak: "unset", whiteSpace: "nowrap" }}>Enter the code we sent to</div><div className={styles["confirm-header-text"]} >{props.email}</div>
                            </div>
                            <div className={styles["confirm-input-fields"]}>
                                <Controller
                                    key={`code-${resetKey}`}  // Apply the key here
                                    control={control}
                                    name="code"
                                    defaultValue=""
                                    rules={{ required: true, minLength: 6, maxLength: 6, pattern: /^\d{6}$/, validate: validateCode}}
                                    render={({ field, fieldState }) => <Input2 field={field} restrict="numeric" maxLength={6} autoFocus="autofocus" autoComplete="off" label="Code" type="code" id="code" inputRef={inputRef} />}
                                />
                            </div>
                        </div>
                        <div className={styles["error"]}>{error}</div>


                    <div className={styles["button-div"]}> <Button isDisabled={!formState.isValid || progressStarted} buttonText="Verify my email" ></Button></div>
                            <div className={styles["confirm-bottom"]}>
                                <div className={styles["confirm-bottom-inner-container"]}>
                                    <div className={styles["confirm-bottom-text"]}>
                                        <a href="#" className="link" onClick={resendOnClick}>Resend Code</a>
                                    </div>
                                </div>
                        </div>
                </form>
            </div>
        </div>
    </Slide>
    </Modal>}
    {windowWidth > 839 && 
        <Modal open={transitionClose} closeAfterTransition>
            <Fade in={transitionClose}>
        <div className={styles["modal-container"]}>
            {showResendModal && <CodeResentModal callback={setShowResendModal} props={props} />}
            <div className={styles["modal-confirm-email-container"]}>
                <form method="post" onSubmit={handleSubmit(onSubmit)} className={styles["modal-confirm-email-form"]}>
                        <div className={styles["back-button"]}>
                            <img src={leftbutton} alt="back arrow" style={{cursor: "pointer"}} onClick={goBack}/>
                        </div>
                        <div className={styles["confirm-inner-container"]}>
                            <div className={styles["confirm-header"]}>
                                <div className={styles["confirm-header-text"]} style={{ overflowWrap: "unset", wordBreak: "unset", whiteSpace: "nowrap" }}>Enter the code we sent to</div><div className={styles["confirm-header-text"]} >{props.email}</div>
                            </div>
                            <div className={styles["confirm-input-fields"]}>
                                <Controller
                                    key={`code-${resetKey}`}  // Apply the key here
                                    control={control}
                                    name="code"
                                    defaultValue=""
                                    rules={{ required: true, validate: validateCode, minLength: 6, maxLength: 6, pattern: /^\d{6}$/ }}
                                    render={({ field, fieldState }) => <Input2 field={field} restrict="numeric" maxLength={6} autoFocus="autofocus" autoComplete="off" label="Code" type="code" id="code" inputRef={inputRef} />}
                                />
                            </div>
                        </div>
                        <div className={styles["error"]}>{error}</div>


                    <div className={styles["button-div"]}> <Button isDisabled={!formState.isValid || progressStarted} buttonText="Verify my email" ></Button></div>
                            <div className={styles["confirm-bottom"]}>
                                <div className={styles["confirm-bottom-inner-container"]}>
                                    <div className={styles["confirm-bottom-text"]}>
                                        <a href="#" className="link" onClick={resendOnClick}>Resend Code</a>
                                    </div>
                                </div>
                        </div>
                </form>
            </div>
        </div>
            </Fade>
    </Modal>}
            </>
    )
}

export default ConfirmEmailModal;

import styles from "../styles/scss/AppleSubscriptionModal.module.scss";
import React from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";



const AppleSubscriptionModal = ({ setShowAppleModal, props }) => {
    const [open, setOpen] = React.useState(props || false);

    const handleClose = () => {
        if(setShowAppleModal) {
            setShowAppleModal(false);
        }
        setOpen(false);
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition

            >
                <Fade in={open} timeout={500}>
                    <div className={styles["modal-screen-container"]}>
                        <div className={styles["modal-container"]}>
                            <div className={styles["modal"]}>
                                <div className={styles["modal-content"]}>
                                    <div className={styles["modal-title"]}>Your subscription is managed through Apple, and is only accessible on an iOS or Mac device.</div>
                                </div>
                            <div className={styles["button-Ok"]} onClick={handleClose}>
                                <div className={styles["modal-button-label"]}>OK</div>
                            </div>

                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    );
}


export default AppleSubscriptionModal;

import React, { useState, useEffect, useRef } from 'react';
import '../styles/scss/ThreatHistory.scss';
import { useAuth } from "../contexts/authContext";
import Card from "./Card";
import {useFetch} from "../hooks/useFetch";
import {useModal} from "../contexts/ModalContext";
import "../styles/scss/DaysSince.scss";
import { sortByDate, formatDate } from "./DaysSince";
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import {screen, track} from "../util/analytics";
import ProgressBar from "../components/ProgressBar";
import useProgressBar from "../hooks/useProgressBar";
import {cacheThreatHistoryImages} from "../util/imageCache";

const ThreatHistory = ({ parentState, changeState }) => {
    const {modalCount, setModalCount} = useModal();
    const { isAuthenticated, authState, isReady} = useAuth();
    const [historyCards, setHistoryCards] = useState(null);
    const {state, fetch, isLoading, uiErrorMsg, ready} = useFetch();
    const [bucketReady, setBucketReady] = useState(null);
    const history = true
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const height = bucketReady ? 
        {height: windowWidth > 839 ? '100%' : '100%'}
         : {alignItems: "center", paddingTop: "unset", height: windowWidth > 839 ? '100%' : '100%'};
    //const dynamic = {minHeight: windowWidth > 700 ? `unset` : `calc(${windowHeight}px - 110px)`};
    const { progressStarted, setNoDelay, noDelay, apiStarted, startProgress, setLastApiCallStarted, setProgressOverride, progressOverride, handleCompletion } = useProgressBar();
    const [transitionClose, setTransitionClose] = useState(false);
    const leftbutton = "https://img.mirkat.com/web/icons/leftbutton.svg";
    const breachesExitButton = "https://img.mirkat.com/web/images/home/breaches-exit-button.svg";
    const [escapeAllowed, setEscapeAllowed] = useState(null);
    const modalCountRef = useRef(modalCount);

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                //setEscapeAllowed(true);
                if (modalCountRef.current === 1) {
                    changeState(!parentState);
                }
            }
        };

        // Add event listener
        document.addEventListener('keydown', handleEscape);

        // Remove event listener on cleanup
        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, []);

    useEffect(() => {
        modalCountRef.current = modalCount;
    }, [modalCount]);

    const backgroundClose = (event) => {
        console.log("TARGETS COMPARED ", event.target, event.currentTarget);
        if (event.target === event.currentTarget) {
            changeState(!parentState);
        }
    };

    const delayedClose = () => {
        setTransitionClose(false);
        setTimeout(() => {changeState(!parentState)}, 500)
    };

    useEffect(() => {
        if (historyCards) {
            setTransitionClose(true);
        }
    }, [historyCards]);

    const scrollableContentRef = useRef(null);

    const handleScroll = () => {
        const element = scrollableContentRef.current;
        if (element) {
            const isBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
            if (isBottom) {
                console.log('Reached bottom of modal');
                //#128
                track('Feed End Reached', {eventType: 'feedEndReached', feedType: 'history'})
                // Perform actions when the bottom of the modal is reached
            }
        }
    };

    useEffect(() => {
        const scrollableElement = scrollableContentRef.current;
        if (scrollableElement) {
            scrollableElement.addEventListener('scroll', handleScroll);
        }

        // Cleanup the event listener when the component unmounts
        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);


    useEffect(() => {

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        if(ready && state.matches('idle')) {
	    fetch('history')
            startProgress()
            setNoDelay(true)
            setLastApiCallStarted()
        }
        if(state.matches('success')) {
            screen('History', {screenType: 'history', numberOfCards: state.context.data?.cards?.length});
            //state.context.data == response
	    console.log("HISTORY RESPONSE :",state.context.data)
            if(state.context.data && state.context.data.hasOwnProperty('cards')) {
                cacheThreatHistoryImages(state.context.data);
                setHistoryCards(state.context.data.cards)
                setProgressOverride(100);
            } else {
                setHistoryCards([]);
                setProgressOverride(100);
            }
        }
        if(state.matches('failure')) {
            console.log(state.context.error);
            setHistoryCards([]);
            setProgressOverride(100);
        }

    }, [state])
    useEffect(() => {
        const handleScroll = () => {
            const totalPageHeight = document.documentElement.scrollHeight;
            const scrollPoint = window.scrollY + window.innerHeight;
            const distanceToBottom = totalPageHeight - scrollPoint;

            // Check if the distance to bottom is within your threshold
            if (distanceToBottom < 100) { // 100px as threshold
                console.log("Reached the bottom!");
                //#128
                track('Feed End Reached', {eventType: 'feedEndReached', feedType: 'history'})
                window.removeEventListener('scroll', handleScroll);
            }
        };

        // Add event listener
        window.addEventListener('scroll', handleScroll);

        // Remove event listener on cleanup
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

//    const buckets = {
//        today: [],
//        yesterday: [],
//        pastWeek: [],
//        pastMonth: [],
//        pastYear: [],
//	pastYears: [],
//    };


    useEffect(() => {
      if (historyCards !== null) {
      console.log("historycards: ", historyCards);
//	historyCards.forEach((item) => {
//            const date = new Date(item.createdAt);
//            const now = new Date();
//	    console.log("DATE: ",date);
//  
//            const todayStart = new Date(now);
//            todayStart.setHours(0, 0, 0, 0);
//            const yesterdayStart = new Date(todayStart);
//            yesterdayStart.setDate(yesterdayStart.getDate() - 1);
//            const lastWeekStart = new Date(todayStart);
//            lastWeekStart.setDate(lastWeekStart.getDate() - 7);
//            const lastMonthStart = new Date(todayStart);
//            lastMonthStart.setMonth(lastMonthStart.getMonth() - 1);
//            const lastYearStart = new Date(todayStart);
//            lastYearStart.setFullYear(lastYearStart.getFullYear() - 1);
//            const pastYearsStart = new Date(todayStart);
//            pastYearsStart.setFullYear(lastYearStart.getFullYear() - 10);
//  
//            if (date >= todayStart) {
//              buckets.today.push(item);
//              console.log("today");
//            } else if (date >= yesterdayStart) {
//              buckets.yesterday.push(item);
//              console.log("two");
//            } else if (date > lastWeekStart) {
//              buckets.pastWeek.push(item);
//              console.log("three");
//            } else if (date > lastMonthStart) {
//              buckets.pastMonth.push(item);
//              console.log("four");
//            } else if (date > lastYearStart) {
//              buckets.pastYear.push(item);
//              console.log("five");
//            } else if (date > pastYearsStart) {
//              buckets.pastYears.push(item);
//              console.log("MANY");
//            }
//	});
      //setBucketReady(buckets);
      //const buckets = sortByDate(historyCards,'createdAt');
        const [buckets, timeMapping] = sortByDate(historyCards,'inHistoryAt');

        const tempArray = Object.entries(buckets);
        if (tempArray.length !== 0) {
          console.log("tempArray: ", tempArray);
          tempArray.sort((a,b) => {
              return timeMapping[a[0]] - timeMapping[b[0]];
          });

          const finalArray = Object.fromEntries(tempArray);
          console.log("finalArray: ", finalArray);

          setBucketReady(finalArray);
        }
      }
    }, [historyCards]);

	            //{isLoading && 
		    //    <div className="history-empty-container">
		    //        <p className="history-empty-top">Loading...</p>
		    //        <p className="history-empty-bottom">All the threats you’ve handled or dismissed can be found here.</p>
		    //    </div>
                    //}
    return (
       <>
        {progressStarted && <ProgressBar progressOverride={progressOverride} apiStarted={apiStarted} onCompleted={handleCompletion} setProgressOverride={setProgressOverride} noDelay={noDelay} />}

        {windowWidth < 840 && historyCards && 
        <Slide direction="left" in={transitionClose}>
            <div className="history" style={height} ref={scrollableContentRef}>
	    <div className="history-container" style={!bucketReady ? {height: "100%"} : {}}>
	    	<div className="history-header">
	    	    Threat History
	    	    {windowWidth > 840 && 
                        <img 
	    	            onClick={delayedClose} 
	    	            src={breachesExitButton} 
	    	            className="history-exit-button"
	    	        />
                    }
	    	    {windowWidth < 840 && 
                        <img 
	    	            onClick={delayedClose} 
	    	            src={leftbutton} 
	    	            className="history-exit-button"
	    	        />
                    }
	    	    <div className="history-divider"/>
	    	</div>
	    	<div className="history-feed-container">	
	    	    <div className="history-feed" >
	            {bucketReady && Object.keys(bucketReady).map((bucket) => (
			    <><div className="dayssince-date">{bucket}</div>
			    {bucketReady[bucket].length !== 0 && bucketReady[bucket].map((item,index) => (
			    <div>
	                    <Card 
	                        key={index}
	                        id={item.id}
	                        title={item.title}
	                        exposedDataTitle={item.exposedDataTitle}
	                        description={item.description}
	                        bannerImageUrl={item.bannerImageUrl}
	                        cardData={item.cardData}
	                        actions={item.actions}
	                        feedCard={item}
				            history={history}
                            riskType={item.rType}
                            riskCategoryType={item.rcType}
                            completedType={(item?.completedAt ? 'handled' : (item?.dismissedAt ? 'skipped' : '' )) }
                            entityId={item?.entityId || ''}
                            feedPosition={item?.order + 1}
                            status={item?.state}
                            windowWidth={windowWidth}
	                    /></div>
			    ))}</>
		    ))}
	    	    {!bucketReady &&
			<div className="history-empty-container">
			    <p className="history-empty-top">No threats</p>
			    <p className="history-empty-bottom">All the threats you’ve handled or dismissed can be found here.</p>
			</div>
		    }
	    	    </div>
	        </div>
	    </div>
            </div>
        </Slide>}
        
        {windowWidth > 839 && 
	<Fade in={parentState} timeout={500}><div className="history" style={height} ref={scrollableContentRef} onClick={backgroundClose}>
          {historyCards && 
          <Fade in={parentState} timeout={500}>
	    <div className="history-container" >
	    	<div className="history-header">
	    	    Threat History
	    	    {windowWidth > 839 && 
                        <img 
	    	            onClick={() => {changeState(!parentState);}} 
	    	            src={breachesExitButton} 
	    	            className="history-exit-button"
	    	        />
                    }
	    	    {windowWidth < 839 && 
                        <img 
	    	            onClick={() => {changeState(!parentState);}} 
	    	            src={leftbutton} 
	    	            className="history-exit-button"
	    	        />
                    }
	    	    <div className="history-divider"/>
	    	</div>
	    	<div className="history-feed-container">	
	    	    <div className="history-feed" >
	            {bucketReady && Object.keys(bucketReady).map((bucket) => (
			    <><div className="dayssince-date">{bucket}</div>
			    {bucketReady[bucket].length !== 0 && bucketReady[bucket].map((item,index) => (
			    <div>
	                    <Card 
	                        key={index}
	                        id={item.id}
	                        title={item.title}
	                        exposedDataTitle={item.exposedDataTitle}
	                        description={item.description}
	                        bannerImageUrl={item.bannerImageUrl}
	                        cardData={item.cardData}
	                        actions={item.actions}
	                        feedCard={item}
				            history={history}
                            riskType={item.rType}
                            riskCategoryType={item.rcType}
                            completedType={(item?.completedAt ? 'handled' : (item?.dismissedAt ? 'skipped' : '' )) }
                            entityId={item?.entityId || ''}
                            feedPosition={item?.order + 1}
                            status={item?.state}
                            windowWidth={windowWidth}
	                    /></div>
			    ))}</>
		    ))}
	    	    {!bucketReady &&
			<div className="history-empty-container">
			    <p className="history-empty-top">No threats</p>
			    <p className="history-empty-bottom">All the threats you’ve handled or dismissed can be found here.</p>
			</div>
		    }
	    	    </div>
	        </div>
	    </div>
          </Fade>}
	</div></Fade>}
    </>
    );
}

export default ThreatHistory;

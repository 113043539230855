import React from "react";
import PasswordResetRequest from "../components/PasswordResetRequest";
import "../styles/scss/Login.scss";
const PasswordResetRequestPage = () => {
    return (
        <div className="col-12">
            <div className="onBoarding">
                <div className="login-row">
                    <div className="login">
                        <PasswordResetRequest />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PasswordResetRequestPage;
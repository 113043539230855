import {createMachine, assign, sendParent} from 'xstate';
import MirkatClient from "../services/mirkatClient";
import AuthStorageService from "../services/authStorage";
import {BaseClientParams} from "../util/clientParams";
import {createDataFetcher} from "../util/fetchFactory";
import {ErrorMachine} from "./errorMachine";
import {updateClient} from "../util/updateClient";
import {track} from "../util/analytics";

const maxRetries = 3;


const client = new MirkatClient(BaseClientParams);

const setPw = (context, event) => {
    return new Promise((resolve, reject) => {
        console.log("setPw context: ", context)
        const event = {method: 'password', params: [context.eventData.data]}
        const dataFetcher = createDataFetcher(client);
        dataFetcher(context, event).then((data) => {
            //#19
            track("User Signed Up", {eventType: "userSignedUp"})
            resolve(data)
        }, (error) => {
            reject(error);
        });
    })
}

export const SetPwMachine = createMachine({
    id: 'setPw',
    initial: 'setPw',
    context: {
        apiClient: null,
        eventData: null,
        error: false,
        retries: 0,
    },

    states: {
        setPw: {
            invoke: {
                id: 'setPw',
                src: (context, event) => setPw(context),
                onDone: {
                    target: 'done',
                    actions: [
                        assign({
                            //data: (context, event) => event.data,
                            errorData: null,
                            error: false,
                            retries: 0,
                            data: (context, event) => event.data,
                        }), sendParent((context) => ({type: 'pwSet', data: context.data})),
                    ],
                },
                onError: {
                    target: 'fixerror',
                    actions: [(context,event) => console.log("FIX ERROR?: ", context, event), assign({
                        error: true,
                        errorData: (context, event) => event?.data || event?.error,
                        retries: (context) => context.retries + 1,
                    })]
                }
            },
        },
        fixerror: {
            entry: (context) => {
                console.log("fixerror");
                console.log(context)
            },
            invoke: {
                src: (context) => ErrorMachine.withContext(context),
                data: {
                    apiClient: (context) => context.apiClient,
                    errorData: (context) => context.errorData,
                    retries: (context) => context.retries,
                },
                onDone: {
                    actions: [(event) => console.log("event2: ", event), assign({
                        data: (context, event) => event.data,
                    }), sendParent((context, event) => ({type: 'ERROR', errorData: context.errorData || event?.data}))]
                },
                onError: {
                    actions: sendParent((context, event) => ({type: 'ERROR', errorData: context.errorData || event?.data}))
                }
            },
            on: {
                UPDATEDCSRF: {
                    actions: [assign({
                        csrf: (context, event) => event.csrf,
                        secret: (context, event) => event.secret,
                    }), sendParent((context) => ({type: 'UPDATEDCSRF', csrf: context.csrf, secret: context.secret})),
                        (context) => updateClient(context), (context) => {
                        console.log("context updated csrf and secret: ", context)
                            console.log("api client should be updated too: ", context.apiClient)
                        }],
                    target: 'setPw',
                },
                GOLOGIN: {
                    target: 'done',
                    actions: [() => console.log("Sigh"), assign({errorData: (context, event) => event.errorData}),
                        sendParent((context) => ({type: 'GOLOGIN', data: context.errorData}))] //Not autohandling, passing back up to AuthMachine
                },
                CONFIRM: {
                    target: 'error',
                    actions: [() => console.log("CONFIRM CODE 37"), assign({errorData: (context, event) => event.errorData}),
                        sendParent((context) => ({type: 'CONFIRM', data: context.errorData}))] //Not autohandling, passing back up to AuthMachine
                },
                ERROR: {
                    target: 'error',
                    actions: [() => console.log("error machine returned error"), sendParent((context, event) => ({
                        type: 'ERROR',
                        errorData: context.errorData || event?.data
                    })), (context) => console.log(context)],
                },
            },
        },
        done: {
            type: 'final',
        },
        retry: {
            on: {
                '': [
                    {
                        id: 'retry',
                        target: 'setPw',
                        cond: 'shouldRetry'
                    },
                    {target: 'error'}]
            }
        },

        //type final cannot send to parent so we do it here
        sendError: {
            actions: [
                sendParent((context) => ({type: 'ERROR', errorData: context.errorData}))
            ],
            target: 'error'
        },
        error: {
            type: 'final',
        }
    },
}, {
    guards: {
        shouldRetry: (context, event) => context.retries < maxRetries
    },
});

//This is not in use at the moment. Work in progress

//Per ross on 2023-04-26 - Do not expose the message strings in production
//need build option to strip them

class ClientErrors {
    constructor() {
        this.errorsMap = new Map(this.codes);
        this.default = {"message": "An unexpected error occurred",
            "uiMsg": "An unexpected error occurred",
            "canRetry": true,
            "hasErrorBody": false};
    }

    getError(errorCode) {
        return this.errorsMap.get(errorCode) || this.default;
    }

    //uiMsg is for the user, message is for the console
    //canRetry is for us - can we retry the client without user involvement
    codes = [
        [1, {"message": "When adding a primary user to an installation over and over, this means the " +
                "user/installation is blocked from generating another confirmation code",
            "uiMsg": "Too many confirmation codes have been requested. Please try again later.",
            "canRetry": false, //Depends on errorBody
            "hasErrorBody": true}],

        [2, {"message": "This error is when you are sending in a confirmation code for a primary or secondary " +
                "account, but the code is wrong more than 3 times",

            "uiMsg": "Too many confirmation attempts have been made. Please try again later.",
            "canRetry": false, // Depends on errorBody
            "hasErrorBody": true}],

        [3, {"message": "Invalid session, this is not a session expired error. This means the session had bad " +
                "parameters stored or something else is wrong with the session (installation or email).",
            "uiMsg": "An unexpected error occurred", //we should handle this error transparently. Error handling could be to delete installation and/or email key and login/verify
            "canRetry": false, //only with an email session
            "hasErrorBody": false}],

        [4, {"message": "Invalid Parameters. So at least one of the parameters is invalid, or a required parameter " +
                "is missing",
            "uiMsg": "Invalid Request",
            "canRetry": false,
            "hasErrorBody": true}],

        [5, {"message": "Expired session, so this is only for email sessions",
            "uiMsg": "Session expired, please login again",
            "canRetry": false, //Call refresh first, then retry
            "hasErrorBody": false}],

        [6, {"message": "Unauthorized, this usually means the route requires an 'Authorization' header, and it " +
                "wasn't sent",
            "uiMsg": "Unauthorized",
            "canRetry": false, //Requires authorization header
            "hasErrorBody": false}],

        [7, {"message": "Invalid email address",
            "uiMsg": "Invalid email address",
            "canRetry": false,
            "hasErrorBody": false}],

        [8, {"message": "Incorrect confirmation code, this could be for a primary or secondary email.",
            "uiMsg": "Oops! That didn't work.  Please enter the verification code we sent to your email.",
            "canRetry": false, //try to call the POST route that generates a new code, then have them try to confirm
            "hasErrorBody": false}],

        [9, {"message": "Unknown Installation, so here the installation either isn't in the db, or, it isn't " +
                "associated with the parameters sent in",
            "uiMsg": "An unexpected error occurred.",
            "canRetry": false,
            "hasErrorBody": false}],

        [10, {"message": "Action not found. This is when you are POSTing a completed action and the actionId is " +
                "either not in the db, or it doesn't belong to the postId sent in",

            "uiMsg": "An unexpected error occurred.",
            "canRetry": false,
            "hasErrorBody": false}],

        [11, {"message": "Invalid confirmation token. this is a regex failure, so the code isn't 6 digits between " +
                "000000 and 999999",
            "uiMsg": "Oops! That didn't work. Please enter the verification code we sent to your email.",
            "canRetry": false,
            "hasErrorBody": true}],

        [12, {"message": "Session creation error. So an installation or email session failed to generate, something " +
                "failed",
            "uiMsg": "An unexpected error occurred. Please try again.",
            "canRetry": true,
            "hasErrorBody": false}],

        [13, {"message": "Obfuscation Error. So here when obfuscating an id, or deobfuscating an id, an error was " +
                "thrown",
            "uiMsg": "An unexpected error occurred.",
            "canRetry": true,
            "hasErrorBody": false}],

        [14, {"message": "Max confirmed secondary emails reached. So here the installation already has the max number" +
                " of confirmed secondary emails",
            "uiMsg": "You have reached the maximum number of confirmed email addresses.",
            "canRetry": false,
            "hasErrorBody": false}],

        [15, {"message": "Max unverified emails limit reached. You can only add so many unconfirmed emails, don't add" +
                " any more until they confirm one.",
            "uiMsg": "You have reached the maximum number of unconfirmed email addresses. Please try again later.",
            "canRetry": false,
            "hasErrorBody": false}],

        [16, {"message": "Primary user not found. This could happen when you are trying to add a secondary email to " +
                "an installation but the primary user sent in the request doesn't exist as a primary for this " +
                "installation",
            "uiMsg": "Primary user not found.",
            "canRetry": false,
            "hasErrorBody": false}],

        [17, {"message": "Confirmation code generation error, so here when generating the 6 digit code, something " +
                "went wrong",
            "uiMsg": "An unexpected error occurred. Please try again later.",
            "canRetry": true, // we need to retry up to 3 times
            "hasErrorBody": false}],

        [18, {"message": "Installation already exists",
            "uiMsg": "An unexpected error occurred.",
            "canRetry": false,
            "hasErrorBody": false}],

        [19, {"message": "Notification not found, so this is when saying a notification has been sent, but that " +
                "notification either isn't in the db, or it doesn't match the user",
            "uiMsg": "An unexpected error occurred.",
            "canRetry": false,
            "hasErrorBody": false}],

        [20, {"message": "Question not found, when marking a question as answered, if the questionId isn't in the db " +
                "or doesn't match the postId sent in.",
            "uiMsg": "An unexpected error occurred.",
            "canRetry": false,
            "hasErrorBody": false}],

        [21, {"message": "Email not found. When confirming a primary email, the primaryEmailAddress sent in wasn't " +
                "found in the db. This could also be returned when deleting an email from an installation if the " +
                "email address doesn't belong to the installation. Finally, if you are trying to refresh an email " +
                "session, but the email has been deleted, you'll get this error.",
            "uiMsg": "An unexpected error occurred.",
            "canRetry": false,
            "hasErrorBody": false}],

        [22, {"message": "When confirming a secondary email, the combination of user, email, and confirmation token " +
                "returned 0 rows. So either the email/user don't go together and/or the confirmation token wasn't " +
                "correct for that email/user",
            "uiMsg": "Invalid confirmation token",
            "canRetry": false,
            "hasErrorBody": false}],

        [23, {"message": "Secondary email is the primary email for this installation. Happens when adding a secondary" +
                " email to an installation",
            "uiMsg": "You are already monitoring this email address.",
            "canRetry": false,
            "hasErrorBody": false}],

        [24, {"message": "Secondary email already exists. Happens when adding a secondary email to an installation",
            "uiMsg": "Email already exists.",
            "canRetry": false,
            "hasErrorBody": false}],

        [25, {"message": "Confirming a primary email address failed because the email is not associated with the" +
                " userId sent in",
            "uiMsg": "An unexpected error occurred.",
            "canRetry": false,
            "hasErrorBody": false}],

        [26, {"message": "Confirming a primary email failed, so either you already confirmed, or the query that " +
                "checks the user/email/code is returning 0 rows. Either way there is nothing to confirm in this case",
            "uiMsg": "Email confirmation failed.",
            "canRetry": false,
            "hasErrorBody": false}],

        [27, {"message": "When adding the same secondary user to an installation over and over (or resending email)," +
                " this means the email is blocked from generating another confirmation code",
            "uiMsg": "Too many confirmation codes have been requested. Please try again later",
            "canRetry": false,
            "hasErrorBody": true}],

        [28, {"message": "Unexpected Error",
            "uiMsg": "An unexpected error occurred.",
            "canRetry": true,
            "hasErrorBody": false}],

        [29, {"message": "Page not found (404)",
            "uiMsg": "Resource not found.",
            "canRetry": false,
            "hasErrorBody": false}],

        [30, {"message": "Can't delete a primary email, so this is when you're doing a DELETE /v1/emails/settings, " +
                "and the email you're trying to delete is in fact your primary email.",
            "uiMsg": "Cannot delete primary email.",
            "canRetry": false,
            "hasErrorBody": false}],

        [31, {"message": "Your installation session isn't valid or has been invalidated. You need to reconfirm the " +
                "installation",
            "uiMsg": "Email needs to be confirmed again.",
            "canRetry": false,
            "hasErrorBody": false}],

        [32, {"message": "You're trying to call an unconfirmed installation endpoint using an installation that has " +
                "been confirmed",
            "uiMsg": "An unexpected error occurred.", //supposedly this is for /v1/installations
            "canRetry": false,
            "hasErrorBody": false}],

        [33, {"message": "You sent in an installationId and/or installationGuid that has been confirmed to a route " +
                "that is specifically for an unconfirmed installation. So basically you are calling the wrong route. " +
                "Currently you can only get this if you send in an installationId/installationGuid that is confirmed " +
                "to the GET /v1/installations/notifications route.",
            "uiMsg": "An unexpected error occurred.",
            "canRetry": false,
            "hasErrorBody": false}],

        [34, {"message": "Invalid CSRF token",
            "uiMsg": "An unexpected error occurred. Please try again.",
            "canRetry": false, //get a new one
            "hasErrorBody": false}],

        [35, {"message": "weak password",
            "uiMsg": "Password is too weak.",
            "canRetry": false,
            "hasErrorBody": false}], //should add checks in the UI to prevent this


        [36, {"message": "Invalid Password was provided when logging in",
            "uiMsg": "Your password is incorrect.",
            "canRetry": false,
            "hasErrorBody": false}],

        [37, {"message": "This is when the user tries to set a password through sign up, but does not have a valid " +
                "password session. This should never happen through the UI.",
            "uiMsg": "An unexpected error occurred.",
            "canRetry": false,
            "hasErrorBody": false}],

        [38, {"message": "Installation not confirmed. This is checked if someone tries to set a password for an " +
                "unconfirmed user.",
            "uiMsg": "Email must be confirmed before setting a password.",
            "canRetry": false,
            "hasErrorBody": false}],

        [39, {"message": "Email already exists",
            "uiMsg": "This user already exists, try signing in instead.",
            "canRetry": false,
            "hasErrorBody": false}], //no meaning, no errorBody - Should relogin

        [40, {"message": "Password Attempt limit reached",
            "uiMsg": "Password attempt limit reached. Please try again later.",
            "canRetry": false,
            "hasErrorBody": true}],

        [41, {"message": "User does not exist. This error is mainly used by login and reset password when a user " +
                "associated with an email doesn't exist.",
            "uiMsg": "User does not exist, try creating an account instead.",
            "canRetry": false,
            "hasErrorBody": false}],

        [42, {"message": "Password too similar. This is currently not being used.",
            "uiMsg": "An unexpected error occurred.",
            "canRetry": false,
            "hasErrorBody": false}],

        [43, {"message": "Reset link limit reached. This means that we have sent too many reset email links.",
            "uiMsg": "Too many password resets requested, try again later",
            "canRetry": false,
            "hasErrorBody": true}],

        [44, {"message": "Invalid Init Session. This means that the session token is either expired or invalid.",
            "uiMsg": "An unexpected error occurred. Please retry.",
            "canRetry": false,
            "hasErrorBody": false}],

        [45, {"message": "Post Not Found. This mean that the API could not find the post for the give post id and the" +
                " locale of the user.",
            "uiMsg": "Post not found.",
            "canRetry": false,
            "hasErrorBody": false}],

        [46, {"message": "Breaches API error when trying to sync user data from dynamo to the mirkat SQL database.",
            "uiMsg": "An unexpected error occurred. Please retry.",
            "canRetry": true,
            "hasErrorBody": false}],

        [47, {"message": "Aggregation API when generating posts for an user.",
            "uiMsg": "An unexpected error occurred. Please retry.",
            "canRetry": true,
            "hasErrorBody": false}],

        [48, {"message": "The password the user entered is in the bad password list",
            "uiMsg": "This password is known to hackers. Please create another password.",
            "canRetry": false,
            "hasErrorBody": false}],

        [49, {"message": "The confirmation code expired",
            "uiMsg": "Confirmation code expired. Please click Resend code.",
            "canRetry": false,
            "hasErrorBody": false}],

        [50, {"message": "The reset password email failed to send",
            "uiMsg": "An unexpected error occurred",
            "canRetry": true,
            "hasErrorBody": false}],

        [51, {"message": "Notification API Error",
            "uiMsg": "An unexpected error occurred",
            "canRetry": false,
            "hasErrorBody": false}],

        [52, {"message": "Notification API Registration Error",
            "uiMsg": "An unexpected error occurred",
            "canRetry": false,
            "hasErrorBody": false}],

        [53, {"message": "The app version is too old (compared to the MINIMUM_APP_VERSION environment variable)",
            "uiMsg": "An unexpected error occurred.",
            "canRetry": true,
            "hasErrorBody": true}],

        [54, {"message": "Password reset token expired",
            "uiMsg": "Password reset token expired. Please request a new one.",
            "canRetry": false,
            "hasErrorBody": false}],

        [55, {"message": "This means that the current installation and/ device token is invalid and the user must " +
                "re-install the app if they want to be able to receive notifications. Note in a mirkat sync meeting " +
                "we decided to silently turn notifications off vs prompting the user to reinstall.  This is subject " +
                "to change but that is the current plan.",
            "uiMsg": "An unexpected error occurred.",
            "canRetry": false,
            "hasErrorBody": false}],

        [56, {"message": "A free (unpaid) user attempted to do something that is only available for mirkat-plus users",
            "uiMsg": "Please upgrade to Mirkat+ to use this feature.",
            "canRetry": false,
            "hasErrorBody": false}],

        [57, {"message": "The mobile installation cookie is missing or is invalid",
            "uiMsg": "An unexpected error occurred.",
            "canRetry": false,
            "hasErrorBody": false}],

        [58, {"message": "Password reset required to login",
            "uiMsg": "Reset your password using the forgot password link.",
            "canRetry": false,
            "hasErrorBody": false}],
    ];

    //move this to more generic utils
    dateIsLessThanCurrent = (someDate) => {
        //2018-09-07 18:14:57
        const dateObject = new Date(someDate); //no timezone means UTC from the API
        const currentDate = new Date().getUTCDate()
        if (currentDate < dateObject) {
            return true;
        }

        return true;
    }

    //handle error:
    //if we can retry, then retry
    //else -> redirect to login

    //take a json object and parse it: someobject.hasOwnProperty('

    //test if field exists:

    //return true/false for retry?
    async parseError(body) {
        if(!body.hasOwnProperty('errorCode')) {
            //Not an error response from the api
            return;
        }


        //switch case error:
        switch (body.errorCode) {
            //errorCode: 1
            case 1:
                //keys: next -> UTC
                //Should have errorBody
                //Please do not retry if the current time is < next
                if (body.hasOwnProperty('nextAvailableConfirmTime')) {
                    if (!this.dateIsLessThanCurrent(body.hasOwnProperty('nextAvailableConfirmTime'))) {
                        //if date is less than current, retry should be false... with a caveat that it can be retried AFTER listed date
                        return;
                    }
                }
                if(body.errorBody.hasOwnProperty('codesLeft')) {
                    if (body.errorBody.errorBody.get('codesLeft') > 1) {
                        //we can retry
                    } else {
                        //no retry
                    }

                }
                return;
            case 2:
                //Should have errorBody
                //keys: next
                //do not retry if current UTC is < next //Too many attempts. Please try again later.

            case 3:
                //No errorBody -> call refresh for session, if it happens again, authState to error.

            case 4:


            case 9:
                //unknown installation, remove installation id and emailid.

            case 10: case 14: case 15: case 16:
                        //do not retry

            case 17:
                //retry 3 times - confirmation code generation

            case 34: case 44:
                //call updatecsrf - if repeat 44 - delete installation id and emailid on localstorage


            default:
                break;


        }
    }

}

export const ErrorToConsole = (error) => {
    const errmap = this.ClientErrors

    if (error.hasOwnProperty('errorCode')) {
        console.log(error.errorCode);
    }
    if (error.hasOwnProperty('errorBody')) {
        console.log(error.errorBody);
    }
    if (error.hasOwnProperty('message')) {
        console.log(error.message);
    }
    if (error.hasOwnProperty('canRetry')) {
        console.log(error.canRetry);
    }
}

export default ClientErrors;

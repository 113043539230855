import { useEffect, useState, useRef } from "react";


const useScrollFix = ({showSettings, threatState, showFeed, showUpgrade, addEmailOpen, modalCount, setModalCount}) => {
    const isFirstRun = useRef(true);
    
    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            console.log("IS THIS FIRST RUN?!?!");
            return;
        } else if (showSettings || threatState || showFeed || showUpgrade || addEmailOpen || showFeed) {
            console.log("ADDEMAILOPEN STATUS: ", addEmailOpen);
            setModalCount(prevCount => prevCount + 1);
        } else {
            setModalCount(prevCount => prevCount - 1);
        }
    }, [showSettings, threatState, showFeed, showUpgrade, addEmailOpen, showFeed]);

    useEffect(() => {
        console.log("STATUS: ",modalCount);
        if (modalCount > 0) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [modalCount]);

    }
export default useScrollFix;

import React, {useState, useEffect, useRef} from "react";
import styles from "../styles/scss/Scanning.module.scss";
import {useNavigate} from "react-router-dom";
import Lottie from 'lottie-react';
//import animationOne from '../assets/animations/animationOne.json';
import {useAuth} from "../contexts/authContext";
import AuthStorageService from "../services/authStorage";
import fetchWithRetry from "../util/simpleFetch.js";
import {preloadTestRiskImages} from "../util/imageCache";

const storage = new AuthStorageService();


const DeepLink = () => {
    const {authService, authState, stateSteady} = useAuth();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const dynamic = {height: windowWidth > 500 ? "unset" : windowHeight}
    const [isStopped, setIsStopped] = useState(false);
    const style = {width: windowWidth > 500 ? "330px" : "100%"}
    const navigate = useNavigate();
    const freeCrumb = storage.getFreeReplayBreadCrumb();
    const [animation, setAnimation] = useState(null);
    const url = 'https://img.mirkat.com/web/animations/animationOne.json'

    useEffect(() => {
        preloadTestRiskImages()
        fetchWithRetry(url)
            .then(response => response.json())
            .then(data => {
                setAnimation(data);
            })
            .catch(error => {
                console.error('failed to get loading animation from bucket', error);
            });
    }, []);

    useEffect(() => {
        if(stateSteady && !authState.matches('authenticated')) {
            sessionStorage.removeItem("scanningReturnBreadCrumb");
            navigate("/login")
        }
    }, [authState, stateSteady]);


    useEffect(() => {
        storage.removeOnboardingBreadCrumb('t') //true
        sessionStorage.setItem("scanningReturnBreadCrumb", "scanning");
    }, []);

    useEffect(() => {
        console.log("FREECRUMB: ", freeCrumb);
        if (freeCrumb === "true") {
            sessionStorage.removeItem("scanningReturnBreadCrumb");
            navigate("/");
        }
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    //useEffect(() => {
    //    //setTimeout(() => navigate("/"), 8500);
    //    setTimeout(() => setIsStopped(true), 8500);
    //    console.log("timeout starts NOW");
    //}, []);

    //useEffect(() => {
    //    if (isStopped) {
    //        authService.send("DISABLE_PROGRESS_BAR");
    //        navigate("/");
    //        storage.setFreeReplayBreadCrumb("true");
    //    }
    //}, [isStopped]);
    const proceedHome = () => {
        setIsStopped(true);
        authService.send("DISABLE_PROGRESS_BAR");
        storage.setFreeReplayBreadCrumb("true");
        sessionStorage.removeItem("scanningReturnBreadCrumb");
        navigate("/");
    };

            //<Lottie isStopped={isStopped} width={windowWidth > 500 ? "330px" : "100%"} options={defaultOptions} onClick={doNothing}/>
    return (
        <div className={styles["main-container"]} style={dynamic} >
            <Lottie 
                animationData={animation}
                isStopped={isStopped}
                style={style}
                loop={false}
                autoplay={true}
                rendererSettings={{
                    preserveAspectRatio: 'xMidYMid slice'
                }}
                onComplete={proceedHome}
            />
        </div>
    );
}

export default DeepLink;

import React, {useState, useEffect} from 'react';
import MobileSidebarCheckmark from "../svg/MobileSidebarCheckmark";
import useIsWindows from "../hooks/useIsWindows";

                       //<div className="bbar-header-text-right">Mirkat+</div>
const PaidBottomBar = ({ animation, changeState, parentState }) => {
    const passwordd = "https://img.mirkat.com/web/images/home/passwordd.svg";
    const accountd = "https://img.mirkat.com/web/images/home/accountd.svg";
    const privated = "https://img.mirkat.com/web/images/home/privated.svg";
    const creditd = "https://img.mirkat.com/web/images/home/creditd.svg";
    const bankingd = "https://img.mirkat.com/web/images/home/bankingd.svg";
    const identityd = "https://img.mirkat.com/web/images/home/identityd.svg";
    //const illustrationBimage = "https://img.mirkat.com/web/images/home/illustrationb.svg";
    const monitoringSpinner= "https://img.mirkat.com/web/images/home/spinner.png";
    const [isWindows, isMac] = useIsWindows();

               //<div className="bbar-illustration-container"><img className="bbar-illustration" src={illustrationBimage} /></div>
    return (
       <div className={`bbar-container ${animation ? 'slide-up' : ''}`} style={{ position: 'relative'}}>
           <div className="bbar-top">
            
        <section className="monitoring content dark">
          <div className="container">
            <div className="visual">
              <div className="particles">
                {[...Array(120)].map((x, i) =>
                  <div className="particle" key={i}></div>
                )}
              </div>
              <div className="spinner">
                <img src={monitoringSpinner} alt="Dark scan scanner"></img>
              </div>
            </div>
          </div>
        </section>

               <div className="bbar-header">
                   <div className="bbar-header-text">
                       <div className="bbar-header-text-left">You have</div>
                        {isWindows ? 
	                    <div className="sidebar-header-status" style={{padding: "7px 14px 7px 14px"}}>Mirkat+</div>
                        : isMac ?
                            <div className="sidebar-header-status" style={{padding: "8.5px 14px 5.5px 14px"}}>Mirkat+</div>
                          :
                            <div className="sidebar-header-status" >Mirkat+</div>
                        }
                   </div>
               </div>
           </div>
           <div className="bbar-items-container">
                   <div className="bbar-items-row">
                       <div className="bbar-item-container">
                           <img src={passwordd} className="bbar-item" />
                           <div className="bbar-item-header">Exposed Passwords</div>
                       </div>
                       <div className="bbar-item-container">
                           <img src={accountd} className="bbar-item" />
                           <div className="bbar-item-header">Account Credentials</div>
                       </div>
                       <div className="bbar-item-container">
                           <img src={privated} className="bbar-item" />
                           <div className="bbar-item-header">Private Accounts</div>
                       </div>
                   </div>
                   <div className="bbar-items-row">
                       <div className="bbar-item-container">
                           <img src={creditd} className="bbar-item" />
                           <div className="bbar-item-header">Credit & Debit Cards</div>
                       </div>
                       <div className="bbar-item-container">
                           <img src={bankingd} className="bbar-item" />
                           <div className="bbar-item-header">Banking Accounts</div>
                       </div>
                       <div className="bbar-item-container" >
                           <img src={identityd} className="bbar-item" />
                           <div className="bbar-item-header" style={{ justifyContent: "center" }}>Identity<br /> Theft</div>
                       </div>
                   </div>
           </div>
	    <div className="sidebar-footer-container" style={{ padding: "20px 30px 44px 30px", alignItems: "center" }}>
	    	<div className="sidebar-checkmark" style={{ left: "calc(50% - 18px)" }}><MobileSidebarCheckmark /></div>
	    	<div className="sidebar-footer" style={{ padding: "29px 15px 15px 15px", maxWidth: "440px", minWidth: "360px", alignSelf: "unset" }}>
	    	    <div className="sidebar-footer-text" style={{ alignItems: "center", fontSize: "18px", lineHeight: "24px" }}>
	    	    	You have maximum protection
	    	    	<span><span className="sidebar-footer-text-mirkat" style={{ fontSize: "17px", lineHeight: "22px" }}>Mirkat+</span><span className="sidebar-footer-text-bottom" style={{ fontSize: "17px", lineHeight: "22px" }}> will continuously scan the dark web and notify you of any threats.</span></span>
	    	    </div>
	        </div>
	    </div>
       </div>
    );
};

export default PaidBottomBar;

import React, {useEffect} from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import styles from "../styles/scss/ErrorModal.module.scss";
import {track} from "../util/analytics";

const SessionExpiredModal = ({props, screen}) =>  {
    const [open, setOpen] = React.useState(props || false);

    useEffect(() => {
        let screenType = 'SessionExpiredModal'
        if(screen) {
            screenType = screen
        }
        //track('Session', {eventType: 'sessionExpiredModal', screenType: screenType});
    }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
            >
                <Fade in={open}>
                    <div className={styles["modal-screen-container"]}>
                        <div className={styles["modal-container"]}>
                            <div className={styles["modal"]}>
                                <div className={styles["modal-content"]}>
                                    <div className={styles["modal-title"]}>Your session has expired</div>
                                    <div className={styles["modal-description"]}>Please login.</div>

                                    <div className={styles["action-container"]}>
                                        <div className={styles["button-Ok"]} onClick={handleClose}>
                                            <div className={styles["modal-button-label"]}>OK</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default SessionExpiredModal;

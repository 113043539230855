import React from "react";
const safeSrc= "https://img.mirkat.com/web/icons/checkmark.svg";


const SafeIcon = () => {
    return (
        <div className="icon-wrapper-safe">
            <img src={safeSrc} className="icon-safe" />
        </div>
    );
}

export default SafeIcon;

import {assign, sendParent, createMachine} from 'xstate';
import {parseMirkatAuthData} from "../util/mirkatAuth";
import {createDataFetcher} from "../util/fetchFactory";

const maxRetries = 3;

const refreshingToken = (context) => (callback, onReceive) => {
    const event = {method: 'refresh'}
    const authState = {context: context.authData};
    const dataFetcher = createDataFetcher(context.apiClient, null, authState);
    dataFetcher(context, event).then((data) => {
        //data returned is a string. we need to parse it
        let parsedData = parseMirkatAuthData(data);
        if (data.hasOwnProperty('accessKey')) {
            let authData = parseMirkatAuthData(data);
            callback({type: 'DONE', data: authData})
        } else {
            callback({type: 'ERROR', data: data})
        }
        // do we want this here?
        callback({type: 'DONE', data: parsedData});
    }, (error) => {
        if(error.hasOwnProperty('errorCode') && error.errorCode === 34) {
            callback({type: 'CSRF_ERROR', data: error})
        } else {
            callback({type: 'ERROR', data: error});
        }

    });
}

//If xstate returns a value that is not used, you'll get an s TypeError
//The event object/fields passed must match exactly or assign/state will fail silently
//after 3 failures, we should goto error & logout
export const TokenMachine = createMachine({
    id: 'token',
    initial: 'refresh',
    context: {
        authData: null,
        eventData: null,
        data: null,
        retries: 0,
    },
    states: {
        refresh: {
            invoke: {
                src: (context) => refreshingToken(context),
                onError: {
                    target: 'error',
                }
            },
            on: {
                DONE: {
                    target: 'done',
                    actions:[ assign({
                        data: (context,event) => event.data,
                        retries: 0,
                    }),
                        sendParent((context, event) => ({type: 'TOKEN_REFRESHED', data: context.data}))
                    ]
                },
                CSRF_ERROR: {
                   // target: 'retry', // we should rerender so we shouldn't need this?
                    actions:[
                        sendParent({type: 'CSRF_ERROR'}),
                        ],
                },
                ERROR: {
                    //we can end up here but then nothing...
                    target: 'retry',
                    actions:[ (context, event) => {console.log('here'); console.log('beforedata'); console.log(context); console.log('afterdata'); console.log(event)}, assign({
                        data: (context,event) => event.data,
                        retries: (context) => context.retries + 1,
                    }),
                        //this works here...
                        sendParent((context, event) => ({type: 'TOKEN_REFRESH_ERROR'})),
                        () => console.log('Should be going to error')
                    ]
                }
            },
        },

        retry: {
            entry: (context) => {console.log('Moved to retry state'); console.log(context)},
            after: {
                RETRY_DELAY: [{
                    actions: () => console.log('Retrying...'),
                    target: 'refresh',
                    cond: (context) => context.retries < maxRetries,
                }, {
                    target: 'error',
                    actions: () => console.log('Max retries reached. Going to error state'),
                }]
            }
        },

        error: {
           // entry: () => console.log('Moved to error state'),
            always: {
                actions: sendParent((context, event) => ({type: 'TOKEN_REFRESH_ERROR', data: context})),
                target: 'done',
            }
        },
        done: {
            //entry: () => console.log('Moved to done state'),
            type: 'final',
        },

    },
}, {
    services: { refreshingToken },
    delays: {
        RETRY_DELAY: (context, event) => {
            const exponent = context.retries ** 2;
            return exponent * 200;
        }
    },

});
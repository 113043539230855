import React from "react";
//import useBilling from "../hooks/useBilling";
import {useNavigate} from "react-router-dom";
import {useStorage} from "../contexts/storageContext";
import DetailsList from "../components/DetailsList";
import "../styles/scss/DetailsPage.scss";

const DetailsPage = () => {
    //const {billingItems, isLoading} = useBilling();
    const { storage } = useStorage();
    const emailId = storage.getEmailId();
    

    const data = storage.getCardData();
    const details = storage.getCardDetails();

    return (
        <div className="emails">
	    <DetailsList emailId={emailId} data={JSON.parse(data)} details={JSON.parse(details)} />
        </div>
    );
}



export default DetailsPage;

import {createBrowserRouter, RouterProvider} from "react-router-dom";
import RootLayout from "./Root";
import ErrorPage from "./ErrorPage";
import HomePage from "./HomePage";
import RegisterPage from "./RegisterPage";
import UpgradePage from "./UpgradePage";
import WelcomePage from "./WelcomePage";
import LoginPage from "./LoginPage";
import LogoutPage from "./LogoutPage";
import BillingPage from "./BillingPage";
import DetailsPage from "./DetailsPage";
import PasswordResetRequestPage from "./PasswordResetRequestPage";
import PasswordResetPage from "./PasswordResetPage";
import React from "react";
import SettingsPage from "./SettingsPage";
import ConfirmPage from "./ConfirmPage";
import PasswordPage from "./PasswordPage";
import DeepLink from "./DeepLink";
import Scanning from "./Scanning";
import ScanningPlus from "./ScanningPlus";
import Loading from "./Loading";
import NativeMigrationPage from "./NativeMigrationPage";
import PasswordV1Page from "./PasswordV1Page";

const MainApp = () => {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <RootLayout />,
            errorElement: <ErrorPage />,
            children: [
                { index: true, element: <HomePage /> },
                { path: '/signup', element: <RegisterPage /> },
                { path: '/upgrade', element: <UpgradePage /> },
                { path: '/welcome', element: <WelcomePage /> },
                { path: '/login', element: <LoginPage /> },
                { path: '/logout', element: <LogoutPage />},
                { path: '/billing', element: <BillingPage /> },
                { path: '/details', element: <DetailsPage /> },
                { path: '/reset', element: <PasswordResetRequestPage /> },  // password reset request form
                { path: '/reset-password/*', element: <PasswordResetPage /> }, {/* Slug for email sent token/key */},
                { path: '/settings', element: <SettingsPage /> },
                { path: '/verify/*', element: <ConfirmPage /> },
                { path: '/confirm/*', element: <ConfirmPage /> },
                { path: '/password', element: <PasswordPage /> },
                { path: '/passwordv1', element: <PasswordV1Page /> },
                { path: '/verifysec/*', element: <ConfirmPage /> },
                { path: '/emails/*', element: <DeepLink /> },
                { path: '/scanning', element: <Scanning /> },
                { path: '/plus', element: <ScanningPlus /> },
                { path: '/loading', element: <Loading /> },



                { path: "/migration", element: <NativeMigrationPage /> },
            ]
        },
    ])

    return (
        <>
            <RouterProvider router={router} />
        </>
    );

}

export default MainApp;

import React, {useEffect, useState} from 'react'
import {useAuth} from '../contexts/authContext'
import {useModal} from '../contexts/ModalContext'
import {useFetch} from "../hooks/useFetch";
import {addOrDeleteEmailUpdate} from "../machines/addOrDeleteEmailMachine";
import {useMachine} from "@xstate/react";
import {useNavigate} from "react-router-dom";
import Fade from '@mui/material/Fade';
import CircularProgress from "@mui/material/CircularProgress";
import {track,screen} from "../util/analytics";
import useClearModals from "../hooks/useClearModals";
import AddEmailModal from "../modals/AddEmailModal";
import GenericErrorModal from "../modals/GenericErrorModal";
import useDetectNetwork from "../hooks/useDetectNetwork";
import ProgressBar from "./ProgressBar";
import useProgressBar from "../hooks/useProgressBar";

import {useSettings} from "../contexts/settingsContext";
import TruncateEmail from "./TruncateEmail";
//import useSettings from "../hooks/useSettings";

const SetttingsMonitoring = ({ fullEmails, refreshCallback, windowWidth }) => {
	const {authState} = useAuth();
	const {setModalType, toggleModal, setHiddenSettings} = useModal();
	//const [emails, setEmails] = useState([]);
	//const [primaryEmail, setPrimaryEmail] = useState(authState.context.emailAddress);
	//const [deleteEmailId, setDeleteEmailId] = useState(null);
	//const [emailAdded, setEmailAdded] = useState(false);
	 // can use authState context.. or call the endpoint
	const {state, uiErrorMsg, isLoading, fetch, ready,reset} = useFetch();
	//const [emailsState,emailSend,  emailsService] = useMachine(addOrDeleteEmailUpdate);

	const navigate = useNavigate();
	const [isLoaded, setIsLoaded] = useState(false);
	//const [emailsLoaded, setEmailsLoaded] = useState(false);
	const [clearModals, setClearModals] = useState(false);
	const [showNextModal, setShowNextModal] = useState(false);
	//const { progressStarted, apiStarted, isComplete, startProgress, handleCompletion,  setLastApiCallStarted, setProgressOverride, progressOverride} = useProgressBar();

	const {genericErrorModal, setGenericErrorModal,
		primaryEmail, emails, emailsLoaded,
		emailsState, emailSend,  emailsService,
		setDeleteEmailId, setEmailAdded, progressStarted,
		apiStarted, isComplete, startProgress, handleCompletion,
		setLastApiCallStarted, setProgressOverride, progressOverride} = useSettings();

	useClearModals(clearModals);


	useEffect(() => {
		setIsLoaded(true)
	}, []);


	const addEmail= () => {
		//navigate('/addEmail')
		//setModalType({modalType: 'introAddEmail'}) //can do this but I think from settings we go straight to addEmail
		let planType = "free"
		if (authState.context.isPlus) {
			planType = "paid"
		}
		//#272
		track('Button Tapped', {buttonType: 'addEmailAddresses', screenType: 'settings'});
		setHiddenSettings(true)
		setShowNextModal(true);


	}
    
	const removeEmail = (emailId, email) => {
		//#276
		track('Button Tapped', {buttonType: 'removeEmailAddress', screenType: 'settings'});
		setModalType({modalType: 'removeEmail', callback: deleteEmail, email: email, emailId: emailId })
			toggleModal()
	}

	const deleteEmail = (emailId, emailAddress) => {
		console.log('delete', emailAddress, emailId)
		setDeleteEmailId(emailId)
	}

    return (<>
			{genericErrorModal && <GenericErrorModal props={true} dim={true} callback={()=>{setGenericErrorModal(false)}} screenType='settings' screenSubType='monitoring' />}
			{showNextModal && <AddEmailModal callback={setEmailAdded} exitCallback={setShowNextModal} props={{emails: fullEmails, refreshCallback: refreshCallback, fromSettings: true}} windowWidth={windowWidth} />}
		{isLoaded && primaryEmail && emails ? (
			<div className="settings-monitoring">
				<div className="settings-header-container"><h2 className="settings-header">Monitoring</h2></div>
				<div className="monitoring-body-container">
					<div className="monitoring-body">
						<div className="monitoring-description-container">
							<div className="monitoring-description">Mirkat is continually monitoring for data breaches and
								threats linked to the following email addresses:
							</div>
						</div>
						<div className="monitoring-list">
							<div className="monitoring-row">
								<div className="monitoring-email-container"><span
									style={{textAlign: 'unset', color: '#8894A1'}}
									className="monitoring-email">{primaryEmail}</span></div>
								<div className="monitoring-icon">
									<svg width="14" height="41" viewBox="0 0 14 41" fill="none"
										 xmlns="http://www.w3.org/2000/svg">
										<path
											d="M7 13C4.50714 13 2.5 15.0071 2.5 17.5V18.25C1.675 18.25 1 18.925 1 19.75V27.25C1 28.075 1.675 28.75 2.5 28.75H11.5C12.325 28.75 13 28.075 13 27.25V19.75C13 18.925 12.325 18.25 11.5 18.25V17.5C11.5 15.0071 9.49286 13 7 13ZM7 14.5C8.70714 14.5 10 15.7929 10 17.5V18.25H4V17.5C4 15.7929 5.29286 14.5 7 14.5ZM7 22C7.825 22 8.5 22.675 8.5 23.5C8.5 24.325 7.825 25 7 25C6.175 25 5.5 24.325 5.5 23.5C5.5 22.675 6.175 22 7 22Z"
											fill="#8894A1"/>
									</svg>
								</div>
							</div>
							{emails.map((email, index) => (
								<div className="monitoring-row" key={email.emailAddress}>
									<div className="monitoring-email-container"><span
										className="monitoring-email"><TruncateEmail email={email.emailAddress} maxLength={42}/>{index < emails.length - 1}</span>
									</div>
									<div className="monitoring-icon">
										<svg onClick={() => removeEmail(email.emailId, email.emailAddress) }

											 style={{cursor: 'pointer'}} width="14" height="41" viewBox="0 0 14 41"
											 fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fillRule="evenodd" clipRule="evenodd"
												  d="M10.6112 25.9046C9.54229 26.6188 8.28558 27 7 27C5.27672 26.998 3.6246 26.3125 2.40605 25.094C1.18751 23.8754 0.502033 22.2233 0.5 20.5C0.5 19.2144 0.881218 17.9577 1.59545 16.8888C2.30968 15.8199 3.32484 14.9868 4.51256 14.4948C5.70028 14.0028 7.00721 13.8741 8.26809 14.1249C9.52896 14.3757 10.6872 14.9948 11.5962 15.9038C12.5052 16.8128 13.1243 17.971 13.3751 19.2319C13.6259 20.4928 13.4972 21.7997 13.0052 22.9874C12.5132 24.1752 11.6801 25.1903 10.6112 25.9046ZM5.22734 22.8636C5.07065 22.8636 4.92038 22.8014 4.80957 22.6906C4.69879 22.5798 4.63656 22.4295 4.63656 22.2728C4.63656 22.1161 4.69879 21.9658 4.80957 21.855L6.16452 20.5001L4.80957 19.1451C4.70193 19.0337 4.64237 18.8844 4.64371 18.7295C4.64506 18.5745 4.70721 18.4263 4.81676 18.3168C4.92632 18.2072 5.07453 18.1451 5.22947 18.1437C5.3844 18.1424 5.53366 18.2019 5.64511 18.3096L7.00007 19.6645L8.35502 18.3096C8.46647 18.2019 8.61573 18.1424 8.77067 18.1437C8.9256 18.1451 9.07381 18.2072 9.18337 18.3168C9.29293 18.4263 9.35507 18.5745 9.35642 18.7295C9.35776 18.8844 9.2982 19.0337 9.19057 19.1451L7.83561 20.5001L9.19057 21.855C9.2982 21.9665 9.35776 22.1157 9.35642 22.2707C9.35507 22.4256 9.29293 22.5738 9.18337 22.6834C9.07381 22.7929 8.9256 22.8551 8.77067 22.8564C8.61573 22.8578 8.46647 22.7982 8.35502 22.6906L7.00007 21.3356L5.64511 22.6906C5.5343 22.8014 5.38403 22.8636 5.22734 22.8636Z"
												  fill="#2F364B"/>
										</svg>
									</div>
								</div>))}
						</div>
					</div>
					<div className="settings-row-container noSelect">
						<div className="settings-row">
							<div className="settings-row-icon-container">
								<svg className="settings-row-icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
									 xmlns="http://www.w3.org/2000/svg">
									<path
										d="M2 6.22222C2 5.63285 2.23413 5.06762 2.65087 4.65087C3.06762 4.23413 3.63285 4 4.22222 4H19.7778C20.3671 4 20.9324 4.23413 21.3491 4.65087C21.7659 5.06762 22 5.63285 22 6.22222M2 6.22222V17.3333C2 17.9227 2.23413 18.4879 2.65087 18.9047C3.06762 19.3214 3.63285 19.5556 4.22222 19.5556H19.7778C20.3671 19.5556 20.9324 19.3214 21.3491 18.9047C21.7659 18.4879 22 17.9227 22 17.3333V6.22222M2 6.22222L12 12.8889L22 6.22222"
										stroke="#2F364B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								</svg>
							</div>
							<div className="settings-row-text-container" style={{cursor: 'pointer'}} onClick={addEmail}>
								<div className="settings-row-text">Add email address</div>
							</div>
							<div className="settings-row-right-container">
								<div className="settings-row-arrow">
									<svg width="9" height="16" viewBox="0 0 9 16" fill="none"
										 xmlns="http://www.w3.org/2000/svg">
										<path d="M2 13.75L7.5 8.25L2 2.25" stroke="#2F364B" strokeWidth="3"
											  strokeLinecap="round" strokeLinejoin="round"/>
									</svg>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		) : (
			<div className="settings-monitoring">
				<div className="settings-header-container"><h2 className="settings-header">Monitoring</h2></div>
				<div className="monitoring-body-container">
				</div>
			</div>
                )}
     </>
    )
}

export default SetttingsMonitoring;

import React, {useEffect, useState} from 'react';
import {useModal} from "../contexts/ModalContext";

const useClearModals = (state) => {
    const {setMobileKebab, setModalType, toggleModal, setThreatState,setShowSettings,setHomePage,setModalCount} = useModal();

    useEffect(() => {
        if (state) {
            setMobileKebab(false);
            setModalType('');
            toggleModal();
            setThreatState(false);
            setShowSettings(false);
            setHomePage(false);
            setModalCount(0);
            document.body.style.overflow = '';
            document.body.style.position = '';
        }
    }, [state]);

}

export default useClearModals;

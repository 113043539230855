import {createMachine, assign, sendParent} from 'xstate';
import MirkatClient from "../services/mirkatClient";
import {BaseClientParams} from "../util/clientParams";
import {createDataFetcher} from "../util/fetchFactory";
import {ErrorMachine} from "./errorMachine";
import {updateClient} from "../util/updateClient";

const maxRetries = 3;


const client = new MirkatClient(BaseClientParams);

//register gets userId, emailId and installationId
//{"userId": "ZwGWx2YZ", "emailId": "4zdmr1oy", "installationId": "4WKWWEMm"}

//Need to add check for error 39 -> redirect to login or do we display error? (user already exists)
const register = (context, event) => {
    return new Promise((resolve, reject) => {
        const event = {method: 'users', params: [context.eventData.email]}
        const dataFetcher = createDataFetcher(client);
        dataFetcher(context, event).then((data) => {
            resolve(data)
        }, (error) => {
            reject(error);
        });
    })
}

export const RegisterMachine = createMachine({
    id: 'register',
    initial: 'register',
    context: {
        apiClient: null,
        eventData: null,
        data: null,
        error: false,
        retries: 0,
    },

    states: {
        register: {
            invoke: {
                id: 'register',
                src: (context, event) => register(context),
                onDone: {
                    target: 'done',
                    actions: [() => console.log("register done"),
                        assign({
                            //data: (context, event) => event.data,
                            data: (context, event) => event.data,
                            error: false,
                            retries: 0,
                        }),
                        sendParent((context) => ({type: 'DONE', data: context.data}))
                    ],
                },
                //need to check error code... 39 redirect to login or display error. 44 reinit session
                onError: {
                    target: 'fixerror',
                    actions: [() => console.log("register fixerror"), (context) => console.log("before: ", context), assign({
                        errorData: (context, event) => event?.data || event?.error,
                        error: true,
                        retries: (context) => context.retries + 1||1,
                    }), (context) => console.log(context),
                        sendParent((context) => ({type: 'ERROR', errorData: context.errorData}))],
                }
            },
        },
        fixerror: {
            entry: (context) => {console.log("fixerror"); console.log(context) },
            invoke: {
                src: (context) => ErrorMachine.withContext(context),
                data: {
                    apiClient: (context) => context.apiClient,
                    errorData: (context) => context.errorData,
                    retries: (context) => context.retries,
                },
                onDone: {
                    actions: [(event) => console.log("event: ", event), assign({
                        csrf: (context, event) => event.csrf,
                        secret: (context, event) => event.secret,
                    }), sendParent((context) => ({type: 'CSRF', csrf: context.csrf, secret: context.secret})),
                        (context) => console.log("context: ", context)],
                },
                onError: {
                    actions: 'logWtfEntry'
                }
            },
            on: {
                UPDATEDCSRF: {
                    actions: [assign({
                        csrf: (context, event) => event.csrf,
                        secret: (context, event) => event.secret,
                    }), sendParent((context) => ({type: 'UPDATEDCSRF', csrf: context.csrf, secret: context.secret})),
                        (context) => updateClient(context)],
                    target: 'register',
                },
                GOLOGIN: {
                    target: 'done',
                    actions: [() => console.log("Sigh"), assign({errorData: (context, event) => event.errorData}),
                        sendParent((context) => ({type: 'GOLOGIN', data: context.errorData}))] //Not autohandling, passing back up to AuthMachine
                },
                ERROR: {
                    target: 'error',
                    actions: [() => console.log("error machine returned error"), sendParent((context) => ({type: 'ERROR', errorData: context.errorData})), (context) => console.log(context)],
                },
            },

        },
        done: {
            type: 'final',
            actions: (context,event) => console.log(context, event)
        },
        retry: {
            on: {
                '': [
                    {
                        id: 'retry',
                        target: 'register',
                        cond: 'shouldRetry'
                    },
                    {target: 'error'}]
            }
        },

        error: {
            type: 'final',
        }
    },
}, {
    guards: {
        shouldRetry: (context, event) => context.retries < maxRetries
    },
});

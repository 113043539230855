import React from 'react'

const UpgradeExit = () => {
    return (
	<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
	    <path fillRule="evenodd" clipRule="evenodd" d="M13.0607 10.9393C12.4749 10.3536 11.5251 10.3536 10.9393 10.9393C10.3536 11.5251 10.3536 12.4749 10.9393 13.0607L14.8787 17L10.9393 20.9393C10.3536 21.5251 10.3536 22.4749 10.9393 23.0607C11.5251 23.6464 12.4749 23.6464 13.0607 23.0607L17 19.1213L20.9393 23.0607C21.5251 23.6464 22.4749 23.6464 23.0607 23.0607C23.6464 22.4749 23.6464 21.5251 23.0607 20.9393L19.1213 17L23.0607 13.0607C23.6464 12.4749 23.6464 11.5251 23.0607 10.9393C22.4749 10.3536 21.5251 10.3536 20.9393 10.9393L17 14.8787L13.0607 10.9393Z" fill="white"/>
	</svg>
    )
}

export default UpgradeExit;

import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useAuth } from "../contexts/authContext";
import {track} from "../util/analytics";

const useRefForElement = (duration, callback, id, options = {}, seenAt, riskType, riskCategoryType, history, completedType, entityId, feedPosition, status) => {
    const { authState} = useAuth();
    const [timeoutIds, setTimeoutIds] = useState({});
    const [cardSeen, setCardSeen] = useState(false)
    const { ref, inView } = useInView({
        //triggerOnce: true,
        ...options
    });
  
    useEffect(() => {
        console.log("in seen")
        console.log("Seen at: ", seenAt)
        console.log("ref")
	if (!seenAt) { //don't do anything if card has seenAt set
            if (inView) { //act when card in view
                if (authState.context.seenIds.includes(id)) {  //check if local tracking array already has this ID
	    	    console.log("WE ALREADY INCLUDE ID: ",id);
	        } else {  //end up here if card ID not in tracking array
                	const timeoutId = setTimeout(() => { //run a timer that does callback
                	    callback(id);  //callback to Card.jsx
                	    clearTimeout(timeoutId);
                	}, duration);
                    setTimeoutIds((prevIds) => ({ ...prevIds, [id]: timeoutId }));  //police up all the timers IDs into state
	        }

            } else {
      	        console.log("NOT IN VIEW: ", inView, id, duration, timeoutIds)
                clearTimeout(timeoutIds[id]);  //clean up timers, this actually does the job unlike the below statement
  	    }
            return () => {
                clearTimeout(timeoutIds[id]); //this won't actually clear the above set timers cause useState is slow, race condition
            };
	}
        if (inView) { //act when card in view
            console.log("IN VIEW: ", id, inView)
            console.log("Firing track call in Seen")
            //#70
            track('Card Viewed', {
                cardType: 'risk',
                cardId: id,
                riskType: riskType,
                riskCategoryType: riskCategoryType,
                feedType: history ? 'history' : 'feed',
                completedType: completedType,
                entityId: entityId,
                feedPosition: feedPosition, // integer
                status: status,
            });

        }

      }, [inView, duration, id]);
  
    return ref;
}
export default useRefForElement;

import React from "react";
import PasswordForm from "../components/PasswordForm";

const PasswordPage = () => {
    return (
        <div className="onBoarding">
            <div className="login-row">
                <div className="login" >
                    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        <PasswordForm />
                    </div>
              </div>
            </div>
        </div>
    );
}

export default PasswordPage;
import { useEffect } from 'react';

const useIntersectionObserver = (ref, callback, options = {}) => {
    useEffect(() => {
        const target = ref.current;
        if (!target) return;

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => callback(entry));
        }, options);

        observer.observe(target);

        return () => observer.disconnect();
    }, [ref, options, callback]);
};


export default useIntersectionObserver;


//Tracking calls
/*

//#287
track('Card Loaded', {
                    cardType: 'monitorMoreEmails',
                    feedType: 'feed',
                    feedSubType: 'risk', // or safe
                    feedPosition: 1, // integer
                    });

track('Card Button Tapped', {
                    buttonType: 'action',
                    feedType: 'feed',
                    feedSubType: 'risk', // or safe
                    });



 */
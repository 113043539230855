import React, {useEffect} from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import styles from "../styles/scss/ErrorModal.module.scss";
import {screen, track} from "../util/analytics";
import GenericErrorModal from "../modals/GenericErrorModal"


const ErrorModal = ({props, dim, screenType, screenSubType, callback }) =>  {
    const [open, setOpen] = React.useState(props || false);

    useEffect(() => {
        screen('Error', {screenType: 'error'})
        //screenType and screenSubType need to be set that this is displayed over.
        //#253
        track('Error Modal Viewed', {
            eventType: 'errorModal',
            screenType: screenType || null,
            screenSubType: screenSubType || null,

        });
    }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        callback(false);
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
            >
                <Fade in={open}>
                    <div style={{background: dim ? "rgba(0, 0, 0, 0.40)" : "unset"}} className={styles["modal-screen-container"]}>
                        <div className={styles["modal-container"]}>
                            <div className={styles["modal"]}>
                                <div className={styles["modal-content"]}>
                                    <div className={styles["modal-title"]}>Oops! Something went wrong</div>
                                    <div className={styles["modal-description"]}>Please try again later.</div>

                                    <div className={styles["action-container"]}>
                                        <div className={styles["button-Ok"]} onClick={handleClose}>
                                            <div className={styles["modal-button-label"]}>OK</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default ErrorModal;

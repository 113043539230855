import React, {useEffect, useState, useRef} from "react";
import { useAuth } from "../contexts/authContext";
import styles from '../styles/scss/AddEmailModal.module.scss';

import Button from "../components/Button";

import {useFetch} from "../hooks/useFetch";
import {useModal} from "../contexts/ModalContext";
import {Controller, useForm} from "react-hook-form";
import Input2 from "../components/Input2";
import {retryableError} from "../util/retryableError";


//TODO: change the scss names to not use the AddEmailModal classes.

//If we'ere not in the right state, we'll get stuck with Loading button. need to check and redirect fix
const ChangeOldPasswordModal = ({setOldPw, setModalType, toggleModal}) => {
    const { register, handleSubmit, formState,
        setValue,
        control, reset, getValues} = useForm({mode: "onChange", reValidateMode: "all"});
    const [resetKey, setResetKey] = useState(Date.now());
    const breachesExitButton = "https://img.mirkat.com/web/images/home/breaches-exit-button.svg";
    const leftbutton = "https://img.mirkat.com/web/icons/leftbutton.svg";

    //const {toggleModal,setModalType} = useModal();
    const onSubmit = (data) => {
        const oldPw = data.password;
        setOldPw(oldPw);
        setModalType('')
        //toggleModal();
    }
    const exit = () => {
        setModalType('')
    }

    return (

        <div className={styles["container"]}>
            <div className={styles["modal-addEmail-container"]}>
                <form noValidate method="post" onSubmit={handleSubmit(onSubmit)}>
                    <img
                        onClick={exit}
                        src={breachesExitButton}
                        className="settings-exit-button"
                    />

                    <div className={styles["email-inner-container"]}>
                        <div className={styles["email-form-header-container"]}>

                            <div className={styles["email-form-header-text"]}>
                                <div className={styles["email-form-header"]}>Enter your current password
                                </div>
                            </div>
                        </div>
                        <div className={styles["inner-div"]}>
                            <Controller
                                key={`oldpassword-${resetKey}`}  // Apply the key here
                                control={control}
                                name="password"
                                defaultValue=""
                                rules={{ required: true, minLength: 8}}
                                render={({ field, fieldState }) => <Input2 field={field}  label="Current Password" type="password" id="oldpassword" autoComplete=""  />}
                            />

                        </div>
                    </div>
                    <div className={styles["button-div"]}> <Button isDisabled={!formState.isValid} buttonText="Confirm" /></div>
                </form>
            </div>
        </div>
    )
}

export default ChangeOldPasswordModal;

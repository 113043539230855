import React, {useState, useEffect} from "react";
import DeepLinkEmail from "../components/DeepLinkEmail";

const DeepLink = () => {
    return (
            <DeepLinkEmail />
    );
}

export default DeepLink;

import React from "react";
const exclamationSrc = "https://img.mirkat.com/web/icons/exclamation.svg";


const AlertIcon = () => {
    return (
        <div className="icon-wrapper">
            <img src={exclamationSrc} className="icon" />
        </div>
    );
}

export default AlertIcon;

import ClientErrors from "./errors";

class ApiClient {
    constructor({ baseURL, headers, language= "en" }) {
        this.baseURL = baseURL;
        this.headers = headers;
        this.acceptLanguage = language;
        this.timeoutDuration = 8000; // 8 seconds max call then throw timeout.
        this.retries = 2;  // total of 3 attempts
        this.backoff = 300; //300 milliseconds
        this.errorLookup = new ClientErrors();
    }

    //allow this to be overridden later
    setRequestTimeout(timeoutDuration) {
        this.timeoutDuration = timeoutDuration;
    }

    // request is a wrapper around fetch requests
    async request({ method, endpoint, body, customHeaders }) {
        const options = {
            method,
            credentials: 'include',
            headers: {
                ...this.headers,
                ...customHeaders,
            },
        };

        if (body) {
            options.body = body;
        }
        console.log(this.baseURL)
        console.log(endpoint)
        console.log(options)
        return await fetch(`${this.baseURL}${endpoint}`, options);
    }

    timeout() {
        return new Promise((_, reject) =>
            setTimeout(() => reject(new Error('Request timed out')), this.timeoutDuration)
        );
    }

    async retryOperation(operation, retries = this.retries, backoff = this.backoff) {
        try {
            return await Promise.race([operation(), this.timeout()]);
        } catch (err) {
            if (err.hasOwnProperty('errorCode')) {
                //Retry transient errors...
                let errCode = this.errorLookup.getError(err.errorCode);
                if (!errCode.canRetry) {
                    throw err;
                }
            }
            if (retries <= 0) throw err;
            await new Promise((resolve) => setTimeout(resolve, backoff));
            return this.retryOperation(operation, retries - 1, backoff * 2);
        }
    }

    async fetch(endpoint, options = {}) {
        const url = `${this.baseURL}${endpoint}`;
        const requestOptions = { ...this.options, ...options };

        return await this.retryOperation(async () => {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }
            return response;
        });
    }

    // jsonRequest is a wrapper around request that sets the Content-Type header
    async jsonRequest({ method, endpoint, body, customHeaders }) {
        const url = `${this.baseURL}${endpoint}`;
        const options = {
            method,
            credentials: 'include',
            headers: {
                ...this.headers,
                ...customHeaders,
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Accept-Language': this.acceptLanguage,
            },
        };

        if (body) {
            options.body = JSON.stringify(body);
        }

        //wrapping request around retryOperation

        return await this.retryOperation(async () => {
            const response = await fetch(url, options);
            if (!response.ok) {
                //we end up here repeatedly.. we should bubble up errors for user to see if after final attempt
                //if we need action here, we should put the decision here... if 34 bubble up etc

                let respclone = await response.clone();
                let resp = await respclone.json();
                if(resp.hasOwnProperty('errorCode')) {
                    switch (resp.errorCode) {
                        case 12: case 13: case 17:
                            //retry these without bubbling up
                            throw new Error(`Error: ${response.statusText}`);
                        default:
                            //bubble up errors we need to know about
                            return resp // returns json - before my changes
                            //return response // returns entire response

                    }
                }
                //Throw error here to have it retry - return stops it.
                throw new Error(`Error: ${response.statusText}`);



            }
            return await response.json();
        });
    }

}

export default ApiClient;

import React, {useEffect, useRef, useState} from "react";
import Button from "./Button";

import {useNavigate} from "react-router-dom";
import {useFetch} from "../hooks/useFetch";
import {Controller, useForm} from "react-hook-form";
import Input2 from "./Input2";
import {retryableError} from "../util/retryableError";
import {track,screen} from "../util/analytics";
import styles from "../styles/scss/PasswordReset.module.scss";
import PasswordUpdatedModal from "../modals/PasswordUpdatedModal";

import Modal from "@mui/material/Modal";
import useDetectNetwork from "../hooks/useDetectNetwork";
import Fade from "@mui/material/Fade";
import useProgressBar from "../hooks/useProgressBar";
import ProgressBar from "./ProgressBar";
import UseClearErrorOnKeyPress from "../hooks/useClearErrorOnKeyPress";
import useClearErrorOnKeyPress from "../hooks/useClearErrorOnKeyPress";

const PasswordReset = () => {
    const { register, handleSubmit, formState,
        setValue,
        control, reset: formReset, watch, getValues} = useForm({mode: "onChange", reValidateMode: "all"});

    const navigate = useNavigate();
    const [submitted, setSubmitted] = useState(false);
    const [password, setPassword] = useState(null);

    const [key, setKey] = useState('');
    const [resetKey, setResetKey] = useState('');
    const {state, uiErrorMsg, fetch, isLoading, reset} = useFetch();
    const {state: state2, uiErrorMsg :uiErrorMsg2, fetch: fetch2, reset: reset2} = useFetch();
    const { progressStarted, apiStarted, isComplete, startProgress, handleCompletion, setNoDelay, noDelay, setLastApiCallStarted, setProgressOverride, progressOverride, resetProgressBar} = useProgressBar();

    const [formResetKey, setFormResetKey] = useState(Date.now())
    const inputRef = useRef(null);
    const [returnLogin, setReturnLogin] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [error, setError] = useState("");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    //const dynamic = {height: windowWidth > 500 ? "unset" : windowHeight}
    const [pwHeaderString, setPwHeaderString] = useState("Change your password")
    const leftbutton = "https://img.mirkat.com/web/icons/leftbutton.svg";
    useClearErrorOnKeyPress({setError, error})


    const isOnline = useDetectNetwork();
    useEffect(() => {
        if(!isOnline){
            setError("No network connection")
        }
        if(isOnline && error === "No network connection") {
            setError("")
        }
    }, [isOnline])

    useEffect(() => {
        if(windowWidth < 840) {
            setPwHeaderString("Change Password")
        }
        if(windowWidth > 839) {
            setPwHeaderString("Change your password")
        }

    }, [windowWidth]);

    useEffect(() => {
       // if (inputRef.current) {
        //    inputRef.current.focus();
       // }
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    // Set up an event listener for window resizing
    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    //Set the vars required by grabbing them and parsing from the url (href)
    useEffect(() => {
        screen('Account: Update Password', {screenType: 'account', screenSubType: 'updatePassword'})
        //TODO: add some error checking here
        const currentUrl = window.location.href;
        const currentUrlParams = new URLSearchParams(window.location.search);
        const lastSlashIndex = currentUrl.lastIndexOf('/')
        const queryStringIndex = currentUrl.indexOf('?');

        const encryptedJwt = currentUrl.substring(lastSlashIndex + 1, queryStringIndex === -1 ? undefined : queryStringIndex);
        let formattedKey = encryptedJwt + "&" + currentUrlParams.toString();

        formReset({'confirmpassword': ''})

        setKey(formattedKey);
    }, []);

    //get reset key from api server
    //one we have all the stuff, we can submit for the reset key
    useEffect(() => {

        //state1 and key
        //if we have the reset "token" (key) then we need to go get the resetKey
        if(state && state.matches('idle') && password && key && !resetKey) {
            fetch('passwordResetToken', [key])
        }
        //set resetKey if we don't have it
        if(state && state.matches('success') && password && key && !resetKey) {
            setProgressOverride(100)
            console.log("Should only see once")
            if(state.context.data?.resetKey) {
                setResetKey(state.context.data.resetKey)
            }
            setKey(null)
        }

        //if we fail - display the error and let the user try again
        if(state && state.matches('failure') && password && key) {
            setProgressOverride(100)
            let {canRetry, errMsg} = retryableError({errorBody: state.context.error})
            setError(errMsg);
            //rendering race condition will cause it to retry without a brief delay resetting fetch hook
            setTimeout(() => {
                setPassword(false)
                //formReset({confirmpassword: ''})
                formReset(formValues => ({
                    ...formValues,
                    confirmpassword: '',
                }))
                reset();
                inputRef.current.focus();
            }, 250);

        }

        if(state2 && state2.matches('idle') && !key && resetKey && password) {
            //do the reset
            fetch2('passwordReset', [resetKey, password])

        }
        if(state2 && state2.matches('success') && !key && password) {
            setProgressOverride(100)

            //#69
            track('User Password Updated', {
                eventType: 'passwordUpdated',
            })
            setPassword(null)
            setShowSuccessModal(true)
        }

        if(state2 && state2.matches('failure') && !key && password && resetKey) {
            setProgressOverride(100)
            formReset(formValues => ({
                ...formValues,
                confirmpassword: '',
            }))
            let {canRetry, errMsg} = retryableError({errorBody: state2.context.error})
            setError(errMsg);
            setPassword(null)
            //rendering race condition will cause it to retry without a brief delay resetting fetch hook
            setTimeout(() => {
                reset2();
                inputRef.current.focus();
            }, 250);
        }



        console.log("WTF1 state: ", state)
        console.log("WTF2 state2: ", state2)
        console.log("WTF3 error: ", error)
        console.log("WTF4 key: ", key)
    }, [state, key, resetKey, password, state2, showSuccessModal, returnLogin] )

    useEffect(() => {
        if(returnLogin) {
            navigate('/login')
        }
        if(formState.isValid && !key && resetKey) {
            console.log("hmmmmm")
            setError("");
        }
    }, [returnLogin, formState.isValid]);

    //Need to get the Reset-Token...
    //get url from email, submit with timestamp and signature
    const onSubmit = (event) => {
        startProgress()
        setLastApiCallStarted(true)
        //reset2();
        event.preventDefault();
        const data = getValues();
        //#66
        track('Button Tapped', {
            buttonType: 'next',
            screenType: 'account',
            screenSubType: 'updatePassword',
        })
        const password = data.password;
        setPassword(password)
    }

    const watchPw = watch('password');
    const watchPw2 = watch('confirmpassword');

    const validatePasswordsMatch = (value, data) => {
        const formData = data

        if(error && formData?.confirmpassword.length > 0){ // clear error if trying again
            setError(null)
        }
        if(formData?.confirmpassword.length >= 8 && formData?.password.length >= 8) {
            //don't check for error yet
            if(formData?.confirmpassword !== formData?.password) {
                setError("The passwords do not match");
                return false;
            }
        }
        return true;
    };
    useEffect(() => {
        //error?
    }, [error]);

    const navToLogin = (a) => {
        navigate('/login')
    }
    return (
        <div style={{ display: "flex", width: "100%", justifyContent: "center" }} >
            {progressStarted && <ProgressBar progressOverride={progressOverride} apiStarted={apiStarted} onCompleted={handleCompletion} setProgressOverride={setProgressOverride} />}
            {showSuccessModal && <PasswordUpdatedModal callback={navToLogin} /> }
    <Fade in={true} timeout={500}>
        <form style={{ maxWidth: "500px", width: "100%" }} noValidate onSubmit={onSubmit}>
            <div className={styles["login-container"]} >
                <div className={styles["login-inner-container"]}>
                    <div className={styles["login-container-navigation"]}/>
                    <div className={styles["login-header-container"]}>
                        <div className={styles["login-header-text"]}>{pwHeaderString}</div>
                    </div>
                    <div className={styles["login-fields-container"]}>
                        <Controller
                            key={`pw-${resetKey}`}  // Apply the key here
                            name="password"
                            control={control}
                            defaultValue=""
                            rules={{required: true, minLength: 8, maxLength: 100, validate: validatePasswordsMatch}}
                            render={({field, fieldState}) => <Input2 field={field} maxLength={100}
                                                                     label="New password" id="password"
                                                                     autoFocus="autofocus"
                                                                     inputRef={inputRef}
                                                                     autoComplete=""/>}
                        />

                    <div className={styles["resetpw-hint"]} style={{paddingBottom: "16px"}}>Use a password with at least
                        8 characters.
                    </div>

                            <Controller
                                key={`confirm-${resetKey}`}  // Apply the key here
                                name="confirmpassword"
                                control={control}
                                defaultValue=""
                                rules={{required: true, minLength: 8, maxLength: 100, validate: validatePasswordsMatch}}
                                render={({field, fieldState}) => <Input2 field={field} maxLength={100}
                                                                         label="Confirm new password" id="password2"
                                                                         autoComplete=""/>}
                            />
                    </div>
                    <div className={styles["error"]} id="error-message">{error}</div>
                    <Button type="submit" isDisabled={!formState.isValid} buttonText="Change password"/>
                </div>
            </div>
        </form>
    </Fade>
    </div>
    )

}

export default PasswordReset;

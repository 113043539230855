import { assign, Machine } from 'xstate';
import {createMachine} from "xstate";

const maxRetries = 3;


//upon success -> fetchMachine sets:  data: (context, event) => event.data,
//upon failure -> fetchMachine sets: error: (context, event) => event.data,
//ensure function returns a promise

const originalConsoleWarn = console.warn;

//silence the warning about changing machines between renders - we're using useMemo already and sometimes it has to re-render
//to update csrf or accessToken etc

console.warn = (message) => {
    if (message?.startsWith('Machine given to `useMachine` has changed between renders.')) {
        return;
    }
    //strictmode outputing wanring %c%s and %c%s %s
    if (message?.startsWith('%c%s') || message?.startsWith('It is highly recommended to set')) {
        return;
    }


    originalConsoleWarn(message);
};

//serviceProp is the function to run
//fetchMachine factory
//if the call needs authService to trigger an update, you can pass authService in as an event object along with your api call
export const createFetchMachine = (fetchFunc) => Machine ({
    id: 'fetch',
    initial: 'idle',
    context: {
        apiClient: null,
        authData: null,
        data: null,
        error: null,
        retries: 0,
    },
    on: {
        UPDATE: {
            actions: [assign({
                apiClient: (_, event) => event.apiClient,
                authData: (_, event) => event.authData,
            })],
        }
    },
    states: {
        idle: {
            on: {
                FETCH: 'loading',
            },
        },
        loading: {
            invoke: {
                cond: 'canRetry',
                id: 'doFetch',
                src: (context, event) => fetchFunc(context, event),
                onDone: {
                    target: 'success',
                    actions: 'saveData',
                },
                onError: {
                    target: 'failure',
                    actions: ['saveErrorMessage', 'incrementRetries'],
                },
            },
        },
        success: {
            entry: assign({retries: () => 0}),
            on: {
                RESET: 'idle',
            }
        },
        failure: {
            entry: assign({retries: () => 0}),
            on: {
                RESET: 'idle',
            }
        },
    }
}, {
    actions: {
        saveData: assign({
            data: (context, event) => event.data,
        }),

        saveErrorMessage: assign({
            error: (context, event) => event.data,
        }),

        clearErrorMessage: assign({ error: null }),

        clearSavedData: assign({ data: null }),

        incrementRetries: assign({
            retries: (context, event) => context.retries + 1,
        }),

        resetRetries: assign({ retries: 0 }),

    },
    guards: {
        canRetry: (context, event) => context.retries < maxRetries,
    },
    delays: { //not used at the moment. Client library handles retries.
        RETRY_DELAY: 1000, // 1 second delay
        //Dynamic retry delay
        /*  RETRY_DELAY: (context) => {
              const exponent = context.retries ** 2;
              return exponent * 200;
          }
          */
    }
});


export const FetchMachine = createMachine({
    id: 'fetch',
    initial: 'FETCH',
    context: {
        fetchData: () => {},
        csrf: null,
        secret: null,
        data: null,
        error: null,
        retries: 0,
    },
    states: {
        FETCH: {
            on: {
                FETCH: 'loading',
            }
        },
        loading: {
            invoke: {
                id: 'getData',
                src: 'fetchData', // pass in client and function into context -  (context, event) => fetchUser(context.userId)
                onDone: {
                    target: 'success',
                    actions: 'saveData',
                },
                onError: {
                    target: 'failure',
                    actions: 'saveErrorMessage',
                },
            },
        },
        success: {},
        failure: {
            on: {
                RESET: 'FETCH',
            }
        },
    }
}, {
    actions: {
        saveData: assign({
            data: (context, event) => event.data,
        }),

        saveErrorMessage: assign({
            error: (context, event) => event.data,
        }),

        clearErrorMessage: assign({ error: null }),

        clearSavedData: assign({ data: null }),

        incrementRetries: assign({
            retries: (context, event) => context.retries + 1,
        }),

        resetRetries: assign({ retries: 0 }),


    },
    guards: {
        canRetry: (context, event) => context.retries < maxRetries,
    },
    services: {
        //override this with useMachine
        fetchData: (context, event) => {
            return fetch(event.url)
                .then(res => res.json())
                .then(data => {
                    if (data) {
                        return { type: 'DONE', data: data };
                    } else {
                        return { type: 'ERROR', data: data };
                    }
                })
                .catch(error => {
                    return { type: 'ERROR', data: error };
                });
        }
    },
    delays: {
        RETRY_DELAY: 1000, // 1 second delay
        //Dynamic retry delay
        /*  RETRY_DELAY: (context) => {
              const exponent = context.retries ** 2;
              return exponent * 200;
          }
         */
    }
})
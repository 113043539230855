import styles from "../styles/scss/ChangePasswordModal.module.scss";
//import styles from "../styles/scss/Login.module.scss";
import React, {useEffect, useRef, useState} from "react";
import Button from "../components/Button";

import {useAuth} from "../contexts/authContext";
import {Link, useNavigate} from "react-router-dom";

import useFormHandler from "../hooks/useFormHandler";

import {useFetch} from "../hooks/useFetch";
import {Controller, useForm} from "react-hook-form";
import Input2 from "../components/Input2";
//import styles from "../styles/scss/Login.module.scss";
import Slide from '@mui/material/Slide';
import {retryableError} from "../util/retryableError";
import {useModal} from "../contexts/ModalContext";
import {track} from "../util/analytics";
import useClearModals from "../hooks/useClearModals";
import useDetectNetwork from "../hooks/useDetectNetwork";
import Modal from "@mui/material/Modal";
import PasswordUpdatedModal from "./PasswordUpdatedModal";

//TODO: need Successfully change password modal after success

//This should be an auth protected route
const ChangePassword = ({setShowChangePasswordModal, props, windowWidth}) => {
    const {authState, authReady, stateSteady} = useAuth();
    const {formState, control, reset, watch, handleSubmit} = useForm({mode:'onSubmit'});
    const [isMounted, setIsMounted] = useState(false);
    const [clearModals, setClearModals] = useState(false);

    const [resetKey, setResetKey] = useState(Date.now());
    const {toggleModal,setModalType, setShowSettings, hiddenSettings, setHiddenSettings} = useModal();


    const [error, setError] = useState(null);
    const [isError, setIsError] = useState(false)
    const [oldPw, setOldPw] = useState(null);
    const [newPw, setNewPw] = useState(null);
    const [confirmPw, setConfirmPw] = useState(null);
    const [returnHome, setReturnHome] = useState(false);

    const {state, fetch, isLoading, uiError, reset: reset2} = useFetch();
    const inputRef = useRef(null);
    const [pwUpdated, setPwUpdated] = useState(false);
    const [transitionClose, setTransitionClose] = useState(true);
    const [showPasswordUpdatedModal, setShowPasswordUpdatedModal] = useState(false);
    const leftbutton = "https://img.mirkat.com/web/icons/leftbutton.svg";
    const [pwAutofocus, setPwAutofocus] = useState(false);
    const [pwSubmitted, setPwSubmitted] = useState(false);

    useEffect(() => {
        //screen('Change Password', {screenType: 'changePassword', entryType: 'reset'});
        if (inputRef.current) {
            inputRef.current.focus();
        }
        setIsMounted(true);
        
        return () => {
            setHiddenSettings(false);
        }

    }, []);

    useClearModals(clearModals);

    const isOnline = useDetectNetwork();
    useEffect(() => {
        if(!isOnline){
            setError("No network connection")
        }
        if(isOnline && error === "No network connection") {
            setError("")
        }
    }, [isOnline])

    const delayedClose = () => {
        setTransitionClose(false);
        setTimeout(() => {setShowChangePasswordModal(false)}, 500)
        //exitCallback(false);
    };

    //setHiddenSettings(false);
    const [pwOkay, setPwOkay] = useState(false);

    useEffect(() => {
        if(pwOkay) {
            setHiddenSettings(false);
        }
    }, [pwOkay])

    useEffect(() => {
        if(state.matches('success')) {
            //#252
            track('User Changed Password', {eventType: 'changePassword'});
            //#69
            track('User Password Updated', {
                eventType: 'passwordUpdated',
            })
            //onSuccess();
           // setPwOkay(true);
            setOldPw(null);
            setNewPw(null);
            setConfirmPw(null);
            //setPwUpdateModal(true);
            setShowPasswordUpdatedModal(true);
        }
        if(state.matches('failure')) {
            let {canRetry, errMsg }= retryableError({errorBody: state.context.error})
            setError(errMsg);
            console.log(errMsg)
            setResetKey(Date.now())
            reset({
                confirmpassword: ''
            })
            if (state.context?.error?.errorCode === 48) {
                console.log("autofocus try")
                setPwAutofocus(true);
            }
            setConfirmPw(null);
            setPwSubmitted(false);
            reset2();
        }
        console.log(state)
        console.log("FormState: ", formState)
        console.log("")

    }, [state, pwAutofocus]);

    useEffect(() => {
        console.log(formState)
    }, [formState]);

    useEffect(() => {
        console.log(oldPw, newPw, confirmPw)
        if(oldPw && newPw && confirmPw) {
            if(newPw === confirmPw) {
                fetch('changePassword', [newPw, oldPw]);
            }
        }

    }, [oldPw, newPw, confirmPw, error]);


    //Need to get the Reset-Token...
    //get url from email, submit with timestamp and signature

    //check new and confirm match
    //check old password is set and fire off test (modal)
    const onSubmit = (event) => {
        //DO NOT USE useForm's handleSubmit on this component. It does not work correctly.
        event.preventDefault();
        setPwSubmitted(true);
        const data = event.target;
        console.log("clicked")
        //#66
        track('Button Tapped',
            {buttonType: 'next',
                screenType: 'account',
                screenSubType: 'updatePassword'})

        track('Button Tapped', {
            buttonType: 'changePassword',
            screenType: 'settings'});
        const oldPassword = data.elements.oldPassword.value;
        const newPassword = data.elements.password.value;
        const confirmPassword = data.elements.password2.value;
        setOldPw(oldPassword);
        setConfirmPw(confirmPassword);
        setNewPw(newPassword);
    }


    useEffect( () => {
        console.log(isLoading);
        console.log(error);
    }, [isLoading, error, isError]);

    const password = watch('password');
    const watchOldPassword = watch('oldPassword');
    const watchConfirmPassword = watch('confirmpassword');

    const watchPw = watch('password');
    const watchPw2 = watch('confirmpassword');

    const validatePasswordsMatch = (value, data) => {
        const formData = data

        if(error && formData?.confirmpassword.length > 0){ // clear error if trying again
            setError(null)
        }
        if(formData?.confirmpassword.length >= 8 && formData?.password.length >= 8) {
            //don't check for error yet
            if(formData?.confirmpassword !== formData?.password) {
                setError("The passwords do not match");
                return false;
            }
        }
        return true;
    };

    const goBack = () => {
        track('Button Tapped', {
            buttonType: 'back',
            screenType: 'account',
            screenSubType: 'updatePassword',
        })
        if (windowWidth > 839) {
            setTransitionClose(false);
            setShowChangePasswordModal(false);
            if (hiddenSettings) {
                setHiddenSettings(false);
            }
        } else {
            delayedClose();
        }
            //setShowChangePasswordModal(false);
            //setModalType('');
            //toggleModal();
    }

    const validateCurrentPassword = (value) => {
        console.log("WHAT THE FUCK: ", value)
        if(error) {

            setError(null);
        }
    }

    const validateNewPassword = (value) => {
        if(error) {
            setError(null);
        }
    }

    const validateConfirmPassword = (value) => {
        if(error) {
            setError(null);
        }
    }
    //app shows "Password updated" in an overlay and darkens the background
    return (
        <>
        {windowWidth < 840 &&
        <Modal open={true} closeAfterTransition hideBackdrop={true} >
        <Slide direction="left" in={transitionClose} >
            <div className={styles["modal-container"]}>
                {showPasswordUpdatedModal && <PasswordUpdatedModal hideBackdrop={false} setShowChangePasswordModal={setShowChangePasswordModal} setHiddenSettings={setHiddenSettings} setPwOkay={setPwOkay}/>}
                <form className={styles["changepw-form"]} method="post" onSubmit={onSubmit}>
                    <div className={styles["changepw-container"]}>
                        <img className={styles["changepw-exit"]} src={leftbutton} alt="back arrow"
                             style={{cursor: "pointer"}} onClick={goBack}></img>
                        <div className={styles["changepw-header-container"]}>
                            <div className={styles["changepw-header"]}>Change password</div>
                        </div>
                        <div className={styles["changepw-container-inner"]}>
                            <div className={styles["changepw-main"]}>
                                <div className={styles["changepw-fields-container"]}>
                                    <div className={styles["changepw-fields-top"]}>
                                        <div className={styles["changepw-fields-top-int"]}>
                                            <Controller
                                                key={`oldPw-${resetKey}`}  // Apply the key here
                                                name="oldPassword"
                                                control={control}
                                                defaultValue=""
                                                rules={{required: true, minLength: 8, maxLength: 100, validate: validateCurrentPassword}}
                                                render={({field, fieldState}) => <Input2 field={field} maxLength={100}
                                                                                         label="Current password"
                                                                                         id="oldPassword"
                                                                                         autoComplete=""
                                                                                         autoFocus={pwAutofocus ? "false" : "true"}
                                                                                         inputRef={inputRef}/>}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles["changepw-fields-top"]}>
                                        <div className={styles["changepw-fields-top-int"]}>
                                            <Controller
                                                key={`pw-${resetKey}`}  // Apply the key here
                                                name="password"
                                                control={control}
                                                defaultValue=""
                                                rules={{required: true, minLength: 8, maxLength: 100, validate: validateNewPassword}}
                                                render={({field, fieldState}) => <Input2 field={field} maxLength={100}
                                                                                         label="New password"
                                                                                         id="password"
                                                                                         autoFocus={pwAutofocus}
                                                                                         autoComplete=""/>}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles["changepw-hint"]} style={{paddingBottom: "16px"}}>Use a password
                                    with at least 8 characters.
                                </div>
                                <div className={styles["changepw-fields-bottom"]}>
                                    <div className={styles["changepw-fields-bottom-int"]}>
                                        <Controller
                                            key={`confirm-${resetKey}`}  // Apply the key here
                                            name="confirmpassword"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: true,
                                                minLength: 8,
                                                maxLength: 100,
                                                validate: validatePasswordsMatch
                                            }}
                                            render={({field, fieldState}) => <Input2 field={field} maxLength={100}
                                                                                     label="Confirm new password"
                                                                                     id="password2" autoComplete=""/>}
                                        />

                                    </div>

                                </div>
                                <div className={styles["error"]} id="error-message">{error}</div>
                                <Button type="submit" isDisabled={!formState.isValid || pwSubmitted} buttonText="Change password"/>
                            </div>


                        </div>
                    </div>
                </form>
            </div>
        </Slide>
        </Modal>}
        {windowWidth > 839 &&
        <Modal open={true} hideBackdrop={true}>
            <div className={styles["modal-container"]}>
                {showPasswordUpdatedModal && <PasswordUpdatedModal hideBackdrop={false} setShowChangePasswordModal={setShowChangePasswordModal} setHiddenSettings={setHiddenSettings} setPwOkay={setPwOkay}/>}

                <form className={styles["changepw-form"]} method="post" onSubmit={onSubmit}>
                    <div className={styles["changepw-container"]}>
                        <img className={styles["changepw-exit"]} src={leftbutton} alt="back arrow"
                             style={{cursor: "pointer"}} onClick={goBack}></img>
                        <div className={styles["changepw-header-container"]}>
                            <div className={styles["changepw-header"]}>Change your password</div>
                        </div>
                        <div className={styles["changepw-container-inner"]}>
                            <div className={styles["changepw-main"]}>
                                <div className={styles["changepw-fields-container"]}>
                                    <div className={styles["changepw-fields-top"]}>
                                        <div className={styles["changepw-fields-top-int"]}>
                                            <Controller
                                                key={`oldPw-${resetKey}`}  // Apply the key here
                                                name="oldPassword"
                                                control={control}
                                                defaultValue=""
                                                rules={{required: true, minLength: 8, maxLength: 100, validate: validateCurrentPassword}}
                                                render={({field, fieldState}) => <Input2 field={field} maxLength={100}
                                                                                         label="Current password"
                                                                                         id="oldPassword"
                                                                                         autoComplete=""
                                                                                         autoFocus={pwAutofocus ? "false" : "true"}
                                                                                         inputRef={inputRef}/>}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles["changepw-fields-top"]}>
                                        <div className={styles["changepw-fields-top-int"]}>
                                            <Controller
                                                key={`pw-${resetKey}`}  // Apply the key here
                                                name="password"
                                                control={control}
                                                defaultValue=""
                                                rules={{required: true, minLength: 8, maxLength: 100, validate: validateNewPassword}}
                                                render={({field, fieldState}) => <Input2 field={field} maxLength={100}
                                                                                         label="New password"
                                                                                         id="password"
                                                                                         autoFocus={pwAutofocus}
                                                                                         autoComplete=""/>}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles["changepw-hint"]} style={{paddingBottom: "16px"}}>Use a password
                                    with at least 8 characters.
                                </div>
                                <div className={styles["changepw-fields-bottom"]}>
                                    <div className={styles["changepw-fields-bottom-int"]}>
                                        <Controller
                                            key={`confirm-${resetKey}`}  // Apply the key here
                                            name="confirmpassword"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: true,
                                                minLength: 8,
                                                maxLength: 100,
                                                validate: validatePasswordsMatch
                                            }}
                                            render={({field, fieldState}) => <Input2 field={field} maxLength={100}
                                                                                     label="Confirm new password"
                                                                                     id="password2" autoComplete=""/>}
                                        />

                                    </div>

                                </div>
                                <div className={styles["error"]} id="error-message">{error}</div>
                                <Button type="submit" isDisabled={!formState.isValid || pwSubmitted} buttonText="Change password"/>
                            </div>


                        </div>
                    </div>
                </form>
            </div>
        </Modal>}
        </>
)
}

export default ChangePassword;

import React, {useEffect,  useState, useRef} from "react";
import {useAuth} from "../contexts/authContext";
import "../styles/scss/Login.scss";
import {Link, useLocation} from "react-router-dom";
import Button from "./Button";
import {useNavigate} from "react-router-dom";
import styles from "../styles/scss/Login.module.scss";
import Input2 from "./Input2";
import {useForm, Controller} from "react-hook-form";
import {retryableError} from "../util/retryableError";


import Fade from '@mui/material/Fade';
import {track, screen, identify} from "../util/analytics";
import useQueryStringParams from "../hooks/useQueryStringParams";

import ProgressBar from "./ProgressBar";
import useProgressBar from "../hooks/useProgressBar";
import useClearModals from "../hooks/useClearModals";
import useDetectNetwork from "../hooks/useDetectNetwork";

const LoginForm2 = ({regSend}) => {
    const location = useLocation();
    const email =  "";
    const { register, handleSubmit, trigger, formState,
        setValue,
        control, watch, reset} = useForm({mode: "onChange", defaultValues: {email: email}, reValidateMode: "all"});

    const [resetKey, setResetKey] = useState(Date.now());
    const [bText, setButtonText] = useState('Log in')
    const { authState, authService, isReady, stateSteady }= useAuth();
    const [error, setError] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const dynamic = {height: windowWidth > 839 ? "unset" : windowHeight}
    //const [clearModals, setClearModals] = useState(false);

    const [isLoaded, setIsLoaded] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const {qEmail, qCampaign} = useQueryStringParams();


    const { progressStarted, apiStarted, isComplete, startProgress, handleCompletion,  setLastApiCallStarted, setProgressOverride, progressOverride} = useProgressBar();


    const [isLoading, setIsLoading] = useState(true)
    const inputRef = useRef(null);
    const [alreadyScreen, setAlreadyScreen] = useState(false);
    const [emailSet, setEmailSet] = useState(false);
    const leftbutton = "https://img.mirkat.com/web/icons/leftbutton.svg";

    const [build, setBuild] = useState("");
    const env = process.env.REACT_APP_ENV;
    const commitHash = process.env.REACT_APP_COMMIT_HASH;


    useEffect(() =>{
        if(env !== 'prod') {
            setBuild(commitHash|| "dev");
        }
    },[env])


    //Chrome issue only -> Disable autocomplete after autofill has run once.
    useEffect(() => {
        screen("Sign-up: Email", {screenType: "signUp", screenSubType: "email"})
        setTimeout(() => {
            document.querySelectorAll('input').forEach(input => {
                input.setAttribute('autocomplete', 'off');
            });
        }, 500);
    }, []);


    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    });


    const isOnline = useDetectNetwork();
    useEffect(() => {
        if(!isOnline){
            setError("No network connection")
        }
        if(isOnline && error === "No network connection") {
            setError("")
        }
    }, [isOnline])




    if(location.pathname === "/") {
        navigate("/login")
    }
    //for clearing Modals
    //setClearModals(true);
    useClearModals(true);

    //Copied from GettingStarted page
    //You can remove this when the stripe redirect does not redirect to the index / page
    const searchParams = new URLSearchParams(location.search);

    const sessionId = searchParams.get('session_id');

    if (sessionId) {
        //redirect from stripe -> go to /welcome
        navigate("/welcome?session_id=" + sessionId);
    }

    useEffect(() => {
        if(location.pathname === "/") {
            navigate("/login")
        }
        if(authState.context.emailLogin && !emailSet) {
            setValue("email", authState.context.emailLogin)
            setEmailSet(true)
        }
        console.log("Email: ", email)
        console.log("Location: ", location.state)
    }, [location, navigate, emailSet, location.state, email]);

    useEffect(() => {
        if (windowWidth < 840) {
            document.body.style.background = "#FFFFFF"
        }
        return () => {
            document.body.style.background = ""
        };
    }, []);

    ////////////////////////
    useEffect(() => {
        if(qCampaign) {
            authService.send({type: 'SET_AD_CAMPAIGN', campaignId: qCampaign})
        }
        setIsMounted(true);
        setTimeout(() => setIsLoaded(true), 200); //flicker workaround
        //setIsLoaded(true) // or we do this and wait for stateSteady
        authService.send('FINISH_PROGRESS')
        setProgressOverride(null)
        return () => {
            authService.send('FINISH_PROGRESS')
            setProgressOverride(null)
        }

    }, []);

    useEffect(() => {
        if(location.pathname !== "/") {
            screen('Sign-In', {screenType: 'signIn'});
        }
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    // Set up an event listener for window resizing
    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const onSubmit = (data) => {
       // setTimeout(() => {
       //     setProgressOverride(100)
       // }, 500)
       // return;
        //#43
        if(submitted){
            return;
        }
        setSubmitted(true); // should change button to disabled...
        track('Button Tapped', {
            buttonType: 'next',
            screenType: 'signIn',

        })
        console.log("WTF")
        const email = data.email
        const password = data.password
        //let the AuthMachine handle the login
        authService.send('START_PROGRESS')
        startProgress()
        authService.send({ type: 'LOGIN', email: email, password: password})
        setLastApiCallStarted(true)
        setResetKey(Date.now())
    }

    const onClick = () => {
        //#45
        track('Button Tapped', {
            buttonType: 'forgotPassword',
            screenType: 'signin',

        })
    }

    useEffect(() => {
        if (!submitted) {
            if (!authState.matches('unauthenticated') && stateSteady) {
                if (!authState.matches('authenticated')) {
                    authService.send('BACK');
                    console.log("WHY IS THIS FIRING");
                }
            }
        }
    }, [submitted])

    useEffect( () => {
        console.log(authState.context.errorData)

        if(isReady && submitted && authState.matches('confirm') && !authState.context.errorData) {
            //wait for progress bar to finish
            setProgressOverride(100)
            regSend('CONFIRM')
            authService.send('FINISH_PROGRESS')
            setSubmitted(false)
        }
         if(isReady && authState.context.errorData ) {
             setProgressOverride(100)
             console.log("errors")
            setButtonText('Log in')
            let {canRetry, errMsg }= retryableError({errorBody: authState.context.errorData})
             setError(errMsg);

            //setError(errMsg);
             authService.send('FINISH_PROGRESS')
             authService.send('CLEAR_ERROR_DATA')

             console.log("Is complete... and error")
             //setResetKey(Date.now())
             //reset({
                 //email: '',
             //    password: ''
            // })


        }

        if(isReady && isMounted && stateSteady && authState.matches('authenticated')) {
            setProgressOverride(100)
            authService.send('FINISH_PROGRESS')
            navigate("/loading")
        }

        //if(authState.context.emailAddress) {
        if(isReady && !qEmail && !email && authState.context.emailLogin) {
            setValue("email", authState.context.emailLogin)
        }


        if(isReady && qEmail && !email && !authState.context.emailLogin) {
            setValue("email", qEmail)
        }
        console.log("IsComplete: ", isComplete)

    }, [authState.value, authState.context.errorData, register, qEmail, isComplete, error]);


    useEffect(() => {
        if(email) {
            setValue("email", email)
        }
        console.log("Got from email: ", email)

    }, [email, setValue])

    useEffect(() => {
        console.log("Progress started: ", progressStarted)
    }, [progressStarted, isComplete]);

    const [isautofilled, setisautofilled] = useState(false);
    useEffect(() => {
        authService.send("CLEAR_ERROR_DATA")
        const handleAnimationStart = (e) => {
            console.log("ANIMATION STARTED: ", e.animationName)
            console.log(e)
            if (e.animationName === "mui-auto-fill") {
                console.log("AUTO FILLED")
                setisautofilled(true);
                const checkEmail = trigger("email")
            }
        }
        //add event listener for autofill
        // Attach the event listener to the window object
        window.addEventListener('animationstart', handleAnimationStart);

         // Cleanup function to remove the listener when the component is unmounted
         return () => {
             window.removeEventListener('animationstart', handleAnimationStart);
         };

     }, []);

    useEffect(() => {
        console.log("EMAIL ", document.querySelector("#email"))
        let isAutofilled = false

        if(window && window?.getComputedStyle && document?.querySelector("#email")) {
            isAutofilled = window?.getComputedStyle(document?.querySelector("#email"), ":-webkit-autofill")?.getPropertyValue("background-color") !== 'rgb(255, 255, 255)';
        }
        if (isAutofilled) {
            // The field was autofilled, so we should trigger the validation manually
            console.log("WAS AUTOFILLED")
            setisautofilled(true);
        }
});
    const watchEm = watch("email");
    const watchPw = watch("password");
const validateEmail = (email) => {
    console.log("FORM IS VALID ", formState.isValid)
    console.log("Email: ", watchEm)
    if(error){ //clear error if valid email and typing again
        setError(null)
        setSubmitted(false)
    }

}
const validatePassword = (password) => {
    console.log("Password: ", watchPw)
    if(error){//clear error if typing again
        setError("")
        setSubmitted(false)

    }
}
    return (

        stateSteady ? (
        <>{progressStarted && <ProgressBar progressOverride={progressOverride} apiStarted={apiStarted} onCompleted={handleCompletion} setProgressOverride={setProgressOverride} />}
        <Fade in={true} timeout={500}>

        <form style={{ width: "100%" }} method="post" onSubmit={handleSubmit(onSubmit)}>

            <div className={styles["login-container"]} style={dynamic}>

              <div className={styles["login-inner-container"]}>
                <div className={styles["login-container-navigation"]} />
                    <div className={styles["login-header-container"]}><div className={styles["login-header-text"]}>Log in to Mirkat</div></div>
                    <div className={styles["login-fields-container"]}>
                        <Controller
                            key={`email-${resetKey}`}  // Apply the key here
                            control={control}
                            name="email"
                            defaultValue={email}
                            rules={{ required: true, maxLength: 100, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, validate: validateEmail}}
                            render={({ field, fieldState }) => <Input2 field={field} isAutofilled={isautofilled} height={59} maxLength={100} label="Email address" type="email" id="email" autoComplete="email" />}
                        />

                        <Controller
                        key={`password-${resetKey}`}  // Apply the key here
                        name="password"
                        control={control}
                        defaultValue=""
                        rules={{required: true, minLength: 8, maxLength: 100, validate: validatePassword}}
                        render={({ field, fieldState }) =><Input2 field={field} isAutofilled={isautofilled} maxLength={100} label="Password" id="password" autoComplete="current-password" />}
                        />

                        <div className={styles["error"]} id="error-message">{error}</div>

                    </div>
                  <Button isDisabled={(!formState.isValid && !error)} buttonText={bText}  />
              </div>

              <div className={styles["bottom"]}>
                <div className={styles["bottom-inner-container"]}>
                    {build ? windowWidth < 839 ? 
                        <div className="build-text" style={{color: "#231C3F"}}>{build  && "Build:"} {build}</div> 
                        : 
                        null
                    : null}
                    <div className={styles["bottom-text"]}><Link className="link" to="/reset" onClick={onClick}>Forgot password?</Link></div>
                    <div className={styles["bottom-text-plain"]}>Don't have an account?</div>
                    <div className={styles["bottom-text-two"]}><Link className="link" to="/signup" onClick={onClick}>Get started for free</Link></div>
                </div>
              </div>

            </div>
        </form>
        </Fade>
        </>): <div></div>





    );

}

export default LoginForm2;

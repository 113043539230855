

// /verify/gAAAAABlao_uAEz59hDy_Und6PyKPuxvycHb-Mqf-JrVqqz6tjm4t1HHTm9hRZhQ0I6xmuz4TypyJHdAKDQ8SAA4baeuvQzwKBjZzRBtYL9QGJ2LB6mXLcsiud7K4OUM8CBkkcEXOcuax3l2sSvpGPYDYQMWaGyZBepgw4X5RpM1Wc0_-65E6RbWilt_3nb5ciZRfwnpuEtlZH14vT1YmG1GH16D3X3ao3IwZOQ5-cY2Q1LnvoPN9US75lFE-6as8PtPjizKbruXmj4ow0Cm95xARLG2KjmfUWAx7ECQx1w076wNKb3f-13_njOtXMPKaOh56a8ccn2uzDybDB9OcqQLUl5lQBT1J3mMeWK9r1512roeYleYyd_BDXP1Z_XrQI3kExgblkmXOiygYpPRzSPK7akunzmzC2kixfzU8u0olWUxeXDITKLXK6-vc9_a7U4t4nEdmj9Fvc5DOpbDFA7N97l1UBiQCNSFluvkEEcizMNPRBh2JWU=?r=email&tID=e789f5ac-f7c4-4736-8909-79555a423f13&vID=1&nID=c_1_a2ad95c0-bf3d-40ba-9b0f-d959cc183b46
import {getQueryString, getConfirmationToken, getAllQueryStrings, getAllQueryStringsAsString} from "../util/urlTools";
import {useEffect, useState} from "react";

const useQueryStringParams = () => {
    const [email, setEmail] = useState(null);
    const [campaign, setCampaign] = useState(null);
    const [code, setCode] = useState(null);

    useEffect(() => {
        setEmail(getQueryString('email'));
        setCampaign(getQueryString('c'));
        setCode(getQueryString('code'));
    }, []);

    //q to denote it came from query string since we use those names elsewhere
    return {qEmail: email, qCampaign: campaign, qCode: code};

}

export default useQueryStringParams;



//campaign, email, verify


export const useGetConfirmationToken = () => {
    const [token, setToken] = useState(null);

    useEffect(() => {
        console.log("IN conf token")
        console.log(getConfirmationToken())
        setToken(getConfirmationToken())
    }, []);
    
    return {qConfToken: token}
}


export const useGetAllQueryStringParams = () => {
    const [params, setParams] = useState(null);

    useEffect(() => {
        console.log("IN conf token")
        console.log(getAllQueryStrings())
        setParams(getAllQueryStrings())
    }, []);

    return {qParams: params}
}

export const useGetAllQueryStringParamsAsString = () => {
    const [params, setParams] = useState(null);

    useEffect(() => {
        console.log("IN conf token")
        console.log(getAllQueryStringsAsString())
        setParams(getAllQueryStringsAsString())
    }, []);

    return {qParams: params}
}
//get jwt from url
//need authstate to be in needPw state?

//if jwt, we will move Authstate straight to needPw. Need pw needs Create wording changed for this?
//


//if url is jwt -> needPw state. AuthMachine needs something else -> csrf should be fine on init.
//IF user logged into another account already? do we need to intercept first? nah

//on password form, we will remove the goback stuff and icon if

//setPassword condition wont be hit because we won't have an installation id yet
import {useAuth} from "../contexts/authContext";
import {useFetch} from "./useFetch";
import {useEffect, useState, useRef} from "react";
import {retryableError} from "../util/retryableError";
import {useNavigate} from "react-router-dom";
import {track,screen, identify} from "../util/analytics";

//This will reset when password in props changes as password will be reset?
export function usePasswordV1Reset({v1Password, setV1Password, v1JWToken,  set1JWToken, setError, error, setPauseReload, startProgress, resetProgressBar, setLastApiCallStarted, setProgressOverride}) {
    const {authState, authService} = useAuth();
    const {fetch, state, reset, service } = useFetch();
    const navigate = useNavigate();
    const [bail, setBail] = useState(false);
    const [preventLoop, setPreventLoop] = useState(false);
    const prevPasswordRef = useRef();

    //do fetch and update useAuth. useAuth will provide useFetch with the ti_ni call data on init
    useEffect(() => {
        if(authState.matches('register_v1_user') && v1JWToken && v1Password && state.matches('idle')) {
            console.log("password and jwt set")
            //

            fetch('passwordV1', [v1Password, v1JWToken])
            setPreventLoop(false)
        }
        if(state.matches('success') && state.context?.data?.accessKey) {
            //user was created?

            track("User Password Updated", {
                eventType: "passwordUpdated",
            });
            track("User Signed Up", {
                eventType: "userSignedUp",
            });
            setProgressOverride(100)

            //we have an access key and are headed to authenticated state
            authService.send({type: 'V1PASSWORDSET', data: state?.context?.data})
            //send them on their way
            navigate('/loading')
        }

        //we can only reset if error is
        if(state.matches('failure') && v1Password && !preventLoop) {
            if (state.context?.error?.errorCode === 35 || (state.context?.error?.errorCode === 48)) {
                let {errMsg} = retryableError({errorBody: state?.context?.error})
                track("User Sign Up", {
                    eventType: "userSignUp",
                    eventSubType: "error",
                    stepType: "password",
                    errorCode: state.context?.error?.errorCode || null,
                });
                //reset()
                //the fetch reset isn't stopping it from looping back through here...
                setPreventLoop(true)
                setError(errMsg);
           } else if (state.context?.error?.errorCode === 28) {
                track("User Sign Up", {
                    eventType: "userSignUp",
                    eventSubType: "error",
                    stepType: "password",
                    errorCode: state.context?.error?.errorCode||null,
                });
                //redirect to signup
                setError("An unexpected error occurred. Please signup again.")
                setBail(true)
            }
            else {
                setError("An unexpected error occurred. Please try again later or contact support.")
                setBail(true)
            }
        }
        console.log("Error is: ", error)
        console.log("state: ", state)
    }, [authState, fetch, state, v1JWToken, v1Password, setBail, error, preventLoop]);

    useEffect(() => {
        const clearQueryString = () => {
            const url = new URL(window.location);
            url.search = ''; // Clear the query string
            window.history.replaceState({}, document.title, url);
        };
        if(state.matches('failure') && bail) {
            resetProgressBar()
            startProgress(true)
            setLastApiCallStarted(true)
            set1JWToken('')
            clearQueryString()
            setTimeout(() => {
                setProgressOverride(100)
                authService.send('ERROR')
            }, 250)
            setTimeout(() => {
                window?.location?.reload(true)
                //setPauseReload(true)
                navigate("/signup");


            }, 3000)
        }
    }, [state, bail]);
    return {state, service}
}

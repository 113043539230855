import React, {useContext, useState} from 'react';


export const ModalContext = React.createContext({
    modalVisible: false,
    toggleModal: () => {},
    setModalType: () => {},
});

export const useModal = () => {
    return useContext(ModalContext);
}

export const ModalProvider = ({ children }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [modalType, setModalType] = useState({});
    const [modalCount, setModalCount] = useState(0);
    const [threatState, setThreatState] = useState(false);
    const [mobileKebab, setMobileKebab] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [hiddenSettings, setHiddenSettings] = useState(false);
    const [homePage, setHomePage] = useState(false);

    const toggleModal = () => {
        setModalVisible(!modalVisible);
    };

    return (
        <ModalContext.Provider value={{ modalVisible, toggleModal, modalType, setModalType, threatState, setThreatState, showSettings, setShowSettings, homePage, setHomePage, modalCount, setModalCount, mobileKebab, setMobileKebab, hiddenSettings, setHiddenSettings}}>
            {children}
        </ModalContext.Provider>
    );
};

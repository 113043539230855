import React, { useReducer } from 'react';


//only for keeping track of call starts for progressbar
export const HomepageReducerInitialState = {
    fetch1: false,
    fetch2: false,
    fetch3: false,
    fetch4: false,
    fetch5: false,
    fetch6: false
};

// Reducer function to handle state changes
const HomepageReducer = (state, action) => {
    switch (action.type) {
        case 'TOGGLE_FETCH1':
            return { ...state, fetch1: !state.fetch1 };
        case 'TOGGLE_FETCH2':
            return { ...state, fetch2: !state.fetch2 };
        case 'TOGGLE_FETCH3':
            return { ...state, fetch3: !state.fetch3 };
        case 'TOGGLE_FETCH4':
            return { ...state, fetch4: !state.fetch4 };
        case 'TOGGLE_FETCH5':
            return { ...state, fetch5: !state.fetch5 };
        case 'TOGGLE_FETCH6':
            return { ...state, fetch6: !state.fetch6 };
        // ... handle other actions for bool3, bool4, bool5, bool6
        default:
            return state;
    }
};

export default HomepageReducer;
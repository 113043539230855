import React, {useState, useEffect} from "react";
import LoginFlow from "../components/LoginFlow";
import "../styles/scss/LoginPage.scss";
import {screen} from "../util/analytics";

const LoginPage = () => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const dynamic = {height: windowWidth > 500 ? "unset" : windowHeight}

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);


    // Set up an event listener for window resizing
    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
            <div className="onBoarding">
                <div className="login-row">
                    <div className="login" >
                        <LoginFlow />
                    </div>
                 </div>
            </div>
    );
}

export default LoginPage;

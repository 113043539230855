import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import './styles/scss/Index.scss';
import App from "./components/App";
import { MirkatClientProvider } from "./contexts/mirkatClientContext";
import { AuthProvider } from "./contexts/authContext";
import { StorageProvider } from "./contexts/storageContext";
import { ModalProvider } from "./contexts/ModalContext";
import { BroadcastEventHandler } from "./contexts/eventHandle";
import { SettingsProvider } from "./contexts/settingsContext";

import {createTheme, ThemeProvider} from "@mui/material/styles";

import ReactPixel from 'react-facebook-pixel';

const options = {
    autoConfig: true,  // set pixel's autoConfig
    debug: false,       // enable logs
};

const initPixel = () => {
    ReactPixel.init('827302899030278', null, options);
    ReactPixel.pageView();  // For tracking page views
};

if ((process.env.REACT_APP_ENV === 'prod') || (process.env.REACT_APP_ENV !== 'dev' && !localStorage.getItem('debug'))) {
        console.log = () => {};

}

                    //backgroundColor: 'rgba(0, 0, 0, 0.40)', // backdrop color for Modal over modal per figma background: rgba(35, 28, 63, 0.50);
const theme = createTheme({
    components: {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'unset', // backdrop color for Modal over modal per figma background: rgba(35, 28, 63, 0.50);
                },
            },
        },
    },
});

initPixel();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   // <StrictMode>
        <StorageProvider>
            <MirkatClientProvider>
                <AuthProvider>
                    <SettingsProvider>
                    <BroadcastEventHandler>
                        <ThemeProvider theme={theme}>
                            <ModalProvider>
                                <App/>
                            </ModalProvider>
                        </ThemeProvider>
                    </BroadcastEventHandler>
                    </SettingsProvider>
                </AuthProvider>
            </MirkatClientProvider>
        </StorageProvider>
   // </StrictMode>
);


const TruncateEmail = ({ email, maxLength }) => {
    if (email.length <= maxLength) {
        return email;
    }

    const [user, domain] = email.split('@');
    const partLength = (maxLength - 3) / 2; // 3 for the ellipsis and equal split for user and domain
    const truncatedUser = user.length > partLength ? user.substring(0, partLength) : user;
    const truncatedDomain = domain.length > partLength ? domain.substring(0, partLength) : domain;

    return `${truncatedUser}...@...${truncatedDomain}`;
};

export default TruncateEmail;

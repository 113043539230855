import React, {useEffect, useState} from 'react';
import '../styles/scss/App.scss';
import MainApp from "../pages/Main";
import DisplayModal from "./Modal";
import {useModal} from "../contexts/ModalContext";
import secureLocalStorage from "react-secure-storage";
import loadAnalytics from '../util/loadAnalytics';
import useInactivityLogout from "../hooks/useInactivityLogout";
import SessionExpiredModal from "../modals/SessionExpired";
import {preloadMainImages} from "../util/imageCache";

preloadMainImages();

const App = () => {
    const {sessionExpired} = useInactivityLogout(parseInt(process.env.REACT_APP_SESSION_IDLE_TIMEOUT) || 1800000);
    const [loading, setLoading] = useState(true);
    const {modalType} = useModal();

    useEffect(() => {
       // loadAnalytics(() => {});
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000); // After 2 seconds, the loading splash screen will disappear
        // Clean up function
        return () => clearTimeout(timer);
    }, []); // Empty array means this effect runs once when the component mounts

    //welcome https://img.mirkat.com/web/images/home/Mirkat.png

    return (
        <div className="App">
            {sessionExpired && <SessionExpiredModal props={true}/>}
            <DisplayModal modalType={modalType}/>
            <MainApp />
        </div>
    );
}

export default App;

import React from 'react'

const MobileKebab = () => {
    return (
        <svg width="5" height="25" viewBox="0 0 5 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2.5" cy="2.5" r="2.5" fill="white"/>
            <circle cx="2.5" cy="12.5" r="2.5" fill="white"/>
            <circle cx="2.5" cy="22.5" r="2.5" fill="white"/>
        </svg>
    )
}

export default MobileKebab;

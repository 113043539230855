import React from 'react'
import Settings from '../components/Settings';
import "../styles/scss/SettingsPage.scss";

const SettingsPage = () => {
    return (
        <div className="settings-page">
           <Settings />
        </div>
    )
}

export default SettingsPage;

import React, {useEffect, useState} from 'react';
import {useModal} from "../contexts/ModalContext";
import styles from "../styles/scss/DeleteAccountModal.module.scss";
import {useFetch} from "../hooks/useFetch";
import { useAuth } from "../contexts/authContext";
import DeletingAccountSpinnerModal from "../modals/DeletingAccountSpinnerModal";
import ErrorModal from "../modals/ErrorModal";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import {track, screen} from "../util/analytics";

async function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const DeleteAccountModal = ({callback, props, setShowDeleteModal}) => {
    // const [showModal, setShowModal] = useState(true);
    //const [emailToRemove, setEmailToRemove] = useState(email);
    const {modalVisible, toggleModal, setModalType} = useModal();
    const { state, fetch, isLoading, uiError, ready, reset} = useFetch()
    const { authState, authService } = useAuth();
    const [submitted, setSubmitted] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [loggedOut, setLoggedOut] = useState(false);
    const [error, setError] = useState(null);
    const [open, setOpen] = React.useState(props || false);
    const [hide, setHide] = useState(false);
    const [preventDup, setPreventDup] = useState(false);

    //const [open, setOpen] = React.useState(props || false);

    useEffect(() => {
        screen('Modal', {screenType: 'settings', screenSubType: 'deleteAccount', modalType: 'deleteAccount'})
    }, []);

    const onClickCancel = () => {
        //#265
        track('Modal Result', {modalType: 'confirmDeleteAccount', screenType: 'settings', result: 'cancelled'});
        setOpen(false);
        setShowDeleteModal(false);
    }

    const deleteAccount = () => {
        let reason = 'other'
        //need to collect reason....:
        //usingAnotherService, dontSeeValue, notEnoughRisks, notConcerned, other
        //#265
        track('Modal Result', {modalType: 'confirmDeleteAccount', screenType: 'settings', result: 'confirmed'});
        setDeleted(true);
    }

    useEffect(() => {
        //Error 39 = user already registered - should we redirect to login?
        if(state) {
            if(state.matches('idle') && deleted && !submitted) {
                setSubmitted(true)
                fetch('deleteAccount', [authState.context.userEmailId])
                //setModalType('')
               // toggleModal()
            }

        }
        if(state.matches('success') && deleted) {
            if(!preventDup) {
                //#269
                track('User Deleted Account', {eventType: 'deleteAccount'});
                setPreventDup(true)
            }

            sleep(3000).then(() => {
                authService.send("DELETE_ACCOUNT");
                setLoggedOut(true);
            })
        }
        if(state.matches('failure')) {
            sleep(3000).then(() => {
                setError(true)
                setTimeout(() => setDeleted(false), 125)
                setTimeout(() => setHide(true), 125)
                setSubmitted(false)

                reset() // not sure reset is even gonna work here...
                // setModalType('') //ensure we don't overlay the modal upon failure to delete and logout
                // toggleModal()

                //Show error modal
            });

        }
    }, [state, deleted, open, submitted, deleted, authState]);

    useEffect(() => {
        console.log("Error: ", error)
    }, [error, deleted]);

    return (
    <>
            <Modal
                open={open}
                onClose={onClickCancel} // this is only set if any click on the modal closes the modal. We want to only close on cancel or delete
                closeAfterTransition
            >
                <Fade in={true} >
            <div id="delete-account-overlay">
           <>{deleted && <DeletingAccountSpinnerModal props={deleted} />} </>
           <>{error && <ErrorModal props={error} dim={false} callback={setShowDeleteModal} screenType='settings' screenSubType='deleteAccount' />} </>
                <div className={styles["modal-container"]}>
                    {<Fade in={!hide}><div className={styles["m-container"]}>
                        <div className={styles["m-content"]}>
                            <div className={styles["m-header-text"]} id='header'>Delete your Mirkat account?</div>
                            <div className={styles["m-content-text"]}>{"By deleting your account, Mirkat will no longer monitor the dark web for your stolen data."}</div>
                        </div>
                        <div className={styles["m-action-container"]}>
                            <div className={`${styles["red-delete-button"]} noSelect`} id='delete-account-confirmed' onClick={deleteAccount}>
                                <div className={styles["red-delete-button-text"]}>Delete Account</div>
                            </div>
                            <div className={`${styles["purple-cancel-button"]} noSelect`} id='cancel-delete' onClick={onClickCancel}>
                                <div className={styles["purple-cancel-button-text"]}>Cancel</div>
                            </div>
                        </div>
                    </div></Fade>}
                </div>
        </div>
                </Fade>
            </Modal>
        </>
    );
}

export default DeleteAccountModal;

import React, {useState, useEffect, useRef} from "react";
import {useAuth} from "../contexts/authContext";

import Button from "./Button";
import useFormHandler from "../hooks/useFormHandler";
import '../styles/scss/ConfirmForm.scss';
import '../styles/scss/PasswordForm.scss';
import styles from "../styles/scss/PasswordForm.module.scss"
import {useFetch} from "../hooks/useFetch";
import {Controller, useForm} from "react-hook-form";
import Input2 from "./Input2";
import {retryableError} from "../util/retryableError";
import Fade from '@mui/material/Fade';

import {track,screen, identify} from "../util/analytics";
import useProgressBar from "../hooks/useProgressBar";
import ProgressBar from "./ProgressBar";
import {useNavigate} from "react-router-dom";
import useDetectNetwork from "../hooks/useDetectNetwork";
import {usePasswordV1Reset} from "../hooks/usePasswordV1Reset";
import {getQueryString} from "../util/urlTools";
import useQueryStringParams from "../hooks/useQueryStringParams";
import email from "./Email";



const PasswordV1Form = ({ prevForm, setStep, regSend, regState }) => {
    const { register, handleSubmit, formState,
        setValue,
        control, reset} = useForm({mode: "onChange", defaultValues: {code: ""}});
    const {authState, isReady, authService, stateSteady} = useAuth();
    const [resetKey, setResetKey] = useState(Date.now());
    const inputRef = useRef(null);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const { progressStarted, apiStarted, isComplete, startProgress, handleCompletion,  setLastApiCallStarted, setProgressOverride, progressOverride, resetProgressBar} = useProgressBar();
    const navigate = useNavigate();
    const [pwFocused, setPwFocused] = useState(false);
    const [v1Password, setV1Password] = useState("");
    const [v1JWToken, set1JWToken] = useState("");
    const {qEmail, qCampaign} = useQueryStringParams();
    const [campaignIdentified, setCampaignIdentified] = useState(false);
    const [pauseReload, setPauseReload] = useState(false); //we're going to navigate away
    const {state, reset: resetV1, service} = usePasswordV1Reset({v1Password, setV1Password, v1JWToken, setError, error, setProgressOverride, resetProgressBar, set1JWToken, setPauseReload, startProgress, setLastApiCallStarted });
    const [idCalled, setIdCalled] = useState(false);

    const isOnline = useDetectNetwork();
    useEffect(() => {
        if(!isOnline){
            setError("No network connection")
        }
        if(isOnline && error === "No network connection") {
            setError("")
        }
    }, [isOnline])


    useEffect(() => {
        screen("Sign-up: Password", {screenType: "signUp", screenSubType: "password"})

        setIsLoaded(true)
        const v1Jwt = getQueryString('jwt')
        if(v1Jwt) {
            set1JWToken(v1Jwt)
        } else {
            navigate("/signup")
        }
        return () => {
            setIsLoaded(false)
            setError(null)
            setProgressOverride(null)
        }
    }, [])

    useEffect(() => {
        if (qCampaign && !campaignIdentified) {
            //#43
            console.log("TEST92")
            identify(null, {adCampaignId: qCampaign})
            authService.send({type: "SET_ADCAMPAIGN_COHORT", adCampaignId: qCampaign})
            setCampaignIdentified(true)
            setIdCalled(true)
        }
    }, [qCampaign]);


    useEffect(() => {
        if(authState.matches('unauthenticated')) {
            authService.send('REGISTERV1')
        }
        if(authState.matches('authenticated')) {
            navigate("/home")
        }
    }, [authState]);

    useEffect(() => {
        const input = inputRef.current
        if (inputRef.current) {
            const length = input.value.length;
            input.focus();
            input.setSelectionRange(length, length);
        }
    });

    const validatePw = (pw) => {
        if(error) {
            setError(null)

        }
        return true;
    }
    const v1Pass = (data) => {
        service.send('RESET')

        track('Button Tapped', {
            buttonType: 'next',
            screenType: 'signup',
            screenSubType: "password"

        })
        startProgress()
        if(!v1JWToken) {
            setError("An unexpected error occurred. Please try again later or contact support.")
        }
        setLastApiCallStarted(true)

        setV1Password(data?.password)


    }

//handleSubmit(v1Pass)
    return (
        isLoaded && stateSteady ? (
            <>{progressStarted && <ProgressBar progressOverride={progressOverride} apiStarted={apiStarted} onCompleted={handleCompletion} setProgressOverride={setProgressOverride} />}
                <Fade in={true} timeout={500}>
                    <div className={styles["row"]}>
                        <div className={styles["container"]}>
                            <div className={styles["back"]}>
                            </div>
                            <form method="post" onSubmit={handleSubmit(v1Pass)}>
                                <div className={styles["inner-container"]}>
                                    <div className={styles["header"]}>
                                        <div className={styles["header-text"]}>Set a password for your Mirkat account</div>
                                    </div>
                                    <div className={styles["input-fields"]}>
                                        <div className={styles["input-field"]}>
                                            <div className={styles["input-inner-container"]}>
                                                <Controller
                                                    key={`password-${resetKey}`}  // Apply the key here
                                                    control={control}
                                                    name="password"
                                                    defaultValue=""
                                                    rules={{ required: true, minLength: 8, maxLength: 100, validate: validatePw }}
                                                    render={ ({ field, fieldState }) => <Input2 field={field} maxLength={100} label="Password" id="password" autoFocus={pwFocused} inputRef={inputRef}/>}
                                                />
                                            </div>
                                        </div>
                                        { (error && isComplete) && <div className="error" id="error-message">{error}</div> }
                                        { (!error || !isComplete) && <div className="error pwnotice">Use a password with at least 8 characters</div>}
                                    </div>
                                    <Button isDisabled={!formState.isValid || progressStarted} buttonText="Set Password" ></Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Fade>
            </>) : (<> </>)
    );
}

export default PasswordV1Form;
import {createMachine, assign, sendParent} from 'xstate';
import {CsrfMachine} from "./csrfMachine";
import AuthStorageService from "../services/authStorage";

//Will pass up to parent AuthMachine to update csrf and storage

const storage = new AuthStorageService();

//"I'm the decider"..... /s
const decider = (context) => (callback) => {
    console.log("decider: ", context)
    console.log(context?.errorData)
    if (context.retries >= maxRetries || !context?.errorData) {
        //need to send error //logout?
        callback({type: 'ERROR'})
    }
    const [retry, task] = retryableError({errorBody: context.errorData})
    //if retry, pass up to parent to update csrf etc and the client and try again with same event
    console.log(task)
    if (task === "UPDATECSRF") {
        console.log("do updatecsrf")
        callback({type: 'UPDATECSRF', canRetry: true, errorData: context.errorData})

    }
    if (task === "GOLOGIN") {
        console.log("HERE!@")
        callback({type: 'GOLOGIN', canRetry: false, errorData: context.errorData})

    }
    if (task === "LOGOUT") {
        callback({type: 'LOGOUT', canRetry: false, errorData: context.errorData})
    }
    if (task === "CONFIRM") {
        console.log("CONFIRM ERROR 37");
        callback({type: 'CONFIRM', canRetry: false, errorData: context.errorData})
    }

    callback({type: 'UNHANDLED', canRetry: false, errorData: context.errorData})
}

//auto retry -> update csrf, secret, session etc && retry same request

//else NO -> fail and logout
//can retry: true/false, task -> LOGOUT, UPDATECSRF  -- If network error, api client should auto retry..... not here
function retryableError({errorBody}) {
    console.log("errorBody: ", errorBody)
    if (errorBody && errorBody.hasOwnProperty('errorCode')) {
        console.log("ERROR CODE: ", errorBody.errorCode)
        switch (errorBody?.errorCode) {
            case 3:
                //case 3: //AuthState needs to LOGOUT/nuke cookies. likely (possibly?)need to resignup
                //keeping this separate from 5,34,44 as refresh "should" work but previously we had to logout (remove local storage data and clear contexts)
                return [true, "UPDATECSRF"];
            case 6:
                //case 6: //we could get a 403 && a case 6,
                //LOGOUT
                //Fire refresh or logout
                return [false, "LOGOUT"];
            case 9:
                //remove installation id and start over signin/signup
                storage.removeInstallationId();
                return [false, "LOGOUT"];
            case 5: case 34: case 44:
                //case 5: //session expired, calling update csrf updates session too
                //case 34: //invalid csrf... call update csrf
                //case 44: //invalid session/session missing - call update csrf endpoint
                //then retry request again - client needs to be updated/rerendered...
                return [true, "UPDATECSRF"];
            case 37:
                return [false, "CONFIRM"];
            case 39:
                //case 39: //no retry, should login instead
                return [false, "GOLOGIN"];
            default:
                break;
        }
    }
    return [false, ""];
}
function logEvent(context, event) {
    console.log('Received event:', event);
    console.log('Current context:', context);
}
const maxRetries = 3;
export const ErrorMachine = createMachine({
    id: 'error',
    initial: 'getError',
    context: {
        apiClient: null,
        csrf: null,
        secret: null,
        error: false,
        errorData: null,
        retries: 0,
        //possibly can add accessToken and refreshToken in here at some point.
    },

    states: {
        getError: {
            entry: logEvent,
            invoke: {
                id: 'decideIt',
                src: decider,
            },
            on: {
                UPDATECSRF: {
                    target: 'updateCSRF',
                    actions: logEvent,
                },
                LOGOUT: {
                    target: 'done',
                    actions: [assign({errorData: (context, event) => event?.errorData || event?.data || event?.error}),
                        sendParent((context) => ({type: 'LOGOUT', errorData: context.errorData}))]
                },
                ERROR: {
                    target: 'error',
                    actions: [(context,event) => console.log("sigh."),
                        sendParent((context) => ({type: 'ERROR', errorData: context.errorData}))]
                },
                GOLOGIN: {
                    target: 'done',
                    actions: [
                        sendParent((context) => ({type: 'GOLOGIN', errorData: context.errorData}))] //Not autohandling, passing back up to AuthMachine

                },
                CONFIRM: {
                    target: 'done',
                    actions: [
                        sendParent((context) => ({type: 'CONFIRM', errorData: context.errorData}))] //Not autohandling, passing back up to AuthMachine

                },
                UNHANDLED: {
                    target: 'error',
                    actions: [(context,event) => console.log("WHAT THE FUCK: "), (context) => console.log(context.errorData),
                        (event) => console.log(event.errorData),
                        sendParent((context) => ({type: 'ERROR', errorData: context.errorData}))] // placeholder incase we want to do something else in the future.
                }
            },

        },
        updateCSRF: { //Handles csrf, secret and session errors (5,34,44) - 3 invalid session logs out//nuke
            //csrfMachine has it's own retry.. so we just do this once.
            invoke: {
                id: 'updateCSRF',
                src: (context) => CsrfMachine.withContext(context),
                data: {
                    apiClient: (context) => context.apiClient,
                },
                onDone: {
                    target: 'done',
                },
                onError: {
                    target: 'error',
                    actions: [sendParent((context) => ({type: 'ERROR'}))]
                },
            },
            on: {
                CSRF: {
                    target: 'done',
                    //whatever child machine called this, should update the csrf of the apiClient and retry the request and send the response back up to AuthMachine
                    actions: [ assign({
                        csrf: (context, event) => event.csrf,
                        secret: (context, event) => event.secret,
                    }), sendParent((context) => ({type: 'UPDATEDCSRF', csrf: context.csrf, secret: context.secret}))],
                },
                ERROR: {
                    target: 'error',
                    actions: [assign({
                        error: true,
                        errorData: (context, event) => event?.errorData || event?.data || event?.error,
                    }), sendParent((context) => ({type: 'ERROR', errorData: context.errorData}))],
                }
            }
        },
        done: {
            type: 'final',
        },
        error: {
            type: 'final',
        }
    },
}, {
    actions: {
        logWtfEntry: () => console.log("here999"),
        logContext: (context) => console.log("Context: ", context),
    }
});

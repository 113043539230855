import React, {useEffect, useState} from 'react';
import {useModal} from "../contexts/ModalContext";
import styles from "../styles/scss/DeleteAccountModal.module.scss";
import {useFetch} from "../hooks/useFetch";
import { useAuth } from "../contexts/authContext";
import {screen, track} from "../util/analytics";
import TruncateEmail from "../components/TruncateEmail";


const RemoveEmailModal = ({props}) => {
    // const [showModal, setShowModal] = useState(true);
    //const [emailToRemove, setEmailToRemove] = useState(email);
    const {modalVisible, toggleModal, setModalType} = useModal();
    const { state, fetch, isLoading, uiError, ready} = useFetch()
    const { authState, authService } = useAuth();
    const [submitted, setSubmitted] = useState(false);
    //const [removed, setRemoved] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [loggedOut, setLoggedOut] = useState(false);

    useEffect(() => {
        screen('Modal', {screenType: 'settings', modalType: 'removeEmailAddress'})
    }, [])

    const onClickCancel = () => {
        track('Modal Result', {screenType: 'settings', modalType: 'confirmRemoveEmailAddress', result: 'cancelled'})
        setModalType('')
        toggleModal()
    }

    const removeEmail = () => {
        track('Modal Result', {screenType: 'settings', modalType: 'confirmRemoveEmailAddress', result: 'confirmed'})
        props.callback(props.emailId,props.email);
        setModalType('')
        toggleModal()
    }

    //useEffect(() => {
    //    //Error 39 = user already registered - should we redirect to login?
    //    if(state) {
    //        if(state.matches('idle') && removed && !submitted) {
    //            setSubmitted(true)
    //            fetch('deleteEmail', [props.emailId])
    //        }

    //    }
    //    if(state.matches('success') && deleted) {
    //        callback(true)
    //        console.log("WE ARE IN SUCESS IN THE THING")
    //        setModalType('')
    //        toggleModal()
    //        authService.send("LOGOUT");
    //        setLoggedOut(true);

    //    }
    //    if(state.matches('failure')) {
    //        callback(false)
    //        setModalType('') //ensure we don't overlay the modal upon failure to delete and logout
    //        toggleModal()
    //            console.log("WE ARE IN FAILURE")
    //    }

    //}, [state, removed]);



    useEffect(() => {
        console.log(modalVisible);
    }, [modalVisible]);

    return (
        <div id="delete-account-overlay">
            { modalVisible && (
                <div className={styles["modal-container"]}>
                    <div className={styles["m-container"]}>
                        <div className={styles["m-content"]}>
                            <div className={styles["m-header-text"]} id='header' >Stop dark web monitoring?</div>
                            <div className={styles["m-content-text"]}>{"We will no longer monitor the dark web for threats linked to "}<TruncateEmail email={props.email} maxLength={42}/></div>
                        </div>
                        <div className={styles["m-action-container"]}>
                            <div className={`${styles["red-delete-button"]} noSelect`} id='remove-email-confirmed' onClick={removeEmail}>
                                <div className={styles["red-delete-button-text"]} style={{ fontSize: "17px", fontWeight: "700" }}>Remove email</div>
                            </div>
                            <div className={`${styles["purple-cancel-button"]} noSelect`} id='cancel-remove' onClick={onClickCancel}>
                                <div className={styles["purple-cancel-button-text"]}>Cancel</div>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            )}

        </div>
    );
}

export default RemoveEmailModal;

import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import "../styles/scss/Upgrade.scss";
import UpgradeExit from "../svg/UpgradeExit";
import WhiteCaret from "../svg/WhiteCaret";
import useStripe from "../hooks/useStripe";
import {useFetch} from "../hooks/useFetch";
import Slide from '@mui/material/Slide';
import {track, screen} from "../util/analytics";
import {useAuth} from "../contexts/authContext";
import AuthStorage from "../services/authStorage";
import useClearModals from "../hooks/useClearModals";
import GenericErrorModal from "../modals/GenericErrorModal";
import ProgressBar from "./ProgressBar";
import useProgressBar from "../hooks/useProgressBar";
import Fade from '@mui/material/Fade';
import styles from "../styles/scss/UpgradeNew.module.scss"
import useIsWindows from "../hooks/useIsWindows";
import {useSettings} from "../contexts/settingsContext";

const storage = new AuthStorage();

const UpgradeNew = ({ page, changeState, parentState, setIsPlus, props }) => {
    console.log("value is: ", page);
    const navigate = useNavigate();
    const {authState, stateSteady, authService} = useAuth()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    //const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    //const backgrounded = {background: page ? (windowWidth >= 650 ? '#231C3F' : 'transparent') : 'transparent'}
    const backgrounded = {background: page ? (windowWidth > 839 ? '#231C3F' : 'transparent') : 'transparent', gap: page ? (windowWidth > 839 ? '55px' : null) : null}
    //const backgroundTwo = {background: page ? (windowWidth >= 650 ? 'rgba(255, 255, 255, 0.06)' : 'transparent') : 'transparent'}
    const backgroundTwo = {background: page ? (windowWidth > 839 ? 'rgba(255, 255, 255, 0.06)' : 'transparent') : 'transparent', padding: page ? (windowWidth > 839 ? '35px 20px 20px' : '0px 20px 20px 20px') : '0px 20px 20px 20px'}
    //const dynamic = {height: windowWidth > 650 ? "100vh" : (otherWindowHeight > windowHeight ? "100%" : "100%") }
    const [clearModals, setClearModals] = useState(false);
    const { progressStarted, apiStarted, isComplete, startProgress, handleCompletion, setNoDelay, noDelay, setLastApiCallStarted, setProgressOverride, progressOverride, resetProgressBar} = useProgressBar();
    const [transitionClose, setTransitionClose] = useState(true);
    const [isWindows, isMac] = useIsWindows();

    const exposedc = "https://img.mirkat.com/web/images/home/exposedc.svg";
    const accountc = "https://img.mirkat.com/web/images/home/accountd.svg";
    const privatec = "https://img.mirkat.com/web/images/home/privated.svg";
    const identityc = "https://img.mirkat.com/web/images/home/identityd.svg";
    const creditc = "https://img.mirkat.com/web/images/home/creditd.svg";
    const bankingc = "https://img.mirkat.com/web/images/home/bankingd.svg";
    const checkMark = "https://img.mirkat.com/web/images/home/upgrade-checkmark.svg";

    const passwords = "https://img.mirkat.com/web/icons/upgrade_passwords.svg";
    const credentials = "https://img.mirkat.com/web/icons/upgrade_credentials.svg";
    const privatea = "https://img.mirkat.com/web/icons/upgrade_private.svg";
    const cards = "https://img.mirkat.com/web/icons/upgrade_cards.svg";
    const bank = "https://img.mirkat.com/web/icons/upgrade_bank.svg";
    const information = "https://img.mirkat.com/web/icons/upgrade_information.svg";


    useClearModals(clearModals);



    const {handlePaymentClick, isError, setDoUpgrade, state2, stripePaymentLink, resume, doUpgrade, reset: reset2, ready2, state: stripeState } = useStripe();
    const { state: state3, fetch: fetch3, ready: ready3 } = useFetch()
    const [genericErrorModal, setGenericErrorModal] = useState(false)
    const location = useLocation();
    const {price, duration} = useSettings();

	useEffect(() => {

		if (state2.matches('success')) {
			//setProgressOverride(100)
			console.log("do we get here?")
			setIsPlus(true)
			reset2()
		}


		if (stripePaymentLink) {
			//drop upgrade crumb
			if (location.pathname.startsWith("/upgrade")) {
				//upgrade page vs upgrade card
				//onboarding breadcrumb
				storage.setOnboardingBreadCrumb('t') //true
			}
			window.location.href = stripePaymentLink;
		}

	}, [doUpgrade, stripePaymentLink, state2, ready2, setIsPlus]);

        useEffect(() => {
            if(stateSteady && !authState.matches('authenticated')) {
                sessionStorage.removeItem("upgradeReturnBreadCrumb");
                navigate("/login")
                setClearModals(true);
            }
        }, [authState, stateSteady]);

		useEffect(() => {
			if(stripeState && stripeState.matches('failure') && doUpgrade && !genericErrorModal) {
				setDoUpgrade(false)
				setProgressOverride(100)
				setGenericErrorModal(true)
			}

			if(doUpgrade && stripeState && stripeState.matches('failure') && genericErrorModal) {
				setGenericErrorModal(false)
				reset2()
			}

		}, [stripeState, genericErrorModal, doUpgrade])
/*
		useEffect(() => {
			if(stripeUpgrade && state2 && (stripeUpgrade.matches('failure') || state2.matches('failure')) && !genericErrorModal) {
				setGenericErrorModal(true)
				console.log("ARE WE HERE?!!!!")
			}
			console.log("Upgrade state2: ", state2)
		}, [stripeUpgrade, state2, genericErrorModal])
*/

	const payment = () => {
                sessionStorage.removeItem("upgradeReturnBreadCrumb");
		console.log("payment")
		//#33?
		if(page) {
			track('Button Tapped', {buttonType: 'upgrade', screenType: 'signUp',
				screenSubType: 'plan',
				amount: price,
				currency: 'usd',
				frequency: duration,
			})

		} else {
			track('Button Tapped', {buttonType: 'upgrade', screenType: 'upgrade',
				amount: price,
				currency: 'usd',
				frequency: duration,
			})
		}

		if (location.pathname.startsWith("/upgrade")) {
			storage.setEntryType('ob') // will be signUp
		}
		storage.setBreadCrumb('f')
		startProgress()
		setLastApiCallStarted(true)
		reset2()
		setDoUpgrade(true);
	}

	const maybeLater = () => {
		storage.removeBreadCrumb()
		//#47 New
                sessionStorage.removeItem("upgradeReturnBreadCrumb");
		if(!page) {
			//#40
			track('Button Tapped', {
				buttonType: 'dismiss',
				screenType: 'menu',
				amount: price,
				currency: 'usd',
				frequency: duration,
			})
		} else {
			//#40
			track('Button Tapped', {
				buttonType: 'free',
				screenType: 'signUp',
				screenSubType: 'plan',
				amount: price,
				currency: 'usd',
				frequency: duration,
			})
		}
		fetch3('sendWelcomeEmail');
	}

	const onClickClose = () => {
		storage.removeBreadCrumb()
		track('Button Tapped', {buttonType: 'close', 'screenType': page ? 'signUp' : 'upgrade', screenSubType: 'plan'})
                changeState(!parentState)

	}
        const delayedClose = () => {
			storage.removeBreadCrumb()
            setTransitionClose(false);
            track('Button Tapped', {buttonType: 'close', 'screenType': page ? 'signUp' : 'upgrade', screenSubType: 'plan'})
            setTimeout(() => {changeState(!parentState)}, 500)
        };
				//<div className="upgrade-page-card-top-header-row">
				//    <div className="upgrade-page-card-top-header-row-text-two">
				//    </div>
				//</div>
                                //<div className="bbar-items-row" style={{ maxWidth: "650px", justifyContent: "unset", flexWrap: "wrap" }}>
			    //<div className="upgrade-page-why-item">
			    //    <img src={checkMark} className="upgrade-page-why-item-icon"></img>
			    //    <div className="upgrade-page-why-item-content">
			    //        Monitor multiple email addresses
			    //        <p className="upgrade-page-why-item-content-two">Find more threats associated with an unlimited number of your email addresses.</p>
			    //    </div>
			    //</div>
    //useEffect(() => {
    //    const handleResize = () => {
    //        setWindowHeight(window.innerHeight);
    //    };
    //    window.addEventListener('resize', handleResize);
    //    // Cleanup the event listener when the component unmounts
    //    return () => {
    //        window.removeEventListener('resize', handleResize);
    //    };
    //}, []);
			    
    useEffect(() => {
		if(page) {
			//#85
			//screen('Upgrade', {screenType: 'upgrade', entryType: 'card'})
			//#14
			screen('Sign-up: Plan', {screenType: 'signUp', screenSubType: 'plan', variantType: 'planSelection'})
		}
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const sendWelcome = () => {
    };
      //<Fade in={true} timeout={300}>
    return (
		<>{progressStarted && <ProgressBar progressOverride={progressOverride} apiStarted={apiStarted} onCompleted={handleCompletion} setProgressOverride={setProgressOverride} noDelay={noDelay} />}
			{genericErrorModal && <GenericErrorModal props={true} dim={true} callback={()=>{setGenericErrorModal(false)}} screenType={page ? 'signup' : 'upgrade'} screenSubType='plan'/>}
    {windowWidth < 840 &&
        <div className={styles["page"]}>
        <div className={styles["outer-container"]}>
            <div className={styles["header-container"]}>
                <div className={styles["header-one"]}>Choose your dark web monitoring plan</div>
                <div className={styles["header-two"]}>Get alerted when your stolen sensitive information is found on the dark web.</div>
            </div>
        <div className={styles["inner-container"]}>
            <div className={styles["free-container"]} style={{height: "259px", width: "330px"}}>
                <div className={styles["free-header"]}>Free Plan</div>
                <div className={styles["free-spacer"]} />
                <div className={styles["free-button-container"]}>
                    <Link to="/scanning" className={styles["free-button"]} onClick={maybeLater}>
                        <div className={styles["free-button-text"]}>
                            Continue for free
                        </div>
                    </Link>
                </div>
                <div className={styles["free-monitored-container"]}>
                    <div className={styles["monitored-header"]}>
                        What's monitored:
                    </div>
                    <div className={styles["monitored-item"]}>
                        <img className={styles["monitored-icon"]} src={passwords} />
                        <div className={styles["monitored-item-text"]} style={isWindows ? {padding: "0px"} : isMac ? {} : {}}>
                            Exposed passwords
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles["paid-container"]} style={{height: "471px", width: "330px"}}>
                <div className={styles["paid-header-one"]}>Mirkat+</div>
                <div className={styles["paid-header-two"]}>Free for 1 month</div>
                <div className={styles["paid-button-container"]}>
                    <Link className={styles["paid-button"]} onClick={payment}>
                        <div className={styles["paid-button-text"]}>Try Mirkat+ for free</div>
                    </Link>
                </div>
                <div className={styles["paid-monitored-container"]}>
                    <div className={styles["monitored-header"]}>
                        What's monitored:
                    </div>
                    <div className={styles["monitored-item"]}>
                        <img className={styles["monitored-icon"]} src={passwords} />
                        <div className={styles["monitored-item-text"]} style={isWindows ? {padding: "0px"} : isMac ? {} : {}}>
                            Exposed passwords
                        </div>
                    </div>
                    <div className={styles["monitored-item"]}>
                        <img className={styles["monitored-icon"]} src={credentials} />
                        <div className={styles["monitored-item-text"]} style={isWindows ? {padding: "0px"} : isMac ? {} : {}}>
                            Account credentials
                        </div>
                    </div>
                    <div className={styles["monitored-item"]}>
                        <img className={styles["monitored-icon"]} src={privatea} />
                        <div className={styles["monitored-item-text"]} style={isWindows ? {padding: "0px"} : isMac ? {} : {}}>
                            Private accounts
                        </div>
                    </div>
                    <div className={styles["monitored-item"]}>
                        <img className={styles["monitored-icon"]} src={cards} />
                        <div className={styles["monitored-item-text"]} style={isWindows ? {padding: "0px"} : isMac ? {} : {}}>
                            Credit & debit cards
                        </div>
                    </div>
                    <div className={styles["monitored-item"]}>
                        <img className={styles["monitored-icon"]} src={bank} />
                        <div className={styles["monitored-item-text"]} style={isWindows ? {padding: "0px"} : isMac ? {} : {}}>
                            Bank accounts
                        </div>
                    </div>
                    <div className={styles["monitored-item"]}>
                        <img className={styles["monitored-icon"]} src={information} />
                        <div className={styles["monitored-item-text"]} style={isWindows ? {padding: "0px"} : isMac ? {} : {}}>
                            Personal information
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
    }
    {windowWidth > 840 &&
        <div className={styles["page"]}>
        <div className={styles["outer-container"]}>
            <div className={styles["header-container"]}>
                <div className={styles["header-one"]}>Choose your dark web monitoring plan</div>
                <div className={styles["header-two"]}>Get alerted when your stolen sensitive information is found on the dark web.</div>
            </div>
        <div className={styles["inner-container"]}>
            <div className={styles["free-container"]}>
                <div className={styles["free-header"]}>Free Plan</div>
                <div className={styles["free-spacer"]} />
                <div className={styles["free-button-container"]}>
                    <Link to="/scanning" className={styles["free-button"]} onClick={maybeLater}>
                        <div className={styles["free-button-text"]}>
                            Continue for free
                        </div>
                    </Link>
                </div>
                <div className={styles["free-monitored-container"]}>
                    <div className={styles["monitored-header"]}>
                        What's monitored:
                    </div>
                    <div className={styles["monitored-item"]}>
                        <img className={styles["monitored-icon"]} src={passwords} />
                        <div className={styles["monitored-item-text"]} style={isWindows ? {padding: "0px"} : isMac ? {} : {}}>
                            Exposed passwords
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles["paid-container"]}>
                <div className={styles["paid-header-one"]}>Mirkat+</div>
                <div className={styles["paid-header-two"]}>Free for 1 month</div>
                <div className={styles["paid-button-container"]}>
                    <Link className={styles["paid-button"]} onClick={payment}>
                        <div className={styles["paid-button-text"]}>Try Mirkat+ for free</div>
                    </Link>
                </div>
                <div className={styles["paid-monitored-container"]}>
                    <div className={styles["monitored-header"]}>
                        What's monitored:
                    </div>
                    <div className={styles["monitored-item"]}>
                        <img className={styles["monitored-icon"]} src={passwords} />
                        <div className={styles["monitored-item-text"]} style={isWindows ? {padding: "0px"} : isMac ? {} : {}}>
                            Exposed passwords
                        </div>
                    </div>
                    <div className={styles["monitored-item"]}>
                        <img className={styles["monitored-icon"]} src={credentials} />
                        <div className={styles["monitored-item-text"]} style={isWindows ? {padding: "0px"} : isMac ? {} : {}}>
                            Account credentials
                        </div>
                    </div>
                    <div className={styles["monitored-item"]}>
                        <img className={styles["monitored-icon"]} src={privatea} />
                        <div className={styles["monitored-item-text"]} style={isWindows ? {padding: "0px"} : isMac ? {} : {}}>
                            Private accounts
                        </div>
                    </div>
                    <div className={styles["monitored-item"]}>
                        <img className={styles["monitored-icon"]} src={cards} />
                        <div className={styles["monitored-item-text"]} style={isWindows ? {padding: "0px"} : isMac ? {} : {}}>
                            Credit & debit cards
                        </div>
                    </div>
                    <div className={styles["monitored-item"]}>
                        <img className={styles["monitored-icon"]} src={bank} />
                        <div className={styles["monitored-item-text"]} style={isWindows ? {padding: "0px"} : isMac ? {} : {}}>
                            Bank accounts
                        </div>
                    </div>
                    <div className={styles["monitored-item"]}>
                        <img className={styles["monitored-icon"]} src={information} />
                        <div className={styles["monitored-item-text"]} style={isWindows ? {padding: "0px"} : isMac ? {} : {}}>
                            Personal information
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
        }
		</>
    );
}

export default UpgradeNew;

import React, {useEffect, useState, useRef} from 'react'
import SettingsMonitoring from "./SettingsMonitoring";
import SettingsNotifications from "./SettingsNotifications";
import SettingsSubscription from "./SettingsSubscription";
import SettingsAccount from "./SettingsAccount";
import '../styles/scss/Settings.scss';
import {useAuth} from "../contexts/authContext";
import {useNavigate} from "react-router-dom";
import {useModal} from "../contexts/ModalContext";
import ThreatHistory from "../components/ThreatHistory";
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import {screen} from "../util/analytics";
import useClearModals from "../hooks/useClearModals";

const Settings = ({ parentState, changeState, emails, refreshCallback, openSettingsMenu }) => {
    const {authState, isAuthenticated, isReady} = useAuth();
    const navigate = useNavigate();
    const [selectedComponent, setSelectedComponent] = useState('monitoring');
    const [clearModals, setClearModals] = useState(false);

    const [highlightMonitoring, setHighlightMonitoring] = useState(true);
    const [highlightNotifications, setHighlightNotifications] = useState(false);
    const [highlightSubscription, setHighlightSubscription] = useState(false);
    const [highlightAccount, setHighlightAccount] = useState(false);
    const {threatState,setThreatState, hiddenSettings, setHiddenSettings} = useModal();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [otherWindowHeight, setOtherWindowHeight] = useState(window.outerHeight);
    //const dynamic = {height: windowWidth > 500 ? "100vh" : "100vh"}
    //const dynamic = {height: windowWidth > 500 ? "100vh" : (otherWindowHeight > windowHeight ? "calc(100vh - 90px)" : windowHeight) }
    const dynamic = {height: windowWidth > 839 ? "100vh" : (otherWindowHeight > windowHeight ? "100%" : "100%") }
    const [loadedOnce, setLoadedOnce] = useState(false);
    const decider = hiddenSettings ? "settings-none" : "settings-background"
    const [transitionClose, setTransitionClose] = useState(true);
    const leftbutton = "https://img.mirkat.com/web/icons/leftbutton.svg";
    const breachesExitButton = "https://img.mirkat.com/web/images/home/breaches-exit-button.svg";
    const mainContainerRef = useRef(null);
    const [bodyPaddingRight, setBodyPaddingRight] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const liveIsLoaded = useRef(isLoaded);
    const [kick, setKick] = useState(false);
    const [scrollable, setScrollable] = useState(false);
    const [scrollbarPixels, setScrollbarPixels] = useState(0);

    useEffect(() => {
        liveIsLoaded.current = isLoaded;
    }, [isLoaded]);

    useEffect(() => {
        //console.error("RUN ONCE");
        setIsLoaded(true);

        let intervalId;

        const checkHeight = () => {
            const container = document.getElementById('settings-container');
            const settings = document.getElementById('settings-background');
            const total = parseInt(container?.clientHeight,10) + parseInt(container?.style.top,10);
                //console.error("TOTAL: ", total);
                //console.error("TOTAL: ", parseInt(window.innerHeight,10));
                //console.error("isLoaded: ", liveIsLoaded);
                if (total) {
                    //console.error("LIVE TOTAL: ", total);
                    setScrollable(true);
                    const scrollbarWidth = settings.offsetWidth - settings.clientWidth;
                    if (scrollbarWidth) {
                        //console.error("SCROLLBAR WIDTH: ", scrollbarWidth);
                        setScrollbarPixels(scrollbarWidth);
                    }
                    clearInterval(intervalId);
                } else { console.log("GOING AGAIN"); }
        };

        
        if (windowWidth > 839) {
            intervalId = setInterval(checkHeight, 100);
        }

        return () => clearInterval(intervalId);
    }, []);

    const delayedClose = () => {
        setTransitionClose(false);
        setTimeout(() => {changeState(!parentState)}, 500)
    };


    useEffect(() => {
        setLoadedOnce(true);
    });

    useClearModals(clearModals);

    useEffect(() => {
        if (windowWidth > 839) {
            const settings = document.getElementById('settings-background');
            //THIS IS AFTER SECOND RE-RENDER BUT BEFORE REFLOW
            //BOTH CAUSED BY CHANGED bodyPaddingRight VARIABLE
            if (bodyPaddingRight === 0) {
                //console.error("0");
                //AFTER SECOND RE-RENDER
                //REFLOW HAS NOT OCCURRED
                //SEQUENCING THIS OVERFLOW CHANGE INTO A SINGLE REFLOW BY BROWSER
                settings.style.overflow = 'auto';
            } else { 
                //AFTER SECOND RE-RENDER
                //REFLOW HAS NOT OCCURRED
                //SEQUENCING THIS OVERFLOW CHANGE INTO A SINGLE REFLOW BY BROWSER
                settings.style.overflow = 'hidden';
            }
        }
    },[bodyPaddingRight]);

    useEffect(() => {
        let observer;

        if (windowWidth > 839) {
            const container = document.getElementById('settings-container');
            const settings = document.getElementById('settings-background');
            const updatePadding = () => {
                //console.error("SELECTED: ", container.clientHeight, container.style.top);
                const total = parseInt(container.clientHeight,10) + parseInt(container.style.top,10);
                if (total > parseInt(window.innerHeight,10) && liveIsLoaded.current) {
                    //CAUSE SECOND RE-RENDER AND SCHEDULES REFLOW
                    setBodyPaddingRight(0);
                    //console.error("TOTAL: ", total);
                    //console.error("HEIGHT: ", parseInt(window.innerHeight,10));
                    //console.error("isLoaded: ", liveIsLoaded);
                } else if (liveIsLoaded.current) { 
                    const paddingRight = settings.offsetWidth - settings.clientWidth;
                    //console.error("PADDINGRIGHT: ", paddingRight);
                    //CAUSE SECOND RE-RENDER AND SCHEDULES REFLOW
                    setBodyPaddingRight(paddingRight);
                }
            }
            // Create a mutation observer to watch for changes in container's style
            observer = new ResizeObserver(updatePadding);
            observer.observe(container);
            updatePadding();
        }

        // Cleanup on return
        return () => {
            observer?.disconnect();
        };
    }, []);


    useEffect(() => {
		screen('Settings', {screenType: 'settings'});
        if(openSettingsMenu) {
            handleClick(openSettingsMenu);
        }
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
            setOtherWindowHeight(window.outerHeight);
            if (mainContainerRef.current) {
                //console.error("KICK RUNS");
                setKick(kick => !kick);
            }
        };
        window.addEventListener('resize', handleResize);
        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])


    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
		if(!authState.matches('authenticated')) {
			navigate('/login');
                        setClearModals(true);
		}
    }, [isReady, isAuthenticated, authState]);

    const components = {
	monitoring: <SettingsMonitoring fullEmails={emails} refreshCallback={refreshCallback} windowWidth={windowWidth} />,
	notifications: <SettingsNotifications />,
	subscription: <SettingsSubscription />,
	account: <SettingsAccount windowWidth={windowWidth} />,
    };

    const handleClick = ( name ) => {
        //CAUSE THE FIRST RE-RENDER AND SCHEDULES REPAINT
	setSelectedComponent(name);
        const settings = document.getElementById('settings-background');
        //DOES NOT CAUSE REFLOW SINCE SCROLLBAR EXISTS?
        if (scrollable && scrollbarPixels) {
            if (settings.style.overflowY === 'auto') {
                settings.style.overflowY = 'scroll';
            }
        }
	if (name === 'monitoring') {
	    setHighlightMonitoring(true);
	    setHighlightNotifications(false);
	    setHighlightSubscription(false);
	    setHighlightAccount(false);
	} else if (name === 'notifications') {
	    setHighlightNotifications(true);
	    setHighlightMonitoring(false);
	    setHighlightSubscription(false);
	    setHighlightAccount(false);
	} else if (name === 'subscription') {
	    setHighlightSubscription(true);
	    setHighlightMonitoring(false);
	    setHighlightNotifications(false);
	    setHighlightAccount(false);
	} else if (name === 'account') {
	    setHighlightAccount(true);
	    setHighlightMonitoring(false);
	    setHighlightNotifications(false);
	    setHighlightSubscription(false);
	};
    };

    useEffect(() => {
        if (mainContainerRef.current) {
            //const topPosition = window.innerHeight / 2 - mainContainerRef.current.clientHeight / 2;
            const topPosition = window.innerHeight / 2 - 425 / 2;
            //console.error(topPosition, window.innerHeight, mainContainerRef.current.clientHeight);
            mainContainerRef.current.style.top = `${topPosition}px`;
            mainContainerRef.current.style.position = "absolute";
        }
    }, [mainContainerRef,kick]);

	 //<>{threatState && <ThreatHistory parentState={threatState} changeState={setThreatState} /> }
    return (
        <>
        {windowWidth > 839 &&
        <div className={decider} style={dynamic} id="settings-background"><div className="settings-container" ref={mainContainerRef} style={{marginRight: bodyPaddingRight}} id="settings-container">
            <div className="settings-main-header-container">
                <img
                    onClick={() => changeState(!parentState)}
                    src={breachesExitButton}
                    className="settings-exit-button"
                    style={{ cursor: "pointer" }}
                />
                <h1 className="settings-main-header">Settings</h1></div>
            <div className="settings-inner-container">
	        <div className="settings-sidebar">
	    	    <div className="settings-sidebar-buttons">
	    		<button onClick={() => handleClick('monitoring')} className={highlightMonitoring ? 'settings-sidebar-1-highlight' : 'settings-sidebar-1'} id="monitoring"><div className="settings-sidebar-2"> <div className="settings-sidebar-3">Monitoring</div></div></button>
	    		<button onClick={() => handleClick('notifications')} className={highlightNotifications ? 'settings-sidebar-1-highlight' : 'settings-sidebar-1'} id="notifications"><div className="settings-sidebar-2"> <div className="settings-sidebar-3">Notifications</div></div></button>
	    		<button onClick={() => handleClick('subscription')} className={highlightSubscription ? 'settings-sidebar-1-highlight' : 'settings-sidebar-1'} id="subscriptions"><div className="settings-sidebar-2"> <div className="settings-sidebar-3">Subscription</div></div></button>
	    		<button onClick={() => handleClick('account')} className={highlightAccount ? 'settings-sidebar-1-highlight' : 'settings-sidebar-1'} id="account"><div className="settings-sidebar-2"> <div className="settings-sidebar-3">Account</div></div></button>
	    	    </div>
	    	</div>
	        <div className="settings-right">{components[selectedComponent]}</div>
            </div>
        </div></div>
        }
        {windowWidth < 840 && 
        <Slide direction="left" in={transitionClose}>
        <div className="settings-background" style={dynamic}>
            <div className="settings-main-header-container">
                <img src={leftbutton} alt="back arrow" className="settings-back-button" style={{cursor: "pointer"}} onClick ={delayedClose} />
                <h1 className="settings-main-header">Settings</h1>
            </div>
        <div className="settings-container" >
            <div className="settings-inner-container">
                <SettingsMonitoring refreshCallback={refreshCallback} windowWidth={windowWidth} />
                <SettingsNotifications loadedOnce={loadedOnce} />
                <SettingsSubscription />
                <SettingsAccount windowWidth={windowWidth} />
            </div>
        </div></div>
        </Slide>
        }
        </>
        
    )
}

export default Settings;

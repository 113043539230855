import {assign, createMachine, sendParent, actions} from "xstate";
import {createDataFetcher}   from "../util/fetchFactory";
import {parseMirkatAuthData} from "../util/mirkatAuth";
import {ErrorMachine} from "./errorMachine";
import {updateClient} from "../util/updateClient";

const maxRetries = 1;


const loginService = (context) => (callback, onReceive) => {
    let event = {method: 'login', params: [context.eventData.email, context.eventData.password]}
    if (context?.authData?.attemptLoginEmail && context?.authData?.attemptLoginPassword) {
        console.log("login retry automagic?")
        event = {method: 'login', params: [context.authData.context.attemptLoginEmail, context.authData.context.attemptLoginPassword]}
    }
   // const authState = {context: context.authData};
    const dataFetcher = createDataFetcher(context.apiClient, null);
    dataFetcher(context, event).then((data) => {
        data.emailAddress = context.eventData.email;
        let parsedData = parseMirkatAuthData(data);
        if(!parsedData.accessKey) {
            parsedData = { ...parsedData, 'needsConfirmation': true};
            callback({type: 'CONFIRM', data: parsedData});
        }

        if(parsedData.hasOwnProperty('accessKey') && parsedData.accessKey !== null) { // we set login if access key is present even if expired... need to fix
            parsedData = { ...parsedData, 'isAuthenticated': true};
        }
        //Add emailAddress to context from successful user input as we don't get it from the API after the first login
        if(parsedData.hasOwnProperty('emailAddress') && parsedData.emailAddress === null) {
            parsedData = {...parsedData, 'emailAddress': context.eventData.email};
        }
        console.log("PARSED DATA just before callback: ", parsedData)
        callback({type: 'DONE', data: parsedData});
    }, (error) => {

        callback({type: 'ERROR', data: error}); // ending up here

    });
}

export const LoginMachine = createMachine({
    id: 'login',
    initial: 'loggingIn',
    context: {
        apiClient: null,
        authData: null,
        errorData: null,
        eventData: null,
        data: null,
        retries: 0,
    },

    states: {
        loggingIn: {
            invoke: {
                id: 'loggingIn',
                src: (context) => loginService(context),
                //no onDone - callback handles it
                onDone: {
                    actions: [() => console.log("login done")],

                },
                onError: {
                    target: 'loginretry',
                    actions: [(context, event) => { console.log(event); console.log(context)},
                        assign({
                        retries: (context) => context.retries + 1,
                        error: true,
                    }),
                    ]
                },
            },
            on: {
                DONE: [{
                    target: 'done',
                    cond: (context, event) => event.data.needsConfirmation,
                    actions: [ () => console.log("WTF"), assign({
                            data: (context, event) => event.data,
                            retries: 0,
                        }),
                        sendParent((context, event) => ({type: 'CONFIRM', data: context.data}))
                    ],

                },
                    {
                        target: 'done',
                        actions: [ () => console.log("HEREDONE") , (context, event) => console.log(event), assign({
                            data: (context, event) => event.data,
                            retries: 0,
                        }),
                            sendParent((context, event) => ({type: 'DONE', data: context.data}))
                        ],
                    }],

                ERROR: {
                    target: 'fixerror',
                    actions: ['logErrorTransition', assign({
                        errorData: (context, event) => event.data,
                        error: true,
                        retries: (context) => context.retries + 1,
                    })],
                }
            },
        },
        fixerror: {
            entry: 'logWtfEntry',
            invoke: {
                src: (context) => ErrorMachine.withContext(context),
                data: {
                    apiClient: (context) => context.apiClient,
                    errorData: (context) => context.errorData,
                    retries: (context) => context.retries,
                },
                onDone: {
                    actions: [(event) => console.log("event: ", event), assign({
                        csrf: (context, event) => event.csrf,
                        secret: (context, event) => event.secret,
                    }), sendParent((context) => ({type: 'CSRF', csrf: context.csrf, secret: context.secret})),
                        (context) => console.log("context: ", context)],
                },
                onError: {
                    actions: [()=> console.log("Do we end up here?"), 'logWtfEntry']
                }
            },
            on: {
                UPDATEDCSRF: {
                    actions: [(event) => console.log("event2: ", event), assign({
                        csrf: (context, event) => event.csrf,
                        secret: (context, event) => event.secret,
                    }), sendParent((context) => ({type: 'UPDATEDCSRF', csrf: context.csrf, secret: context.secret})),
                        (context) => updateClient(context),
                        (context) => console.log("context2: ", context)],
                         target: 'loggingIn',
                },
                ERROR: {
                    target: 'error',
                    actions: [() => console.log("HERE1"), assign({
                        error: true,
                        errorData: (context, event) => event.errorData,
                    }), sendParent((context) => ({type: 'ERROR', errorData: context.errorData}))]
                },
            },

        },
        loginretry: {
            entry: [() => console.log("login retry")],
            after: {
                RETRY_DELAY: [{

                        target: 'loggingIn',
                        cond: (context) => context.retries < maxRetries,
                    },
                    {
                        target: 'error',
                        actions: [
                            sendParent((context, event) => ({type: 'ERROR', data: context.errorData}))]
                    }
                ]
            }
        },

        done: {
            type: 'final',
        },
        error: {
            type: 'final'
        }
    },
},
    {
        guards: {
        canRetry: (context) => context.retries < maxRetries,
    },
    delays: {
        RETRY_DELAY: (context, event) => {
            const exponent = context.retries ** 2;
            return exponent * 200;
        }
    },
    actions: {
        logWtfEntry: () => console.log("here"),
        logErrorTransition: () => console.log("error: heading to errorMachine"),

    }
});
import React, {useRef, useState, useEffect, reduce, useCallback} from 'react';
import '../styles/scss/ReviewBreaches.scss';
import Breach from './Breach';
import Caret from '../svg/Caret';
import { sortByDate } from "./DaysSince";
import { useAuth } from "../contexts/authContext";
import Fade from '@mui/material/Fade';
import {useFetch} from "../hooks/useFetch";
import {useNavigate} from "react-router-dom";
import {track, screen} from "../util/analytics";
import useObserveVisibility from "../hooks/useObserveVisibility";
import Slide from '@mui/material/Slide';

const ReviewBreaches = ({ parentState, changeState, props, windowWidth }) => {
    const { authState, authService, isReady: authReady} = useAuth();
    const [breachCards, setBreachCards] = useState(null);
    const [bucketReady, setBucketReady] = useState(null);
    const [breaches, setBreaches] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const {state, fetch, ready} = useFetch();
    const navigate = useNavigate();
    const [analyticsAlreadySent, setAnalyticsAlreadySent] = useState(false);
    const [transitionClose, setTransitionClose] = useState(true);
    const breachesIcon = "https://img.mirkat.com/web/icons/breaches-icon.svg";
    const breachesExitButton = "https://img.mirkat.com/web/images/home/breaches-exit-button.svg";
    const heightRef = useRef(null);
    const [top, setTop] = useState(true);
    const [displayReady, setDisplayReady] = useState(false);
    const isMounted = useRef(false);
    const [bodyPaddingRight, setBodyPaddingRight] = useState('');
    const initialHeight = 60; // Initial height of the element in pixels
    const [height, setHeight] = useState(initialHeight);
    const scrollRef = useRef(null);

    useEffect(() => {

        const handleScroll = () => {
            // Calculate the height reduction based on scroll position
            const scrollDistance = scrollRef.current.scrollTop;
            const newHeight = initialHeight - initialHeight * 0.1 * Math.floor(scrollDistance / 10);
            // Set the new height, ensuring it does not reduce below 0 for extreme cases
            setHeight(Math.max(newHeight, initialHeight * 0.6));
        };

        // Add event listener for scroll
        if (scrollRef.current) {
            scrollRef.current.addEventListener('scroll', handleScroll);
        }
        // Cleanup the event listener on component unmount
        return () => {
            if (scrollRef.current) {
                scrollRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [scrollRef.current]);

        useEffect(() => {
        if (isMounted.current) {
            setDisplayReady(true);
            // Place your update logic here
        } else {
            isMounted.current = true; // Set the ref to true after the first render
        }
    });



    useEffect(() => {
        // Function to update state based on body's padding-right
        const updatePadding = () => {
            const paddingRight = window.getComputedStyle(document.body).paddingRight;
            setBodyPaddingRight(paddingRight);
        };

        // Create a mutation observer to watch for changes in body's style
        const observer = new MutationObserver((mutations) => {
            for (let mutation of mutations) {
                if (mutation.attributeName === 'style') {
                    updatePadding();
                    break; // Exit the loop once the necessary change is detected
                }
            }
        });

        // Start observing the body for attribute changes
        observer.observe(document.body, { attributes: true, attributeFilter: ['style'] });

        // Initial update in case the padding is already set
        updatePadding();

        // Cleanup function to disconnect the observer
        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        let observer;

        const setupObserver = () => {
            if (heightRef.current) {
                observer = new MutationObserver((mutations) => {
                    for (const mutation of mutations) {
                        if (mutation.type === 'childList' || mutation.type === 'attributes') {
                            checkHeight();
                        }
                    }
                });
                const config = {
                    attributes: true,
                    childList: true,
                    subtree: true
                };
                observer.observe(heightRef.current, config);
            }
        };

        const checkHeight = () => {
            if (heightRef.current) {
                const usableHeight = window.innerHeight - 130;
                console.log('Content height:', heightRef.current.clientHeight, 'usable height:', usableHeight);
            if (heightRef.current.clientHeight < usableHeight) {
                setTop(false);
                setDisplayReady(true);
            } else {
                setTop(true);
                setDisplayReady(true);
            }
            }
        };

        setupObserver();
        //checkHeight();  //initial check

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [heightRef.current]); // Assuming no dependencies, adjust as necessary

    //useEffect(() => {
    //    if (heightRef.current) {
    //        
    //    setTimeout(() => {
    //        const adjustedHeight = heightRef.current.clientHeight - 130;
    //        console.log("adjustedHeight: ", adjustedHeight);
    //        console.log("otherHeight: ", window.innerHeight);

    //        if (adjustedHeight < window.innerHeight) {
    //            setTop(false);
    //            setDisplayReady(true);
    //        } else {
    //            setTop(true);
    //            setDisplayReady(true);
    //        }
    //    }, 25);
    //    }
    //}, [heightRef.current]);

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                changeState(!parentState);
            }
        };

        // Add event listener
        document.addEventListener('keydown', handleEscape);

        // Remove event listener on cleanup
        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, []);

    const backgroundClose = (event) => {
        console.log("TARGETS COMPARED ", event.target, event.currentTarget);
        if (event.target === event.currentTarget) {
            changeState(!parentState);
        }
    };

    const delayedClose = () => {
        setTransitionClose(false);
        setTimeout(() => {changeState(!parentState)}, 500)
    };


    const transformBreachResponse = (response) => {
        // Check that feed.existingRisks.cards exists.
        // using safe navigation operator (?.)
        if (response?.dataBreaches) {
	    setBreachCards(response.dataBreaches);
        } else {
            setBreachCards([]);
        }
    };
    
    useEffect( () => {
        if (breaches) {
	    transformBreachResponse(breaches);
        }
    }, [breaches]);

    useEffect(() => {
        if(ready && state.matches('idle')) {
	    fetch('breaches', [authState.context.userId, authState.context.emailId]) // this isn't updating if the access token updates.
        }
        if(state.matches('success')) {
    	    setBreaches(state.context.data);
    	    setLoaded(true)
        }
        if(state.matches('failure')) {
    	    console.log("error on breaches retrieval: ", state.error)
            setBreaches([]);
        }

    }, [state])

    useEffect(() => {
        console.log("bucketReady: ", bucketReady);
    }, [bucketReady]);
    
    useEffect(() => {
      if (breachCards !== null) {
      console.log("breaches: ", breaches);
      if(!analyticsAlreadySent) {
          screen('Data Breaches', {
              screenType: 'dataBreaches',
              entryType: 'card',
              numBreaches: breaches?.dataBreaches?.length || 0
          });


          setAnalyticsAlreadySent(true)
      }
//	historyCards.forEach((item) => {
//            const date = new Date(item.createdAt);
//            const now = new Date();
//	    console.log("DATE: ",date);
//  
//            const todayStart = new Date(now);
//            todayStart.setHours(0, 0, 0, 0);
//            const yesterdayStart = new Date(todayStart);
//            yesterdayStart.setDate(yesterdayStart.getDate() - 1);
//            const lastWeekStart = new Date(todayStart);
//            lastWeekStart.setDate(lastWeekStart.getDate() - 7);
//            const lastMonthStart = new Date(todayStart);
//            lastMonthStart.setMonth(lastMonthStart.getMonth() - 1);
//            const lastYearStart = new Date(todayStart);
//            lastYearStart.setFullYear(lastYearStart.getFullYear() - 1);
//            const pastYearsStart = new Date(todayStart);
//            pastYearsStart.setFullYear(lastYearStart.getFullYear() - 10);
//  
//            if (date >= todayStart) {
//              buckets.today.push(item);
//              console.log("today");
//            } else if (date >= yesterdayStart) {
//              buckets.yesterday.push(item);
//              console.log("two");
//            } else if (date > lastWeekStart) {
//              buckets.pastWeek.push(item);
//              console.log("three");
//            } else if (date > lastMonthStart) {
//              buckets.pastMonth.push(item);
//              console.log("four");
//            } else if (date > lastYearStart) {
//              buckets.pastYear.push(item);
//              console.log("five");
//            } else if (date > pastYearsStart) {
//              buckets.pastYears.push(item);
//              console.log("MANY");
//            }
//	});
      //setBucketReady(buckets);
      const [buckets, timeMapping] = sortByDate(breachCards,'effectiveBreachDate');

        const tempArray = Object.entries(buckets);
        console.log("tempArray: ", tempArray);
        tempArray.sort((a,b) => {
            return timeMapping[a[0]] - timeMapping[b[0]];
        });

        const finalArray = Object.fromEntries(tempArray);
        console.log("finalArray: ", finalArray);

      setBucketReady(finalArray);
      }
    }, [breachCards]);

	
    const feedDescription = "Your personal data is included in the following data beaches. The data is displayed where possible, otherwise the type of data exposed is indicated.\n\nWe have analyzed every breach and notified you of any threats."

		                //<div className="breaches-feed-internal">
    return (
          <>
        {windowWidth > 839 && <div className="home-popover" onClick={backgroundClose} style={top ? {} : {justifyContent: "center", paddingTop: "0px"}}>
        {breaches &&
	    <div className="breaches-container" ref={heightRef} style={!displayReady ? {visibility: "hidden"} :  {marginRight: bodyPaddingRight}} >
	    	<div className="breaches-header">
                <div className="breaches-icon"><img className="breaches-icon-svg" src={breachesIcon} alt="history icon" /></div>
	    	    <img 
	    		onClick={() => {changeState(!parentState);}} 
	    		src={breachesExitButton} 
	    		className="breaches-exit-button"
	    	    />
	    	    <div className="breaches-caret" onClick={() => {changeState(!parentState);}} style={{ cursor: "pointer"}}>
                        <Caret />
                    </div>
	    	</div>
	    	<div className="breaches-feed-container">	
	    	    <div className="breaches-feed-description-container">
	    		<div className="breaches-feed-description">{breaches.description}</div>
	    	    </div>
	        </div>
	    	    {breaches?.dataBreaches.length !== 0 ? <div className="breaches-feed-breaches">
	                {bucketReady && Object.keys(bucketReady).map((bucket) => (
                                    <>
                                    <div className="dayssince-date">{bucket}</div>
		                        {bucketReady[bucket].length !== 0 && bucketReady[bucket].map((item,index) => (
		                            <div>
                                                <Breach
                                                    key={index}
                                                    card={item}
                                                />
	                                    </div>
		                        ))}
                                    </>
		        ))}
	    	    </div> : null}
	    </div>
        }
        </div>}
        {windowWidth < 840 && <div className="home-popover" onClick={backgroundClose}>
        {breaches && <Slide direction="up" in={transitionClose}>
	    <div className="breaches-container" style={windowWidth > 839 ? {marginRight: bodyPaddingRight} : {}} >
                <div className="breaches-header-container">
                    <div className="breaches-header" style={{padding: "12px 22px 18px"}}>
                        <div className="breaches-icon" style={{height: height, width: height}}><img className="breaches-icon-svg" src={breachesIcon} /></div>

                            <img
                                onClick={() => {
                                changeState(!parentState);
                            }}
                            src={breachesExitButton}
                            className="breaches-exit-button"
                        />
                        <div className="breaches-caret" onClick={delayedClose} style={{cursor: "pointer"}}>
                            <Caret/>
                        </div>
                    </div>
                </div>

            <div className="breaches-feed-outer-container" ref={scrollRef} >	
	    	<div className="breaches-feed-container">	

	    	    <div className="breaches-feed-description-container">
	    		<div className="breaches-feed-description">{breaches.description}</div>
	    	    </div>
	        </div>
	    	    {breaches?.dataBreaches.length !== 0 ? <div className="breaches-feed-breaches">
	                {bucketReady && Object.keys(bucketReady).map((bucket) => (
                                    <>
                                    <div className="dayssince-date">{bucket}</div>
		                        {bucketReady[bucket].length !== 0 && bucketReady[bucket].map((item,index) => (
		                            <div>
                                                <Breach
                                                    key={index}
                                                    card={item}
                                                />
	                                    </div>
		                        ))}
                                    </>
		        ))}
	    	    </div> : null}
	        </div>
	    </div>
        </Slide>}</div>}</>
    );
}

export default ReviewBreaches;

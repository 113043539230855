import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from "react-router-dom";
import ConfirmForm from "./ConfirmForm";
import LoginForm2 from "./LoginForm2";
import { useAuth } from "../contexts/authContext";
import {useMachine, useSelector} from "@xstate/react";
import {createMachine} from "xstate";

const confirm = (state) => {
    try {
        return state.matches('confirm');
    } catch (e) {
        return false;
    }

};

const FlowState = createMachine({
    id: 'LoginFlow',
    initial: 'login',
    context: {
        flow: '', //register or login
        email: '',
    },
    states: {
        login: {
            on: {
                CONFIRM: {
                    target: 'confirm',
                } //show confirm form
            }
        },
        confirm: {
            on: {
                DONE: {
                    target: 'done',
                },
                BACK: {
                    target: 'login',
                }
            }
        },
        error: {

        },
        done: {
            type: 'final'
        }
    },
});


//currently reflashes login page briefly if error on confirm form... maybe nav directly from confirm back to home
//and remove the rerender here....

const LoginFlow = () => {
    const navigate = useNavigate();
    const { authState, authService, isReady, stateSteady}  = useAuth();
    const  isConfirm = useSelector(authService, confirm);
    const [allowBack, setStep] = useState(0);
    const [state, send]= useMachine(FlowState);
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        if(isReady) {
            setIsLoaded(true)
        }
    }, []);



    useEffect(() => {
        if(authState.matches('authenticated') && stateSteady && !authState.context.progressWait) {
            navigate("/loading");
            console.log("NAVIGATE TWO");
        }
        /*
        if(authState.matches('needPw')) { // issue with clicking sign in after registering... always returns to here...
            //could be if sign in clicked... we clear the register state?
            //if we go back.. it goes to confirm if we go back from there it goes to register page? need to adapt authMachine then...
            navigate('/register');
        }

         */
    }, [authState, navigate])


    return (
        isLoaded ? (
        <div style={{ display: "flex", width: "100%", justifyContent: "center" }} >
            { (state.matches('login') && (!authState.matches('authenticated') || (authState.matches('authenticated') && authState.context.progressWait))) && <LoginForm2 regSend={send} regState={state} /> }

            {  (state.matches('confirm') &&  (!authState.matches('authenticated') || (authState.matches('authenticated') && authState.context.progressWait))) && <ConfirmForm prevForm="login" regSend={send} regState={state} /> }

        </div>
        ): (<></>)
    )
}

export default LoginFlow

// This is for the first overlay

//2 different ones... one free one paid
import React, {useEffect, useState, useRef} from "react";
import styles from "../styles/scss/IntroAddEmailModal.module.scss";
import Button from "../components/EmailButton";
import {useAuth} from "../contexts/authContext";
import {useFetch} from "../hooks/useFetch";
import {useModal} from "../contexts/ModalContext";
import Caret from '../svg/Caret';
import Modal from "@mui/material/Modal";
import {screen,track} from "../util/analytics";
import GenericErrorModal from "../modals/GenericErrorModal"
import Slide from '@mui/material/Slide';
import AddEmailModal from "../modals/AddEmailModal";
import TruncateEmail from "../components/TruncateEmail";

//If we'ere not in the right state, we'll get stuck with Loading button. need to check and redirect fix
const IntroAddEmailModal = ({backEmail, props}) => {
    const {authState} = useAuth();
    const {setModalType} = useModal();
    const [header, setHeader] = useState("We monitor the dark web for passwords associated your email address");
    const [content, setContent] = useState("Add all your email addresses for complete coverage.");
    //const [emails, setEmails] = useState(['jane@gmail.com', 'jane@yahoo.com']); //placeholders
    const [emails, setEmails] = useState([]);
    const [emailF, setEmailF] = useState("");
    const {state, fetch, isLoading, uiErrorMsg, reset, ready} = useFetch();
    const [dataFetched, setDataFetched] = useState(false);
    const [emailsLoaded, setEmailsLoaded] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    //const dynamic = {height: windowWidth > 839 ? "unset" : windowHeight}
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [transitionClose, setTransitionClose] = useState(true);
    const [showNextModal, setShowNextModal] = useState(false);
    const EmailIcon = "https://img.mirkat.com/web/images/home/add_email_icon_plus.svg";
    const ExitButton = "https://img.mirkat.com/web/images/home/breaches-exit-button.svg";

    useEffect(() => {
        console.log("props: ", props)
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useEffect(() => {
        //we don't have any other entry points except from feed card or settings card
        screen('Add Email Address: Overview', {
            screenType: 'addEmailAddress',
            screenSubType: 'overview',
            entryType: 'card'
        })
    }, []);

    //need to call emails endpoint.

    // /v3/emails/emails Get request:
    // { emails: [ 'jane@gmailcom' ],
    const onClick = () => {
        setTransitionClose(false);
        setTimeout(() => {
            //setModalType();
            if (props.homeCallback) {
                props.homeCallback(false);
            }
        },500);
    }

    useEffect(() => {
        if(state.matches('idle') && ready && !dataFetched && !props?.emails) {
            //fetch('emails', emails)
            fetch('emails')
            setDataFetched(true)
        }
        if(state.matches('success')) {
            setEmailsLoaded(true);
            //let emList = state.context.data; //should be an array of email objects
            //console.log(emList)
            //console.log(typeof(emList))
            //let ems = [];
            //for (let email of emList.emails) {
            //    if(!emails.includes(email.emailAddress)) {
            //        ems = [...ems, email.emailAddress]
            //    }
            //}
            //setEmails(emails => [...emails, ...ems]);
            setEmails(state.context.data.emails.map(item => item.emailAddress));


        }

        if(state.matches('failure')) {
            setShowErrorModal(true);
        }
        console.log(backEmail)
    }, [state, ready]);

    useEffect(() => {
        if(authState.context.isPlus) {
            setHeader("We monitor the dark web for threats associated your email address");
            setContent("This allows us to find your passwords, account credentials, bank accounts, credit cards, and more. Add all your email addresses for complete coverage.")
        }
        if(backEmail) {
            setEmailF(backEmail)
        }
        console.log("WINDOWWIDTH: ", props.windowWidth);
    }, []);

    //Free:
    //We monitor the dark web for passwords associated your email address
    //Add all your email addresses for complete coverage.
    //
    // We are currently monitoring: jane@gmail.com, jane@yahoo.com



    //Plus+
    //We monitor the dark web for threats associated your email address
    //This allows us to find your passwords, account credentials, bank accounts, credit cards, and more. Add all your email addresses for complete coverage.
    //
    // We are currently monitoring: jane@gmail.com, jane@yahoo.com
    //button color override

    const onSubmit = (event) => {
        //#302
        track('Button Tapped', {buttonType: 'addEmailAddress', screenType: 'addEmailAddress', screenSubType: 'overview', entryType: props?.fromFeed ? 'card' : 'settings'})
        event.preventDefault();
        //setModalType({modalType: 'addEmail', backEmail: emailF, exitCallback: props.callback, emails: emails, refreshCallback: props.refreshCallback, fromFeed: props?.fromFeed || false})
        setShowNextModal(true);
    }

                                        //<div>We are currently monitoring:<div className={styles["modal-intro-description-emails-span"]}>Loading... </div></div>
    //should show something isLoading... or we cache all this ahead of time and store in context
    return (<>
            {showErrorModal && <GenericErrorModal callback={() => setShowErrorModal(false)} props={true} screenType='addEmailAddress' screenSubType='overview' />}
            {showNextModal && <AddEmailModal homeCallback={props.homeCallback} callback={props.callback} backEmail={emailF} exitCallback={setShowNextModal} props={{emails: emails, refreshCallback: props.refreshCallback, fromFeed: props?.fromFeed || false}} windowWidth={windowWidth} />}
    {props.windowWidth < 840 &&
     <Modal open={transitionClose} closeAfterTransition>
        <Slide direction="up" in={transitionClose}>
        <div className={styles["modal-container"]}>
            <div className={styles["modal-intro-inner-container"]}>
                                <div className={styles["modal-into-caret"]} ><div onClick={onClick} alt="Exit Button" style={{cursor: 'pointer'}}><Caret /></div></div>
            <div className={styles["modal-intro-container"]}>
                <form noValidate method="post" onSubmit={onSubmit} style={{display: "flex", maxWidth: "600px", minWidth: "360px", width: "100%", height: "100%"}}>
                <div className={styles["modal-main-container"]} >
                    <div className={styles["modal-top-container"]}>

                            <div className={styles["modal-intro-header-container"]} >
                                <div className={styles["modal-email-icon"]}><div className={styles["modal-email-icon"]}><img className={styles["modal-email-icon-svg"]} src={EmailIcon} alt="Email Icon"></img></div></div>
                                <div className={styles["modal-into-exit-icon"]} ><img onClick={onClick} src={ExitButton} alt="Exit Button" style={{cursor: 'pointer'}}></img></div>

                            </div>
                            <div className={styles["modal-intro-description-container"]}>
                                <div className={styles["modal-intro-description-header"]}>{header}</div>
                                <div className={styles["modal-intro-description-text"]} style={{gap: 24}} >{content}
                                    {emailsLoaded ? (
                                        <div className={styles["modal-intro-description-text"]}>We are currently monitoring:{emails.map((email, index) => (<span key={email} className={styles["modal-intro-description-emails-span"]}> <TruncateEmail email={email} maxLength={42}/>{index < emails.length - 1 ? ', ' : ''}</span> ))}</div>
                                        ) : ( props.emails ? (
                                        <div className={styles["modal-intro-description-text"]}>We are currently monitoring:{props.emails.map((email, index) => (<span key={email} className={styles["modal-intro-description-emails-span"]}> <TruncateEmail email={email} maxLength={42}/>{index < props?.emails.length - 1 ? ', ' : ''}</span> ))}</div>) : "Loading..."
                                        )}
                                </div>
                            </div>
                    </div>
                    <div className={styles["modal-intro-bottom-container"]}>
                        <div className={styles["modal-intro-bottom-inner-container"]}>
                        <div className={styles["modal-intro-bottom-button"]}><Button buttonText="Add email address" /></div>
                        </div>
                    </div>
                </div>
                </form>
            </div>
            </div>
        </div>
        </Slide>
                </Modal>}

    {props.windowWidth > 839 &&
                <Modal open={transitionClose} closeAfterTransition>
        <div className={showNextModal ? "settings-none" : styles["modal-container"]}>
            <div className={styles["modal-intro-inner-container"]}>
            <div className={styles["modal-intro-container"]}>
                <form noValidate method="post" onSubmit={onSubmit} style={{display: "flex", maxWidth: "500px", minWidth: "360px", width: "100%"}}>
                <div className={styles["modal-main-container"]} >
                    <div className={styles["modal-top-container"]}>

                            <div className={styles["modal-intro-header-container"]} >
                                <div className={styles["modal-email-icon"]}><div className={styles["modal-email-icon"]}> <img src={EmailIcon} alt="Email Icon"></img></div></div>
                                <div className={styles["modal-into-exit-icon"]} ><img onClick={onClick} src={ExitButton} alt="Exit Button" style={{cursor: 'pointer'}}></img></div>
                                <div className={styles["modal-into-caret"]} ><div onClick={onClick} alt="Exit Button" style={{cursor: 'pointer'}}><Caret /></div></div>

                            </div>
                            <div className={styles["modal-intro-description-container"]}>
                                <div className={styles["modal-intro-description-header"]}>{header}</div>
                                <div className={styles["modal-intro-description-text"]} style={{gap: 25}} >{content}
                                    {emailsLoaded ? (
                                        <div className={styles["modal-intro-description-text"]}>We are currently monitoring:{emails.map((email, index) => (<span key={email} className={styles["modal-intro-description-emails-span"]}> <TruncateEmail email={email} maxLength={42}/>{index < emails.length - 1 ? ', ' : ''}</span> ))}</div>
                                        ) : ( props.emails ? (
                                        <div className={styles["modal-intro-description-text"]}>We are currently monitoring:{props.emails.map((email, index) => (<span key={email} className={styles["modal-intro-description-emails-span"]}> <TruncateEmail email={email} maxLength={42}/>{index < props?.emails.length - 1 ? ', ' : ''}</span> ))}</div>) : "Loading..."
                                        )}
                                </div>
                            </div>
                    </div>
                    <div className={styles["modal-intro-bottom-container"]}>
                        <div className={styles["modal-intro-bottom-inner-container"]}>
                        <div className={styles["modal-intro-bottom-button"]}><Button buttonText="Add email address" /></div>
                        </div>
                    </div>
                </div>
                </form>
            </div>
            </div>
        </div>
                </Modal>}
        </>
    )
}

export default IntroAddEmailModal;

import React from "react";
import PasswordV1 from "../components/PasswordV1";

const PasswordV1Page = () => {
    return (
        <div className="onBoarding">
            <div className="login-row">
                <div className="login" >
                    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        <PasswordV1 />
                    </div>
              </div>
            </div>
        </div>
    );
}

export default PasswordV1Page;
import React from 'react'

const RightCaret = () => {
    return (
        <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 13.75L7.5 8.25L2 2.25" stroke="#2F364B" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default RightCaret;

import React from "react";
import styles from "../styles/scss/PasswordUpdatedModal.module.scss";
import Modal from "@mui/material/Modal";

const PasswordUpdatedModal = ({callback, setHiddenSettings, setPwOkay, setShowChangePasswordModal, hideBackdrop}) => {
    // const [showModal, setShowModal] = useState(true);
    const [open, setOpen] = React.useState(true);

    const onClick = () => {
        if(!callback) {
            if (typeof setHiddenSettings === 'function') {
                setHiddenSettings(false)
            }
            if (typeof setPwOkay === 'function') {
                setPwOkay(true)
            }
            if (typeof setShowChangePasswordModal === 'function') {
                setShowChangePasswordModal(false)
            }

            setOpen(false)
        } else {
            callback(true);
            setOpen(false)
        }
    }

    return (
        <div>
            <Modal open={open} hideBackdrop={hideBackdrop || true}>
                <div className={styles["modal-screen-container"]}>
                    <div className={styles["modal-container-informative"]}>
                        <div className={styles["modal-content-container"]}>
                            <div className={styles["modal-title"]}>Password updated</div>
                        </div>
                        <div className={styles["action-container"]}>
                            <div className={styles["modal-button"]} onClick={onClick}>
                                <div className={styles["modal-button-label"]}>OK</div>
                            </div>
                        </div>
                    </div>
                </div>
           </Modal>
        </div>
    );
}

export default PasswordUpdatedModal;

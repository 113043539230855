import {useState, useEffect} from  'react';

const useIsWindows = () => {
    const [isWindows, setIsWindows] = useState(true);
    const [isMac, setIsMac] = useState(false);

    useEffect(() => {
        const userAgent = window.navigator.userAgent;

    if (/Macintosh/i.test(userAgent) && !/iPad|iPhone/i.test(userAgent)) {
        setIsMac(true);
        setIsWindows(false);
    } else if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
        setIsWindows(false);
    } else if (/android/i.test(userAgent)) {
        setIsWindows(false);
    } else {
        setIsWindows(true);
    }
    }, []);

    return [isWindows, isMac];
}
export default useIsWindows;

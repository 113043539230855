import React, {useState, useEffect, useRef} from  'react';
import '../styles/scss/KebabMenu.scss';
import { Link } from "react-router-dom";
import {useModal} from "../contexts/ModalContext";
import Caret from '../svg/Caret';
import RightCaret from '../svg/RightCaret';
import MobileSettings from '../svg/MobileSettings';
import MobileThreatHistory from '../svg/MobileThreatHistory';
import MobileKebab from "../svg/MobileKebab";
import {screen, track} from "../util/analytics";
import Slide from '@mui/material/Slide';
import Modal from "@mui/material/Modal";

const MobileKebabMenu = ({ }) => {
    const {mobileKebab, setMobileKebab, threatState, setThreatState, showSettings, setShowSettings,modalCount,setModalCount} = useModal();

    const isFirstRun = useRef(true);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        } else if (mobileKebab) {
            screen('Menu', {screenType: 'menu'})
            track('Button Tapped', {eventType: 'menu', screenType: 'feed'})
            setModalCount(prevCount => prevCount + 1);
        } else {
            setModalCount(prevCount => prevCount - 1);
        }
    }, [mobileKebab]);

    //useEffect(() => {
    //    console.log("STATUS: ",modalCount);
    //    if (modalCount > 0) {
    //        document.body.style.overflow = 'hidden';
    //    } else {
    //        document.body.style.overflow = 'auto';
    //    }
    //}, [modalCount]);


    //useEffect(() => {
    //    console.log("STATUS: ",modalCount);
    //    if (modalCount > 0) {
    //        document.body.style.overflow = 'hidden';
    //    } else {
    //        document.body.style.overflow = 'auto';
    //    }
    //}, [modalCount]);


                                    //<button onClick={() => changeState(!parentState)} key="settings" className="button-label-text" >Threat history</button>
                                    //<Link key="settings" className="label-text" to="/settings">Settings</Link>

            //<div className="dot"></div>
            //<div className="dot"></div>
            //<div className="dot"></div>
    return (
            <>
            <Modal open={mobileKebab}>
            <Slide direction="up" in={mobileKebab}>
                <div className="mobile-kebab-container noSelect">
                    <div className="mobile-kebab-navigation"><div className="mobile-kebab-caret-container" onClick={() => setMobileKebab(!mobileKebab)} style={{cursor: "pointer"}}><Caret /></div></div>
                    <div className="mobile-kebab-button-container">

                        <div className="mobile-kebab-row-container" onClick={() => setShowSettings(!showSettings)} style={{cursor: "pointer"}}><div className="mobile-kebab-row">
                            <div className="mobile-kebab-row-icon-container">
                                <div className="mobile-kebab-row-icon"><MobileSettings /></div>
                            </div>
                            <div className="mobile-kebab-row-text-container"><div style={{ cursor: "pointer" }} className="mobile-kebab-row-text" >Settings</div></div>
                            <div className="mobile-kebab-row-right-container"><RightCaret /></div>
                        </div></div>

                        <div className="mobile-kebab-row-container" onClick={() => setThreatState(!threatState)} style={{cursor: "pointer"}}><div className="mobile-kebab-row">
                            <div className="mobile-kebab-row-icon-container">
                                <div className="mobile-kebab-row-icon"><MobileThreatHistory /></div>
                            </div>
                            <div className="mobile-kebab-row-text-container"><div style={{ cursor: "pointer" }} className="mobile-kebab-row-text" >Threat History</div></div>
                            <div className="mobile-kebab-row-right-container"><RightCaret /></div>
                        </div></div>

                    </div>
                </div>
            </Slide>
            </Modal>
            

        <div className="mobile-menu-dots" style={{ padding: "unset" }} onClick={() => setMobileKebab(!mobileKebab)}>
        <div className="kebab-menu" >
            <MobileKebab />
        </div>
        </div>
        </>
    );
};

export default MobileKebabMenu;

import React, {useState, useEffect, useRef} from "react";
import {useAuth} from "../contexts/authContext";

import Button from "./Button";
import useFormHandler from "../hooks/useFormHandler";
import '../styles/scss/ConfirmForm.scss';
import '../styles/scss/PasswordForm.scss';
import styles from "../styles/scss/PasswordForm.module.scss"
import {useFetch} from "../hooks/useFetch";
import {Controller, useForm} from "react-hook-form";
import Input2 from "./Input2";
import {retryableError} from "../util/retryableError";
import Fade from '@mui/material/Fade';

import {track,screen} from "../util/analytics";
import useProgressBar from "../hooks/useProgressBar";
import ProgressBar from "./ProgressBar";
import {useNavigate} from "react-router-dom";
import useDetectNetwork from "../hooks/useDetectNetwork";
import ReactPixel from 'react-facebook-pixel';

const PasswordForm = ({ prevForm, setStep, regSend, regState }) => {
    const { register, handleSubmit, formState,
        setValue,
        control, reset} = useForm({mode: "onChange", defaultValues: {code: ""}});
    const {authState, isReady, authService, stateSteady} = useAuth();
    const [resetKey, setResetKey] = useState(Date.now());
    const inputRef = useRef(null);

    //  const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null);
    //const [imgVis, setImgVis] = useState(false);
    // const {state, fetch, uiErrorMsg, isLoading} = useFetch();
    const [pwSet, setPwSet] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [errorCount, setErrorCount] = useState(0);
    const { progressStarted, apiStarted, isComplete, startProgress, handleCompletion,  setLastApiCallStarted, setProgressOverride, progressOverride} = useProgressBar();
    const navigate = useNavigate();
    const mask = "https://img.mirkat.com/web/icons/mask.svg";
    const leftbutton = "https://img.mirkat.com/web/icons/leftbutton.svg";
    const [pwFocused, setPwFocused] = useState(false);
    const [alreadyNewUser, setAlreadyNewUser] = useState(false);

    const isOnline = useDetectNetwork();
    useEffect(() => {
        if(!isOnline){
            setError("No network connection")
        }
        if(isOnline && error === "No network connection") {
            setError("")
        }
    }, [isOnline])


    useEffect(() => {
        if(!authState.context.ptk && !authState.context.isNewUser) {
            //if we have a ptk, we can set the password.
            //probably need to inform the user...
            console.log("Redirecting to login")
            navigate("/login")
        }
        if(authState.matches('authenticated') && authState.context.isNewUser) {
            console.log("Redirecting to upgrade")
            //authService.send('FINISH_PROGRESS')
            //somehow this is firing twice.
            //#58
            if(!alreadyNewUser) {
                setAlreadyNewUser(true)
                track('User Sign Up', {eventType: 'userSignUp', eventSubType: 'passwordAccepted'})
                ReactPixel.track('CompleteRegistration');
            }
            navigate("/welcome")

        }


    }, [authState, alreadyNewUser])


    useEffect(() => {
        screen("Sign-up: Password", {screenType: "signUp", screenSubType: "password"})
        setIsLoaded(true)
        authService.send('FINISH_PROGRESS')
        return () => {
            setIsLoaded(false)
            setError(null)
            setErrorCount(0)
            setProgressOverride(null)
            authService.send('FINISH_PROGRESS')
        }
    }, [])


    const goBack = () => {
        track("Button Tapped", {buttonType: 'back', screenType: 'signUp', screenSubType: 'password'})
        console.log("going BACK: ", regSend);
        if(prevForm === "confirm" && regSend) {
            authService.send('GOBACK_OVERRIDE') // need this here cause otherwise resigning up skips confirm if we made it to password previously
            regSend({type: 'BACK'});
        }
        if(!prevForm) {
            authService.send('GOBACK_OVERRIDE')
            navigate("/confirm")
        }
    }

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    });


    useEffect(() => {
        if(stateSteady && authState.matches('confirm')) {
            console.log("GOING BACK TO CONFIRM ON CODE 37");
            authService.send('GOBACK_OVERRIDE')
            navigate("/confirm")
        }
        if(isReady && authState.matches('needPw') && pwSet) {
            console.log("setting pw?")
            authService.send('START_PROGRESS')
            authService.send({type: 'SETPW', data: pwSet}) // not sure how to track this....
            setLastApiCallStarted(true)
            setPwSet("")
        }

        if(isReady && authState.context.errorData && !authState.matches('confirm')) {
            setProgressOverride(100)


            let {errMsg} = retryableError({errorBody: authState.context.errorData})
            setError(errMsg);

            authService.send("CLEAR_ERROR") //prevent loop.
            setResetKey(Date.now())
            if(authState.context.errorData?.errorCode === 48) {
                setPwFocused(true)
            }
            //setProgressOverride(null)
            setPwSet("")
        }

        /*
        if(state.matches('failure')) {
            let {errMsg} = retryableError({errorBody: state.context?.errorData})
            setError(errMsg);
            setResetKey(Date.now())
            reset({
                password: '',
            })
            //setSubmitted(false);
            setError(error);
        }
        if(state.matches('success')) {
            //success modal?
            console.log("success?")
            authService.send({type: 'pwSet', data: state.context.data })
        }
        console.log(state)
            ///if ptk is null but we still want to send pw, we should throw an error.
         */

        if(authState.matches('unauthenticated') && authState.context.ptk === null) {
            setError("An error occurred. Please try again.")
            setTimeout(() => {
                setError(null)
                navigate('/signup')
            }, 1000)

        }
        console.log("authState: ", authState)
        console.log("pwSet: ", pwSet)
        console.log("isComplete: ", isComplete)
        console.log("error: ", error)
        console.log("1234")
    }, [authState, pwSet, error, stateSteady, progressStarted])

    const onSubmit = (data) => {
        //#16
        track("Button Tapped", {
            buttonType: "next",
            screenType: "signUp",
            screenSubType: "password"
        })
        console.log("data: ", data)
        console.log("PASSWORD: ", data.password)
        //fetch('password', [data.password])
        setPwSet(data.password)
        startProgress()
    }
    const validatePw = (pw) => {
        if(error) {
            setError(null)
        }
        return true;
    }

    return (
        isLoaded ? (
            <>{progressStarted && <ProgressBar progressOverride={progressOverride} apiStarted={apiStarted} onCompleted={handleCompletion} setProgressOverride={setProgressOverride} />}
                <Fade in={true} timeout={500}>
                    <div className={styles["row"]}>
                        <div className={styles["container"]}>
                            <div className={styles["back"]}>
                                <img src={leftbutton} alt="back arrow" style={{cursor: "pointer"}} onClick={goBack}/>
                            </div>
                            <form method="post" onSubmit={handleSubmit(onSubmit)}>
                                <div className={styles["inner-container"]}>
                                    <div className={styles["header"]}>
                                        <div className={styles["header-text"]}>Finally, create a password for your Mirkat account</div>
                                    </div>
                                    <div className={styles["input-fields"]}>
                                        <div className={styles["input-field"]}>
                                            <div className={styles["input-inner-container"]}>
                                                <Controller
                                                    key={`password-${resetKey}`}  // Apply the key here
                                                    control={control}
                                                    name="password"
                                                    defaultValue=""
                                                    rules={{ required: true, minLength: 8, maxLength: 100, validate: validatePw }}
                                                    render={ ({ field, fieldState }) => <Input2 field={field} maxLength={100} label="Password" id="password" autoFocus={pwFocused} inputRef={inputRef}/>}
                                                />
                                            </div>
                                        </div>
                                        { (error && isComplete) && <div className="error" id="error-message">{error}</div> }
                                        { (!error || !isComplete) && <div className="error pwnotice">Use a password with at least 8 characters</div>}
                                    </div>
                                    <Button isDisabled={!formState.isValid || progressStarted} buttonText="Create account" ></Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Fade>
            </>) : (<> </>)
    );
}

export default PasswordForm;

import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import {track} from "../util/analytics";

const useRefForElement = ({ id, options = {}, cardType, feedType, feedSubType, feedPosition}) => {
    const { ref, inView } = useInView({
        //triggerOnce: true,
        ...options
    });

    useEffect(() => {
        if (inView) { //act when card in view
            //#406
            track('Card Viewed', {
                cardType: cardType,
                feedType: feedType,
                feedSubType: feedSubType,
                feedPosition: feedPosition, // integer

            });

        }

    }, [inView]);

    return ref;
}
export default useRefForElement;

import React, {createContext, useContext, useEffect, useState} from "react";
import sysend from 'sysend';
import { useAuth } from "../contexts/authContext";
import {useSelector} from "@xstate/react";

export const BroadcastHandlerContext = createContext({
    isBroadcasting: null,
});

// This is a custom hook that will be used to access the authentication information.
export const useEventHandler = () => {
    return useContext(BroadcastHandlerContext);
}

//for state machine selector

// This is the provider that will be used to wrap the application.
// Get the persisted state config object from somewhere, e.g. localStorage


const skipDataUpdates = ['ajs_user_traits', 'persisted-queue', 'analytics_session_id.last_access', 'ajs_user_id', 'ajs_anonymous_id', 'NRBA_SESSION', 'analytics_session_id']

export const BroadcastEventHandler = ({ children }) => {

    const { authState, send} = useAuth();
    const [isInitialized, setIsInitialized] = useState(false);
    const [isLogout , setIsLogout] = useState(false);
    //TODO: LOGOUT all tabs via event
    //Refresh token heartbeat -> ensure 1 tab is refreshing not all.


    useEffect(() => {
        sysend.on('logout', (message) => {
            console.log("Got logout message")
            console.log("Authstate in logout message is: ", authState)
            if(authState.context.windowId !== message.windowId && authState.matches('authenticated')) {
                setIsLogout(true);
                //send('LOGOUT')
                send('CLEAR_AND_RESET')
            }
        });
        return () => {
            sysend.off('logout');
        }
    }, [authState]);

    useEffect(() => {
        //Issue with storage event and secure local storage appears to be causing an issue here.
        const handleStorageChange = (event) => {
            //console.log("Storage key changed: ", event.key)
            //console.log(event)

            //Analytics and stripe causing this to fire too often. Todo: update only our keys
            if (!skipDataUpdates.includes(event.key)) {
                if (event.key !== "data" && event.key !== 'lastActivity' && event.key !== 'resumedSession' && !isLogout) {
                    //if data is encrypted, we need to "get" key from storage
                    //think we just tell the machine to reload from storage
                    send('UPDATE_FROMSTORAGE')
                }
            }
        }


        // Attach the event listener to the window object
        window.addEventListener('storage', handleStorageChange);

        // Cleanup function to remove the listener when the component is unmounted
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    useEffect(() => {
        /*
                    sysend.on('requestCsrf', (message) => {
                        console.log("BROADCAST CSRF REQUESTED")
                        console.log(message)
                        if (authState?.context?.windowId !== message.windowId) {
                            if (message?.csrf !== authState?.context?.csrf || message?.secret !== authState?.context?.secret) {
                                sysend.broadcast('csrfResponse', {
                                    csrf: authState.context.csrf,
                                    secret: authState.context.secret,
                                })
                            }
                        }

                    })
          */
        sysend.on('requestCsrf', (message) => { //CSRF was requested
            if (authState.context.windowId !== message?.windowId && authState.context?.csrf && authState.context?.secret) {
                if (message.csrf && message.secret) {
                    sysend.broadcast('csrfResponse', {
                        csrf: message.csrf,
                        secret: message.secret,
                        windowId: authState.context.windowId
                    });
                }
            }
        });


        sysend.on('updateCsrf', (message) => { //csrf was updated on another tab
            if (authState.context.windowId !== message?.windowId) {
                if (authState.context.csrf !== message?.csrf || authState.context.secret !== message?.secret) {
                    if (message.csrf && message.secret) {
                        send({
                            type: 'CSRF_UPDATED',
                            csrf: message.csrf,
                            secret: message.secret,
                        });
                    }
                }
            }
        });

        setIsInitialized(true);

        return () => {
            sysend.off('requestCsrf');
            sysend.off('updateCsrf');
        }
    }, []);

    useEffect(() => {

        if (authState.matches('waitingForCsrfSync')) {
            if(isInitialized) {
                if (authState.context.windowId) {
                    sysend.broadcast('requestCsrf', {windowId: authState.context.windowId})
                    const timeoutId = setTimeout(() => {
                        send('TIMEOUT');
                    }, 1000);


                    sysend.on('csrfResponse', (message) => {
                        send({type: 'CSRF_RECEIVED', csrf: message.csrf, secret: message.secret})
                        clearTimeout(timeoutId);
                    });
                }
            }

        }


        //sysend.broadcast('requestCsrf', {windowId: authState.context.windowId})

        return () => {

            sysend.off('csrfResponse');
        };
    }, [authState, authState.context.windowId, authState.context.csrf, isInitialized]);

    if(!isInitialized) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {children}
        </div>
    );
}

export default BroadcastEventHandler;

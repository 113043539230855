import { useRef, useCallback } from 'react';
import useIntersectionObserver from './useIntersectionObserver'; // Adjust the import path as needed


function debounce(func, wait) {
    let timeout;
    return function(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            func.apply(this, args);
        }, wait);
    };
}

const useObserveVisibility = (onVisible, options = {}) => {
    const targetRef = useRef(null);
   // const debouncedOnVisible = debounce(onVisible, 30000);
    const handleIntersection = useCallback((entry) => {
        if (entry.isIntersecting) {
            //debouncedOnVisible();
            onVisible();
        }
    }, [onVisible]);

    useIntersectionObserver(targetRef, handleIntersection, options);

    return targetRef;
};

export default useObserveVisibility;
import React, {useState, useEffect, useRef, useCallback} from 'react';
import Action from "./Action";
import '../styles/scss/Threat.scss';
import {useAuth} from "../contexts/authContext";
import {useFetch} from "../hooks/useFetch";
import {screen, track} from "../util/analytics";
import useObserveVisibility from "../hooks/useObserveVisibility";
import Slide from '@mui/material/Slide';
import ErrorModal from "../modals/ErrorModal";
import Caret from '../svg/Caret';
import useWebKitDetector from "../hooks/useWebKitDetector";


const Details = ({ details, data, parentState, changeState, history, dismissState, setDismiss, riskType, cardId, riskCategoryType, entityId, numActions, numOutboundActions, setRefreshNeeded, windowWidth, isLastRisk, setLastCardDismissed }) => {
	const {authState} = useAuth();
	const {state, fetch, reset} = useFetch();
	const [isExpanded, setIsExpanded] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	//const [windowWidth, setWindowWidth] = useState(window.innerWidth);
        const [transitionClose, setTransitionClose] = useState(true);
    const ExitButton = "https://img.mirkat.com/web/images/home/breaches-exit-button.svg";
    const isWebKitBrowser = useWebKitDetector();
    const initialHeight = 60; // Initial height of the element in pixels
    const [height, setHeight] = useState(initialHeight);
    const scrollRef = useRef(null);

    useEffect(() => {

        const handleScroll = () => {
            // Calculate the height reduction based on scroll position
            const scrollDistance = scrollRef.current.scrollTop;
            const newHeight = initialHeight - initialHeight * 0.1 * Math.floor(scrollDistance / 10);
            // Set the new height, ensuring it does not reduce below 0 for extreme cases
            setHeight(Math.max(newHeight, initialHeight * 0.6));
        };

        // Add event listener for scroll
        if (scrollRef.current) {
            scrollRef.current.addEventListener('scroll', handleScroll);
        }
        // Cleanup the event listener on component unmount
        return () => {
            if (scrollRef.current) {
                scrollRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [scrollRef.current]);


    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                changeState(!parentState);
            }
        };

        // Add event listener
        document.addEventListener('keydown', handleEscape);

        // Remove event listener on cleanup
        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, []);

    const backgroundClose = (event) => {
        console.log("TARGETS COMPARED ", event.target, event.currentTarget);
        if (event.target === event.currentTarget) {
            if (windowWidth > 829) {
                changeState(!parentState);
            } else {
                delayedClose();
            }
        }
    };
    const handleTouchEnd = (event) => {
        //console.log("TARGETS COMPARED ", event.target, event.currentTarget);
        if (event.target === event.currentTarget) {
        //    delayedClose();
            event.preventDefault();
        }
    };

        const delayedClose = () => {
            setTransitionClose(false);
            setTimeout(() => {changeState(false)}, 500)
            //exitCallback(false);
        };


	//useEffect(() => {
	//    const handleResize = () => {
	//        setWindowWidth(window.innerWidth);
	//    };

	//    window.addEventListener('resize', handleResize);

	//    return () => {
	//        window.removeEventListener('resize', handleResize);
	//    };
	//}, []);

	useEffect(() => {
		//#62
		screen('Risk Details', {
			screenType: 'risk',
			screenSubType: 'details',
			riskType: riskType,
			riskCategoryType: riskCategoryType,
			numOfActions: numActions,
			numbOutboundActions: numOutboundActions,
			entityId: entityId,
		});
	}, []);


	const handleComplete = async (actions) => {
		//#209
		track('Button Tapped', {
			buttonType: 'completed',
			screenType: 'risk',
			screenSubType: 'details',
			cardId: cardId
		});


		fetch('complete', [data.id, authState.context.emailId])

	};

    useEffect(() => {
    	if(state.matches('success')) {
    		console.log("CONTEXT COMPLETE DATA:", state.context.data);
			track('Risk Completed', {
				eventType: 'riskCompleted',
				feedType: 'feed',
				riskType: riskType,
				completedType: dismissState ? 'skipped' : 'handled',
				cardId: cardId,

			})
			changeState(!parentState)
                        if (isLastRisk) {
                            setLastCardDismissed(true)
			    setDismiss(!dismissState)
			    //setRefreshNeeded(true)
                        } else {
			    setDismiss(!dismissState)
                        }
    	}
		if(state.matches('failure')) {
			console.log("error on COMPLETE3: ", state.context.error)
			setShowErrorModal(true)
			reset()
		}

    }, [state, dismissState, showErrorModal]);
	const [detailsSeen, setDetailsSeen] = useState(false)
	const onDetailsVisible = useCallback(() => {
		if(!detailsSeen) {
			console.log(detailsSeen)
			console.log('Details Target card is now in view');
			//#175
			track('Button Viewed', {
				buttonType: 'riskDetailCompletedButtons',
				screenType: 'risk',
				screenSubType: 'details',
				feedType: 'feed',
				riskType: riskType,
				cardId: cardId,

			});
			setDetailsSeen(true)

		}

	}, [detailsSeen]);

	const detailsButtonRef = useObserveVisibility(onDetailsVisible, { threshold: 0.5 })




    const checkPassword = (data) => data.startsWith('⦁') && data.endsWith('⦁');

    const handleShowAll = () => setIsExpanded(!isExpanded);

    const analyzeString = (str) => {
        let result = {
            bulletClass: null,
            dashClass: null,
            nonBulletClass: null,
            defaultClass: 'defaultText', // Set a default class
        }
        if (str) {
        const hasBulletPoints = str.includes('⦁');
        const onlyBulletsAndDashes = /^[⦁-]+$/.test(str);
        const bulletsAndOtherChars = hasBulletPoints && /[^⦁-]/.test(str);

        if (hasBulletPoints) {
            result.bulletClass = 'feedcard-value-heavy';
            if (onlyBulletsAndDashes) {
                result.dashClass = 'feedcard-value-dashes';
                result.defaultClass = null;
            } else if (bulletsAndOtherChars) {
                result.nonBulletClass = 'feedcard-value-char';
                result.bulletClass = 'feedcard-value-mixed';
                result.defaultClass = null;
            }
        }
        }
        
        return result;
    };

    return (
        <>{showErrorModal && <ErrorModal props={true} dim={true}  screenType='card' callback={() => {setShowErrorModal(false)}} screenSubType='details' />}
        {windowWidth < 840 &&
	<div className="card-details" onClick={backgroundClose} onTouchEnd={handleTouchEnd}>
        <Slide direction="up" in={transitionClose}>
        <div className="threatcard">
            <div className="threatcard-header-container">
	    <div className="threatcard-header"><img src={data.iconImageUrl} alt="threat card" className="threatcard-image" style={{height: height, width: height}} loading="lazy" /><img src={ExitButton} alt="threat card exit" className="threatcard-exit" onClick={delayedClose} /><div alt="threat card exit" className="threatcard-caret" onClick={delayedClose}><Caret /></div></div>
            </div>
        <div className="threatcard-outer-container" ref={scrollRef}>
	    <div className="threatcard-inner-container">
	        <div className="threatcard-left-container">
	    	    <div className="threatcard-left-header">What happened?</div>
	    	    <div className="threatcard-left-description">{data.actionDescription}</div>
	    	    <div className="threatcard-header-row">
	    	      <span className="threatcard-showtitle">{data.exposedDataTitle}</span>
	    	      <button className="threatcard-showbutton" onClick={handleShowAll}>Show all</button>
	    	    </div>
	    	    {!isExpanded && <div className="threatcard-display">
            	        {data.cardData.map((cardData) => {
                            const { bulletClass, dashClass, nonBulletClass, defaultClass } = analyzeString(cardData?.value);

                            return (
            	            <li key={cardData.id} className="threatcard-row">
            	                <div className="threatcard-label">{cardData.label}</div>
            	                <div className="threatcard-value">
                                    {bulletClass ? <>
                                        {(<div className="feedcard-value-row">
                                            {cardData?.value.split('').map((char, index) => {
                                                if (char === '⦁') {
                                                    return <span className={bulletClass}>{char}</span>
                                                } else if (char === ' ') {
                                                    return <span className={nonBulletClass}>&nbsp;</span>
                                                } else if (char === '-' && dashClass) {
                                                    return <span className={isWebKitBrowser ? "feedcard-webkit-dashes" : dashClass}>{char}</span>
                                                } else {
                                                    return <span className={nonBulletClass}>{char}</span>
                                                }
                                            })}
                                          </div>)}</> : (cardData?.value && <>{cardData?.value}<br /></>)}
                                        {cardData?.subtitle && <div className="feedcard-lite">{cardData?.subtitle}<br /></div>}
                                        {cardData?.subtitle2 && <div className="feedcard-lite">{cardData?.subtitle2}<br /></div>}
                                        {cardData?.message && <div className="feedcard-message">{cardData?.message}<br /></div>}
                                </div>
            	            </li>)}
            	        )}
            	    </div>}
	    	</div>
	    <div className="threatcard-divider-container"><div className="threatcard-divider"/ ></div>
	    <div className="threatcard-right-container">
	    	<div className="threatcard-subtitle">{data.actionGroupTitle}</div>
            	{details && details.map((action) => (
	    	  <li key={action.actionId}>
            	    <Action
            	      action={action}
					  cardId={cardId}
            	    />
	    	  </li>
            	))}
	    </div>
            {history ? null :
	    <div className="threatcard-button-container">
	        <button className="threatcard-button-dismiss" ref={detailsButtonRef} onClick={() => handleComplete()}><div className="threatcard-button-dismiss-text">{data.buttonTitle}</div>
	    </button>
            </div>}
	    </div>
        </div>
        </div>
        </Slide></div>}
        {windowWidth > 839 &&
	<div className="card-details" onClick={backgroundClose} onTouchEnd={handleTouchEnd}>
        <div className="threatcard">
	    <div className="threatcard-header"><img src={data.iconImageUrl} alt="threat card" className="threatcard-image" loading="lazy" /><img src={ExitButton} alt="threat card exit" className="threatcard-exit" onClick={() => changeState(!parentState)} /></div>
	    <div className="threatcard-inner-container">
	        <div className="threatcard-left-container">
	    	    <div className="threatcard-left-header">What happened?</div>
	    	    <div className="threatcard-left-description">{data.actionDescription}</div>
	    	    <div className="threatcard-header-row">
	    	      <span className="threatcard-showtitle">{data.exposedDataTitle}</span>
	    	      <button className="threatcard-showbutton" onClick={handleShowAll}>Show all</button>
	    	    </div>
	    	    {!isExpanded && <div className="threatcard-display">
            	        {data.cardData.map((cardData) => {
                            const { bulletClass, dashClass, nonBulletClass, defaultClass } = analyzeString(cardData?.value);

                            return (
            	            <li key={cardData.id} className="threatcard-row">
            	                <div className="threatcard-label">{cardData.label}</div>
            	                <div className="threatcard-value">
                                    {bulletClass ? <>
                                        {(<div className="feedcard-value-row">
                                            {cardData?.value.split('').map((char, index) => {
                                                if (char === '⦁') {
                                                    return <span className={bulletClass}>{char}</span>
                                                } else if (char === ' ') {
                                                    return <span className={nonBulletClass}>&nbsp;</span>
                                                } else if (char === '-' && dashClass) {
                                                    return <span className={isWebKitBrowser ? "feedcard-webkit-dashes" : dashClass}>{char}</span>
                                                } else {
                                                    return <span className={nonBulletClass}>{char}</span>
                                                }
                                            })}
                                          </div>)}</> : (cardData?.value && <>{cardData?.value}<br /></>)}
                                        {cardData?.subtitle && <div className="feedcard-lite">{cardData?.subtitle}<br /></div>}
                                        {cardData?.subtitle2 && <div className="feedcard-lite">{cardData?.subtitle2}<br /></div>}
                                        {cardData?.message && <div className="feedcard-message">{cardData?.message}<br /></div>}
                                </div>
            	            </li>)}
            	        )}
            	    </div>}
	    	</div>
	    <div className="threatcard-divider-container"><div className="threatcard-divider"/ ></div>
	    <div className="threatcard-right-container" style={history ? {paddingBottom: "unset"} : {}}>
	    	<div className="threatcard-subtitle">{data.actionGroupTitle}</div>
            	{details && details.map((action) => (
	    	  <li key={action.actionId}>
            	    <Action
            	      action={action}
					  cardId={cardId}
            	    />
	    	  </li>
            	))}
	    </div>
	    </div>
            {history ? null :
	    <div className="threatcard-button-container">
			<button className="threatcard-button-dismiss" ref={detailsButtonRef} onClick={() => handleComplete()}><div className="threatcard-button-dismiss-text">{data.buttonTitle}</div>
			</button>
		</div>}
		</div>
		</div>
		}
	</>
    );
};

export default Details;

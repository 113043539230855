import { useEffect, useState } from "react";


const useWebKitDetector = () => {
    const [isWebKitBrowser, setIsWebKitBrowser] = useState(false);

    useEffect(() => {
        const userAgent = navigator.userAgent;
        const isMacOS = /Mac OS/.test(userAgent);
        const isFirefox  = /Firefox/.test(userAgent);
        setIsWebKitBrowser(isMacOS && !isFirefox);
    }, []);

    return isWebKitBrowser;

    }
export default useWebKitDetector;

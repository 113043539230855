import {createMachine, assign, send} from "xstate";
import MirkatClient from "../services/mirkatClient";
import AuthStorageService from "../services/authStorage";
import { CsrfMachine } from "./csrfMachine";
import { ConfirmMachine } from "./confirmMachine";
import { LoginMachine } from "./loginMachine";
import { TokenMachine } from "./tokenMachine";
import {BaseClientParams} from "../util/clientParams";
import {LogoutMachine} from "./logoutMachine";
import {RegisterMachine} from "./registerMachine";
import {HandleContextData} from "../util/handleResponse";
import secureLocalStorage from "react-secure-storage";
import {SetPwMachine} from "./pwMachine";
import {v4 as uuidv4} from "uuid";
import sysend from "sysend";
import {track, identify, clearUserId} from "../util/analytics";


//Todo: "For web, even if the refresh token didn't expire, if the access token is more than 2X minutes old (2 hours), the api will not refresh the token and the user will need to login again. This would ensure that we logout if there is prolonged period of inactivity."
//If access token is older than 2x it's expiration time, refresh_token will not work -> forced signout & login again.

//ToDo: Move storage updates here. Once that's done remove all storage event updates in the authContext provider and only update client when authContext changes

//Current issue is this new tab will make csrf call as it's in session storage and not local storage....
//new tab will make a csrf call causing csrf issue on the other tab

//Note: If you see Uncaught (in promise) TypeError: s is undefined in the console, it's because the data is not being returned correctly and assign in that state or event is failing


//Note: This is an okayish start. Testing multiple tabs with persistent state could lead to me clicking logout on one
//tab, switching to the other tab and doing an action. Switching back still made it look logged in but cookies were deleted...
//may need some endpoint to check if cookie based





//apparently this can ALSO return an access key and refresh key if we confirmed already.... which is undocumented

//We need to store the expiration time as if we refresh, it restarts the counter... and can expire
//need to update token machine...


function isExpired(xpTime) {
    //we should set an expired window of several seconds before the actual expiration time to minimize 400s
    if(xpTime === null) {
        return false; // don't want to be stuck in a loop
    }
    if(isNaN(xpTime)) {
        return true;
    }
    return Math.floor(Date.now()/1000) > parseInt(xpTime);
}

export function saveStateToLocalStorage(state) {
    //Causing issues with multitab
    //if ((process.env.REACT_APP_ENV === 'prod') || (process.env.REACT_APP_ENV !== 'dev' && !localStorage.getItem('debug'))) {
     //   secureLocalStorage.setItem('data', JSON.stringify(state));
    //} else {
       // localStorage.setItem('data', state);
    //}
}



export function loadStateFromLocalStorage() {
    let savedState = "";
    //Causing issues with multitab
    //if ((process.env.REACT_APP_ENV === 'prod') || (process.env.REACT_APP_ENV !== 'dev2' && !localStorage.getItem('debug'))) {
    //    savedState = secureLocalStorage.getItem('data');
    //} else {
    /*
    savedState = localStorage.getItem('data');
    //}
    if (savedState) {
        try {
            return JSON.parse(savedState);
        } catch (error) {
            console.log('Error loading state from localStorage:', error);
        }
    }

     */
    return authMachine.initialState;
}

//next action should be to clear updateContext => null
const updateContext = (context, event) => {
    let toretrun = {};
    if(context.updateContext) {
        context.updateContext.forEach(function(item) {
            console.log(item);
            toretrun[item.field] = item.value;
        });
        return toretrun;
    }

    return {};

}

const initClient = (context) => {
    const client = new MirkatClient({
        baseURL: BaseClientParams.baseURL,
        headers: BaseClientParams.headers
    });
    const storage = new AuthStorageService();
    return Promise.resolve({client, storage});
}

function updateClientandStorage(context, storage) {
    context.apiClient.setCsrf(context.csrf);
    context.apiClient.setSecret(context.secret);
    context.apiClient.setInstallationId(context.installationId);
    context.apiClient.setAccessKey(context.accessToken);
    context.apiClient.setRefreshKey(context.refreshToken);
    context.apiClient.setUserId(context.userId);
    context.apiClient.setEmailId(context.emailId);
    context.apiClient.setPTK(context.ptk);

    HandleContextData(context, storage);
}

function updateCSRFClient(context) {
    context.apiClient.setCsrf(context.csrf);
    context.apiClient.setSecret(context.secret);
}

function updateClientFromStorage(context) {
    context.apiClient.setInstallationId(context.installationId);
    context.apiClient.setAccessKey(context.accessToken);
    context.apiClient.setRefreshKey(context.refreshToken);
    context.apiClient.setUserId(context.userId);
    context.apiClient.setEmailId(context.emailId);
    context.apiClient.setPTK(context.ptk);
}

//first logins storage can get called to set context before it exists. Likely caused by previous versions expecting to
// call "handleResponse" which used to save to storage before returning.
function fixNans(context) {
    if(isNaN(context.accessTokenExp)) {
        const axp = context.storage.getAccessTokenExp()
        if(axp) {
            context.accessTokenExp = axp;
        } else {
            context.accessTokenExp = 0;
        }
    }
    if(isNaN(context.refreshTokenExp)) {
        const rtxp = context.storage.getRefreshTokenExp()
        if(rtxp) {
            context.refreshTokenExp = rtxp;
        } else {
            context.refreshTokenExp = 0;
        }
    }
    if(isNaN(context.sessionStamp)) {
        const ss = context.storage.getSessionStamp()
        if(ss) {
            context.sessionStamp = ss;
        } else {
            context.sessionStamp = 0;
        }
    }
    return context;
}

function refreshBad(context, event) {
    //check refresh key exists
    //check if refresh key is expired
    if(context?.refreshToken && isExpired(context?.refreshTokenExp)) {
        return true;
    }
    return false
}

function refreshKeyExpired(context, event) {
    if(context.refreshTokenExp === 0 && context.refreshToken === null) {
        return false;
    }
    return isExpired(context.refreshTokenExp);
}


function accessKeyExpired(context, event) {
    if (!context.accessToken && context.accessTokenExp === 0) {
        return false;
    }
    return isExpired(context.accessTokenExp);
}


//check for secret existing too
function csrfExpired(context, event) {
    if((context?.csrf && isExpired(context?.csrfExp)) || !context?.csrf) {
        return true;
    }
    return false;
}
//capture password set error
function trackPwError(context, event) {
    console.log("CONTEXT AUTH: ", context);
//#60
    track('User Sign Up', {
        eventType: 'userSignUp',
        eventSubType: 'error',
        stepType: "password", //email, (confirmation) pin, password
        errorCode: context.errorData?.errorCode || null,
    })
}

const shouldLogoutTime = process.env.REACT_APP_SESSION_RESUME_TIMEOUT || 300000;


function shouldLogout() {
    const now = Date.now();
    const lastActivity = parseInt(localStorage.getItem('lastActivity') || 0);
    if (lastActivity && (now - lastActivity) >= parseInt(shouldLogoutTime)) {
        localStorage.removeItem('lastActivity');//prevent loop
        return true;
    }
    return false;
}

const windowId = uuidv4();

//Have to store/retrieve data to local storage to support the external client
//until we can use a clientMachine of some sort
export const authMachine = createMachine({
    id: 'authentication',
    initial: 'initClient',
    context: {
        windowId: windowId,
        apiClient: null,
        storage: null,
        retries: 0,
        accessToken: null, // we should start storing the token and refresh as objects with expiration times
        accessTokenExp: 0,
        refreshToken: null,
        refreshTokenExp: 0,
        sessionStamp: null, //timestamp to use for card/feed updates
        emailId: null,
        emailAddress: "", //primary email address - stored in local storage
        settings: {}, //settings object - we get it on login or new user
        emailLogin: "", // same as emailRegister but for login
        emailRegister: "", // for registration - allowing goback to refill form
        secondaryEmails: null, //secondary email addresses
        isPlus: false, //only if stored in local storage -> might only want to set this via a fetch
        installationId: null, //storage.getInstallationId(),
        csrf: null,
        csrfExp: 0,
        secret: null,
        userId: null,
        ptk: null,
        isAuthenticated: false,
        needsConfirm: false,
        error: false,
        errorData: null, // could just use error as errorData and if null no error else error -> handle error
        refreshCalls: 0, //going to use as a throttle to stop stampede of refresh calls
        dismissed: false,
        userEmailId: null,
        deviceTypeId: 0, //used to tell where original request came from for confirm
        seenIds: null,
        progressWait: false,
        isNewUser: false,
        campaignId: "", //ad campaign id
        paymentSessionId: "", //sessionId to force check if user is plus or not right after payment -> call endpoint and wait - or we could poll?
        disableProgressBar: false,
        adCampaignId: '',
        cohorts: '',
        price: '',
        duration: '',
        //updateContext: null, // if not null, needs to be [{}] array of objects being {field: value}
    },
    on: {
        REGISTER: 'registering',
        UPDATE_SESSION_STAMP: {
                actions: [assign({
                    sessionStamp: () => (Math.floor(Date.now() / 1000)),
                }), (context) => context.storage.setSessionStamp(Math.floor(Date.now() / 1000))]
        },
        UPDATEDCSRF: {
                actions: [assign({
                    csrf: (context, event) => event.csrf,
                    csrfExp: () => Math.floor(Date.now()/1000 + 3540), // little bit less than an hour
                    secret: (context, event) => event.secret,
                }), (context) => updateClientandStorage(context, context.storage)]
        },
        CSRF_RECEIVED: {
            actions: [assign({
                csrf: (_, event) => event.csrf,
                csrfExp: () => Math.floor(Date.now()/1000 + 3540),
                secret: (_, event) => event.secret,
            }), updateCSRFClient, 'logIt'],
        },
        TIMEOUT: 'requestingCSRF',
        CSRF_UPDATED: { //sent via sysend
            actions: [assign({
                csrf: (_, event) => event.csrf,
                csrfExp: () => Math.floor(Date.now()/1000 + 3540),
                secret: (_, event) => event.secret,
            }), updateCSRFClient, 'logIt'],

        },
        UPDATE_FROMSTORAGE: { //update or use existing context
            actions: [() => console.log("Next is getting storage uid"), (context) => console.log("userId from storage: ", context.storage.getUserId()), assign({
                accessToken: (context) => context.storage.getAccessToken(),
                accessTokenExp: (context) => context.storage.getAccessTokenExp(),
                refreshToken: (context) => context.storage.getRefreshToken(),
                refreshTokenExp: (context) => context.storage.getRefreshTokenExp(),
                sessionStamp: (context) => context.storage.getSessionStamp(),
                emailId: (context) => context.storage.getEmailId(),
                emailAddress: (context) => context.storage.getEmail(),
                secondaryEmails: (context) => context.storage.getSecondaryEmail(),
                installationId: (context) => context.storage.getInstallationId(),
                userId: (context) => context.storage.getUserId(),
                ptk: (context) => context.storage.getPTK(),
               // needsConfirm: (context) => context.storage.getNeedsConfirm(),
                dismissed: (context) => context.storage.getDismissed(),
            }), updateClientFromStorage, 'logIt'],
        },
        CANCELED_SUBSCRIPTION: {
            actions: [assign({
                isPlus: false,
            })],
        },
        SUBSCRIPTION_ENABLED: {
            actions: [assign({
                isPlus: true,
            })],
        },

        //Force request a new access key. If this gets an error (likely 401 or 403 - errorcode 6) we need to logout
        FORCE_REFRESH_TOKEN: {
            target: 'authenticated.refreshing',
        },
        //wait for progress bar to finish - for pages that transition authstate
        START_PROGRESS: {
            actions: [assign({
                progressWait: true,
            })],

        },
        //progress bar finished - change state
        FINISH_PROGRESS: {
            actions: [assign({
                progressWait: false,
            })],
        },
        CLEAR_ERROR: {
            actions: [assign({
                error: false,
                errorData: null,
            })]

        },
        CLEAR_ISNEW_USER: {
            actions: [assign({
                isNewUser: false,
            })],
        },
        LOGOUT: 'loggingOut',
        UPDATE_USER_EMAILID: {
            actions: assign({
                userEmailId: (_, event) => event.userEmailId,
            }),
        },
        UPDATE_PRICE: {
            actions: assign({
                price: (_, event) => event.price,
            }),
        },
        UPDATE_DURATION: {
            actions: assign({
                duration: (_, event) => event.duration,
            }),
        },
        SET_AD_CAMPAIGN: {
            actions: assign({
                campaignId: (_, event) => event.campaignId,
            })
        },
        CLEAR_AD_CAMPAIGN: {
            actions: assign({
                campaignId: "",
            })
        },
        SET_PAYMENT_SESSIONID: {
            actions: assign({
                paymentSessionId: (_, event) => event.paymentSessionId,
            })
        },
        CLEAR_PAYMENT_SESSIONID: {
            actions: assign({
                paymentSessionId: "",
            })
        },
        SET_LOGIN_EMAIL: {
            actions: assign({
                emailLogin: (_, event) => event.email,
            })
        },
        CLEAR_LOGIN_EMAIL: {
            actions: assign({
                emailLogin: "",
            })
        },
        DISABLE_PROGRESS_BAR: {
            actions: assign({
                disableProgressBar: true,
            })
        },
        ENABLE_PROGRESS_BAR: {
            actions: assign({
                disableProgressBar: false,
            })
        },
        DISABLE_LOADING: {
            actions: assign({
                showLoading: false,
            })
        },
        CLEAR_ERROR_DATA: {
            actions: assign({
                errorData: null,
            }),
        },
        SET_ADCAMPAIGN_COHORT: {
            actions: assign({
                adCampaignId: (context, event) => event.adCampaignId||context.adCampaignId,
                cohort: (context, event) => event.cohort||context.cohort,
            })
        }

    },

    states: {
        initClient: {
            invoke: {
                id: 'initClient',
                src: (context) => initClient(context),
                onDone: {
                    target: 'requestingCSRF',
                    actions: [assign({
                        apiClient: (context, event) => event.data.client,
                        storage: (context, event) => event.data.storage,
                        windowId: () => windowId,
                    }),
                    ]
                },
                onError: {
                    target: 'error',
                }
            },
        },
        /* //Can't cleanly do this once... race condition with AuthState and the broadcast channels where this always timesout
        waitingForCsrfSync: {
            on: {
                CSRF_RECEIVED: { //From sysend (boradcast channel) - (external in a component or global context)
                    cond: (_, event) => event.csrf,
                    target: 'loadFromStorage',
                    actions: [assign({
                        csrf: (_, event) => event.csrf,
                        secret: (_, event) => event.secret,
                    }), updateCSRFClient, () => console.log('skipping requesting csrf - got one from another window')],
                },
                TIMEOUT: {
                    target: 'requestingCSRF',
                }
            }
        },

         */
        requestingCSRF: {
            onEntry: (context, event) => console.log("CSRF: ", context, event),
            invoke: {
                id: 'getCsrf',
                src: (context) => CsrfMachine.withContext(context),
                data: {
                    apiClient: (context) => context.apiClient,
                },
                onDone: {
                    target: 'INITIAL', // cannot access childmachine data here/assign action.
                },
                onError: { // ToDo: Should add a retry...
                    target: 'error',
                    actions: [(context, event) => console.log("WTF: ", context, event), 'logIt'],
                    meta: {
                        errorType: 'csrf',
                    }
                }
            },
            on: {
                'CSRF':
                    { //Have to use named callback (sendParent({type: 'CSRF', csrf: context.csrf, secret: context.secret})) to access child machine data
                        actions: [() => console.log("WTF2"), (context) => console.log(context), assign({
                            csrf: (context, event) => event.csrf,
                            csrfExp: () => Math.floor(Date.now() / 1000 + 3540), // little bit less than an hour
                            secret: (context, event) => event.secret,
                            retries: 0,
                            error: false,
                        }),
                            (context, _) => {
                                context.apiClient.setCsrf(context.csrf);
                                context.apiClient.setSecret(context.secret);
                            },
                            (context, event) => {
                                updateCSRFClient(context)
                            },
                            'broadcastCsrf'
                        ],
                        target: 'loadFromStorage',
                    },
            },
        },

        loadFromStorage: {
            always: {
                target: 'INITIAL',
                actions: [
                    assign({
                        accessToken: (context) => context.storage.getAccessToken(), // we should start storing the token and refresh as objects with expiration times
                        accessTokenExp: (context) => context.storage.getAccessTokenExp() || 0,
                        refreshToken: (context) => context.storage.getRefreshToken() || null,
                        refreshTokenExp: (context) => context.storage.getRefreshTokenExp() || 0,
                        sessionStamp: (context) => context.storage.getSessionStamp(), //timestamp to use for card/feed updates
                        emailId: (context) => context.storage.getEmailId(),
                        emailAddress: (context) => context.storage.getEmail(), //primary email address
                        installationId: (context) => context.storage.getInstallationId(), //storage.getInstallationId(),
                        //csrf: (context) => context.storage.getCSRF(),
                        //csrfExp: (context) => context.storage.getCSRFExp() || 0,
                        //secret: (context) => context.storage.getSessionSecret(),
                        userId: (context) => context.storage.getUserId(),
                        ptk: (context) => context.storage.getPTK(),
                       // needsConfirm: (context) => context.storage.getNeedsConfirm() || false, // this is causing issues.
                        dismissed: (context) => context.storage.getDismissed() || false,
			seenIds: (context) => [],
                        cohorts: (context) => context.storage.getCohorts(),
                    }), (context) => console.log("after WTF2: ", context),
                        (context, event) => {
                        updateClientandStorage(context, context.storage);
                    }
                ],
            }

        },
        //We need to check if we're authenticated or not...
        /////If accessKey is expired, and refresh key is not, try to get access key else unset and go to login
        INITIAL: {
            always: [
                {
                    cond: shouldLogout,
                    target: 'loggingOut',
                },
                {
                    cond: refreshKeyExpired, // if refresh key bad, logout
                    target: 'loggingOut',
                },
                {
                    cond: accessKeyExpired, // if access key expired, and refresh key is not, try to get new access key
                    target: 'authenticated.refreshing',
                },
                //{
                 //   cond: 'noCSRF',
                 //   target: 'csrf'
               // },
               // {
                //    cond: csrfExpired,
                //    target: 'csrf'
                //},
                {
                    target: 'unauthenticated',
                }
            ],
        },
        csrf: {
            onEntry: (context, event) => console.log("CSRF: ", context, event),
            invoke: {
                id: 'csrf',
                src: (context) =>  CsrfMachine.withContext(context),
                data: {
                    apiClient: (context) => context.apiClient,
                },
                onDone: {
                    target: 'INITIAL', // cannot access childmachine data here/assign action.
                },
                onError: { // ToDo: Should add a retry...
                    //target: 'error',
                    meta: {
                        errorType: 'csrf',
                    }
                }
            },
            on: {
                'CSRF': [                {
                    cond: (context, event) => context.callback === 'loggingIn',
                    target: 'loggingIn',
                    actions: [assign({
                        csrf: (context, event) => event.csrf,
                        csrfExp: () => Math.floor(Date.now()/1000 + 3540), // little bit less than an hour
                        secret: (context, event) => event.secret,
                        retries: 0,
                        error: false,
                    }),
                        (context, _) => {
                            context.apiClient.setCsrf(context.csrf);
                            context.apiClient.setSecret(context.secret);
                        },
                        //(context, event) => {
                         //   updateClientandStorage(context, context.storage)
                        //}
                        'broadcastCsrf'
                    ]
                },

                    { //Have to use named callback (sendParent({type: 'CSRF', csrf: context.csrf, secret: context.secret})) to access child machine data
                    actions: [assign({
                        csrf: (context, event) => event.csrf,
                        csrfExp: () => Math.floor(Date.now()/1000 + 3540), // little bit less than an hour
                        secret: (context, event) => event.secret,
                        retries: 0,
                        error: false,
                    }),
                        (context, _) => {
                            context.apiClient.setCsrf(context.csrf);
                            context.apiClient.setSecret(context.secret);
                        },
                        (context, event) => {
                            updateClientandStorage(context, context.storage)
                        }
                    ]
                },
                {
                    cond: (context, event) => context.retries >= 3,
                    target: 'error',

                },

                ],




            }
        },

        //Need to check if we're logged in or not via access_key
        unauthenticated: {
            always: [
                {
                    cond: 'hasAccessToken',
                    target: 'authenticated',
                }, // if we have a ptk, we need to set a password.
                {
                    cond: 'setPassword',
                    target: 'needPw',
                },
                {
                    cond: 'needsConfirm', //I think we need to change this to needsConfirm key set.
                    target: 'confirm',
                }
            ],
            //onExit: [assign({
             //   error: false,
              //  errorData: null,
           // })],
            on: {
                LOGIN: 'loggingIn',
                LOGOUT: 'loggingOut',
                CONFIRM: 'confirming',
                UPDATECSRF: 'csrf',
                REGISTER: {
                    target: 'registering',
                    actions: [(context, event) => console.log(context), (context, event) => console.log(event),
                        assign({
                            emailAddress: (context, event) => event.email,
                            installationId: null,
                            userId: null,
                            emailId: null,
                            accessToken: null,
                            refreshToken: null,
                            accessTokenExp: null, // todo: remove and test register doesn't break
                            sessionStamp: null,
                            isPlus: false,
                            isAuthenticated: false,
                            needsConfirm: false,
                            error: false,
                            errorData: null,
                            userEmailId: null,
                        })]
                },
                REGISTERV1: {
                    target: 'register_v1_user'
                }
            }

        },

        register_v1_user: {
            on: {
                V1PASSWORDSET: {
                    actions: [(context, event) => console.log(context), (context, event) => console.log(event),
                        assign({
                            emailAddress: (context, event) => event.data.email || context.emailAddress,
                            //cohorts: //
                            userEmailId: (context, event) => event.data?.userEmailId || context.userEmailId,
                            installationId: (context, event) => event.data.installationId || context.installationId,
                            userId: (context, event) => event.data.userId || context.userId,
                            emailId: (context, event) => event.data.emailId || context.emailId,
                            accessToken: (context, event) => event.data.accessKey || context.accessKey,
                            cohorts: (context, event) => event.data.cohorts || context.cohorts,
                            accessTokenExp: Math.floor(Date.now() / 1000) + parseInt(process.env.REACT_APP_ACCESS_TOKEN_INTERVAL)||Math.floor(Date.now() / 1000) + 3540,
                            refreshToken: (context, event) => event.data.refreshKey || context.refreshKey,
                            refreshTokenExp: Math.floor(Date.now() / 1000) + (parseInt(process.env.REACT_APP_REFRESH_TOKEN_INTERVAL))||Math.floor(Date.now() / 1000) + 86340,
                        }),
                        (context, event) => {
                            updateClientandStorage(context, context.storage)}
                    ],
                    target: 'authenticated'

                },
                BACK: {
                    target: 'unauthenticated'
                },
                ERROR: {
                  target: 'clearAndReset'
                }
            }
        },

        registering: { // could we do... on entry strip all the vars except email?
            entry: (context, event) => console.log("registering: ", context, event),
            invoke: {
                id: 'registering',
                cond: context => context.retries < 3,
                src: (context) => RegisterMachine.withContext(context), // need to add email bubbled up to context
                data: {
                    apiClient: (context) => context.apiClient,
                    eventData: (_, event) => event,
                    retries: (context) => context.retries,
                },
                onDone: {
                    target: 'confirm',
                },
                onError: {
                    target: 'error',
                }
            },
            on: {
                DONE: {
                    target: 'confirm',
                    actions: [assign({
                        emailId: (context, event) => event.data.emailId || context.emailId,
                        emailAddress: (context, event) => event.data.emailAddress || context.emailAddress,
                        emailLogin: (context, event) => event.data.emailAddress || "",
                        installationId: (context, event) => event.data.installationId || context.installationId,
                        userId: (context, event) => event.data.userId || context.userId,
                        price : (_, event) => event.data.price || "",
                        duration: (_, event) => event.data.duration || "",
                        errorData: null,
                        //needsConfirm: () => true, //might not want this behavior if they should back out of registration
                    }), (context, event) => console.log(event.data.price), (context)  => {
                        //context.storage.setNeedsConfirm(true)
                        updateClientandStorage(context, context.storage)},
                        (context) => fixNans(context)
                    ]
                },
                UPDATEDCSRF: {
                    actions: [assign({
                        csrf: (context, event) => event.csrf,
                        csrfExp: () => Math.floor(Date.now()/1000 + 3540), // little bit less than an hour
                        secret: (context, event) => event.secret,
                    }), (context) => updateCSRFClient(context),
                        'broadcastCsrf'

                    ]
                },
                GOLOGIN: { //User exists so goto login
                  actions: [() => console.log("GOT GOLOGIN"), assign({
                      errorData: (context, event) => event?.errorData || event?.data || event?.error,
                  })]
                },
                ERROR: [
                    /*
                    {
                        cond: (context, event) => context.retries >= 3,
                        target: 'error',
                        actions: (context) => console.log("going to error state: ", context)
                    },

                     */
                    {
                    target: 'unauthenticated',
                    actions: [(context, event) => console.log("CONTEXT: ", context), (context, event) => console.log("Event: ", event),
                        assign({
                            emailAddress: null,
                            error: true,
                            errorData: (context, event) => event.errorData,
                            //retries: (context) => context.retries + 1,
                        }), (context) => console.log("AUTH REGISTER ERROR CONTEXT: "), (context) => console.log(context), (context, event) => console.log(event)]
                }]
            },
            after: { // we can bail out of here if longer than 10 seconds.
                10000: [{
                    target: 'error',

                    actions: [assign({
                        error: true,
                        errorData: {"errorBody": "", "errorCode": 28}
                    })],
                }]
            },
        },

        loggingIn: {
            invoke: {
                id: 'login',
                src: (context) => LoginMachine.withContext(context), // need to add email bubbled up to context
                data: (context, event) => ({
                    apiClient: context.apiClient,
                    authData: context,
                    eventData: event,
                    retries: 0,
                }),
                onDone: {
                    target: 'unauthenticated',
                    actions: () => console.log("DONE"),
                },
                onError: {
                    target: 'error',
                    actions: () => console.log("ERROR"),
                }
            },
            on: {
                CONFIRM: {
                    target: 'confirm',
                    actions: [(context, event) => console.log(event), assign({
                        emailId: (context, event) => event.data.emailId || context.emailId,
                        emailAddress: (context, event) => event.data.emailAddress || context.emailAddress,
                        emailLogin: (context, event) => event.data.emailAddress || "",
                        installationId: (context, event) => event.data.installationId || context.installationId,
                        userId: (context, event) => event.data.userId || context.userId,
                        ptk: (context, event) => event.data.ptk|| context.ptk,
                        cohorts: (context, event) => event.data.cohorts || context.cohorts,
                        error: false,
                        errorData: null,

                    }), (context, event) => {
                        //context.storage.setNeedsConfirm(true)
                        updateClientandStorage(context, context.storage)

                    },
                        (context) => fixNans(context)
                    ],
                },
                DONE: {
                    target: 'authenticated',
                    actions: [assign({
                        accessToken: (context, event) => event.data.accessKey || context.accessToken, //needs _context or xstate refresh blows up
                        accessTokenExp: (context, event) => event.data.accessKeyExp,
                        refreshToken: (context, event) => event.data.refreshKey || context.refreshToken,
                        refreshTokenExp: (context, event) => event.data.refreshKeyExp,
                        emailId: (context, event) => event.data.emailId || context.emailId,
                        emailAddress: (context, event) => event.data.emailAddress,
                        emailLogin: (context, event) => event.data.emailAddress || "",
                        installationId: (context, event) => event.data.installationId,
                        userId: (context, event) => event.data.userId,
                        ptk: (context, event) => event.data.ptk,
                        isAuthenticated: (context, event) => event.data.isAuthenticated,
                        sessionStamp: (context) => context.storage.getSessionStamp(), //this is NaN if not set on storage...
                        cohorts: (context, event) => event.data.cohorts || context.cohorts,
                        errorData: null,
                        needsConfirm: false,
			            seenIds: [],
                    }), (context, event) => { //update storage from context
                        context.storage.removeNeedsConfirm()
                        updateClientandStorage(context, context.storage)
                    }, (context) => console.log("logged in context after actions: ", context),
                        (context) => fixNans(context)
                    ]

                },

                CSRF_ERROR: {
                    target: 'csrf',
                    actions: [assign({
                        retries: (context) => context.retries + 1,
                        callback: 'loggingIn',
                    }),

                    ]

                },
                ERROR: {
                    target: '#errorAndReset',
                    actions: [assign({
                        error: true,
                        errorData: (context, event) => event.errorData,
                    }),

                    ]

                },
                LOGOUT: 'loggingOut',
            },
            after: { // we can bail out of here if longer than 5 seconds.
                10000: [{
                    target: 'error',

                    actions: [assign({
                        error: true,
                        errorData: {"errorBody": "", "errorCode": 28}
                    })],
                }]
            },
        },

        //We use this state to sit here after initial login. confirmation code onsubmit will send CONFIRM event
        confirm: { /// NEED to add registration scenerio....
            on: {
                LOGIN: 'loggingIn', //if someone goes back from the confirm code and tries to login again.
                CONFIRM: 'confirming',
                UPDATECSRF: 'csrf',
                BACK: 'unauthenticated',
                ERROR: {
                    target: 'error',
                },
                REGISTER: {
                    target: 'unauthenticated', // we shouldn't end up here with this
                    actions: assign({
                        error: false,
                        errorData: null,
                        emailAddress: null,
                        emailId: null,
                        installationId: null,
                        userId: null,
                        needsConfirm: false,
                    }),
                },
                LOGOUT: 'loggingOut',
                POLLING_NEWUSER_CONFIRM_SUCCESS: {
                    target: 'unauthenticated', // let unauthenticated figure out where to go next
                    actions: [assign({
                        emailId: (context, event) => event.data.emailId || context.emailId,
                        emailAddress: (context, event) => event.data.emailAddress || context.emailAddress,
                        settings: (context, event) => event.data.settings || context.settings,
                        deviceTypeId: (context, event) => event.data.deviceTypeId || context.deviceTypeId,
                        ptk: (context, event) => event.data.ptk || context.ptk,
                        needsConfirm: false,
                        error: false,
                        errorData: null,
                        seenIds: [],
                    }), (context) => context.storage.removeNeedsConfirm(),
                        (context) => {
                            context.storage.removeNeedsConfirm()
                            updateClientandStorage(context, context.storage)
                        }, assign({ //storage events don't update on same tab so we have updateClientandStorage set the exp times, then we reread them from storage
                            sessionStamp: (context) => context.storage.getSessionStamp(), //check existing
                            refreshTokenExp: (context) => context.storage.getRefreshTokenExp(),
                            accessTokenExp: (context) => context.storage.getAccessTokenExp(),
                        }),
                        (context) => fixNans(context)
                    ]

                },
                POLLING_AUTHCONFIRM_SUCCESS: {
                    target: 'authenticated',
                    actions: [assign({
                        accessToken: (context, event) => event.data.accessKey || context.accessToken, // Sometimes we can get these here in /confirm
                        refreshToken: (context, event) => event.data.refreshKey || context.refreshToken,
                        emailAddress: (context, event) => event.data.emailAddress || context.emailAddress,
                        sessionStamp: (context) => context.storage.getSessionStamp(),
                        refreshTokenExp: (context) => context.storage.getRefreshTokenExp(),
                        accessTokenExp: (context) => context.storage.getAccessTokenExp(),
                        deviceTypeId: (context, event) => event.data.deviceTypeId || context.deviceTypeId,
                        needsConfirm: false,
                        error: false,
                        errorData: null,
                        seenIds: [],
                        showLoading: true,
                    }), (context) => console.log("ARG: ", context), (context) => {
                        context.storage.removeNeedsConfirm()
                        updateClientandStorage(context, context.storage)

                    }, assign({ //storage events don't update on same tab so we have updateClientandStorage set the exp times, then we reread them from storage
                        sessionStamp: (context) => context.storage.getSessionStamp(), //check existing
                        refreshTokenExp: (context) => context.storage.getRefreshTokenExp(),
                        accessTokenExp: (context) => context.storage.getAccessTokenExp(),
                    }),
                        (context) => fixNans(context)
                    ]
                }
                //

            }
        },
        //Apparently we can get an access and refresh key in /confirm AND/OR /password endpoints...

        confirming: {
            invoke: {
                id: 'confirming',
                src: (context) => ConfirmMachine.withContext(context),
                data: (context, event) => ({
                    apiClient: context.apiClient,
                    authData: context,
                    eventData: event,
                }),

                onError: {
                    target: 'unauthenticated', //remove the authStates fields once all this is working locally
                }
            },
            on: {
                AUTHENTICATED: {
                    target: 'authenticated',
                    actions: [assign({
                        ptk: (context, event) => event.data.ptk,
                        accessToken: (context, event) => event.data.accessKey || context.accessToken, // Sometimes we can get these here in /confirm
                        refreshToken: (context, event) => event.data.refreshKey || context.refreshToken,
                        emailAddress: (context, event) => event.data.emailAddress || context.emailAddress,
                        sessionStamp: (context) => context.storage.getSessionStamp(), //check existing
                        refreshTokenExp: (context) => context.storage.getRefreshTokenExp(),
                        accessTokenExp: (context) => context.storage.getAccessTokenExp(),
                        needsConfirm: false,
                        error: false,
                        errorData: null,
			seenIds: [],
                        cohorts: (context, event) => event.data.cohorts || context.cohorts,
                    }), (context) => console.log("ARG: ", context), (context) => {
                        context.storage.removeNeedsConfirm()
                        updateClientandStorage(context, context.storage) //this sets the exps for refresh, access and session stamp

                    }, assign({ //storage events don't update on same tab so we have updateClientandStorage set the exp times, then we reread them from storage
                        sessionStamp: (context) => context.storage.getSessionStamp(), //check existing
                        refreshTokenExp: (context) => context.storage.getRefreshTokenExp(),
                        accessTokenExp: (context) => context.storage.getAccessTokenExp(),
                    }),
                        (context) => fixNans(context),
                    ]
                },
                //First new login on device -> nothing set on storage initially
                DONE: {
                    target: 'unauthenticated', // let unathenticated figure out where to go next
                    actions: [assign({
                        ptk: (context, event) => event.data.ptk || context.ptk,
                        accessToken: (context, event) => event.data.accessKey || context.accessToken, // Sometimes we can get these here in /confirm
                        refreshToken: (context, event) => event.data.refreshKey || context.refreshToken,
                        emailAddress: (context, event) => event.data.emailAddress || context.emailAddress,
                        sessionStamp: (context) => context.storage.getSessionStamp(),
                        refreshTokenExp: (context) => context.storage.getRefreshTokenExp(),
                        accessTokenExp: (context) => context.storage.getAccessTokenExp(),
                        needsConfirm: false, //prevents us getting stuck... Don't think we need confirm after registering... since we already check the code..
                        error: false,
                        errorData: null,
			            seenIds: [],
                    }), (context) => context.storage.removeNeedsConfirm(),
                         (context) => {
                            updateClientandStorage(context, context.storage)
                    },
                        assign({ //storage events don't update on same tab so we have updateClientandStorage set the exp times, then we reread them from storage
                            sessionStamp: (context) => context.storage.getSessionStamp(), //check existing
                            refreshTokenExp: (context) => context.storage.getRefreshTokenExp(),
                            accessTokenExp: (context) => context.storage.getAccessTokenExp(),
                        }),
                        (context) => fixNans(context)
                    ]
                },
                //If we end up stuck in this state, we need to remove all except installationId and start over

                //what type of error?
                //error code 44, invalid init session
                //error 5 expired session
                //Error code 8 - invalid code
                INVALID_CODE: {
                  target: 'confirm',
                  actions: [assign({
                      error: true,
                      errorData: (context, event) => event.data,
                  })]
                },
                EXPIRED_CODE: {
                  target: 'confirm',
                  actions: [assign({
                      error: true,
                      errorData: (context, event) => event.data,
                  }), (context, event) => console.log("ERROR TEXT IS: ", event.data)]
                },
                //Update for expired session or invalid session
                UPDATEDCSRF: {
                    actions: [() => console.log("need to call UPDATEDCSRF"), assign({
                        csrf: (context, event) => event.csrf,
                        csrfExp: () => Math.floor(Date.now()/1000 + 3540), // little bit less than an hour
                        secret: (context, event) => event.secret,
                    }), (context) => updateCSRFClient(context),
                        'broadcastCsrf'
                    ]
                },
                ERROR: {
                    target: 'errorAndReset', //Likely change to error as target
                    actions: [() => console.log("arggg"), (context, event) => console.log("event: ", event), assign({
                        error: true,
                        errorData: (context, event) => event.data,
                    }),

                    ]

                },
            }
        },

        needPw: { //fetch machine tells authMachine if we are successful or not.
            on: {
                LOGIN: 'loggingIn',
                LOGOUT: 'loggingOut',
                CONFIRM: 'confirming',
                BACK: 'unauthenticated',
                GOBACK_OVERRIDE: 'confirm',
                SETPW: 'settingPw',
                REGISTER: 'registering',
                ERROR: {
                    target: 'error', // idk how we should handle a registration failure...
                }
            }

        },

        settingPw: {
            entry: () => console.log("setting PW"),
            invoke: {
                id: 'settingPw',
                src: (context) => SetPwMachine.withContext(context),
                data: (context, event) => ({
                    apiClient: context.apiClient,
                    authData: context,
                    eventData: event,
                }),

                onError: {
                    target: 'errorAccountCreation', //remove the authStates fields once all this is working locally
                }
            },
            on: {
                BACK: 'unauthenticated',
                pwSet: { //PasswordSet
                    target: 'authenticated', // unathenticated will figure out where to go next
                    actions: [assign({
                        accessToken: (context, event) => event.data.accessKey || context.accessToken,
                        refreshToken: (context, event) => event.data.refreshKey || context.refreshToken,
                        sessionStamp: (context) => context.storage.getSessionStamp(),
                        refreshTokenExp: (context) => context.storage.getRefreshTokenExp(),
                        accessTokenExp: (context) => context.storage.getAccessTokenExp(),
                        cohorts: (context, event) => event.data.cohorts || context.cohorts,
                        needsConfirm: false,
                        errorData: null,
                        isNewUser: true,
                        ptk: null,
                        seenIds: [],
                    }), (context) => {
                        context.storage.removeNeedsConfirm()
                        updateClientandStorage(context, context.storage)
                    },
                        (context) => fixNans(context)
                    ],
                },
                UPDATEDCSRF: {
                    actions: [() => console.log("need to call UPDATEDCSRF"), assign({
                        csrf: (context, event) => event.csrf,
                        csrfExp: () => Math.floor(Date.now()/1000 + 3540), // little bit less than an hour
                        secret: (context, event) => event.secret,
                    }), (context) => updateCSRFClient(context),
                        'broadcastCsrf'
                    ]
                },
                CONFIRM: {
                    target: 'confirm', //Likely change to error as target
                    actions: [() => console.log("ERROR 37 GOING TO CONFIRM"), (context, event) => console.log("event: ", event), assign({
                        error: true,
                        errorData: (context, event) => event.data,
                    }),]
                },
                ERROR: [
                    {
                    target: 'needPw', //Likely change to error as target
                    actions: [assign({
                        error: true,
                        errorData: (context, event) => event.errorData,
                    }),
                        (context, event)=> trackPwError(context, event),
                        (context, event) => console.log("event: ", event),

                    ]

                }],
            }
        },
        errorAccountCreation: {
            always: {
                target: 'error',
            },
        },
        authenticated: { //Possible Todo: within here.... we could setup fetchMachine and have parallel machines running for multiple requests...
            initial: 'idle',
	        onEntry: (context) => console.log("onEntry authenticated array: ",context.seenIds),
            states: {
                idle: {
                    id: 'idle',
                    on: {
                        REFRESH: 'refreshing',
                        UPDATECSRF: 'updateCSRF',
                        DELETE_ACCOUNT: '#clearAndReset',
                        LOGOUT: {target: '#loggingOut'},
                        CLEAR_AND_RESET: '#clearAndReset',
                    },
                    after: {
                        10000: [
                            {
                              target: '#loggingOut' ,
                              cond:   (context) => Math.floor(Date.now()/1000 + 15) > parseInt(context.refreshTokenExp),
                            },
                            {
                            target: 'refreshing',
                            cond: (context) => Math.floor(Date.now()/1000 + 15) > parseInt(context.accessTokenExp), // if access key going to expire in 15 seconds or less, refresh
                        }, {
                            target: 'idle',
                        }],
                    },
                },
                refreshing: { // if we have a csrf error here... how do we want to handle this? // set a conditional
                    invoke: [{
                        id: 'refreshing',
                        src: (context, event) => TokenMachine.withContext(context),
                        //The data replaces the default context defined on the machine; it is not merged. This behavior will change in the next major version.
                        data: {
                            apiClient: (context) => context.apiClient,
                            authData: (context) => context,
                            eventData: (event) => event,
                        },
                        onError: {
                            target: '#error', //any target that starts with a hash (#) uses the id field of the state
                        },
                    }],
                    on: {
                        TOKEN_REFRESHED: {
                            target: 'idle',
                            actions: [assign({
                                accessToken: (context, event) => event.data.accessKey || context.accessToken,
                                accessTokenExp: () => Math.floor(Date.now()/1000 + parseInt(process.env.REACT_APP_ACCESS_TOKEN_INTERVAL)),
                                //Don't update the refresh token. It's the max session length.
                                //refreshTokenExp: () => Math.floor(Date.now()/1000 + parseInt(process.env.REACT_APP_REFRESH_TOKEN_INTERVAL)),
                                retries: 0,
                                refreshCalls: (context, event) => context.refreshCalls + 1, // need to fix if we refresh at a normal rate, then don't increment the call?
                            }), (context) => {
                                updateClientandStorage(context, context.storage)
                            },
                                (context) => fixNans(context)
                            ]
                        },

                        TOKEN_REFRESH_ERROR: {
                            target: '#error',
                            actions: [() => console.log("Parent Message received")]
                        },
                        //when this occurs, we go to updateCSRF and get stuck
                        CSRF_ERROR: {
                          target: 'updateCSRF',
                          actions: assign({
                              callback: 'refreshing',
                              error: true,
                              errorData: 'refreshing', //return to retry
                          })
                        }
                    }
                },
                //getting stuck here
                updateCSRF: {
                    invoke: {
                        id: 'updatingCSRF',
                        src: (context) =>  CsrfMachine.withContext(context),
                        data: {
                            apiClient: (context) => context.apiClient,
                        },
                        onDone: [
                            {
                                cond: 'refreshingCallback',
                                target: 'refreshing', // wonder if we can make this variable...

                            },
                            {
                                actions: () => console.log("fucking hell")
                            },
                            {
                                cond: (context, event) => context.retries >= 3,
                                target: '#error',
                                actions: [ () => console.log('too may retries: csrf')]
                            },
                            {
                                target: 'idle',
                                actions: [assign({
                                    csrf: (context, event) => event.data.csrf,
                                    csrfExp: () => Math.floor(Date.now()/1000 + 3540),
                                    secret: (context, event) => event.data.secret,
                                    retries: 0,
                                    error: false,
                                }), (context) => updateCSRFClient(context),
                                    'broadcastCsrf'
                                ]
                            }],
                        //we end up here "Ondone"
                        onError: {
                            target: {target:'error', external: true},
                        }
                    },
                    on: {
                        CSRF: [{
                            actions: [() => console.log("do we end up in here1234987324?"), assign({
                                csrf: (context, event) => event.csrf,
                                secret: (context, event) => event.secret,
                                retries: (context, event) => context.retries + 1,
                                error: false,
                                errorData: null,
                            }), (context) => {
                                updateCSRFClient(context)
                            },
                                'broadcastCsrf'
                            ],
                            target: 'idle',
                        }],
                        ERROR: [{
                            cond: (context, event) => context.retries <= 3,
                            target: 'updateCSRF',
                            actions: assign({
                                error: true,
                                retries: (context) => context.retries + 1,
                            })
                        }, {
                            target: '#error',
                            actions: [() => console.log("CSRF failure. Logging out")]
                        }

                        ]
                    }
                },
                LOGOUT: {
                    target: '#loggingOut',
                }
            }
        },

        error: {
            id: 'error',
            after: {
                1000: {
                    target: 'loggingOut',
                }
            }
        },
        errorAndReset: {
            id: 'errorAndReset',
            after: {
                1000: {
                    target: 'clearAndReset',
                }
            }
        },
        clearAndReset: {
            id: 'clearAndReset',
            always: {
                target: 'unauthenticated',
                actions: [
                    (context) => console.log("clearAndReset context ",context),
		    assign({
                        isAuthenticated: false,
                        accessToken: null,
                        accessTokenExp: 0,
                        refreshToken: null,
                        refreshTokenExp: 0,
                        emailId: null,
                        emailAddress: "",
                        emailLogin: "",
                        emailRegister: "",
                        sessionStamp: null,
                        //userId: null,
                        ptk: null,
                        error: false,
                        errorData: null,
                        needsConfirm: false,
                        dismissed: false,
                        userEmailId: null,
                        disableProgressBar: false,
                        adCampaignId: '',
                        cohorts: '',
                        price: '',
                        duration: '',
                    }),
                    (context) => {
                        updateClientandStorage(context, context.storage)
                    },
                    (context) => context.storage.logout(),
                    () => localStorage.removeItem('lastActivity'),
                    () => sessionStorage.removeItem('resumedSession'),
                    () => sessionStorage.removeItem('lb'),
                    () => clearUserId(),
                    () => localStorage.clear(),
                ]
            },
        },

        loggingOut: {
            id: 'loggingOut',
            on: {
                LOGOUT: 'loggingOut',
            },
	    exit: (context, event) => context.storage.setDismissed(false),
            invoke: {
                id: 'loggingOut',
                src: (context) => LogoutMachine.withContext(context),
                onDone: {
                    target: 'INITIAL',
                    actions: [
                        (context) => console.log("log out array: ",context.seenIds),
                        'broadcastlogout',
			assign({
                        isAuthenticated: false,
                        accessToken: null,
                        accessTokenExp: 0,
                        refreshToken: null,
                        refreshTokenExp: 0,
                        emailId: null,
                        emailAddress: "",
                        emailLogin: "",
                        emailRegister: "",
                        sessionStamp: null,
                        //userId: null,
                        ptk: null,
                        error: false,
                        errorData: null,
                        needsConfirm: false,
			            dismissed: false,
			            userEmailId: null,
                        disableProgressBar: false,
                        adCampaignId: '',
                        cohorts: '',
                        price: '',
                        duration: '',
                    }),
                        (context) => {
                            updateClientandStorage(context, context.storage)
                        },
                        (context) => context.storage.logout(),
                        () => localStorage.removeItem('lastActivity'),
                        () => sessionStorage.removeItem('resumedSession'),
                        () => sessionStorage.removeItem('lb'),


                    ]

                },
                onError: {
                    target: 'INITIAL', //remove the authStates fields once all this is working locally
                    actions: ['broadcastlogout',
                        assign({
                            csrf: null,
                            csrfExp: null,
                            isAuthenticated: false,
                            accessToken: null,
                            accessTokenExp: 0,
                            refreshToken: null,
                            refreshTokenExp: 0,
                            emailId: null,
                            emailAddress: "",
                            emailLogin: "",
                            emailRegister: "",
                            sessionStamp: null,
                            //userId: null,
                            ptk: null,
                            error: false,
                            errorData: null,
                            needsConfirm: false,
                            dismissed: false,
                            userEmailId: null,
                            disableProgressBar: false,
                            adCampaignId: '',
                            cohorts: '',
                            price: '',
                            duration: '',
                    }),
                        (context) => {
                        updateClientandStorage(context, context.storage)
                    },
                        (context) => context.storage.logout(),
                        () => localStorage.removeItem('lastActivity'),
                        () => sessionStorage.removeItem('resumedSession'),
                        () => sessionStorage.removeItem('lb'),

                    ]
                }
            }
        },
    },
}, {
    guards: {
        csrfError: context => context.csrfError,
        hasAccessToken: context => context.accessToken !== null,
        noCSRF: context => context.csrf === null,

        needsConfirm: (context) => {
            return ((context.installationId) && (context.needsConfirm === true));
        },

        setPassword: (context) => {
            return ((context.installationId) && (context.ptk !== null));
        },
        refreshingCallback: (context, event) => {
            console.log(context)
            console.log(event)
            if (context.callback === "refreshing") {
                console.log("true")
                return true
            } else {
                console.log("callback does not === refreshing")
                return false
            }
        }

    },
    actions: {
        logOutput: (context, event) => {
            console.log(context);
            console.log(event);
        },
        logIt: (context) => {
            console.log("Context: ", context)
        },
        broadcastCsrf: send((context) => {
            // Broadcast the new CSRF token using sysend
            sysend.broadcast('updateCsrf', { csrf: context.csrf, secret: context.secret, windowId: context.windowId}); // synchronize other windows when this updates.
            return { type: 'DUMMY_EVENT' }; // Return a dummy event since send action expects to send an event
        }),
        broadcastlogout: send((context) => {
            // Broadcast logout when one tab logs out -  using sysend
            sysend.broadcast('logout', { windowId: context.windowId}); // synchronize other windows when this updates.
            return { type: 'DUMMY_EVENT' }; // Return a dummy event since send action expects to send an event
        }),
        updateContext: assign((context, event) => {
          return updateContext(context, event);
        })
    },
});

/*
import { Machine, actions } from 'xstate';
const { assign } = actions;

function computeMultipleUpdates(context, event) {
  switch (event.type) {
    case 'ERROR':
      return {
        errorCount: context.errorCount + 1,
        lastErrorTimestamp: Date.now()
      };
    case 'WARNING':
      return {
        warningCount: context.warningCount + 1,
        lastWarningMessage: event.message || 'Unknown warning'
      };
    default:
      return {};
  }
}

const machine = Machine({
  id: 'myMachine',
  initial: 'idle',
  context: {
    errorCount: 0,
    warningCount: 0,
    lastErrorTimestamp: null,
    lastWarningMessage: null
  },
  states: {
    idle: {
      on: {
        ERROR: {
          target: 'error',
          actions: 'dynamicMultipleUpdate'
        },
        WARNING: {
          target: 'warning',
          actions: 'dynamicMultipleUpdate'
        }
      }
    },
    error: {},
    warning: {}
  }
}, {
  actions: {
    dynamicMultipleUpdate: assign((context, event) => {
      return computeMultipleUpdates(context, event);
    })
  }
});
 */

import React, {useEffect, useState} from 'react'
import PaymentHistory from "./PaymentHistory";
import {useAuth} from "../contexts/authContext";
//import usePaddle from "../hooks/usePaddle";
import useStripe from "../hooks/useStripe";
import {useModal} from "../contexts/ModalContext";
import {useFetch} from "../hooks/useFetch";
import {epochToFormattedDate} from "../util/formattedDate";
import {useNavigate} from "react-router-dom";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";
import AppleSubscriptionModal from "../modals/AppleSubscriptionModal";
import useIsPlus from "../hooks/useIsPlus";
import useProgressBar from "../hooks/useProgressBar";
import ProgressBar from "./ProgressBar";
import useClearModals from "../hooks/useClearModals";
import {track} from "../util/analytics";
import AuthStorageService from "../services/authStorage";
import GenericErrorModal from "../modals/GenericErrorModal";
import useDetectNetwork from "../hooks/useDetectNetwork";
import {useSettings} from "../contexts/settingsContext";

const storage = new AuthStorageService();


const SetttingsSubscription= () => {
    const {authState} = useAuth();
    const {toggleModal, setModalType, modalVisible} = useModal();
    const [showPaymentHistory, setShowPaymentHistory] = useState(false);
    const [userEmail, setUserEmail] = useState(authState.context.emailAddress);
    const [userId,setUserId] = useState(authState.context.userId);
    const { state, fetch, isLoading, uiError, ready, reset} = useFetch()
    const [isPaid, setIsPaid] = useState(false);
   // const [subStatus, setSubStatus] = useState(null);
   // const [subExp, setSubExp] = useState(null);
    const [clearModals, setClearModals] = useState(false);

    //const {openCheckout} = usePaddle();
    const {setDoUpgrade, state2, reset2: portalReset, stripePaymentLink, doUpgrade, setRequestPortalSession, requestPortalSession, portalSessionLink, state: stripeState, reset: reset2} = useStripe();
    const [finalRerender, setFinalRerender] = useState(false);

    const [cancelCallback, setCancelCallback] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showAppleModal, setShowAppleModal] = useState(false);


    const navigate = useNavigate()
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [subStatusLoaded, setStatusLoaded] = useState(false);
    const [subStatusLoaded1, setStatusLoaded1] = useState(false);
    const [subStatusLoaded2, setStatusLoaded2] = useState(false);
    //const {isPlus, billingRequestStatus, billingRequestError, iosManaged, expDate, formattedExpDate, autoRenewStatus} = useIsPlus();
    const { progressStarted, apiStarted, isComplete, startProgress, handleCompletion,  setLastApiCallStarted, setProgressOverride, progressOverride} = useProgressBar();
    //const [genericErrorModal, setGenericErrorModal] = useState(false)

    const {genericErrorModal, setGenericErrorModal, subStatus, subExp, isPlus, iosManaged, autoRenewStatus} = useSettings()
    const ChangePlanIcon = "https://img.mirkat.com/web/icons/ChangePlan.svg";


    useEffect(() => {
        setIsLoaded(true)
    }, []);

    //Now need to check if we're not plus, show upgrade button. Upgrade button

    //Call create webPurchase
    //Then call createPortalSession - timeout after 5min.

    //For manage subscription, onclick, create portalUrl and then redirect to that url

    //If not plus, show upgrade button. Upgrade button calls

    useClearModals(clearModals);


    useEffect(() => {
        if(isLoaded) {
            if (!authState.matches('authenticated')) {
                navigate('/login');
                setClearModals(true);
            }
        }
    }, [authState, ready]);

    //this is for autofill user info to stripe
    useEffect(() => {
        setUserEmail(authState.context.emailAddress);
        setUserId(authState.context.userId)

    }, [authState.context.userId, authState.context.emailAddress]);

    useEffect(() => {
/*
        if (billingRequestStatus === "success") {
            setProgressOverride(100)
            if (isPlus) {
                setIsPaid(true)
                setSubStatus("Monthly") //used to be Free/Trialing/Monthly // what if billing issue? -> trigger modal
                setSubExp(formattedExpDate)
            }
            setStatusLoaded1(true)

        }

        if (billingRequestStatus === "failure" && !genericErrorModal) {
            setProgressOverride(100)
            setGenericErrorModal(true)
        }
*/
        if(isPlus) {
            setIsPaid(true)
        }
/*
        if (stripePaymentLink) {

            window.location.href = stripePaymentLink;
        }
*/
        if(portalSessionLink && !iosManaged) {
            console.log("Autorenew status: ", autoRenewStatus)
            if(autoRenewStatus) {
                storage.setEntryType('s') //s for settings
                storage.setBreadCrumb('p') // once the user goes back to home, we need to check the autorenew status, send the tracking event and then clear the breadcrumb.
            } else {
                storage.setEntryType('s') //s for settings
                storage.setBreadCrumb('f')
            }
            window.location.href = portalSessionLink;
            setRequestPortalSession(false)
        }



    }, [state, state2, isPaid, isPlus, finalRerender, stripePaymentLink, portalSessionLink, iosManaged, showAppleModal, autoRenewStatus]);

    useEffect(() => {
        //state2 is portal session. stripeState is state var from useStripe hook
        if(stripeState && stripeState.matches('failure') && doUpgrade && !genericErrorModal) {
            setDoUpgrade(false)
            setProgressOverride(100)
            setGenericErrorModal(true)
        }

       // if(doUpgrade && stripeState && stripeState.matches('failure') && genericErrorModal) {
            //setGenericErrorModal(false)
        //    reset2()
       // }


    }, [stripeState, genericErrorModal, doUpgrade])

    useEffect(() => {
        if (!iosManaged && stripePaymentLink) {
            console.log("STRIPE PAYMENT LINK2: ", stripePaymentLink)
            storage.setEntryType('s') //s for settings
            storage.setBreadCrumb('f')
            window.location.href = stripePaymentLink;
            //setDoUpgrade(false)
        }

        console.log("IOS MANAGED: ", iosManaged)
        console.log("DO UPGRADe: ", doUpgrade)
        console.log("STRIPE PAYMENT LINK: ", stripePaymentLink)
    }, [iosManaged, stripePaymentLink]);



    useEffect(() => {
        //state2 is portal session. stripeState is state var from useStripe hook
        if(state2 && state2.matches('failure') && requestPortalSession && !genericErrorModal) {
            setRequestPortalSession(false)
            setProgressOverride(100)
            setGenericErrorModal(true)
        }

        if(requestPortalSession && state2 && state2.matches('failure') && genericErrorModal && portalReset) {
            setProgressOverride(100)
            //setGenericErrorModal(false)
            portalReset()
        }
    }, [state2, requestPortalSession, portalReset])


const manageSubscription = () => {
    //#270
    track('Button Tapped', {buttonType: 'manageSubscription', screenType: 'settings'})
    if(!iosManaged) {
        startProgress()
        setLastApiCallStarted(true)
        setRequestPortalSession(true);
    } else {
        setShowAppleModal(true);
    }
}

const payment = () => {
    console.log("payment")
    //openCheckout();
    startProgress()
    setLastApiCallStarted(true)
    reset2()
    setDoUpgrade(true);
}


                                                //<form style={{display: 'none'}} id="pre-checkout">
                                                //    <input id="userEmail" type="hidden" value={userEmail}></input>
                                                //    <input id="userId" type="hidden" value={userId}></input>
                                                //</form>
                                                //    <div className="settings-row-text" style={{ color: '#241e3f', fontSize: '18px' }}>Upgrade to Mirkat+</div>
    return (
        <>
            {genericErrorModal && <GenericErrorModal props={true} dim={true} callback={()=>{setGenericErrorModal(false)}} screenType='settings' screenSubType='subscription' />}
            {progressStarted && <ProgressBar progressOverride={progressOverride} apiStarted={apiStarted} onCompleted={handleCompletion} setProgressOverride={setProgressOverride} />}
            {isLoaded ? (
            <>
		    {showAppleModal && <AppleSubscriptionModal setShowAppleModal={setShowAppleModal} props={showAppleModal}/>}
                <div className="settings-subscription">
                    <div className="subscription-top">
                        <div className="subscription-header-container"><h2 className="subscription-header">Subscription</h2></div>

                                <div className="subscription-top-body">
                                    <div className="settings-row-container">
                                        <div className="settings-row">
                                            <div className="settings-row-left-text-container"><div style={{ color: '#000000' }} className="settings-row-left-text">{!isPaid ? "None" : subStatus}</div></div>
                                            <div className="settings-row-right-text-container"><div className="settings-row-right-text">{isPaid && "End " + subExp}</div></div>
                                        </div>
                                    </div>

                                {isPaid ? ( <div className="settings-row-container noSelect"  style={{ cursor: 'pointer' }} onClick={isPaid ? manageSubscription : payment} >
                                    <div className="settings-row">
                                        <div className="settings-row-left-container"><img src={ChangePlanIcon} alt="Change Plan" style={{ width: '24px', height: '24px'}}/></div>
                                        <div className="settings-row-text-container">
                                                <div className="settings-row-text" style={{fontSize: '18px' }}>Manage subscription</div>
                                        </div>
                                        <div className="settings-row-right-container">
                                            <div className="settings-row-arrow">
                                                <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2 13.75L7.5 8.25L2 2.25" stroke="#2F364B" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>) : null }

                            </div>
                    </div>
               </div>
            </>
        ) : (
            !genericErrorModal ? (
                    <div className="settings-subscription">
                        <div className="subscription-top">
                            <div className="subscription-header-container"><h2 className="subscription-header">Subscription</h2></div>
                            <div className="subscription-top-body">
                                <div className="settings-row-container">
                                    <div className="settings-row settings-subscription-loader">
                                        <div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            ) : (
                    <div className="settings-subscription">
                        <div className="subscription-top">
                            <div className="subscription-header-container"><h2 className="subscription-header">Subscription</h2></div>
                            <div className="subscription-top-body">
                                <div className="settings-row-container">
                                    <div className="settings-row settings-subscription-error">
                                        <div>
                                            {genericErrorModal && <GenericErrorModal props={true} dim={true} callback={()=>{setGenericErrorModal(false)}} screenType='settings' screenSubType='subscription' />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            )
        )}
        </>
    )
}


export default SetttingsSubscription;


import * as React from "react";
import {useEffect, useState, useRef} from "react";
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";

import {forwardRef} from "react";
import { ReactComponent as CustomVisibilityIcon} from "../assets/icons/mask.svg";
import { ReactComponent as CustomVisibilityOffIcon} from "../assets/icons/mask-off.svg";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';



const StyledTextField = styled(TextField)({
    "& .MuiInputBase-root": {
        //paddingTop: "6px",
        //paddingBottom: "8px",
        //height: "59px",
        fontSize: "20px",
        //lineHeight: "24.5px",
        letterSpacing: "0em",
        //fontFamily: "Gilroy",
        fontFamily: "Gilroy",
        fontWeight: "500",



    },
    "& .MuiInputBase-input": {
      //padding: "6px 0px 7px",
      //  margin: "8px 0px 0px",
        height: "25px",
        //padding: "6px 0px 10px",
        padding: "0px 0px 9px",
        marginBottom: "0px",
    },
    '&.MuiTextField-root': {
        marginTop: '0 !important',
        marginBottom: '0 !important',
        height: "unset",
    },
    "&.MuiFormControl-root": {
        marginTop: 0,
        marginBottom: 0,
        height: "59px",

    },

    '& .MuiTextField-root': {
        marginTop: '0 !important',
        marginBottom: '0 !important',
    },
    "& .MuiInputBase-root.MuiInput-root:before": {
        content: "none",
        transition: "none",
        transform: "none",


    },
    "& .MuiInput-root": {
        marginTop: "25px",
        //paddingTop: "6px",
        //paddingBottom: "7px",
    },
    "& .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled, .Mui-error)": {
        borderBottom: "none",
    },
    "& .MuiInputBase-root.MuiInput-root:after": {
        borderBottom: "2px solid #ccc",
        borderImageSlice: 1,
        transition: "none",
        transform: "none",
        position: "absolute",
    },
    "& .MuiInputBase-root.MuiInput-root.Mui-focused:after": {
        borderBottom: "2px solid transparent",
        borderImage: "linear-gradient(90deg, #FF553C 2.94%, #FF960B 100%)",
        borderImageSlice: 1,
        transform: "none",
        transition: "none",
        position: "absolute",
        height: "24px"
    },
    //possibly remove margin-top: 18ppx
    "& .MuiInputLabel-root": {
        right: 0,
        textAlign: "center",
        color: "#2F364B",
        border: "none",
        transition: "none",
        width: "100%",
        fontSize: "20px",
       // lineHeight: "24.5px",
        letterSpacing: "0em",
        fontFamily: "Gilroy",
        fontWeight: "600",
        //transform: "translateY(100%)",
        position: "absolute",
        marginTop: "6px",
        height: "25px !important",
       // marginTop: "-3px",

    },

    '& .MuiFormControl-root.MuiTextField-root': {
        display: "block",

        //marginTop: '0 !important',
        //marginBottom: '0 !important',
    },
    "& .MuiFormLabel-root": {
        lineHeight: "25px",
        //top: "2px",
        //paddingTop: "25px"
    },
    "& .MuiFormLabel-root.MuiInputLabel-shrink.Mui-focused": {
        margin: "0 auto",
        position: "absolute",
        right: "0",
        left: "0",
        //top: "9px",
        width: "100%", // Need to give it a width so the positioning will work
        background: "linear-gradient(90deg, #FF553C 2.94%, #FF960B 100%)",
        color: "transparent",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        opacity: 1,
        fontSize: "15px",
        lineHeight: "normal",
        fontWeight: 600,
        transition: "0.2s",
        transform: "translateY(0%)",
        height: "19px",
        marginTop: "unset"
    },
    "& .MuiFormLabel-root.MuiInputLabel-shrink": {
        color: "#D1D0D4",
        fontSize: "15px",
        fontFamily: "Gilroy",
        fontWeight: "600",
        lineHeight: "normal",
        //top: "9px",
        //bottom: "10px",
        transform: "translateY(0%)",
        width: "100%",
        top: "2px",
        height: "19px",
        marginTop: "unset"
        //marginTop: "2px",

    },

});

const Input2 = forwardRef((props, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const [hasText, setHasText] = useState(props?.field?.value > 0 || false);
    const localRef = useRef(null);
    const [maxLength, minLength ] = [props?.maxLength, props?.minLength];
    const [isAutofilled, setIsAutofilled] = useState(props?.isAutofilled || false);
    const [af, setAf] = useState(props?.autoFocus || false);

    const handleTogglePasswordVisibility = () => {
        console.log("showPassword: ", showPassword)
        setShowPassword(!showPassword);
        // Use the forwarded ref if provided, otherwise use the local ref
        const currentRef = ref || localRef;
        currentRef.current.focus();  // Return focus to the input after toggling visibility
    };
    useEffect(() => {
        if(props?.field?.value.length > 0) {
            setHasText(true);
        }

    }, [hasText]);

    const handleInputChange = (e) => {
        const value = e.target.value
        setHasText(value.length > 0);
        if (props?.field?.onChange) {
            props?.field?.onChange(e);
        }
    }

    useEffect(() => {
        console.log("PROPS: ", props)
        console.log("wtf autoFocus: ", props?.autoFocus)
    }, [props]);

    const handleNumericChange = (e) => {
        return e.replace(/[^0-9]/g, '');
    }
    console.log("PROPS: ", props)

    /*
    console.log("IS AUTO FILLED: ", isAutofilled)
    useEffect(() => {
        if (props?.isAutofilled) {
            console.log("IS AUTO FILLED: ", props?.isAutofilled)
            setIsAutofilled(true);
        }
    }, [props?.isAutofilled, isAutofilled]);
*/
    //id, name, label, type, autoComplete, extras, error, helperText, ref}
    if (props?.id === "password" || props?.id === "password2" || props?.id === "oldPassword") {
        //let autoFocus = props?.label === "New password" ? "autoFocus" : "";

        return (
            <StyledTextField
                style={{ backgroundColor: "white"}}
                onChange={showPassword ? props?.field?.onChange : handleInputChange}
                value={props.field.value}
                type={showPassword ? 'text' : 'password'}
                autoComplete={props?.field?.autoComplete || "on"}
                ref={ref || localRef}
                autoFocus={af}
                inputRef={props?.inputRef}
                InputProps={{
                    endAdornment: hasText && (
                        <InputAdornment position="end" style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
                            <IconButton
                                onClick={handleTogglePasswordVisibility}
                                edge="end"
                            >
                                {showPassword ? <CustomVisibilityOffIcon style={{margin: 0, padding: 0 }} /> : <CustomVisibilityIcon style={{margin: 0, padding: 0 }} />}
                            </IconButton>
                        </InputAdornment>),
                    inputProps: {
                        maxLength: maxLength,
                        minLength: minLength,
                        style: { textAlign: "center",},
                        autoFocus: af,
                    },
                }}
                sx={{
                    'input::-ms-reveal, input::-ms-clear': { display: 'none' },
                    '& .MuiFormControl-root.MuiTextField-root': {
                        display: 'block',
                    },
                    '& input:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 1000px white inset',
                    },
                }}
                {...props}

                variant="standard"
                margin="normal"
                fullWidth
            />
        );

    }
    return (
        <StyledTextField
            ref={ref || localRef}
            onChange={props.field.onChange}
            autoComplete={props?.field?.autoComplete || "on"}
            autoFocus={props?.autoFocus}
            value={props?.restrict === "numeric" ? handleNumericChange(props.field.value) : props.field.value}
            InputProps={{
                inputProps: {
                    maxLength: maxLength,
                    minLength: minLength,
                    style: { textAlign: "center",},
                },
            }}
            sx={{
                '::-ms-reveal': { display: 'none' },
                '::-ms-clear': { display: 'none' },

                '& .MuiFormControl-root.MuiTextField-root': {
                    display: 'block',
                },
                '& input:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 1000px white inset',
                    WebKitBoxShadow: '#999',
                },
            }}
            {...props}


            variant="standard"
            margin="normal"
            fullWidth
        />
    );
    });

export default Input2;

/*
//This needs to be able to handle field validation... number of chars, char type etc....
//maybe we just extend/improve the useFormHandler...
const Input = forwardRef(({text, onChange, type, id, name, onClear, defaultValue}, ref) => {
   const [inputValue, setInputValue] = useState('');
    const handleClear = () => {
        setInputValue("");
        if (onClear) {
            onClear();
        }
    };
    useImperativeHandle(ref, () => ({
        clear: () => {
            handleClear();
        }
    }));

    const handleChange = (event) => {
        setInputValue(event.target.value);
        if(onChange) {
            onChange(event); //pass the entire event over to the passed in onChange function
        }
    };

    return (
        <label className="mLabel">
            {!defaultValue &&<input className="mInput" type={type} id={id} name={name} value={inputValue} placeholder=" " onChange={handleChange}/> }
            {defaultValue &&<input className="mInput" type={type} id={id} name={name} value={defaultValue} placeholder=" " onChange={handleChange}/> }
            <span className="mSpan"><span className="mSpanText">{text}</span></span>
        </label>
    )
} );

export default Input;
*/


import {assign, createMachine} from "xstate";
import {createDataFetcher}   from "../util/fetchFactory";


const maxRetries = 1;

const logoutService = (context) => {
    return new Promise((resolve, reject) => {
        const event = {method: 'logout'}
        const dataFetcher = createDataFetcher(context.apiClient)
        dataFetcher(context, event).then((data) => {
            resolve(data)
        }, (error) => {
            reject(error);
        });
    })
}

export const LogoutMachine = createMachine({
        id: 'logoutMachine',
        initial: 'loggingOut',
        context: {
            apiClient: null,
            authData: null,
            eventData: null,
            data: null,
            retries: 0,
        },

        states: {
            loggingOut: {
                invoke: {
                    id: 'loggingOut',
                    src: (context, event) => logoutService(context),
                    onDone: {
                      target: 'done',
                    },
                    onError: {
                        target: 'logoutRetry',
                        actions: assign({
                                retries: (context) => context.retries + 1,
                                error: true,
                        })
                    },
                },
            },

            logoutRetry: {
                after: {
                    RETRY_DELAY: [{

                        target: 'loggingOut',
                        cond: (context) => context.retries < maxRetries,
                    },
                        {
                            target: 'error',
                            actions: [() => console.log('logout error callback')]
                        }
                    ]
                }
            },

            done: {
                type: 'final',
            },
            error: {
                type: 'final'
            }
        },
    },
    {
        guards: {
            canRetry: (context) => context.retries < maxRetries,
        },
        delays: {
            RETRY_DELAY: (context, event) => {
                const exponent = context.retries ** 2;
                return exponent * 200;
            }
        },
    });
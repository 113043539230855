import {createMachine, assign, sendParent} from 'xstate';
import MirkatClient from "../services/mirkatClient";
import AuthStorageService from "../services/authStorage";
import {BaseClientParams} from "../util/clientParams";
import {createDataFetcher} from "../util/fetchFactory";

const maxRetries = 3;


const client = new MirkatClient(BaseClientParams);

const getCSRF = (context, event) => {
    console.log("WTF: ", context, event)
    return new Promise((resolve, reject) => {
        const event = {method: 'auth'}
        const dataFetcher = createDataFetcher(client);
        dataFetcher(context, event).then((data) => {
            resolve(data)
        }, (error) => {
            reject(error);
        });
    })
}

export const CsrfMachine = createMachine({
    id: 'csrf',
    initial: 'getCSRF',
    context: {
        apiClient: null,
        csrf: null,
        secret: null,
        error: false,
        retries: 0,
    },

    states: {
        getCSRF: {
            invoke: {
                id: 'getCSRF',
                src: (context, event) => getCSRF(context),
                onDone: {
                    target: 'done',
                    actions: [
                        assign({
                            //data: (context, event) => event.data,
                            csrf: (context, event) => event.data.__farsce,
                            secret: (context, event) => event.data._gi_ase,
                            error: false,
                            retries: 0,
                        }),
                            sendParent((context) => ({type: 'CSRF', csrf: context.csrf, secret: context.secret}))
                    ],
                },
                onError: {
                    target: 'error',
                    actions: assign({
                        error: true,
                        errorData: (context, event) => event?.data || event?.error,
                        retries: (context) => context.retries + 1,
                    })
                }
            },
        },

        done: {
            type: 'final',
        },
        retry: {
            on: {
                '': [
                    {
                        id: 'retry',
                        target: 'getCSRF',
                        cond: 'shouldRetry'
                    },
                    {target: 'error'}]
            }
        },

        //type final cannot send to parent so we do it here
        sendError: {
            actions: [
                sendParent((context) => ({type: 'ERROR', errorData: context.errorData}))
            ],
            target: 'error'
        },
        error: {
            type: 'final',
        }
    },
}, {
    guards: {
        shouldRetry: (context, event) => context.retries < maxRetries
    },
    services: {
        CSRF: getCSRF, // can override this service with a different client function
    }
});

import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {
    aliasAndIdentifyUser,
    anonUserID,
    clearUserAnonymousId,
    identify,
    initializeAnonymousUser,
    setAnonUserID, updateUserTraits
} from "../util/analytics";

import { useMachine, useSelector } from '@xstate/react';
import { authMachine, saveStateToLocalStorage } from '../machines/AuthMachine';
import {track, clearUserId, clearUserTraits} from "../util/analytics";

// This is the context that will be used to provide authentication information to the rest of the application.
export const AuthContext = createContext({
    isAuthenticated: null,
    handleAuthData: () => {},
    logout: () => {},
});

// This is a custom hook that will be used to access the authentication information.
export const useAuth = () => {
    return useContext(AuthContext);
}

//for state machine selector
const authenticated = (state) => {
    return state.matches('authenticated');
};




// This is the provider that will be used to wrap the application.
// Get the persisted state config object from somewhere, e.g. localStorage

export const AuthProvider = ({ children }) => {
    const [authState, send, authService] = useMachine(authMachine);
    const [isReady, setIsReady] = useState(false);
    const [stateSteady, setStateSteady] = useState(false);

    //for analytics
    const [firedLogoutEvent, setFiredLogoutEvent] = useState(false);

    useEffect(() => {
        setIsReady(true)
        initializeAnonymousUser()

    }, []);

    useEffect(() => {
        let timeout;
        if (authState) {
            //saveStateToLocalStorage(authState)

            //if coming from confirming -> AUTHENTICATED is the event
            if(authState.matches('authenticated') && (authState.event.type === "DONE" || authState.event.type === "AUTHENTICATED" || authState.event.type === "POLLING_AUTHCONFIRM_SUCCESS" || authState.event.type === "V1PASSWORDSET") && authState.context.userId) {
                //identify(authState.context.userId, {})
                    //already identify called
                aliasAndIdentifyUser(authState.context.userId);
                updateUserTraits(authState.context.userId, {
                    email: authState.context.emailAddress,
                    adCampaignId: authState.context.adCampaignId,
                    createdAt: new Date().toISOString(),
                    cohort: authState.context.cohorts
                } )
                //aliasAndIdentifyUser(authState.context.userId, {
                //    email: authState.context.emailAddress,
                //    adCampaignId: authState.context.adCampaignId,
                //    createdAt: new Date().toISOString(),
                //    cohort: authState.context.cohorts
                //})

                //#47
                if (authState.event.type != "V1PASSWORDSET") {
                    track('User Signed In',
                     {
                        eventType: 'userSignedIn',
                    })
                }
            }

            if(authState.matches('loggingOut') || authState.matches('clearAndReset') || authState.event.type === "LOGOUT") {
                console.log("Does this stuff fire off?")
                //identify("", {})
                const tempUid = anonUserID()
                clearUserAnonymousId()
                setAnonUserID(tempUid)
                clearUserId()
                clearUserTraits()
                // we will keep the anonymous id
            }

           // if(authState.matches('authenticated') ){
            //    setFiredLogoutEvent(false)
            //}

            if(authState.matches('unauthenticated') || authState.matches('authenticated') || authState.matches('confirm') || authState.matches('needPw')) {
                setTimeout(() => {
                   timeout = setStateSteady(true)
                }, 100)
            }
            return () => {
                if (timeout) {
                    //clear timeout if rerender
                    clearTimeout(timeout);
                }
            };
        }
    }, [authState]);

    /*
    useEffect(() => {
        //this lets us listen to the raw events and can react to them
        const subscription = authService.subscribe((currentState) => {
            if (currentState.matches('loggingOut') && !firedLogoutEvent) { // OR loggingOut state cause session expired....
                //#239
                track('User Signed Out', {eventType: 'logout'})
                setFiredLogoutEvent(true)
            }
        });

        // Cleanup subscription on unmount
        return () => subscription.unsubscribe();

    }, [authService, setFiredLogoutEvent, firedLogoutEvent]);
*/

    useEffect(() => {
        console.log("AuthState VALUE: ", authState.value)
    }, [authState.context, authState.value]);

    useEffect(() => {
        console.log("AuthState EVENT: ", authState.event)
    }, [authState.event]);

    //using selector to prevent rerenders
    //see "Improving Performance" here for more info:
    //https://xstate.js.org/docs/recipes/react.html#global-state-react-context
    return (
        <AuthContext.Provider value={{
            isReady,
            stateSteady,
            isAuthenticated: useSelector(authService, authenticated),
            send,
            authState,
            authService,
        }}>
            {children}
        </AuthContext.Provider>
    );
}

import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import MainNavigation from "../components/MainNavigation";
import MainFooter from "../components/MainFooter";
import "../styles/scss/Root.scss";

const RootLayout = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        setIsLoading(false);
        return () => {
            window.removeEventListener('resize', handleResize);
        };


    }, []);

    return (
        isLoading ? (<></>) : (

        <>
            {windowWidth >= 840 && <MainNavigation />}
            <Outlet />
            {windowWidth >= 840 && <MainFooter />}
        </>
        )
    );
}

export default RootLayout;

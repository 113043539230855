import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import EmailForm from "./EmailForm";
import ConfirmForm from "./ConfirmForm";
import PasswordForm from "./PasswordForm";
import {useSelector} from "@xstate/react";
import {useAuth} from "../contexts/authContext";
import {createMachine, assign} from "xstate";
import {useMachine } from "@xstate/react";
import {track} from "../util/analytics";
import Modal from "@mui/material/Modal";

const confirm = (state) => {
    try {
        return state.matches('confirm');
    } catch (e) {
        return false;
    }

};

const RegFlowState = createMachine({
    id: 'RegisterFlow',
    initial: 'register',
    context: {
        flow: '', //register or login
        email: '',
    },
    states: {
        register: {
            on: {
                CONFIRM: {
                    target: 'confirm',
                    actions: [(context, event) => console.log(event), assign({
                        email: (context, event) => event.email
                    })]
                } //show confirm form
            }
        },
        confirm: {
            on: {
                PASSWORD: {
                    target: 'password',
                },
                BACK: {
                    target: 'register',
                }
            }

        },
        password: {
            on: {
                RESET: {
                    target: 'register',
                },
                BACK: {
                    target: 'confirm',
                }
            }

        },
        error: {

        }
    },
});



const RegisterFlow = () => {
    // This is a multistep flow that will be used to register a new user.
    // Steps:
    // 1. Enter email address
    // 2. Confirm email address with code
    // 3. Enter password

    const navigate = useNavigate();
    //maybe change this name to be set step? need to change loginflow piece to mach
    const {authService, authState}  = useAuth();
    const [state, send]= useMachine(RegFlowState);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
        return () => {
            setIsLoaded(false);
        }
    }, []);


    useEffect(() => {
        if(authState.matches('authenticated') && !authState.context.progressWait) {
            navigate("/welcome");
        }


    }, [ navigate, authState, authState.context.progressWait])


    //{ state.matches('confirm') && <Modal open={true}><ConfirmForm prevForm="register" regSend={send} regState={state} /></Modal> }
    //{ state.matches('confirm') && <ConfirmForm prevForm="register" regSend={send} regState={state} />}
    return (
        isLoaded ? (
            <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                { state.matches('register') && <EmailForm regSend={send} regState={state}/> }
                { state.matches('confirm') && <ConfirmForm prevForm="register" regSend={send} regState={state} />}
                { state.matches('password') && <PasswordForm prevForm="confirm" regSend={send} regState={state}/> }
            </div>
        ) : (<></>)
    )
}

export default RegisterFlow;
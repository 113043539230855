import React from "react";
import styles from "../styles/scss/EmailLinkModal.module.scss";

import {useModal} from "../contexts/ModalContext";

const CodeResentModal = ({props, callback}) => {
    // const [showModal, setShowModal] = useState(true);
    const {modalVisible, toggleModal, setModalType} = useModal();

    const onClick = () => {
        if (callback) {
            callback(false);
        } else if(!props) {
            toggleModal();
            setModalType('')
        } else {
           // propOverride
            //props(false)
            toggleModal();
            setModalType('');
        }
    }

    return (
        <div>
            { (modalVisible || props) && (
                <div className={styles["modal-screen-container"]}>
                    <div className={styles["modal-container-informative"]}>
                        <div className={styles["modal-content-container"]}>
                            <div className={styles["modal-title"]}>Code sent</div>
                            <div className={styles["modal-description"]}>A new confirmation code has been sent to {props.email}.</div>
                        </div>
                        <div className={styles["action-container"]}>
                            <div className={styles["modal-button"]} onClick={onClick}>
                                <div className={styles["modal-button-label"]}>OK</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CodeResentModal;

import { format, isToday, isYesterday, differenceInCalendarDays, differenceInYears, differenceInMonths } from 'date-fns';

export const numberToWord = (number) => {
    const numWords = {
        '1': 'One',
        '2': 'Two',
        '3': 'Three',
        '4': 'Four',
        '5': 'Five',
        '6': 'Six',
        '7': 'Seven',
        '8': 'Eight',
        '9': 'Nine',
        '10': 'Ten',
        '11': 'Eleven',
        '12': 'Twelve',
        // Add more mappings if needed
    };
    
    return numWords[number.toString()] || number.toString();
};

export const replaceNumberWithWord = (str) => {
    // Regular expression to find an integer in the string
    const regex = /\b(\d+)\b/;

    // Find the integer in the string
    const match = str.match(regex);

    if (match) {
        // Get the integer as a number
        const number = parseInt(match[1], 10);

        // Replace the integer with its word equivalent
        return str.replace(regex, numberToWord(number));
    }

    return str; // Return the original string if no integer is found
};

//export const sortByDate = (data, dateKey) => {
//
//    const buckets = data.reduce((buckets, item) => {
//        const keyDate = new Date(item[dateKey]);
//        keyDate.setHours(0,0,0,0);
//        const today = new Date();
//        today.setHours(0,0,0,0);
//        const yesterday = new Date();
//        yesterday.setDate(today.getDate() - 1);
//        
//        let bucket = ''
//        
//        if (keyDate.toDateString() === today.toDateString()) {
//            bucket = 'Today';
//        } else if (keyDate.toDateString() === yesterday.toDateString()) {
//            bucket = 'Yesterday';
//        }
//        
//        if (bucket === '') {
//            const yearsDifference = today.getFullYear() - keyDate.getFullYear();
//            let monthsDifference = yearsDifference * 12 + today.getMonth() - keyDate.getMonth();
//            
//            if (keyDate.getDate() > today.getDate()) {
//                monthsDifference -= 1;
//            }
//            
//            const daysSince = Math.floor((today - keyDate) / (1000 * 60 * 60 * 24));
//            
//            if (daysSince === -1) {
//                bucket = `Today`;
//            }  else if (daysSince < 7 ) {
//                bucket = `${daysSince} days ago`;
//            } else if (daysSince >= 7 && daysSince < 14) {
//                bucket = `A week ago`;
//            } else if (daysSince >= 14 && daysSince < 21) {
//                bucket = `2 weeks ago` ;
//            } else if (daysSince >= 21 && daysSince < 28) {
//                bucket = `3 weeks ago`;
//            } else if (daysSince >= 28 && monthsDifference < 2) {
//                bucket = `A month ago`;
//            } else if (monthsDifference > 1 && monthsDifference < 12) {
//                bucket = `${monthsDifference} months ago`
//            } else if (yearsDifference === 1) {
//                bucket = `Over a year ago`;
//            }  else if (yearsDifference > 1) {
//                bucket = `${yearsDifference} years ago`
//            } else {
//                bucket = "Unknown"
//            }
//        }
//        
//        //const namedBucket = replaceNumberWithWord(bucket);
//        const namedBucket = bucket;
//        
//        if (!buckets[namedBucket]) {
//            buckets[namedBucket] = [];
//        }
//        buckets[namedBucket].push(item);
//        
//        
//        return buckets;
//    }, {})
export const sortByDate = (data, dateKey) => {
    const buckets = data.reduce((buckets, item) => {
        const keyDate = new Date(item[dateKey]);
        //console.log("keydate: ", keyDate);
        const today = new Date();
        //console.log("today: ", today);

        let bucket = '';

        if (isToday(keyDate)) {
            bucket = 'Today';
        } else if (isYesterday(keyDate)) {
            bucket = 'Yesterday';
        }
        
        if (bucket === '') {
            const yearsDifference = differenceInYears(today, keyDate);
            let monthsDifference = differenceInMonths(today, keyDate);
            const daysSince = differenceInCalendarDays(today, keyDate);
            
            if (daysSince < 0 ) {
                bucket = `Today`;
            } else if (daysSince < 7 ) {
                bucket = `${daysSince} days ago`;
            } else if (daysSince >= 7 && daysSince < 14) {
                bucket = `A week ago`;
            } else if (daysSince >= 14 && daysSince < 21) {
                bucket = `2 weeks ago` ;
            } else if (daysSince >= 21 && daysSince < 28) {
                bucket = `3 weeks ago`;
            } else if (daysSince >= 28 && monthsDifference < 1) {
                bucket = `4 weeks ago`;
            } else if (monthsDifference === 1) {
                bucket = `A month ago`;
            } else if (monthsDifference > 1 && monthsDifference < 12) {
                bucket = `${monthsDifference} months ago`
            } else if (yearsDifference === 1) {
                bucket = `Over a year ago`;
            }  else if (yearsDifference > 1) {
                bucket = `${yearsDifference} years ago`
            } else {
                bucket = "Unknown"
            }
        }
        
        //const namedBucket = replaceNumberWithWord(bucket);
        const namedBucket = bucket;
        
        if (!buckets[namedBucket]) {
            buckets[namedBucket] = [];
        }
        buckets[namedBucket].push(item);
        
        
        return buckets;
    }, {})

    const timeMapping = {
        'Today': 0,
        'Yesterday': 1,
        '2 days ago': 2,
        '3 days ago': 3,
        '4 days ago': 4,
        '5 days ago': 5,
        '6 days ago': 6,
        'A week ago': 7,
        '2 weeks ago': 8,
        '3 weeks ago': 9,
        '4 weeks ago': 10,
        'A month ago': 11,
        '2 months ago': 12,
        '3 months ago': 13,
        '4 months ago': 14,
        '5 months ago': 15,
        '6 months ago': 16,
        '7 months ago': 17,
        '8 months ago': 18,
        '9 months ago': 19,
        '10 months ago': 20,
        '11 months ago': 21,
        '12 months ago': 22,
        'Over a year ago': 23,
        '2 years ago': 24,
        '3 years ago': 25,
        '4 years ago': 26,
        '5 years ago': 27,
        '6 years ago': 28,
        '7 years ago': 29,
        '8 years ago': 30,
        '9 years ago': 31,
        '10 years ago': 32,
        '11 years ago': 33,
        '12 years ago': 34
    };

    return [buckets, timeMapping];

};

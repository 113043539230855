import React, {useState} from "react";
import styles from "../styles/scss/EmailLinkModal.module.scss";
import {useModal} from "../contexts/ModalContext";
import Modal from "@mui/material/Modal";
const EmailLinkModal = () => {
   // const [showModal, setShowModal] = useState(true);
    const {modalVisible, toggleModal} = useModal();
    const [open, setOpen] = useState(true);
    const onClick = () => {
        setOpen(false);
    }

    return (
        <Modal open={open}>
        <div>
            <div className={styles["modal-screen-container"]}>
                <div className={styles["modal-container-informative"]}>
                    <div className={styles["modal-content-container"]}>
                        <div className={styles["modal-title"]}>Email link sent</div>
                        <div className={styles["modal-description"]}>Click on the password reset link we sent to your email.</div>
                    </div>
                    <div className={styles["action-container"]}>
                        <div className={styles["modal-button"]} onClick={onClick}>
                            <div className={styles["modal-button-label"]}>OK</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </Modal>
    );
}

export default EmailLinkModal;

import React from "react";
import styles from "../styles/scss/Button.module.scss"


const Button = ({buttonText, onClick, isDisabled}) => {
    let button = <button className={styles["button"]} onClick={onClick} type="submit" style={{background: "#1B122C"}}><span style={{color: "#FFF"}}>{buttonText}</span></button>
    if (isDisabled) {
        button = <button className={styles["button-disabled"]} onClick={onClick} disabled type="submit"><span style={{flex: "1 0 0", color: "rgba(255, 255, 255, 0.3)"}}>{buttonText}</span></button>
    }

    return (
        <div className={styles["button-div"]}>
        {button}
        </div>
    );
}

export default Button;

import React, { useEffect, useState } from 'react';
import styles from "../styles/scss/ProgressBar.module.scss";

const ProgressBar = ({ apiStarted, onCompleted, progressOverride, lastApiCallStarted, setProgressOverride, noDelay }) => {
    const [progress, setProgress] = useState(0);
    const progressBarClasses = `${styles.progressBar} ${progress >= 100 ? styles.progressComplete : ''}`;


    // Initial progress to 10%
    useEffect(() => {
        if(noDelay) {
            setProgress(10)
        } else {
            setTimeout(() => setProgress(10), 500);
        }
    }, []);

    // Update progress to 20% when apiStarted changes to true
    useEffect(() => {
        if (apiStarted) {
            if(progress === 10) {
                if (lastApiCallStarted) {
                    console.log("Last api call started")
                    console.log("Progress: ", progress)
                    setProgress(20)

                } else {
                    console.log("Api started, waiting for timeout")
                    setTimeout(() => setProgress(20), 250); //max wait 250ms
                }
            }
        }
    }, [apiStarted, lastApiCallStarted, progress]);

    // Continue progress after reaching 20%
    useEffect(() => {
        if (!progressOverride && progress === 20) {
            if(noDelay) {
                setProgress(30)
            } else {
                setTimeout(() => setProgress(30), 250); // Adjust the time as needed
            }

            // Incrementing by 10% every 150ms after reaching 30%
            for (let i = 40; i <= 100; i += 10) {
                if (!progressOverride) {
                setTimeout(() => {
                    if (i < 100) {
                        setProgress(i);
                    } else {
                        setProgress(i);
                        setTimeout(() => {
                            setProgress(0); // Reset progress
                            onCompleted(); // Notify completion
                        }, 150);
                    }
                }, 500 + (i - 30) / 10 * 150);
                } else {
                    break;
                }
            }
        }
        if(progressOverride && progress >= 20) {
            setProgress(progressOverride)
            setTimeout(() => {
                setProgress(0); // Reset progress
                onCompleted(); // Notify completion
                setProgressOverride(null)
            }, 200);
        }

    }, [progress, onCompleted, progressOverride]);

    /*
    useEffect(() => {
        if (progressOverride) {
            setProgress(progressOverride);
        }
    }, [progressOverride]);
*/
    return (
        <div>

            <div className={progressBarClasses} style={{
                width: `${progress}%`, // Width based on progress
                display: progress === 0 ? 'none' : 'block' // Hide when progress is 0%
            }} />
        </div>
    );
};

export default ProgressBar;


//zIndex: 100,
//                background: `linear-gradient(to right, #FF553C ${progress}%, #FF960B 100%)`


/*
                position: 'fixed', // Fixed at the top
                top: 0,
                left: 0,
                width: `${progress}%`, // Width based on progress
                height: '1px',
                zIndex: 9999,
                background: 'linear-gradient(90deg, #FF3F29 0%, #FF8E00 100%)',
 */
import styles from "../styles/scss/DeletingAccountSpinnerModal.module.scss";
import React from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Spinner from "../components/Spinner";



const DeletingAccountSpinnerModal = ({ props }) => {
    const [open, setOpen] = React.useState(props || false);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition

            >
                <Fade in={open} timeout={500}>
                    <div className={styles["modal-screen-container"]}>
                        <div className={styles["modal-container"]}>
                            <div className={styles["modal"]}>
                                <div className={styles["modal-content"]}>
                                    <Spinner />
                                    <div className={styles["modal-title"]}>Deleting account...</div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    );
}


export default DeletingAccountSpinnerModal;
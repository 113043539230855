import React, {useEffect, useState, useRef} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { useAuth } from "../contexts/authContext";
import styles from "../styles/scss/Register.module.scss";
import useFormHandler from "../hooks/useFormHandler";
import Button from "./Button";
import "../styles/scss/OnboardingCard.scss";
import Fade from '@mui/material/Fade';

import {useFetch} from "../hooks/useFetch";
import {retryableError} from "../util/retryableError";
import {Controller, useForm} from "react-hook-form";
import Input2 from "./Input2";
import {track, screen, identify} from "../util/analytics";
import useQueryStringParams from "../hooks/useQueryStringParams";

import useProgressBar from "../hooks/useProgressBar";
import ProgressBar from "./ProgressBar";
import useDetectNetwork from "../hooks/useDetectNetwork";
import ReactPixel from 'react-facebook-pixel';


//If we'ere not in the right state, we'll get stuck with Loading button. need to check and redirect fix
const EmailForm = ({ regSend, regState }) => {
    const { register, handleSubmit, formState,
        setValue,
        control, reset, getValues} = useForm({mode: "onChange", reValidateMode: "all"});

    const [resetKey, setResetKey] = useState(Date.now());
    const {authState, authService} = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [email, setEmail] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const location = useLocation();
    const [runOnce, setRunOnce] = useState(false);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const dynamic = {height: windowWidth > 839 ? "unset" : windowHeight}
    const [campaignEmail, setCampaignEmail] = useState(null);
    //qCampain for tracking
    //q denotes it got it email from query string
    const {qEmail, qCampaign} = useQueryStringParams();
    const [campaignIdentified, setCampaignIdentified] = useState(false);
    const inputRef = useRef(null);
    const leftbutton = "https://img.mirkat.com/web/icons/leftbutton.svg";

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    });



    const { progressStarted, apiStarted, isComplete, startProgress, handleCompletion,  setLastApiCallStarted, setProgressOverride, progressOverride} = useProgressBar();
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        ReactPixel.track('ViewContent', {page: 'Sign Up'})
        setIsLoaded(true)
        screen("Sign-up: Email", {screenType: "signUp", screenSubType: "email"})
        return () => {
            authService.send('FINISH_PROGRESS')
            setProgressOverride(null)
            setIsLoaded(false)
        }
    }, []);

    const isOnline = useDetectNetwork();
    useEffect(() => {
        if(!isOnline){
            setError("No network connection")
        }
        if(isOnline && error === "No network connection") {
            setError("")
        }
    }, [isOnline])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    // Set up an event listener for window resizing
    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        if(regState.context.email && !runOnce) {
            setValue("email", regState.context.email, { shouldValidate: true })
        }

        if (authState.matches('confirm') && !submitted) {
            authService.send({type: 'unauthenticated'}) //?
        }

        setSubmitted(false); //ensure false
        console.log("EMAIL")
        console.log(email)
        console.log(regState.context.email)
    }, []);

    useEffect(() => {
        if (qEmail && !regState.context.email) {
            setValue("email", qEmail, {shouldValidate: true})
        }
        if (qCampaign && !regState.context.email && !campaignIdentified) {
            authService.send({type: 'SET_AD_CAMPAIGN', campaignId: qCampaign}) //not sure if we need to track all the way through of if the identify call is enough
            identify(null, {adCampaignId: qCampaign})
            setCampaignIdentified(true)
        }
    }, [qEmail, regState.context.email, qCampaign])

    useEffect(() => {
        if (authState.matches('confirm') && regState.matches('register') && !submitted) {
            authService.send('BACK')
        } else if (authState.matches('register_v1_user')) {
            authService.send('BACK')
        }
    }, [authState])

    useEffect(() => {
        //Error 39 = user already registered - should we redirect to login?
        if(authState && email) {
            console.log("3")
            if((authState.matches('unauthenticated') || authState.matches('needPw')) && !submitted && formState.isValid) {
                //setIsLoading(current => !current);
                console.log("2")
                authService.send({type: 'REGISTER', email: email})
                setSubmitted(true);
                setLastApiCallStarted(true)
            }


            if(authState.context?.errorData && submitted) {
                //#60
                track("User Sign Up", {
                    eventType: "userSignUp",
                    eventSubType: "error",
                    stepType: "email",
                    errorCode: authState.context.errorData?.errorCode||null,
                })
                if(authState.context.errorData?.errorCode === 39) {
                    console.log("State to email: " + email)
                    authService.send('SET_LOGIN_EMAIL', {email: email})
                    navigate('/login', {state: {email: email}});
                }
                let {errMsg} = retryableError({errorBody: authState.context.errorData})
                setError(errMsg);
                setResetKey(Date.now())
                reset({
                    email: '',
                    password: ''
                })
                setSubmitted(false);
                setEmail(null)
                setProgressOverride(100)

            }
            //This doesn't appear to be hit.
            if(authState.matches('confirm') && !authState.context.errorData && !submitted) {
                track("User Sign Up", {
                    eventType: "userSignUp",
                    eventSubType: "emailAccepted",
                }) //Email was accepted
                console.log("1")
                authService.send({type: 'REGISTER', email: email})
                setLastApiCallStarted(true)
                setSubmitted(true);
                regSend('CONFIRM', {email: email})
                //setEmail(null);
            }
        }

        console.log("submitted: ", submitted)
        if (authState.matches('confirm') && regState.matches('register') && submitted && email) {
            setProgressOverride(100)
            track("User Sign Up", {
                eventType: "userSignUp",
                eventSubType: "emailAccepted",
            }) //Email was accepted
            console.log("5")
            regSend('CONFIRM', {email: email})
        }

        console.log(email)
        console.log("formstate: ", formState)
        console.log("State to email: " + email)
        console.log("authState: ", authState)
        console.log("email: ", email)
        console.log("Regstate: ", regState)
    }, [authState, email, submitted, formState, regState]);

    useEffect(() => {
        if(location.state?.email) {
            setValue("email", location.state.email)
        }
        console.log("which loop are we in?")
    }, [location.state?.email, setValue])
    const onSubmit = (data) => {
        //#7
        track("Button Tapped", {
            buttonType: "next",
            screenType: "signUp",
            screenSubType: "signUp",
        })
        setEmail(data.email);
        startProgress()
    }

    return (
        isLoaded ? (
            <>{progressStarted && <ProgressBar progressOverride={progressOverride} apiStarted={apiStarted} onCompleted={handleCompletion} setProgressOverride={setProgressOverride} />}
            <Fade in={true} timeout={500}>
            <div style={{ display: "flex", width: "100%", justifyContent: "center" }} className="col-12">
            <div className="onboardingcard" style={dynamic}>
               <form style={{ display: "flex", width: "100%", justifyContent: "center" }} noValidate method="post" onSubmit={handleSubmit(onSubmit)}>
                   <div className={styles["email-container"]}>
                   <div className={styles["email-top-container"]}>
                <div className={styles["login-container-navigation"]}></div>
                <Link to="/"><img src={leftbutton} className={styles["login-exit"]} style={{cursor: "pointer"}} /></Link>
                       <div className={styles["email-inner-container"]}>
                           <div className={styles["email-form-header-container"]}>
                               <div className={styles["email-form-header-text"]}>
                                   <div className={styles["email-form-header"]}>Sign up with your most commonly used email address
                                   </div>
                               </div>
                           </div>
                           <div className={styles["email-input-fields"]}>
                               <Controller
                                   key={`email-${resetKey}`}  // Apply the key here
                                   control={control}
                                   name="email"
                                   defaultValue=""
                                   rules={{ required: true, maxLength: 100, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i }}
                                   render={({ field, fieldState }) => <Input2 field={field} maxLength={100} label="Email address" type="email" id="email" autoComplete="email" inputRef={inputRef} />}
                               />
                              { error && <div className="error" id="error-message">{error}</div> }
                              { !error && <div className="error"></div>}
                           </div>
                           <Button isDisabled={!formState.isValid || progressStarted} buttonText="Next" />
                       </div>
                   </div>
                        <div className={styles["bottom"]}>
                            <div className={styles["bottom-inner-container"]}>
                                <div className={styles["bottom-text"]}>By continuing, you agree to the Mirkat <a className={styles["a"]} href="https://mirkat.com/terms/" target="_blank" rel="noopener noreferrer">Terms of Service</a> and <a className={styles["a"]} href="https://www.mirkat.com/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</div>
                                <Link to="/" className={styles["bottom-text-mobile"]}>Already have an account?</Link>
                            </div>
                        </div>
                   </div>
               </form>
           </div>
       </div>
    </Fade>
                </>): (<></>)
    )
}

export default EmailForm;

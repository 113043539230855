import StorageService from "./storage";

const CSRF_STORAGE_KEY = "csrf";
const CSRF_EXP = "csrf_exp";
const SESSION_SECRET_STORAGE_KEY = "session_secret";
const ACCESS_TOKEN_STORAGE_KEY = "access_token";
const ACCESS_TOKEN_EXP = "acexp";
const REFRESH_TOKEN_EXP = "rexp";
const REFRESH_TOKEN_STORAGE_KEY = "refresh_token";
const USER_ID_STORAGE_KEY = "user_id";
const EMAIL_ID_STORAGE_KEY = "email_id";
const INSTALLATION_ID_STORAGE_KEY = "installation_id";
const PTK_STORAGE_KEY = "password_token";
const SECONDARY_EMAIL_KEY = "secondary_email";
const CARD_DATA_KEY = "card_data";
const CARD_DETAILS_KEY = "card_details";
const CARD_ID_KEY = "card_id";
const NEEDS_CONFIRM = "needs_confirm";
const PRIMARY_EMAIL_KEY = "email";
const SESSION_STAMP = "session_stamp";
const DISMISSED = "dismissed";
const SUBSCRIPTION_BREADCRUMB = "sb"; // to track once the user leaves the app to stripe and back - check if upgrade or cancelled - previous state before left app
const ENTRY_TYPE = 'et'; // to track if user entered via upgrade, settings, card, footer, signup
const HAS_PREVIOUSLY_UPGRADED = 'hu'; //has user previously upgraded?
const HAS_PREVIOUSLY_CANCELLED = 'hc'
const ONBOARDING_UPGRADE_BREADCRUMB = 'oub'; // to track once the user leaves the app to stripe and back. If back -> show loading screen then home.
const LOADING_BREADCRUMB = 'lb'; // to track once the user sees Loading and/or sees HomePage - so they don't see loading ever after HomePage
const FREE_REPLAY = "free_replay"; //prevent scanning animation from replaying until ready
const PAID_REPLAY = "paid_replay"; //prevent paid scanning animation from replaying until ready
const IS_VERIFY_URI = 'VerifyUri'
const COHORTS = 'cohorts';

// The session storage service contains all the logic for storing and retrieving session data.
class AuthStorageService extends StorageService {
    // CSRF
    setCSRF(csrf) {
        this.setLocalData(CSRF_STORAGE_KEY, csrf);
    }

    setCSRFExp(time) {
        this.setLocalData(CSRF_EXP, time);
    }

    getCSRFExp(time) {
        return this.getLocalData(CSRF_EXP);
    }


    getCSRF() {
        return this.getLocalData(CSRF_STORAGE_KEY);
    }

    removeCSRF() {
        this.removeLocalData(CSRF_STORAGE_KEY);
    }

    // Session secret
    setSessionSecret(sessionSecret) {
        this.setLocalData(SESSION_SECRET_STORAGE_KEY, sessionSecret);
    }

    getSessionSecret() {
        return this.getLocalData(SESSION_SECRET_STORAGE_KEY);
    }

    removeSessionSecret() {
        this.removeLocalData(SESSION_SECRET_STORAGE_KEY);
    }

    // Access token
    setAccessToken(token) {
        this.setLocalData(ACCESS_TOKEN_STORAGE_KEY, token);
    }

    getAccessToken() {
        return this.getLocalData(ACCESS_TOKEN_STORAGE_KEY);
    }

    removeAccessToken() {
        this.removeLocalData(ACCESS_TOKEN_STORAGE_KEY);
    }

    setAccessTokenExp(time) {
        this.setLocalData(ACCESS_TOKEN_EXP, time);
    }

    getAccessTokenExp() {
        return parseInt(this.getLocalData(ACCESS_TOKEN_EXP));
    }

    removeAccessTokenExp() {
        this.removeLocalData(ACCESS_TOKEN_EXP);
    }

    // Refresh token
    setRefreshToken(token) {
        this.setLocalData(REFRESH_TOKEN_STORAGE_KEY, token);
    }

    getRefreshToken() {
        return this.getLocalData(REFRESH_TOKEN_STORAGE_KEY);
    }

    removeRefreshToken() {
        this.removeLocalData(REFRESH_TOKEN_STORAGE_KEY);
    }

    setRefreshTokenExp(time) {
        this.setLocalData(REFRESH_TOKEN_EXP, time);
    }

    getRefreshTokenExp() {
        return parseInt(this.getLocalData(REFRESH_TOKEN_EXP));
    }

    removeRefreshTokenExp() {
        this.removeLocalData(REFRESH_TOKEN_EXP);
    }

    //session stamp - timestamp to use for session.
    setSessionStamp(stamp) {
        this.setLocalData(SESSION_STAMP, stamp);
    }

    getSessionStamp() {
        return parseInt(this.getLocalData(SESSION_STAMP));
    }

    removeSessionStamp() {
        this.removeLocalData(SESSION_STAMP);
    }



    // User ID
    setUserId(userId) {
        this.setLocalData(USER_ID_STORAGE_KEY, userId);
    }

    getUserId() {
        return this.getLocalData(USER_ID_STORAGE_KEY);
    }

    removeUserId() {
        this.removeLocalData(USER_ID_STORAGE_KEY);
    }

    setEmail(email) {
        this.setLocalData(PRIMARY_EMAIL_KEY, email);
    }
    getEmail() {
        return this.getLocalData(PRIMARY_EMAIL_KEY);
    }

    removeEmail() {
        this.removeLocalData(PRIMARY_EMAIL_KEY);
    }

    // Email ID
    setEmailId(emailId) {
        this.setLocalData(EMAIL_ID_STORAGE_KEY, emailId);
    }

    getEmailId() {
        return this.getLocalData(EMAIL_ID_STORAGE_KEY);
    }

    removeEmailId() {
        this.removeLocalData(EMAIL_ID_STORAGE_KEY);
    }

    // Installation ID
    setInstallationId(installationId) {
        this.setLocalData(INSTALLATION_ID_STORAGE_KEY, installationId);
    }

    getInstallationId() {
        return this.getLocalData(INSTALLATION_ID_STORAGE_KEY);
    }

    removeInstallationId() {
        this.removeLocalData(INSTALLATION_ID_STORAGE_KEY);
    }

    setNeedsConfirm(){
        return this.setLocalData(NEEDS_CONFIRM, 'true');
    }

    getNeedsConfirm(){
        return !!this.getLocalData(NEEDS_CONFIRM);
    }

    removeNeedsConfirm(){
        return this.removeLocalData(NEEDS_CONFIRM);
    }

    setPTK(ptk) {
        this.setSessionData(PTK_STORAGE_KEY, ptk);
    }

    getPTK() {
        return this.getSessionData(PTK_STORAGE_KEY);
    }

    removePTK() {
        this.removeSessionData(PTK_STORAGE_KEY);
    }
    
    setSecondaryEmail(email) {
        this.setSessionData(SECONDARY_EMAIL_KEY, email);
    }

    getSecondaryEmail() {
        return this.getSessionData(SECONDARY_EMAIL_KEY);
    }

    setCardData(data) {
        this.setSessionData(CARD_DATA_KEY, data);
    }
    
    getCardData() {
        return this.getSessionData(CARD_DATA_KEY);
    }

    setCardDetails(details) {
        this.setSessionData(CARD_DETAILS_KEY, details);
    }
    
    getCardDetails() {
        return this.getSessionData(CARD_DETAILS_KEY);
    }

    setCardId(id) {
        this.setSessionData(CARD_ID_KEY, id);
    }
    
    getCardId() {
        return this.getSessionData(CARD_ID_KEY);
    }

    setDismissed(bool) {
        this.setLocalData(DISMISSED, bool);
    }
    
    getDismissed() {
        return this.getLocalData(DISMISSED);
    }

    setBreadCrumb(crumb) {
        this.setLocalData(SUBSCRIPTION_BREADCRUMB, crumb);
    }

    getBreadCrumb() {
        return this.getLocalData(SUBSCRIPTION_BREADCRUMB);
    }

    removeBreadCrumb() {
        this.removeLocalData(SUBSCRIPTION_BREADCRUMB);
    }

    setOnboardingBreadCrumb(crumb) {
        this.setSessionData(ONBOARDING_UPGRADE_BREADCRUMB, crumb);
    }

    getOnboardingBreadCrumb() {
        return this.getSessionData(ONBOARDING_UPGRADE_BREADCRUMB);
    }

    removeOnboardingBreadCrumb() {
        this.removeSessionData(ONBOARDING_UPGRADE_BREADCRUMB);
    }

    setEntryType(etype) {
        this.setLocalData(ENTRY_TYPE, etype);
    }

    getEntryType() {
        return this.getLocalData(ENTRY_TYPE);
    }

    removeEntryType() {
        this.removeLocalData(ENTRY_TYPE);
    }

    setHasPrevUpgraded(key) {
        this.setLocalData(HAS_PREVIOUSLY_UPGRADED, key);
    }

    getHasPrevUpgraded() {
        return this.getLocalData(HAS_PREVIOUSLY_UPGRADED);
    }

    removeHasPrevUpgradede() {
        this.removeLocalData(HAS_PREVIOUSLY_UPGRADED);
    }

    setHasPrevCancelled(key) {
        this.setLocalData(HAS_PREVIOUSLY_CANCELLED, key);
    }

    getHasPrevCancelled() {
        return this.getLocalData(HAS_PREVIOUSLY_CANCELLED);
    }

    removeHasPrevCancelled() {
        this.removeLocalData(HAS_PREVIOUSLY_CANCELLED);
    }

    setLoadingBreadCrumb(loading) {
        this.setSessionData(LOADING_BREADCRUMB, loading);
    }

    getLoadingBreadCrumb() {
        return this.getSessionData(LOADING_BREADCRUMB);
    }

    removeLoadingBreadCrumb() {
        this.removeSessionData(LOADING_BREADCRUMB);
    }

    setFreeReplayBreadCrumb(free_replay) {
        this.setLocalData(FREE_REPLAY, free_replay);
    }

    getFreeReplayBreadCrumb() {
        return this.getLocalData(FREE_REPLAY);
    }

    setPaidReplayBreadCrumb(paid_replay) {
        this.setLocalData(PAID_REPLAY, paid_replay);
    }

    getPaidReplayBreadCrumb() {
        return this.getLocalData(PAID_REPLAY);
    }

    setIsVerifyUri() {
        this.setSessionData(IS_VERIFY_URI, 'true');
    }

    getIsVerifyUri() {
        return super.getSessionData(IS_VERIFY_URI);
    }

    removeIsVerifyUri() {
        this.removeSessionData(IS_VERIFY_URI);
    }

    setCohorts(cohorts) {
        this.setLocalData(COHORTS, cohorts);
    }
    getCohorts() {
        return this.getLocalData(COHORTS);
    }

    removeCohorts() {
        this.removeLocalData(COHORTS);
    }


    // Logout clears all the data except installation ID
    // the installation ID will be used on login, if there is a different installation ID
    // the current installation value will be overwritten
    logout() {
        this.removeCSRF();
        this.removeSessionSecret();
        this.removeSessionStamp();
        this.removeAccessToken();
        this.removeAccessTokenExp();
        this.removeRefreshToken();
        this.removeRefreshTokenExp();
        this.removeUserId();
        this.removeEmailId();
        this.removePTK();
        this.removeNeedsConfirm(); //incase we get stuck, reset and remove this. We can reset it in loginMachine if we need to
        this.removeLoadingBreadCrumb();
        this.removeCohorts();
    }
}

export default AuthStorageService;

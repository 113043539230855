import { useEffect, useState } from "react";


const useAppleFix = ({mobileKebab, showSettings, threatState, showFeed, showUpgrade, addEmailOpen}) => {
    const [isSafari, setIsSafari] = useState(false);

    useEffect(() => {
        const userAgent = navigator.userAgent;
        const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(userAgent);
        setIsSafari(isSafariBrowser);
    }, []);


    useEffect(() => {
        if (isSafari) {
            if (mobileKebab || showSettings || threatState || showFeed || showUpgrade || addEmailOpen) {
                // Store the scroll position
                const scrollY = window.scrollY;
                const body = document.body;
                
                // Lock the body's scroll position
                body.style.position = 'fixed';
                body.style.top = `-${scrollY}px`;
                body.style.left = '0';
                body.style.right = '0';
                document.documentElement.style.height = '100vh';
    
                return () => {
                  // Unlock the scroll position
                  const scrollY = body.style.top;
                  body.style.position = '';
                  body.style.top = '';
                  body.style.left = '';
                  body.style.right = '';
                  document.documentElement.style.height = '';
                  
                  // Restore the scroll position
                  window.scrollTo(0, parseInt(scrollY || '0') * -1);
                };
            }
        }
    }, [isSafari, mobileKebab, showSettings, threatState, showFeed, showUpgrade, addEmailOpen]);

    }
export default useAppleFix;

import ApiClient from "./apiClient";
import {sha1Hash} from "../util/signing";

class MirkatClient extends ApiClient {
    // override the constructor to set the baseURL

    constructor({ baseURL, headers, language= "en" }) {
        super({ baseURL, headers, language });
        this.csrf = null;
        this.secret = null;
        this.accessKey = null;
        this.refreshKey = null;
        this.userId = null;
        this.emailId = null;
        this.installationId = null;
        this.ptk = null;
    }

    setCsrf(csrf) {
        this.csrf = csrf;
    }

    setSecret(secret) {
        this.secret = secret;
    }

    setAccessKey(key) {
        this.accessKey = key;
    }

    setRefreshKey(key) {
        this.refreshKey = key;
    }

    setUserId(id) {
        this.userId = id;
    }

    setEmailId(id) {
        this.emailId = id;
    }

    setInstallationId(id) {
        this.installationId = id;
    }

    setPTK(ptk) {
        this.ptk = ptk;
    }

    //CSRF endpoint
    async auth() {
        const endpoint = "/v3/auth/ti_ni"

        return this.request({
            method: "GET",
            endpoint: endpoint,
            customHeaders: {},
        })
    }

    async install(deviceToken) {
        const endpoint = "/v3/users/installation"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];
        const body = {
            'timestamp': timestamp,
            'signature': signature,
            'deviceToken': deviceToken,
        };

        return this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'csrf': this.csrf,
            }
        })
    }

    async users(email) { // returns an error if an email of a confirmed user that has a password is provided
        const endpoint = "/v3/users/"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];
        const body = {
            'timestamp': timestamp,
            'signature': signature,
            'primaryEmailAddress': email,
        };

        return this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'csrf': this.csrf,
            }
        })
    }

    async initFeed() {
        const endpoint = "/v3/feed/init"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];
        const body = {
            'timestamp': timestamp,
            'signature': signature,
            'userId': this.userId,
            'emailId': this.emailId,
        };

        return this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'csrf': this.csrf,
            }
        })
    }

    async confirm(confirmationCode) {
        const endpoint = "/v3/users/confirm"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];
        const body = {
            'timestamp': timestamp,
            'signature': signature,
            'userId': this.userId,
            'confirmationToken': confirmationCode,
            'emailId': this.emailId,
        };

        return await this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'csrf': this.csrf,
            }
        })
    }

    async resendConfirm() {
        const endpoint = "/v3/users/resend"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];
        const body = {
            'timestamp': timestamp,
            'signature': signature,
            'userId': this.userId,
            'emailId': this.emailId,
        };

        return await this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'csrf': this.csrf,
            }
        })
    }


    //Confirm using deeplink - confirmButton
    async confirmToken(confirmationToken) {
        let endpoint = "/v3/users/confirmToken"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];
        const body = {
            'timestamp': timestamp,
            'signature': signature,
            'confirmationToken': confirmationToken,
        };

        return await this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'csrf': this.csrf,
            }
        })
    }

    //confirmPoll - should be "authenticated"
    async confirmPoll() {
        let  endpoint = "/v3/users/confirmPoll";
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];

        endpoint = endpoint + "?userId=" + this.userId + "&emailId=" + this.emailId + "&timestamp=" + timestamp + "&signature=" + signature;

        return this.jsonRequest({
            method: "GET",
            endpoint: endpoint,
            customHeaders: {
                'csrf': this.csrf,
            }
        })
    }


    //ConfirmSecondaryPoll - need to call v3/feed/refresh to bring in secondaries and play animation while backend updates
    async confirmSecondaryPoll(userEmailId) {
        let  endpoint = "/v3/emails/emails/confirmPoll";
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];

        endpoint = endpoint + "?timestamp=" + timestamp + "&signature=" + signature + "&secondaryUserEmailId=" + userEmailId

        return this.jsonRequest({
            method: "GET",
            endpoint: endpoint,
            customHeaders: {
                'csrf': this.csrf,
                'Authorization': this.accessKey,
            }
        })
    }


    //Confirm secondary emails - must be authenticated
    async confirmTokenSecondaryEmails(confirmationToken, params) {
        let endpoint = "/v3/emails/emails/confirmToken"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];
        endpoint = endpoint + "?" + params;
        const body = {
            'timestamp': timestamp,
            'signature': signature,
            'confirmationToken': confirmationToken,
        };

        return await this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'csrf': this.csrf,
            }
        })
    }


    async password(password) {
        const endpoint = "/v3/users/password"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];
        const body = {
            'timestamp': timestamp,
            'signature': signature,
            'userId': this.userId,
            'emailId': this.emailId,
            'password': password,
        };

        return await this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'csrf': this.csrf,
                'ptk': this.ptk,
            }
        })
    }
    //New as of 2024-05-22 - v1 user migration -> just jwt and csrf/sig needed. no installation etc first
    //error code 28 if anything is wrong with the jwt or if userEmail does not have v1User=1 and v1UserSetPw=0
    async passwordV1(password, jwt) {
        console.log("we are here")
        const endpoint = "/v3/users/passwordV1"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];
        const body = {
            'timestamp': timestamp,
            'signature': signature,
            'password': password,
            'v1Verify': jwt
        };

        return await this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'csrf': this.csrf,
            }
        })
    }


    //Request a reset
    async passwordResetRequest(email) {
        const endpoint = "/v3/users/password/reset/link"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];
        const body = {
            'timestamp': timestamp,
            'signature': signature,
            "primaryEmailAddress": email,
        };

        return this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'csrf': this.csrf,
            }
        })
    }

    //Get token from emailed link. use token to get a reset-key
    async passwordResetToken(key) {
        let  endpoint = "/v3/users/password/reset";
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];

        endpoint = endpoint + "?" + key + "&timestamp=" + timestamp + "&signature=" + signature;

        return this.jsonRequest({
            method: "GET",
            endpoint: endpoint,
            customHeaders: {
                'csrf': this.csrf,
                'reset-token': key,
            }
        })
    }

    //Use reset-key to set password
    //POST /v3/users/password/reset
    //returns 200 with empty body on success
    async passwordReset(resetKey, password) {
        const endpoint = "/v3/users/password/reset"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];
        const body = {
            'timestamp': timestamp,
            'signature': signature,
            'password': password,
        };

        return this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'csrf': this.csrf,
                'reset-key': resetKey,
            }
        })
    }
    async changePassword(password, oldPassword) {
        const endpoint = "/v3/users/password/change"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];
        const body = {
            'timestamp': timestamp,
            'signature': signature,
            'password': password,
            'oldPassword': oldPassword,
            'userId': this.userId,
        };

        return this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'csrf': this.csrf,
                'Authorization': this.accessKey,
            }
        })
    }



    async login(email, password) {
        const endpoint = "/v3/auth/login"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];
        const body = {
            'timestamp': timestamp,
            'signature': signature,
            'primaryEmailAddress': email,
            'password': password,
        };

        return this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'csrf': this.csrf,
            }
        })
    }

    async logout() {
        const endpoint = "/v3/auth/logout"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];
        const body = {
            'timestamp': timestamp,
            'signature': signature,
        };

        return this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'csrf': this.csrf,
                'Authorization': this.accessKey,
            }
        })
    }

    async refresh() {
        const endpoint = "/v3/auth/refresh"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];
        const body = {
            'timestamp': timestamp,
            'signature': signature,
        };

        return this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'csrf': this.csrf,
                'Authorization': this.refreshKey,
            }
        })
    }

    async feed(session) {
        let endpoint = "/v3/feed/"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];

        endpoint = endpoint + "?emailId=" + this.emailId + "&userId=" + this.userId + "&timestamp=" + timestamp + "&signature=" + signature + "&session=" + session

        return this.jsonRequest({
            method: "GET",
            endpoint: endpoint,
            customHeaders: {
                'Authorization': this.accessKey,
                'csrf': this.csrf,
            }
        })
    }

    async breaches(session) {
        let endpoint = "/v3/dataBreaches/"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];

        endpoint = endpoint + "?userId=" + this.userId + "&emailId=" + this.emailId + "&timestamp=" + timestamp + "&signature=" + signature + "&session=" + session

        return this.jsonRequest({
            method: "GET",
            endpoint: endpoint,
            customHeaders: {
                'Authorization': this.accessKey,
                'csrf': this.csrf,
            }
        })
    }
    
    async history() {
        let endpoint = "/v3/history/"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];

        endpoint = endpoint + "?emailId=" + this.emailId + "&timestamp=" + timestamp + "&signature=" + signature

        return this.jsonRequest({
            method: "GET",
            endpoint: endpoint,
            customHeaders: {
                'Authorization': this.accessKey,
                'csrf': this.csrf,
            }
        })
    }
    
    async emails() {
        let endpoint = "/v3/emails/emails"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];

        endpoint = endpoint + "?timestamp=" + timestamp + "&signature=" + signature
        return this.jsonRequest({
            method: "GET",
            endpoint: endpoint,
            customHeaders: {
                'Authorization': this.accessKey,
                'csrf': this.csrf,
            }
        })
    }
    
    async addEmail(email) {
        let endpoint = "/v3/emails/emails"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];
        const body = {
           'timestamp': timestamp,
            'signature': signature,
	        'secondaryEmailAddress': email,
        };

        return this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
	        body: body,
            customHeaders: {
                'Authorization': this.accessKey,
                'csrf': this.csrf,
            }
        })
    }

    async confirmAddEmail(email, confirmationCode) {
        let endpoint = "/v3/emails/emails/confirm"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];

        const body = {
            'timestamp': timestamp,
            'secondaryEmailAddress': email,
            'signature': signature,
            'confirmationToken': confirmationCode,
        };

        return this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
	        body: body,
            customHeaders: {
                'Authorization': this.accessKey,
                'csrf': this.csrf,
            }
        })
    }

    //deletes secondary emails from settings page
    //takes obfuscated email id
    async deleteEmail(emailId) {
        let endpoint = "/v3/emails/emails/delete/" + emailId
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];

       endpoint = endpoint + "?timestamp=" + timestamp + "&signature=" + signature

        return this.jsonRequest({
            method: "DELETE",
            endpoint: endpoint,
            customHeaders: {
                'Authorization': this.accessKey,
                'csrf': this.csrf,
            }
        })
    }

//we had a duplicate call here for some reason
//    //similar to confirmAddEmail.... I didn't want to break existing... but that endpoint doesn't require email.
//    //just the emailed jwt.
//    async confirmSecondaryEmail(confirmationToken) {
//        let endpoint = "/v3/emails/emails/confirmToken"
//        let sig = await this.signRequest(endpoint);
//        let signature = sig[0];
//        let timestamp = sig[1];
//
//        const body = {
//            'timestamp': timestamp,
//            'signature': signature,
//            'confirmationToken': confirmationToken,
//        };
//
//        return this.jsonRequest({
//            method: "POST",
//            endpoint: endpoint,
//            body: body,
//            customHeaders: {
//                'Authorization': this.accessKey,
//                'csrf': this.csrf,
//            }
//        })
//    }
    
    async sendWelcomeEmail() {
        let endpoint = "/v3/users/sendWelcomeEmail"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];

        const body = {
            'timestamp': timestamp,
            'signature': signature,
            'userId': this.userId,
            'emailId': this.emailId,
        };

        return this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'Authorization': this.accessKey,
                'csrf': this.csrf,
            }
        })
    }


    async billing() {
        let endpoint = "/v3/billing/purchase"
            
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];
        endpoint = endpoint + "?userId=" + this.userId + "&emailId=" + this.emailId + "&timestamp=" + timestamp + "&signature=" + signature

        return this.jsonRequest({
            method: "GET",
            endpoint: endpoint,
            customHeaders: {
                'Authorization': this.accessKey,
                'csrf': this.csrf,
            }
        })
    }


    // is emailId the account id or could it be a secondary emailId? If it's the account id, can change to this.emailId
    async complete(cardId, emailId) {
        let endpoint = "/v3/card/" + cardId + "/complete"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];

        const body = {
                'timestamp': timestamp,
                'signature': signature,
                'emailId': emailId,
        }

        return this.jsonRequest({
            method: "PUT",
            endpoint: endpoint,
	        body: body,
            customHeaders: {
                'Authorization': this.accessKey,
                'csrf': this.csrf,
            }
        })
    }
    
    async dismiss(cardId) {
        let endpoint = "/v3/card/" + cardId + "/dismiss"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];

        const body = {
                'timestamp': timestamp,
                'signature': signature,
                'emailId': this.emailId,
        }

        return this.jsonRequest({
            method: "PUT",
            endpoint: endpoint,
	        body: body,
            customHeaders: {
                'Authorization': this.accessKey,
                'csrf': this.csrf,
            }
        })
    }

    async emailSettings(emailId, email, ios) {
        let endpoint = "/v3/emails/settings"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];

        const body = {
            'timestamp': timestamp,
            'signature': signature,
            'emailId': emailId,
            'notifyEmail': email,
            'notifyWindows': 0,
            'notifyAndroid': 0,
            'notifyIOS': ios,
        };

        return this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'Authorization': this.accessKey,
                'csrf': this.csrf,
            }
        })
    }
    
    async feedRefresh() {
        let endpoint = "/v3/feed/refresh"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];

        const body = {
            'timestamp': timestamp,
            'signature': signature,
            'userId': this.userId,
            'emailId': this.emailId,
        };

        return this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'Authorization': this.accessKey,
                'csrf': this.csrf,
            }
        })
    }

    async emailDismiss() {
        let endpoint = "/v3/feed/dismissAddSecondaryCard"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];

        const body = {
            'timestamp': timestamp,
            'signature': signature,
            'emailId': this.emailId,
        };

        return this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'Authorization': this.accessKey,
                'csrf': this.csrf,
            }
        })
    }
    
    async seen(ids) {
        let endpoint = "/v3/card/seen"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];

        const body = {
            'timestamp': timestamp,
            'signature': signature,
            'emailId': this.emailId,
	    'cardIds': [ids],
        };

        return this.jsonRequest({
            method: "PUT",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'Authorization': this.accessKey,
                'csrf': this.csrf,
            }
        })
    }
    
    async deleteAccount(id) {
        let endpoint = "/v3/users/delete/" + id
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];

        const body = {
            'timestamp': timestamp,
            'signature': signature,
            'emailId': this.emailId,
	        'userId': this.userId,
        };

        return this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'Authorization': this.accessKey,
                'csrf': this.csrf,
            }
        })
    }

    //signRequest that generates a signature for the request
    //need to return the timestamp here as well or signature won't match
    async signRequest(endpoint) {
        const timestamp = Math.floor(Date.now()/1000);
        const s = this.csrf + '_' + timestamp + '_' + endpoint + this.secret
        let hash =  await sha1Hash(s);
        return [hash, timestamp];
    }


    //Initiate a purchase
    async webPurchase() {
        let endpoint = "/v3/billing/webPurchase"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];

        const body = {
            'timestamp': timestamp,
            'signature': signature,
            'emailId': this.emailId,
            'userId': this.userId,
        };

        /*
        return code 201
        {
            "success": 1,
            "customerId": "some hash",
            "portalUrl": "this is a link for them to access their stripe portal, has a 5 minute timeout",
            "checkoutUrl": "this is a link for them to fill in their credit card information and actually complete the purchase, this has a 24 hour timeout I think"
        }
         */

        return this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'Authorization': this.accessKey,
                'csrf': this.csrf,
            }
        })
    }

    //Native purchase
    async purchase(transactionId, originalTransactionId, productId) {
        let endpoint = "/v3/billing/purchase"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];

        const body = {
            'timestamp': timestamp,
            'signature': signature,
            'userId': this.userId,
            'emailId': this.emailId,
            'transactionId': transactionId,
            'originalTransactionId': originalTransactionId,
            'productId': productId
        };

        console.log("Native Purchase", body);
        return this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'Authorization': this.accessKey,
                'csrf': this.csrf,
            }
        });
    }


    //call before redirecting to stripe portal
    //link expires after 5 minutes, you can always call this route again. does not update anything related to billing
    //just creating a session for them to access their portal in stripe.
    async createPortalSession() {
        let endpoint = "/v3/billing/createPortalSession"
        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];

        const body = {
            'timestamp': timestamp,
            'signature': signature,
            'emailId': this.emailId,
            'userId': this.userId,
        };

        /*
        {
            "success": 1,
            "portalUrl": "the link to their portal in stripe"
        }
         */

        return this.jsonRequest({
            method: "POST",
            endpoint: endpoint,
            body: body,
            customHeaders: {
                'Authorization': this.accessKey,
                'csrf': this.csrf,
            }
        })
    }

    async getWebBillingStatus() {
        let endpoint = "/v3/billing/getWebBillingStatus"

        let sig = await this.signRequest(endpoint);
        let signature = sig[0];
        let timestamp = sig[1];
        endpoint = endpoint + "?userId=" + this.userId + "&emailId=" + this.emailId + "&timestamp=" + timestamp + "&signature=" + signature


        return this.jsonRequest({
            method: "GET",
            endpoint: endpoint,
            customHeaders: {
                'Authorization': this.accessKey,
                'csrf': this.csrf,
            }
        })
    }

}

export default MirkatClient;

import React, { useEffect, useState, useRef } from 'react'
import ToggleEnabled from '../svg/ToggleEnabled';
import ToggleDisabled from '../svg/ToggleDisabled';
import {useNavigate} from "react-router-dom";
import {useFetch} from "../hooks/useFetch";
import { useAuth } from "../contexts/authContext";
import Fade from '@mui/material/Fade';
import {track} from "../util/analytics";
import useClearModals from "../hooks/useClearModals";

import CircularProgress from '@mui/material/CircularProgress';
import ProgressBar from "../components/ProgressBar";
import useProgressBar from "../hooks/useProgressBar";
import GenericErrorModal from "../modals/GenericErrorModal";
import useDetectNetwork from "../hooks/useDetectNetwork";
import {useSettings} from  "../contexts/settingsContext";

const SetttingsNotifications = ({ loadedOnce }) => {
   // const [isEmailEnabled, setEmailEnabled] = useState(false);
   // const [isIOSEnabled, setIOSEnabled] = useState(false);
   // const {state, reset, fetch, ready} = useFetch();
    const {state: state2, fetch: fetch2, reset: reset2} = useFetch();
    const {state: state3, fetch: fetch3, reset: reset3} = useFetch();
    const { isAuthenticated, authState} = useAuth();
    const navigate = useNavigate();
    const isFirstRun = useRef(true)
    const [clearModals, setClearModals] = useState(false);

	const [isLoaded, setIsLoaded] = useState(false);
    const [settingsReady, setSettingsReady] = useState(false);
    const [settingsRetrieved, setSettingsRetrieved] = useState(false);
    const { progressStarted, setNoDelay, noDelay, apiStarted, startProgress, setLastApiCallStarted, setProgressOverride, progressOverride, handleCompletion } = useProgressBar();

    const {
        setIosNotificationsEnabled: setIOSEnabled,
        iosNotificationsEnabled: isIOSEnabled,
        emailNotificationsEnabled: isEmailEnabled,
        setEmailNotificationsEnabled: setEmailEnabled,
        genericErrorModal,
        setGenericErrorModal,
        setUpdateNotificationType,
        setUpdateNotifications,
    } = useSettings();

    useEffect(() => {
    	setIsLoaded(true)
    }, []);

    useEffect(() => {
        console.log("isEmail changed: ", isEmailEnabled)
        console.log("isIOS changed: ", isIOSEnabled)

    }, [isEmailEnabled, isIOSEnabled]);


    //get initial email settings
    useEffect(() => {
    	if(isLoaded) {
    		if (!authState.matches('authenticated')) {
    			navigate('/login');
                setClearModals(true);
    		}
    	}
    }, [isAuthenticated, authState]);

    const onEmailClick = () => {
        //email
        setUpdateNotificationType('email')
        setEmailEnabled(!isEmailEnabled)
        setUpdateNotifications(true)
        //#240
        track('Toggle Tapped', {toggleType: 'emailNotifications', screenType: 'settings', toggleState: !isEmailEnabled ? 'enabled' : 'disabled'})
    }
     const onMobileClick = () => {
        //ios
         //set
         setUpdateNotificationType('ios')
         setIOSEnabled(!isIOSEnabled)
         setUpdateNotifications(true)
         //#244
         track('Toggle Tapped', {toggleType: 'pushNotifications', screenType: 'settings', toggleState: !isIOSEnabled ? 'enabled' : 'disabled'})
     }

    return (
         <>
            {genericErrorModal && <GenericErrorModal props={true} dim={true} callback={()=>{setGenericErrorModal(false)}} screenType='settings' screenSubType='notifications' />}
		    {isLoaded  ? (
               <div className="settings-notifications noSelect">
                    <div className="settings-header-container"><h2 style={{ color: 'var(--header-on-white, #2F364B)' }} className="settings-header">Notifications</h2></div>
                    <div className="notifications-body">

                        <div className="settings-row-container">
                        <div className="settings-row">
                                <div className="settings-row-icon-container">
                            <svg className="settings-row-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 6.22222C2 5.63285 2.23413 5.06762 2.65087 4.65087C3.06762 4.23413 3.63285 4 4.22222 4H19.7778C20.3671 4 20.9324 4.23413 21.3491 4.65087C21.7659 5.06762 22 5.63285 22 6.22222M2 6.22222V17.3333C2 17.9227 2.23413 18.4879 2.65087 18.9047C3.06762 19.3214 3.63285 19.5556 4.22222 19.5556H19.7778C20.3671 19.5556 20.9324 19.3214 21.3491 18.9047C21.7659 18.4879 22 17.9227 22 17.3333V6.22222M2 6.22222L12 12.8889L22 6.22222" stroke="#2F364B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                                </div>
                                <div className="settings-row-text-container"><div className="settings-row-text">Email notifications</div></div>
                                <div className="notifications-toggle-container"><button onClick={onEmailClick} className="notifications-toggle">
                                { isEmailEnabled ? <ToggleEnabled /> : <ToggleDisabled /> }
                                </button></div>
                            </div>
                        </div>
                        <div className="settings-row-container">
                        <div className="settings-row">
                                <div className="settings-row-icon-container">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.1111 2.22222H13.5556M12.3333 18.1111V18.1233M5 3.44444C5 2.79614 5.25754 2.17438 5.71596 1.71596C6.17438 1.25754 6.79614 1 7.44444 1H17.2222C17.8705 1 18.4923 1.25754 18.9507 1.71596C19.4091 2.17438 19.6667 2.79614 19.6667 3.44444V20.5556C19.6667 21.2039 19.4091 21.8256 18.9507 22.284C18.4923 22.7425 17.8705 23 17.2222 23H7.44444C6.79614 23 6.17438 22.7425 5.71596 22.284C5.25754 21.8256 5 21.2039 5 20.5556V3.44444Z" stroke="#2F364B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                                </div>
                                <div className="settings-row-text-container"><div className="settings-row-text">Mobile notifications</div></div>
                                <div className="notifications-toggle-container"><button onClick={onMobileClick} className="notifications-toggle">
                                { isIOSEnabled ? <ToggleEnabled /> : <ToggleDisabled /> }
                                </button></div>
                            </div>
                        </div>
                    </div>
                </div>
                ) : (
               <div className="settings-notifications">
                    <div className="settings-header-container"><h2 style={{ color: 'var(--header-on-white, #2F364B)' }} className="settings-header">Notifications</h2></div>
                    <div className="notifications-body">
                        <div className="settings-row-container">
                        <div className="settings-row">
                                <div className="settings-row-icon-container">
                            <svg className="settings-row-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 6.22222C2 5.63285 2.23413 5.06762 2.65087 4.65087C3.06762 4.23413 3.63285 4 4.22222 4H19.7778C20.3671 4 20.9324 4.23413 21.3491 4.65087C21.7659 5.06762 22 5.63285 22 6.22222M2 6.22222V17.3333C2 17.9227 2.23413 18.4879 2.65087 18.9047C3.06762 19.3214 3.63285 19.5556 4.22222 19.5556H19.7778C20.3671 19.5556 20.9324 19.3214 21.3491 18.9047C21.7659 18.4879 22 17.9227 22 17.3333V6.22222M2 6.22222L12 12.8889L22 6.22222" stroke="#2F364B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                                </div>
                                <div className="settings-row-text-container"><div className="settings-row-text">Email notifications</div></div>
                                <div className="notifications-toggle-container"><button className="notifications-toggle">
                                { <ToggleDisabled/> }
                                </button></div>
                            </div>
                        </div>
                        <div className="settings-row-container">
                        <div className="settings-row">
                                <div className="settings-row-icon-container">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.1111 2.22222H13.5556M12.3333 18.1111V18.1233M5 3.44444C5 2.79614 5.25754 2.17438 5.71596 1.71596C6.17438 1.25754 6.79614 1 7.44444 1H17.2222C17.8705 1 18.4923 1.25754 18.9507 1.71596C19.4091 2.17438 19.6667 2.79614 19.6667 3.44444V20.5556C19.6667 21.2039 19.4091 21.8256 18.9507 22.284C18.4923 22.7425 17.8705 23 17.2222 23H7.44444C6.79614 23 6.17438 22.7425 5.71596 22.284C5.25754 21.8256 5 21.2039 5 20.5556V3.44444Z" stroke="#2F364B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                                </div>
                                <div className="settings-row-text-container"><div className="settings-row-text">Mobile notifications</div></div>
                                <div className="notifications-toggle-container"><button className="notifications-toggle">
                                { <ToggleDisabled/> }
                                </button></div>
                            </div>
                        </div>
                    </div>
                </div>
                )
            }
         </>

	)
}

export default SetttingsNotifications;

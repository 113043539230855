import React from 'react';
import {track} from "../util/analytics";

const Action = ({ action, cardId }) => {
	const onClick = () => {
		console.log('Action clicked');
		track('Button Tapped', {
			buttonType: 'action',
			screenType: 'risk',
			screenSubType: 'details',
			actionType: action?.friendlyName,
			outboundLink: action?.deepLinkUrl,
			cardId: cardId,
		})
	}

    return (
	    <div className="threatcard-action">
	      <div className="threatcard-actionrow">
	        <span className="threatcard-actiontitle">{action.title}</span>
	        <img src={action.imageUrl} alt="threat card action" className="threatcard-actionimage" loading="lazy"></img>
	      </div>
	      <div className="threatcard-actiondescription">{action.description}</div>
	      {action.buttonTitle === null  ? null : <a target="_blank" href={action.deepLinkUrl} className="threatcard-button" onClick={onClick}>{action.buttonTitle}</a>}
	    </div>
    );
};

export default Action;

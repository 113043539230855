import React from "react";
import PasswordReset from "../components/PasswordReset";
import "../styles/scss/LoginPage.scss";

const PasswordResetPage = () => {
    return (
        <div className="col-12">
            <div className="onBoarding">
                <div className="login-row">
                    <div className="login">
                        <PasswordReset />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PasswordResetPage;
import React, {useCallback, useEffect, useState} from 'react';
import {track} from "../util/analytics";
import useObserveVisibility from "../hooks/useObserveVisibility";
import useIsWindows from "../hooks/useIsWindows";


const FreeSidebar = ({ changeState, parentState, upgradeClickFrom, feedSubType, feedPosition }) => {
	const [sidebarSeen, setSideBarSeen] = useState(false)
	const [sidebarButtonSeen, setSidebarButtonSeen] = useState(false)
	const [sideBarLoaded, setSideBarLoaded] = useState(false)
    const passwords = "https://img.mirkat.com/web/images/home/passwords.svg";
    const accountc = "https://img.mirkat.com/web/images/home/accountc.svg";
    const privatec = "https://img.mirkat.com/web/images/home/privatec.svg";
    const identityc = "https://img.mirkat.com/web/images/home/identityc.svg";
    const creditc = "https://img.mirkat.com/web/images/home/creditc.svg";
    const bankingc = "https://img.mirkat.com/web/images/home/bankingc.svg";
    const [isWindows, isMac] = useIsWindows();

	const onSidebarVisible = useCallback(() => {
		if(!sidebarSeen) {
			console.log(sidebarSeen)
			console.log('Sidebar Target card is now in view');
			setSideBarSeen(true)
			if(feedPosition > 3) {
				console.log("RISK")
			} else {
				console.log("SAFE")
			}
			//Not considered a card so we do not want
			/*
			//#386
			track('Card Viewed', {
				cardType: 'upgradeMirkat+',
				feedType: 'feed',
				feedSubType: feedPosition> 0 ? 'risk' : 'safe', // or safe
				feedPosition: feedPosition, // integer
			});

			 */
		}

	}, [feedPosition, sidebarSeen]);

	const sidebarCardRef = useObserveVisibility(onSidebarVisible, { threshold: 0.8 })

	const onSidebarButtonVisible = useCallback(() => {
		//prevent triggering on resizing window as well
		if(!sidebarButtonSeen) {
			console.log(sidebarButtonSeen)
			console.log('Bottombar button Target is now in view');
			 // not specified in the spec but leaving here incase they want it.
			setSidebarButtonSeen(true)
			//Not considered a card so we do not want
			//#371
			track('Button Viewed', {
				cardType: 'upgrade',
				feedType: 'feed',
			});

		}

	}, [feedPosition, sidebarSeen]);

	const sidebarButtonRef = useObserveVisibility(onSidebarButtonVisible, { threshold: 0.5 })
	useEffect(() => {
		if(!sideBarLoaded) {
			/*
			//#381
			track('Card Loaded', {
				cardType: 'upgradeMirkat+', feedType: 'feed', feedSubType: feedSubType,
				feedPosition: 0
			})

			 */
		}
	}, [sideBarLoaded]);



    return (
        <div className="sidebar-container" ref={sidebarCardRef}>
	    <div className="sidebar-header-container"><div className="sidebar-header">You have
	        {isWindows ? 
                    <div className="sidebar-header-status-free" style={{padding: "7px 14px 7px 14px"}}>limited protection</div>
                : isMac ?
                    <div className="sidebar-header-status-free" style={{padding: "8.5px 14px 5.5px 14px"}}>limited protection</div>
                  :
                    <div className="sidebar-header-status-free" >limited protection</div>
                }
	    </div></div>
	    <div className="sidebar-items-container">
	    	<div className="sidebar-row">
	    	    <div className="sidebar-left">
	    	        <img className="sidebar-image" src={passwords} />
	    	        <div className="sidebar-description sidebar-description-override-white">Exposed Passwords</div>
	    	    </div>
	    	    <div className="sidebar-right" style={{ alignItems: "center" }} >
	    	        <img className="sidebar-image" src={accountc} style={{ alignSelf: "unset", width: "unset" }}/>
	    	        <div className="sidebar-description">Account Credentials</div>
	    	    </div>
	        </div>
	    	<div className="sidebar-row">
	    	    <div className="sidebar-left" style={{ alignItems: "center" }} >
	    	        <img className="sidebar-image" src={privatec} style={{ alignSelf: "unset", width: "unset" }}/>
	    	        <div className="sidebar-description">Private<br />Accounts</div>
	    	    </div>
	    	    <div className="sidebar-right" style={{ alignItems: "center" }} >
	    	        <img className="sidebar-image" src={identityc} style={{ alignSelf: "unset", width: "unset" }}/>
	    	        <div className="sidebar-description">Identity<br />Theft</div>
	    	    </div>
	        </div>
	    	<div className="sidebar-row">
	    	    <div className="sidebar-left" style={{ alignItems: "center" }} >
	    	        <img className="sidebar-image" src={creditc} style={{ alignSelf: "unset", width: "unset" }}/>
	    	        <div className="sidebar-description">Credit &<br />Debit Cards</div>
	    	    </div>
	    	    <div className="sidebar-right" style={{ alignItems: "center" }} >
	    	        <img className="sidebar-image" src={bankingc} style={{ alignSelf: "unset", width: "unset" }}/>
	    	        <div className="sidebar-description">Banking Accounts</div>
	    	    </div>
	        </div>
	    </div>
	    <div className="sidebar-footer-free noSelect" ref={sidebarButtonRef} onClick={() => {changeState(!parentState); upgradeClickFrom("sideBar")}} style={{ cursor: "pointer" }}>
                Unlock full protection
            </div>
        </div>
    );
};

export default FreeSidebar;

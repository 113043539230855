import React, {useEffect, useState} from "react";
import RegisterFlow from "../components/RegisterFlow";

const RegisterPage = () => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [keyboardHeight, setKeyboardHeight] = useState(0);

    useEffect(() => {
        if ("virtualKeyboard" in navigator) {
            const virtualKeyboard = navigator.virtualKeyboard;

            const handleGeometryChange = (event) => {
                // Adjust the window height based on the keyboard height
                setKeyboardHeight(event.boundingRect.height);
            };

            virtualKeyboard.addEventListener('geometrychanged', handleGeometryChange);

            return () => {
                // Cleanup
                virtualKeyboard.removeEventListener('geometrychanged', handleGeometryChange);
            };
        }
    }, []);

    const adjustedHeight = `calc(100vh - ${keyboardHeight}px)`;

    // Set up an event listener for window resizing
    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

                //<div className="login" style={{height: windowHeight }}>
    return (
        <div className="onBoarding">
            <div className="login-row">
                <div className="login" >
                    <RegisterFlow />
                </div>
          </div>
        </div>
    );
}

export default RegisterPage;

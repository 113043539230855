import { useEffect, useState, useRef } from "react";


const useUpgradeOpacityFix = ({showUpgrade, windowWidth}) => {
    const isFirstRun = useRef(true);
    const [opacity, setOpacity] = useState(0);
    
    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        } else if (showUpgrade) {
            setOpacity(1);
        } else if (!showUpgrade) {
            setOpacity(0);
        }
    }, [showUpgrade]);

    const style = {
        opacity: opacity,
        transition: `opacity 400ms cubic-bezier(0.4, 0, 0.2, 2)`,
        height: windowWidth > 650 ? "100vh" : "100%"
    };

    return style;

    }
export default useUpgradeOpacityFix;

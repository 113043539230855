
//this does not modify context data - xstate doesn't allow it
export const HandleContextData = (context, storage) => {
    // this adjusts storage based on context data...

    if (context) {
        // Check if the access key is present and has changed
        if (context.accessToken && context.accessToken !== storage.getAccessToken()) {
            storage.setAccessToken(context.accessToken);
            //Access token updates but refresh never does, only expires
            if (process.env.REACT_APP_ACCESS_TOKEN_INTERVAL) {
                const accExp = Math.floor(Date.now() / 1000) + parseInt(process.env.REACT_APP_ACCESS_TOKEN_INTERVAL);
                storage.setAccessTokenExp(accExp);
            } else {
                const accExp = Math.floor(Date.now() / 1000) + 3540;
                storage.setAccessTokenExp(accExp);
            }
            storage.removeNeedsConfirm();
        }

        // Check if the refresh key is present and has changed
        if (context.refreshToken && context.refreshToken !== storage.getRefreshToken()) {
            //Access token updates but refresh never does, only expires
            if (process.env.REACT_APP_REFRESH_TOKEN_INTERVAL) {
                const refExp = Math.floor(Date.now() / 1000) + (parseInt(process.env.REACT_APP_REFRESH_TOKEN_INTERVAL));
                storage.setRefreshTokenExp(refExp);
            } else {
                //If we're in prod refresh is 1 day
                const refExp = Math.floor(Date.now() / 1000) + 86340; //Expire just before 1 day
                storage.setRefreshTokenExp(refExp);
            }
            storage.setRefreshToken(context.refreshToken);
            storage.setSessionStamp(Math.floor(Date.now() / 1000));
        }

        // Check if the installation id is present and has changed
        if (context.installationId && context.installationId !== storage.getInstallationId()) {
            storage.setInstallationId(context.installationId);
        }

        // Check if the user id is present and has changed
        if (context.userId && context.userId !== storage.getUserId()) {
            storage.setUserId(context.userId);
        }

        // Check if the email id is present and has changed
        if (context.emailId && context.emailId !== storage.getEmailId()) {
            storage.setEmailId(context.emailId);
        }

        // Check if the ptk is present and has changed
        if (context.ptk && context.ptk !== storage.getPTK()) {
            storage.setPTK(context.ptk);
        }

        if (context.installationId && !context.accessKey) {

            //storage.setNeedsConfirm();
        }

        if (context.emailAddress && context.emailAddress !== storage.getEmail()) {
            storage.setEmail(context.emailAddress);
        }

        if (context.csrf && context.csrf !== storage.getCSRF()) {
            //storage.setCSRF(context.csrf);
            //storage.setCSRFExp(context?.csrfExp);

        }

        if (context.sessionStamp && context.sessionStamp !== storage.getSessionStamp()) {
            storage.setSessionStamp(context.sessionStamp);
        }

        if (context.accessTokenExp && context.accessTokenExp !== storage.getAccessTokenExp()) {
            storage.setAccessTokenExp(context.accessTokenExp);
        }

        if (context.refreshTokenExp && context.refreshTokenExp !== storage.getRefreshTokenExp()) {
            storage.setRefreshTokenExp(context.refreshTokenExp);

        }

        if (context.secret && context.secret !== storage.getSessionSecret()) {
            //storage.setSessionSecret(context.secret);
        }

        if (context.ptk && context.ptk !== storage.getPTK()) {
            storage.setPTK(context.ptk);
        }

        if (context.secondaryEmails && context.secondaryEmails !== storage.getSecondaryEmail()) {
            storage.setSecondaryEmail(context.secondaryEmails);
        }
        if (context.cohorts && context.cohorts !== storage.getCohorts()) {
            storage.setCohorts(context.cohorts);
        }
    }
}

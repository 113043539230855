import React, {useEffect, useState} from "react";
import "../styles/scss/WelcomePage.scss";
import {useLocation, useNavigate} from "react-router-dom";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/authContext";
import {track, screen, anonUserID, identify, aliasAndIdentifyUser} from "../util/analytics";
import useIsPlus from "../hooks/useIsPlus";
import Fade from '@mui/material/Fade';
import useClearModals from "../hooks/useClearModals";
import {useFetch} from "../hooks/useFetch";
import AuthStorageService from "../services/authStorage";
import {preloadUpgradeCardImages} from "../util/imageCache";
import useIsWindows from "../hooks/useIsWindows";
import ReactPixel from 'react-facebook-pixel';
import {useSettings} from "../contexts/settingsContext";

const storage = new AuthStorageService();


const WelcomePage = () => {
    const location = useLocation();
    const { isAuthenticated, authState, authService, isReady, stateSteady} = useAuth();
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();
    const [clearModals, setClearModals] = useState(false);
    const {state, fetch, ready} = useFetch();
    const {state: state2, fetch: fetch2, ready: ready2} = useFetch();
    const [userIdentified, setUserIdentified] = useState(false);
    const [isWindows, isMac] = useIsWindows();
    const [sentPixel, setSentPixel] = useState(false);
    const {setNeedsRefetch} = useSettings();

    let body = ""
    let body1 = "" 
    let body2 = "" 
    let email = ""
    const [plus, setPlus] = useState(null)
    const {isPlus, error, billingRequestStatus, isInTrial} = useIsPlus();
    const [componentMounted, setComponentMounted] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const dynamic = {minHeight: windowWidth > 839 ? "calc(100vh - 130px)" : "100%"}
    const dynamic1 = {minHeight: windowWidth > 839 ? "auto" : "100%"}
    body = "We’ll monitor the dark web for passwords, account credentials, banking accounts, credit cards, and more associated with your email addresses.\n\nCriminals use this stolen information to take over accounts, steal money, or assume identities.\n\nYou’ll be notified when you’re at risk."
    const Mirkat = "https://img.mirkat.com/web/images/home/Mirkat.png";
                

    useClearModals(clearModals);

    //Do we need this?
    useEffect(() => {
        if(stateSteady && !authState.matches('authenticated')) {
            sessionStorage.removeItem("welcomeReturnBreadCrumb");
            navigate("/login")
            setClearModals(true);
        }
    }, [authState, stateSteady]);



    useEffect(() => {
        preloadUpgradeCardImages();
        //commit 635edbc4 broke
        storage.setLoadingBreadCrumb("true");

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useEffect(() => {
        if(isLoaded && isReady && isPlus) {
            console.log("isPlus is true")
            setPlus(true)
        } else if (isLoaded && isReady && isPlus === false) {
            const searchParams = new URLSearchParams(location.search);

            const sessionId = searchParams.get('session_id');
            //potential issue if the user does a full refresh here? need to check in query if no session id present?
            if (sessionId) {
                setPlus(true)
            } else {
                setPlus(false)
            }
        }

        if(error) {
            console.log(error)
        }
        if(isReady && (billingRequestStatus === 'success' || billingRequestStatus === 'failure') && !userIdentified) {
           // setIsLoaded(true)

            if(authState.matches('authenticated') && authState.context.userId) {
                const anonId = anonUserID()
                //new account was created.
                /*
                identify(authState.context.userId, {
                    email: authState.context.emailAddress,
                    plan: isPlus ? 'paid': 'free',
                    createdAt: new Date().toISOString(),
                    anonymousId: anonId,
                })

                */
                aliasAndIdentifyUser(authState.context.userId, {
                        email: authState.context.emailAddress,
                        plan: isPlus ? 'paid': 'free',
                        createdAt: new Date().toISOString(),
                        cohort: authState.context.cohorts,
                        anonymousId: anonId
                    })

                setUserIdentified(true)

            }
            const searchParams = new URLSearchParams(location.search);

            const sessionId = searchParams.get('session_id');
            //potential issue if the user does a full refresh here? need to check in query if no session id present?
            if (sessionId) {
                screen('Welcome', {screenType: 'welcome', entryType: authState.context.isNewUser ? 'signUp' : 'upgrade' , planType: 'paid'})
                if (!sentPixel) {
                    ReactPixel.track('Subscribe')
                    if (isInTrial) {
                        ReactPixel.track('StartTrial')
                    }
                    setSentPixel(true);
                }
            } else {
                screen('Welcome', {screenType: 'welcome', entryType: 'signUp', planType: isPlus ? 'paid' : 'free'})

            }

        }

        console.log("WTF")
        console.log("AUthState: ", authState)
    }, [isPlus, billingRequestStatus, error, isLoaded, isReady, authState, userIdentified]);




    const onClick = () => {
        //#47
        storage.setFreeReplayBreadCrumb("false");
        sessionStorage.removeItem("welcomeReturnBreadCrumb");
        track('Button Tapped', {
            buttonType: 'continue',
            screenType: 'welcome',
            screenSubType: 'welcome',

        })
    }

    useEffect(() => {
        //signup flow vs upgrade flow

        setNeedsRefetch(true) //fetch on component mount
        sessionStorage.setItem("welcomeReturnBreadCrumb", true);

        setComponentMounted(true);
        //setTimeout(() => setIsLoaded(true), 200);
        setIsLoaded(true);


        return () => {
            setComponentMounted(false);
            setIsLoaded(false)
        }
        //after 5 seconds, load component anyway
        /*
        setTimeout(() => {
            console.log("Timeout Reached. Setting isLoaded to true")
            setIsLoaded(true)

        }, 5000);
        */
    }, []);

    useEffect(() => {
        if(isReady) {
            console.log("Welcome authstate: ", authState)
            if (stateSteady && isReady && !authState.matches('authenticated')) {
               sessionStorage.removeItem("welcomeReturnBreadCrumb");
               navigate('/login');
               setClearModals(true);
            }
        }
        if(authState.matches('authenticated') && isReady && stateSteady) {
            console.log("authenticated and Ready")
	        console.log(authState.context.emailAddress)
        }
        if(isReady && authState.context.emailAddress) {
	    //body = "<span>Mirkat includes free dark web monitoring for any stolen passwords associated with <b>" + authState.context.emailAddress + "</b>.\n\nCriminals use stolen passwords to hijack accounts.\n\nYou’ll be notified when you’re at risk.</span>"
	    body1 = "Mirkat includes free dark web monitoring for any stolen passwords associated with "
            body2 = "\n\nCriminals use stolen passwords to hijack accounts.\n\nYou’ll be notified when you’re at risk."
            email = authState.context.emailAddress
        }

        //set isNweUser back to false


        //setShowUser(storage.getEmail());
    }, [ isReady, isAuthenticated, authState, stateSteady ]);

    useEffect(() => {
        if(authState.matches('authenticated') && isReady && stateSteady) {
            fetch('initFeed');
        }
    }, [ isReady, isAuthenticated, authState ]);

    const sendWelcome = () => {
        storage.setPaidReplayBreadCrumb("false");
        sessionStorage.removeItem("welcomeReturnBreadCrumb");
        fetch2('sendWelcomeEmail');
    };

    
    if (isReady && authState.context.emailAddress) {
	    body1 = "Mirkat includes free dark web monitoring for any stolen passwords associated with "
            body2 = "\n\nCriminals use stolen passwords to hijack accounts.\n\nYou’ll be notified when you’re at risk."
            email = authState.context.emailAddress
    } else {
	body = "Mirkat includes free dark web monitoring for any stolen passwords associated with TBD@TBD.COM.\n\nCriminals use stolen passwords to hijack accounts.\n\nYou’ll be notified when you’re at risk."
    }


    return (
        <Fade in={isLoaded} timeout={500}>
	<div className="welcome-page" style={dynamic}>
        {plus === true ?
	    <div className="welcome-page-main" style={dynamic1}>
	    	<div className="welcome-page-welcome" style={dynamic1}>
	    	    <div className="welcome-page-welcome-inner">
	    		<div className="welcome-page-welcome-inner-icon-container">
	    		    <img src={Mirkat} width="120" height="120" alt="Mirkat Logo" className="welcome-page-inner-icon"></img>
	    		</div> 
	    		    <div className="welcome-page-welcome-inner-header">
	    		        <div className="welcome-page-welcome-inner-header-row">
	    		    	<div className="welcome-page-welcome-inner-header-text">Welcome to</div>
                        {isWindows ?
                            <div className="welcome-page-welcome-inner-header-row-tag" style={{padding: "5px 14px 5px 14px"}}>Mirkat+</div>
                        : isMac ?
                            <div className="welcome-page-welcome-inner-header-row-tag" style={{padding: "6.5px 14px 3.5px 14px"}}>Mirkat+</div>
                          :
                            <div className="welcome-page-welcome-inner-header-row-tag" >Mirkat+</div>
                        }
	    		        </div> 
	    		    </div>
                        <div className="welcome-page-welcome-inner-body">{body}</div>
	    		<Link to="/plus" className="welcome-page-welcome-inner-button" onClick={sendWelcome}>Continue</Link>
	    	    </div>
	    	</div>
	    </div> : null }
        {plus === false ? 
            <div className="welcome-page-main" style={dynamic1}>
                <div className="welcome-page-welcome" style={dynamic1}>
                    <div className="welcome-page-welcome-inner">
                        <div className="welcome-page-welcome-inner-icon-container">
                            <img src={Mirkat} width="120" height="120" alt="Mirkat Logo" className="welcome-page-inner-icon"></img>
                        </div>
	    	            <div className="welcome-page-welcome-inner-header">
	    		        <div className="welcome-page-welcome-inner-header-text">Welcome to Mirkat!</div>
	    		    </div>
	    		    <div className="welcome-page-welcome-inner-body"><span>{body1}<b>{authState.context.emailAddress}</b>{body2}</span></div>
	    		<Link to="/upgrade" className="welcome-page-welcome-inner-button" onClick={onClick}>Continue</Link>
                    </div>
                </div>
            </div> : null }
	    </div>
        </Fade>
    );
}

export default WelcomePage;



const storage = window.localStorage;
//This works in tandem with loadAnalytics.js. Ensure loadAnalytics is run on App mount in App.jsx
export const track = (event, properties) => {
    if (window.analytics && typeof window.analytics.track === 'function') {
        window.analytics && window.analytics.track(event, properties);
    }
};

export const page = (name, properties) => {
    if (window.analytics && typeof window.analytics.page === 'function') {
        window.analytics && window.analytics.page(name, properties);
    }
};

export const identify = (userId, properties) => {
    const existingTraits = JSON.parse(localStorage.getItem('userTraits')) || {};
    const updatedTraits = { ...existingTraits, ...properties };
    if (window.analytics && typeof window.analytics.identify === 'function') {
        window.analytics && window.analytics.identify(userId, updatedTraits);
    }
};

export const screen = (name, properties) => {
    if (window.analytics && typeof window.analytics.screen === 'function') {
        if(!storage?.getItem('analytics_session_id')) {
            setTimeout(() => {
                const a_s_id = parseInt(storage?.getItem('analytics_session_id'))||Date.now();
                window.analytics && window.analytics.screen(name, properties, {integrations: {"Actions Amplitude": {"session_id": a_s_id}}});
            }, 500)
        } else {
            const a_s_id = parseInt(storage?.getItem('analytics_session_id'))||Date.now();
            window.analytics && window.analytics.screen(name, properties, {integrations: {"Actions Amplitude": {"session_id": a_s_id}}});
        }

    }
};

export const clearUserId = () => {
    console.log('clearing user id')
    console.log(window.analytics)
    if (window.analytics && typeof window.analytics.user === 'function') {
        console.log("clear user id")
        //window.analytics && window.analytics.user().id(); //clears userId
        window.analytics && window.analytics.reset(); //clears userId
        localStorage.removeItem('ajs_user_id');

    }
};

export const clearUserTraits = () => {
    if (window.analytics && typeof window.analytics.user === 'function') {
        window.analytics && window.analytics.user().traits({}); //clears userId
        localStorage.removeItem('ajs_user_traits');
    }
};

export const clearUserAnonymousId = () => {
    if (window.analytics && typeof window.analytics.user === 'function') {
        window.analytics && window.analytics.user().anonymousId();
        window.analytics.reset()
        //localStorage.removeItem('ajs_anonymous_id');
    }
};


export const anonUserID = () => {
    if (window.analytics && window.analytics.user && typeof window.analytics.user === 'function' &&
        window.analytics.user().anonymousId && typeof window.analytics.user().anonymousId === 'function') {

       return window.analytics.user()?.anonymousId()
    }
    return null;
};

export const setAnonUserID = (id) => {
    if (window.analytics && window.analytics.user && typeof window.analytics.user === 'function' &&
        window.analytics.setAnonymousId && typeof window.analytics.setAnonymousId === 'function') {

        window.analytics.setAnonymousId(id);
        return true;
    }
    return false;
};


export const initializeAnonymousUser = () => {
    console.log(9)
    if (window.analytics && typeof window.analytics.identify === 'function' && typeof window.analytics.user === 'function') {
        console.log(99)
        const anonymousId = JSON.parse(localStorage.getItem('ajs_anonymous_id'));
        if (!anonymousId) {
            // Generate a new anonymous ID
            window?.analytics.identify();
            // Store the anonymous ID
           // localStorage.setItem('ajs_anonymous_id', window?.analytics.user().anonymousId());
        } else {
            // Identify with the existing anonymous ID
            window?.analytics.identify(null, {}, { anonymousId });
        }
    }
};

export const updateUserTraits = (userId, newTraits) => {
    if (window.analytics && typeof window.analytics.identify === 'function') {
        const existingTraits = JSON.parse(localStorage.getItem('ajs_user_traits')) || {};
        const updatedTraits = {...existingTraits, ...newTraits};
        //ensure double quotes don't get returned
        const anonymousId = JSON.parse(localStorage.getItem('ajs_anonymous_id'));
        const ajs_user_id = localStorage.getItem('ajs_user_id');
        let id = anonymousId;
        if(ajs_user_id) {
            id = ajs_user_id;
        }
        if(userId) {
            window.analytics.identify(userId, updatedTraits, {});
        } else {
            window.analytics.identify(id, updatedTraits, {});
        }

       // localStorage.setItem('ajs_user_traits', updatedTraits);
    }
};


export const aliasAndIdentifyUser = (newUserId, traits) => {
    if (window.analytics && typeof window.analytics.identify) {

        //ajs_user_id
        const anonymousId = JSON.parse(localStorage.getItem('ajs_anonymous_id'));

        if (anonymousId) {
            // Alias the anonymous ID to the new user ID
            window?.analytics.alias(newUserId, anonymousId, {
            });
            //only call identify if more traits are passed?
            if(traits) {
                // Identify the user with the new user ID
                const existingTraits = JSON.parse(localStorage.getItem('ajs_user_traits')) || {};
                const updatedTraits = {...existingTraits, ...traits};
                window?.analytics.identify(newUserId, updatedTraits, {});
            }

            // Store the user ID and traits
           // localStorage.setItem('ajs_user_id', newUserId);
            //localStorage.setItem('ajs_user_traits', updatedTraits);
        }
    }
};

export function accountCreatedTrack(userId, emailAddress, adCampaign, cohort) {
    //cohort
    /*
    multiple strings:
    premium|price499
    premium|price999
    trialWithoutPayment
     */
    identify(userId, {
        email:emailAddress,
        adCampaignId: adCampaign,
        createdAt: new Date().toISOString(),
      //  cohort: cohort
    })
}







//clear client side cookie methods
//analytics.user().id('') //clears userId
//analytics.user().anonymousId('');
//analytics.user().traits({});
//analytics.group().id('');
//analytics.group().traits({})

//need context -> set userId if available,
//app: build, name, version, hash?


//identify:
/*
 Account created {
  createdAt: "iso8601"
  email: "primary email address"
  provider: ""
  }
*/

import {useState, useEffect} from  'react';
import {useAuth} from "../contexts/authContext";
import {useFetch} from "./useFetch";



const useStripe = () => {
    //Back button from checkout logs out...
    const {authState} = useAuth()
    const [stripePaymentLink, setStripePaymentLink] = useState(); //process.env.REACT_APP_STRIPE_PAYMENT_LINK
    const [paymentOverride, setPaymentOverride] = useState(process.env.REACT_APP_STRIPE_OVERRIDE)
    const { state, fetch, isLoading, uiError, ready, reset} = useFetch()
    const { state: state2, fetch: fetch2, ready: ready2, isLoading: isLoading2, uiErrorMsg: uiErrorMsg2, reset: reset2} = useFetch() // getting status and exp times
    const { state: state3, fetch: fetch3, ready: ready3, isLoading: isLoading3, uiErrorMsg: uiErrorMsg3, reset: reset3} = useFetch() // getting status and exp times



    const [stripePortalSession, setStripePortalSession] = useState(false);
    const [portalSessionLink, setPortalSessionLink] = useState("");

    const [doUpgrade, setDoUpgrade] = useState(false);

    const [requestPortalSession, setRequestPortalSession] = useState(false);

    useEffect(() => {

        if(ready && doUpgrade && state.matches('idle')) {
            fetch('webPurchase')
        }
        if(state.matches('success')) {
            //do we want to save customerId?

            if(state.context.data?.success) {
                /*
                    return code 201
                    {
                        "success": 1,
                        "customerId": "some hash",
                        "portalUrl": "this is a link for them to access their stripe portal, has a 5 minute timeout",
                        "checkoutUrl": "this is a link for them to fill in their credit card information and actually complete the purchase, this has a 24 hour timeout I think"
                    }
                 */
                console.log("CustomerId: ", state.context.data?.customerId)
                console.log("Payment override: ", paymentOverride)
                if(paymentOverride) {
                    const payLink = paymentOverride + "?prefilled_email=" + encodeURIComponent(authState.context.emailAddress)
                    console.log("Paylink: ", payLink)
                    setStripePaymentLink(payLink)
                } else {
                    setStripePaymentLink(state.context.data?.checkoutUrl)
                }
                setDoUpgrade(false)
            }

            setDoUpgrade(false)
            reset()
        }

        if(state.matches('failure')) {
            //Todo -> error modal?

        }


        //Portal session to manage subscription
        if(ready2 && requestPortalSession && state2.matches('idle')) {
            fetch2('createPortalSession')
            console.log("fetch portal session")
        }

        if(state2.matches('success')) {
            //portal url
            //state.context.data?.portalUrl
            console.log("Portalsession")
            setPortalSessionLink(state2.context.data?.portalUrl)
            setRequestPortalSession(false)

            reset2()
        }

        if(state2.matches('failure')) {
            //no active subscription or customer
            //Error modal?
            setStripePortalSession(false)
            setRequestPortalSession(false)
            //reset2() - will cause dos loop here
        }

        console.log("Portal state: ", state2)
    }, [authState.context.emailAddress, authState.context.userId, state, ready, stripePaymentLink,  stripePortalSession, state2, ready2, doUpgrade, requestPortalSession]);

    const handlePaymentClick = () => {
        console.log("CLICK")
        //issue fetch...
        setDoUpgrade(true)
        window.location.href = stripePaymentLink;
    };

    const handlePortalSessionClick = () => {
        console.log("CLICK")
        //issue fetch...
        setRequestPortalSession(true)
        window.location.href = portalSessionLink;
    }


    return {setDoUpgrade, doUpgrade, stripePaymentLink, state2, reset, setRequestPortalSession, requestPortalSession, portalSessionLink, state}

}

export default useStripe;

//need stripe cancel



import React, {useEffect, useState} from "react";

import "../styles/scss/MainNavigation.scss";
import { useAuth } from "../contexts/authContext";
import KebabMenu from "./KebabMenu2";
import {useLocation, Link} from "react-router-dom";

const MainNavigation = () => {
    const { isAuthenticated, authState, stateSteady}  = useAuth();
    const location = useLocation();
    const [showSignup, setShowSignup] = useState(false);
    const [showUser, setShowUser] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isVerifyLocation, setIsVerifyLocation] = useState(false);
    const menuLogo = "https://img.mirkat.com/web/images/menu/headerLogo.png";
    const wordMark = "https://img.mirkat.com/web/images/menu/wordmark.svg";

    //if on /login -> menu is Sign up
    //else Sign In
    //if not isAuthenticated

    useEffect(() => {
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (location.pathname === "/login" || location.pathname === "/") {
                setShowSignup(false);
        }
        if (location.pathname === "/signup"){
            setShowSignup(true);
        }
        if (location.pathname.includes('/verify') || location.pathname.includes('/verifysec')) {
            setIsVerifyLocation(true);
        }
        console.log("location rerenders in main nav")
    }, [location]);

    useEffect(() => {
        if(authState.context.emailAddress && !authState.context.progressWait) {
            setShowUser(authState.context.emailAddress);
        }
    }, [authState, authState.context.progressWait]);

    return (
        isLoading ? (<></>) : (
        <header className="header">
            <div className="header-container">
                <div className="inner-container">
                    <div className="logo-container">

                        <div className="group-12549">
                            <Link to="/">
                                <img src={menuLogo} alt="menu logo" className="menu-logo"></img>
                            </Link>
                        </div>
                        <div className="header-row-container"></div>
                    </div>
                    <div className="header-link-container"></div>
                    <div className="account-container">
                        <div className="account-inner-container">
                            <div className="account-label-container">
                                {stateSteady && ((!isAuthenticated || isVerifyLocation) || authState.context.progressWait) && !showSignup &&
                                    <div className="account-label-text"><Link className="link-style-override"
                                                                              to="/signup">Sign
                                        Up</Link></div>}
                                {stateSteady && ((!isAuthenticated ||  isVerifyLocation) || authState.context.progressWait) && showSignup &&
                                    <div className="account-label-text"><Link className="link-style-override"
                                                                              to="/login">Log
                                        In</Link></div>}
                                {stateSteady && isAuthenticated && !authState.context.progressWait && !isVerifyLocation &&
                                    <div className="account-label-user">{showUser}</div>}
                            </div>
                            {stateSteady && isAuthenticated && !authState.context.progressWait && !isVerifyLocation && <KebabMenu/>}
                        </div>
                    </div>

                </div>
                <div className="divider"></div>
            </div>
        </header>
        )
    )
}

export default MainNavigation;

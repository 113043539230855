import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import "../styles/scss/Upgrade.scss";
import UpgradeExit from "../svg/UpgradeExit";
import WhiteCaret from "../svg/WhiteCaret";
import useStripe from "../hooks/useStripe";
import {useFetch} from "../hooks/useFetch";
import Slide from '@mui/material/Slide';
import {track, screen} from "../util/analytics";
import {useAuth} from "../contexts/authContext";
import AuthStorage from "../services/authStorage";
import useClearModals from "../hooks/useClearModals";
import GenericErrorModal from "../modals/GenericErrorModal";
import ProgressBar from "./ProgressBar";
import useProgressBar from "../hooks/useProgressBar";
import Fade from '@mui/material/Fade';
import useIsWindows from "../hooks/useIsWindows";

const storage = new AuthStorage();

const Upgrade = ({ page, changeState, parentState, setIsPlus, hasHadTrial, props }) => {
    console.log("value is: ", page);
    const navigate = useNavigate();
    const {authState, stateSteady, authService} = useAuth()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    //const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    //const backgrounded = {background: page ? (windowWidth >= 650 ? '#231C3F' : 'transparent') : 'transparent'}
    const backgrounded = {background: page ? (windowWidth > 839 ? '#231C3F' : 'transparent') : 'transparent', gap: page ? (windowWidth > 839 ? '55px' : null) : null}
    //const backgroundTwo = {background: page ? (windowWidth >= 650 ? 'rgba(255, 255, 255, 0.06)' : 'transparent') : 'transparent'}
    const backgroundTwo = {background: page ? (windowWidth > 839 ? 'rgba(255, 255, 255, 0.06)' : 'transparent') : 'transparent', padding: page ? (windowWidth > 839 ? '35px 20px 20px' : '0px 20px 20px 20px') : '0px 20px 20px 20px'}
    //const dynamic = {height: windowWidth > 650 ? "100vh" : (otherWindowHeight > windowHeight ? "100%" : "100%") }
    const [clearModals, setClearModals] = useState(false);
    const { progressStarted, apiStarted, isComplete, startProgress, handleCompletion, setNoDelay, noDelay, setLastApiCallStarted, setProgressOverride, progressOverride, resetProgressBar} = useProgressBar();
    const [transitionClose, setTransitionClose] = useState(true);
    const [isWindows, isMac] = useIsWindows();
    const [durationy, setDurationy] = useState(false);

    const exposedc = "https://img.mirkat.com/web/images/home/exposedc.svg";
    const accountc = "https://img.mirkat.com/web/images/home/accountd.svg";
    const privatec = "https://img.mirkat.com/web/images/home/privated.svg";
    const identityc = "https://img.mirkat.com/web/images/home/identityd.svg";
    const creditc = "https://img.mirkat.com/web/images/home/creditd.svg";
    const bankingc = "https://img.mirkat.com/web/images/home/bankingd.svg";
    const checkMark = "https://img.mirkat.com/web/images/home/upgrade-checkmark.svg";


    useClearModals(clearModals);



    const {handlePaymentClick, isError, setDoUpgrade, state2, stripePaymentLink, resume, doUpgrade, reset: reset2, ready2, state: stripeState } = useStripe();
    const { state: state3, fetch: fetch3, ready: ready3 } = useFetch()
    const [genericErrorModal, setGenericErrorModal] = useState(false)
    const location = useLocation();
    const [isYearly, setIsYearly] = useState(false)

	useEffect(() => {

		if (state2.matches('success')) {
			//setProgressOverride(100)
			console.log("do we get here?")
			setIsPlus(true)
			reset2()
		}


		if (stripePaymentLink) {
			//drop upgrade crumb
			if (location.pathname.startsWith("/upgrade")) {
				//upgrade page vs upgrade card
				//onboarding breadcrumb
				storage.setOnboardingBreadCrumb('t') //true
			}
			window.location.href = stripePaymentLink;
		}

	}, [doUpgrade, stripePaymentLink, state2, ready2, setIsPlus]);

        useEffect(() => {
            if(stateSteady && !authState.matches('authenticated')) {
                navigate("/login")
                setClearModals(true);
            }
            if(stateSteady && authState.matches('authenticated')) {
                if (/yearly/.test(authState.context.duration)) {
                    setDurationy('year')
                    setIsYearly(true);
                } else if (/monthly/.test(authState.context.duration)) {
                    setDurationy('month')
                }
            }
        }, [authState, stateSteady]);

		useEffect(() => {
			if(stripeState && stripeState.matches('failure') && doUpgrade && !genericErrorModal) {
				setDoUpgrade(false)
				setProgressOverride(100)
				setGenericErrorModal(true)
			}

			if(doUpgrade && stripeState && stripeState.matches('failure') && genericErrorModal) {
				setGenericErrorModal(false)
				reset2()
			}

		}, [stripeState, genericErrorModal, doUpgrade])
/*
		useEffect(() => {
			if(stripeUpgrade && state2 && (stripeUpgrade.matches('failure') || state2.matches('failure')) && !genericErrorModal) {
				setGenericErrorModal(true)
				console.log("ARE WE HERE?!!!!")
			}
			console.log("Upgrade state2: ", state2)
		}, [stripeUpgrade, state2, genericErrorModal])
*/


	const payment = () => {
		console.log("payment")
		//#33?
		if(page) {
			track('Button Tapped', {buttonType: 'upgrade', screenType: 'signUp',
				screenSubType: 'plan',
				amount: authState.context.price,
				currency: 'usd',
				frequency: authState.context.duration,
			})

		} else {
			track('Button Tapped', {buttonType: 'upgrade', screenType: 'upgrade',
				amount: authState.context.price,
				currency: 'usd',
				frequency: authState.context.duration,
			})
                        storage.setEntryType('f')
		}

		if (location.pathname.startsWith("/upgrade")) {
			storage.setEntryType('ob') // will be signUp
		}
                storage.setBreadCrumb('f')
		startProgress()
		setLastApiCallStarted(true)
		reset2()
	        setDoUpgrade(true);
	}

	const maybeLater = () => {
		storage.removeBreadCrumb()
		if(!page) {
			//#40
			track('Button Tapped', {
				buttonType: 'dismiss',
				screenType: 'menu',
				amount: authState.context.price,
				currency: 'usd',
				frequency: authState.context.duration,
			})
		} else {
			//#40
			track('Button Tapped', {
				buttonType: 'dismiss',
				screenType: 'signUp',
				screenSubType: 'upgrade',
				amount: authState.context.price,
				currency: 'usd',
				frequency: authState.context.duration,
			})
		}
		fetch3('sendWelcomeEmail');
	}

	const onClickClose = () => {
		storage.removeBreadCrumb()
		track('Button Tapped', {buttonType: 'close', 'screenType': page ? 'signUp' : 'upgrade', screenSubType: 'plan'})
                changeState(!parentState)

	}
        const delayedClose = () => {
            storage.removeBreadCrumb()
            setTransitionClose(false);
            track('Button Tapped', {buttonType: 'close', 'screenType': page ? 'signUp' : 'upgrade', screenSubType: 'plan'})
            setTimeout(() => {changeState(!parentState)}, 500)
        };
				//<div className="upgrade-page-card-top-header-row">
				//    <div className="upgrade-page-card-top-header-row-text-two">
				//    </div>
				//</div>
                                //<div className="bbar-items-row" style={{ maxWidth: "650px", justifyContent: "unset", flexWrap: "wrap" }}>
			    //<div className="upgrade-page-why-item">
			    //    <img src={checkMark} className="upgrade-page-why-item-icon"></img>
			    //    <div className="upgrade-page-why-item-content">
			    //        Monitor multiple email addresses
			    //        <p className="upgrade-page-why-item-content-two">Find more threats associated with an unlimited number of your email addresses.</p>
			    //    </div>
			    //</div>
    //useEffect(() => {
    //    const handleResize = () => {
    //        setWindowHeight(window.innerHeight);
    //    };
    //    window.addEventListener('resize', handleResize);
    //    // Cleanup the event listener when the component unmounts
    //    return () => {
    //        window.removeEventListener('resize', handleResize);
    //    };
    //}, []);

    useEffect(() => {
		console.log("PAGE: ", page)
		if(page) {
			//#85
			//screen('Upgrade', {screenType: 'upgrade', entryType: 'card'})
			//#14
			screen('Sign-up: Plan', {screenType: 'signUp', screenSubType: 'plan', variantType: 'upgrade'})
		}
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const sendWelcome = () => {
    };
      //<Fade in={true} timeout={300}>
    return (
		<>{progressStarted && <ProgressBar progressOverride={progressOverride} apiStarted={apiStarted} onCompleted={handleCompletion} setProgressOverride={setProgressOverride} noDelay={noDelay} />}
			{genericErrorModal && <GenericErrorModal props={true} dim={true} callback={()=>{setGenericErrorModal(false)}} screenType={page ? 'signup' : 'upgrade'} screenSubType='plan'/>}
    {stateSteady ? <>
    {windowWidth < 840 &&
        <Slide direction="up" in={transitionClose}>
	<div className="upgrade-page-main-container">
		<div className="upgrade-page-upgrade-container" style={backgrounded}>
		    <div className="upgrade-page-card" style={backgroundTwo}>
			<div className="upgrade-page-card-top" style={{padding: "unset", paddingBottom: "25px"}}>
                            <>{page ?
			    <div className="upgrade-page-card-top-header">
				<div className="upgrade-page-card-top-header-row" style={{ padding:  "12px 0px 18px 0px", alignItems: "center" }}>
                                        {isWindows ?
                                            <div className="upgrade-page-card-top-header-row-tag" style={{padding: "4px 14px 6px 14px"}}>Mirkat+</div>
                                        : isMac ?
                                            <div className="upgrade-page-card-top-header-row-tag" style={{padding: "6.5px 14px 3.5px 14px"}}>Mirkat+</div>
                                          :
                                            <div className="upgrade-page-card-top-header-row-tag" >Mirkat+</div>
                                        }
                                </div>
				<div className="upgrade-page-card-top-header-row">
	    			    {page === true ? <Link to="/scanning" className="upgrade-page-exit-icon" ><UpgradeExit /></Link> : null}
	    			    {page !== true ? <div className="upgrade-exit-icon" onClick={delayedClose}><UpgradeExit /></div> : null}
	    			    {page !== true ? <div className="upgrade-caret" onClick={delayedClose}><WhiteCaret /></div> : null}
				    <div className="upgrade-page-card-top-header-row-text-one" style={{fontSize: "27px", lineHeight: "35px", paddingTop: "unset"}}>
					Get comprehensive dark web monitoring
				    </div>
				</div>
                            </div>
                                 :
			    <div className="upgrade-page-card-top-header">
                                <div className="upgrade-page-card-top-header-row-mobile" style={{ padding:  "12px 0px 18px 0px" }}>
                                        {isWindows ?
                                            <div className="upgrade-page-card-top-header-row-tag" style={{padding: "4px 14px 6px 14px"}}>Mirkat+</div>
                                        : isMac ?
                                            <div className="upgrade-page-card-top-header-row-tag" style={{padding: "6.5px 14px 3.5px 14px"}}>Mirkat+</div>
                                          :
                                            <div className="upgrade-page-card-top-header-row-tag" >Mirkat+</div>
                                        }
                                    </div>
				<div className="upgrade-page-card-top-header-row">
	    			    {page === true ? <Link to="/scanning" className="upgrade-page-exit-icon" ><UpgradeExit /></Link> : null}
	    			    {page !== true ? <div className="upgrade-exit-icon" onClick={delayedClose}><UpgradeExit /></div> : null}
	    			    {page !== true ? <div className="upgrade-caret" onClick={delayedClose}><WhiteCaret /></div> : null}
				    <div className="upgrade-page-card-top-header-row-text-one" style={{fontSize: "27px", lineHeight: "35px", paddingTop: "unset"}}>
					Get comprehensive dark web monitoring
				    </div>
				</div>
                            </div>}</>
			    <div className="upgrade-page-card-top-text">
			        Be alerted when any of your sensitive information is found on the dark web.
			    </div>
			</div>
                        <div className="bbar-items-container" style={{ padding: "unset" }}>
                                <div className="upgrade-items-row">
                                    <div className="upgrade-item-container">
                                        <img src={exposedc} className="upgrade-item" />
                                        <div className="upgrade-item-header">Exposed Passwords</div>
                                    </div>
                                    <div className="upgrade-item-container">
                                        <img src={accountc} className="upgrade-item" />
                                        <div className="upgrade-item-header">Account Credentials</div>
                                    </div>
                                    <div className="upgrade-item-container">
                                        <img src={privatec} className="upgrade-item" />
                                        <div className="upgrade-item-header">Private Accounts</div>
                                    </div>
                                    <div className="upgrade-item-container">
                                        <img src={creditc} className="upgrade-item" />
                                        <div className="upgrade-item-header">Credit & Debit Cards</div>
                                    </div>
                                    <div className="upgrade-item-container">
                                        <img src={bankingc} className="upgrade-item" />
                                        <div className="upgrade-item-header">Banking Accounts</div>
                                    </div>
                                    <div className="upgrade-item-container">
                                        <img src={identityc} className="upgrade-item" />
                                        <div className="upgrade-item-header">Identity<br /> Theft</div>
                                    </div>
                                </div>
                        </div>
			<div className="upgrade-page-card-button-container">
			    <div className="upgrade-page-card-button-inner-container">
				<div className="upgrade-page-card-buttons">
				    <Link className="upgrade-page-card-button-one" onClick={payment}>
				        {!hasHadTrial ? `Try Mirkat+ free for 1 month` : `Upgrade to Mirkat+`}
				    </Link>
			            {isYearly ? <div className="upgrade-page-tagline">{!hasHadTrial ? `Then $${authState.context.price} per ${durationy}.` :  `$${authState.context.price} per ${durationy}.`}</div>
                                            :
			            <div className="upgrade-page-tagline">{!hasHadTrial ? `Then $${authState.context.price} per ${durationy}. Cancel anytime.` :  `$${authState.context.price} per ${durationy}. Cancel anytime.`}</div>}
					{page &&
				            <div className="upgrade-page-card-button-two">
				                <Link to="/scanning" className="welcome-link" onClick={maybeLater}>
				                    Maybe later
				                </Link>
				            </div>
                                        }
				</div>
			    </div>
			</div>
		    </div>
		    <div className="upgrade-page-why">
			<div className="upgrade-page-why-header">Why Mirkat+</div>
			<div className="upgrade-page-why-items-container">
			    <div className="upgrade-page-why-item">
				<img src={checkMark} className="upgrade-page-why-item-icon"></img>
				<div className="upgrade-page-why-item-content">
				    Know which accounts are exposed
				    <p className="upgrade-page-why-item-content-two">Get notified when the credentials to your most used and valuable accounts are for sale on the dark web.</p>
				</div>
			    </div>
			    <div className="upgrade-page-why-item">
				<img src={checkMark} className="upgrade-page-why-item-icon"></img>
				<div className="upgrade-page-why-item-content">
				    Fend off fraudulent purchases and cash withdrawals
				    <p className="upgrade-page-why-item-content-two">Be alerted when your credit card number or bank account details are for sale on the dark web.</p>
				</div>
			    </div>
			    <div className="upgrade-page-why-item">
				<img src={checkMark} className="upgrade-page-why-item-icon"></img>
				<div className="upgrade-page-why-item-content">
				    Don’t be a victim of identity theft
				    <p className="upgrade-page-why-item-content-two">Find out if your driver’s license, SSN, name, address, phone number are exposed. Stop loans or credit cards from being opened in your name.</p>
				</div>
			    </div>
			    <div className="upgrade-page-why-item">
				<img src={checkMark} className="upgrade-page-why-item-icon"></img>
				<div className="upgrade-page-why-item-content">
				    Your exposed private accounts
				    <p className="upgrade-page-why-item-content-two">Learn when your private accounts are public and any information is exposed, including dating and adult.</p>
				</div>
			    </div>
			</div>
		    </div>
	    </div>
	</div>
    </Slide>}
    {windowWidth > 840 &&
	<div className="upgrade-page-main-container" style={{boxShadow: page !== true ? ('0px 0px 30px 0px #272041') : 'null'}}>
		<div className="upgrade-page-upgrade-container" style={backgrounded}>
		    <div className="upgrade-page-card" style={backgroundTwo}>
			<div className="upgrade-page-card-top">
                            <>{page ?
			    <div className="upgrade-page-card-top-header">
                                    <div className="upgrade-page-card-top-header-row">
				        <div className="upgrade-page-card-top-header-row-text-one">
				    	Get comprehensive dark web
				        </div>
				    </div>
				    <div className="upgrade-page-card-top-header-row">
				        <div className="upgrade-page-card-top-header-row-text-two">
				    	    monitoring with
                                        {isWindows ?
                                            <div className="upgrade-page-card-top-header-row-tag" style={{fontSize: "28px", padding: "2px 14px 5px 14px", marginLeft: "8px"}}>Mirkat+</div>
                                        : isMac ?
                                            <div className="upgrade-page-card-top-header-row-tag" style={{fontSize: "28px", padding: "5.5px 14px 1.5px 14px", marginLeft: "8px"}}>Mirkat+</div>
                                          :
                                            <div className="upgrade-page-card-top-header-row-tag" style={{fontSize: "28px", padding: "6px 14px 1px 14px", marginLeft: "8px"}}> >Mirkat+</div>
                                        }
                                        </div>
				    </div>
			    </div>
                            :
			    <div className="upgrade-page-card-top-header">
				<div className="upgrade-page-card-top-header-row" style={{ padding:  "22px 0px 18px 0px", alignItems: "center" }}>
                                        {isWindows ?
                                            <div className="upgrade-page-card-top-header-row-tag" style={{padding: "4px 14px 6px 14px"}}>Mirkat+</div>
                                        : isMac ?
                                            <div className="upgrade-page-card-top-header-row-tag" style={{padding: "6.5px 14px 3.5px 14px"}}>Mirkat+</div>
                                          :
                                            <div className="upgrade-page-card-top-header-row-tag" >Mirkat+</div>
                                        }
                                </div>
				<div className="upgrade-page-card-top-header-row">
	    			    {page === true ? <Link to="/scanning" className="upgrade-page-exit-icon" ><UpgradeExit /></Link> : null}
	    			    {page !== true ? <div className="upgrade-exit-icon" onClick={onClickClose}><UpgradeExit /></div> : null}
	    			    {page !== true ? <div className="upgrade-caret" onClick={onClickClose}><WhiteCaret /></div> : null}
				    <div className="upgrade-page-card-top-header-row-text-one" style={{fontSize: "27px", lineHeight: "35px", paddingTop: "unset"}}>
					Get comprehensive dark web monitoring
				    </div>
				</div>
                            </div>}</>
				<div className="upgrade-page-card-top-text">
				    Be alerted when any of your sensitive information is found on the dark web.
			    </div>
			</div>
                        <div className="bbar-items-container" style={{ padding: "unset" }}>
                                <div className="upgrade-items-row">
                                    <div className="upgrade-item-container">
                                        <img src={exposedc} className="upgrade-item" />
                                        <div className="upgrade-item-header">Exposed Passwords</div>
                                    </div>
                                    <div className="upgrade-item-container">
                                        <img src={accountc} className="upgrade-item" />
                                        <div className="upgrade-item-header">Account Credentials</div>
                                    </div>
                                    <div className="upgrade-item-container">
                                        <img src={privatec} className="upgrade-item" />
                                        <div className="upgrade-item-header">Private Accounts</div>
                                    </div>
                                    <div className="upgrade-item-container">
                                        <img src={creditc} className="upgrade-item" />
                                        <div className="upgrade-item-header">Credit & Debit Cards</div>
                                    </div>
                                    <div className="upgrade-item-container">
                                        <img src={bankingc} className="upgrade-item" />
                                        <div className="upgrade-item-header">Banking Accounts</div>
                                    </div>
                                    <div className="upgrade-item-container">
                                        <img src={identityc} className="upgrade-item" />
                                        <div className="upgrade-item-header">Identity<br /> Theft</div>
                                    </div>
                                </div>
                        </div>
			<div className="upgrade-page-card-button-container">
			    <div className="upgrade-page-card-button-inner-container">
				<div className="upgrade-page-card-buttons">
				    <Link className="upgrade-page-card-button-one" onClick={payment}>
				        {!hasHadTrial ? `Try Mirkat+ free for 1 month` : `Upgrade to Mirkat+`}
				    </Link>
			            {isYearly ? <div className="upgrade-page-tagline">{!hasHadTrial ? `Then $${authState.context.price} per ${durationy}.` :  `$${authState.context.price} per ${durationy}.`}</div>
                                            :
			            <div className="upgrade-page-tagline">{!hasHadTrial ? `Then $${authState.context.price} per ${durationy}. Cancel anytime.` :  `$${authState.context.price} per ${durationy}. Cancel anytime.`}</div>}
					{page &&
				            <div className="upgrade-page-card-button-two">
				                <Link to="/scanning" className="welcome-link" onClick={maybeLater}>
				                    Maybe later
				                </Link>
				            </div>
                                        }
				</div>
			    </div>
			</div>
		    </div>
		    <div className="upgrade-page-why">
			<div className="upgrade-page-why-header">Why Mirkat+</div>
			<div className="upgrade-page-why-items-container">
			    <div className="upgrade-page-why-item">
				<img src={checkMark} className="upgrade-page-why-item-icon"></img>
				<div className="upgrade-page-why-item-content">
				    Know which accounts are exposed
				    <p className="upgrade-page-why-item-content-two">Get notified when the credentials to your most used and valuable accounts are for sale on the dark web.</p>
				</div>
			    </div>
			    <div className="upgrade-page-why-item">
				<img src={checkMark} className="upgrade-page-why-item-icon"></img>
				<div className="upgrade-page-why-item-content">
				    Fend off fraudulent purchases and cash withdrawals
				    <p className="upgrade-page-why-item-content-two">Be alerted when your credit card number or bank account details are for sale on the dark web.</p>
				</div>
			    </div>
			    <div className="upgrade-page-why-item">
				<img src={checkMark} className="upgrade-page-why-item-icon"></img>
				<div className="upgrade-page-why-item-content">
				    Don’t be a victim of identity theft
				    <p className="upgrade-page-why-item-content-two">Find out if your driver’s license, SSN, name, address, phone number are exposed. Stop loans or credit cards from being opened in your name.</p>
				</div>
			    </div>
			    <div className="upgrade-page-why-item">
				<img src={checkMark} className="upgrade-page-why-item-icon"></img>
				<div className="upgrade-page-why-item-content">
				    Your exposed private accounts
				    <p className="upgrade-page-why-item-content-two">Learn when your private accounts are public and any information is exposed, including dating and adult.</p>
				</div>
			    </div>
			</div>
		    </div>
	    </div>
	</div>
        }</> : <></>}
		</>
    );
}

export default Upgrade;

import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { useAuth } from "../contexts/authContext";

import styles from "../styles/scss/DeepLink.module.scss";
import Fade from '@mui/material/Fade';
import useDetectNetwork from "../hooks/useDetectNetwork";

//If we'ere not in the right state, we'll get stuck with Loading button. need to check and redirect fix
const DeepLinkEmail = ({deviceTypeId, errorCode, isComplete}) => {
    const {authState, authService, stateSteady} = useAuth();
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const dynamic = {height: windowWidth > 500 ? "unset" : windowHeight}
    const [isLoaded, setIsLoaded] = useState(false);
    const [isIOS, setIsIOS] = useState(false);
    const [error, setError] = useState(errorCode ? true : false);

    const isOnline = useDetectNetwork();
    useEffect(() => {
        if(!isOnline){
            setError("No network connection")
        }
        if(isOnline && error === "No network connection") {
            setError("")
        }
    }, [isOnline])

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useEffect(() => {
        //    2 - IOS
        //     3 - windows 10
        //     4 - windows 10 s
        //     5 - windows 8
        //     6 - other

        //back to checking OS instead of initial sign-up
        //if(deviceTypeId === 2) {
        //    setIsIOS(true)
        //}

        //if we have an error code, we need to show an error message - in future we can switch on the actual errorCode
        if(errorCode) {
            //49 is code expired
            setError(true)
        }
        console.log("Device Type: ", deviceTypeId)
        if(deviceTypeId) {
            //if the documented deviceTypeId is true, we only need to look at deviceTypeId
            /*
            The deviceTypeId is where the request originated from

            2 - IOS
            3 - windows 10
            4 - windows 10 s
            5 - windows 8
            6 - other
            */
            //setIsIOS(/iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1));
            setIsIOS(deviceTypeId === 2)

        }
        console.log("Error: " + errorCode)
    }, [deviceTypeId, errorCode]);

    useEffect(() => {
        //Text flashing... add delay
        setTimeout(() => setIsLoaded(true), 250)
        return () => {
            setIsLoaded(false)
        }

    }, []);

    return (
        isLoaded && isComplete ? (
            <Fade in={isLoaded} timeout={500}>
            <div className={styles["main-container"]} style={dynamic}>
                <div className={styles["message-container"]}>
                    {isIOS && !error ? <>
                        <div className={styles["message-content"]}>
                            <div className={styles["message-header"]}>Email address confirmed</div>
                        </div>
                        <a href="mirkat://" className={styles["message-button"]}>Continue in the Mirkat App</a></>
                        : !error ? (
                        <div className={styles["message-content"]}>
                            <div className={styles["message-header"]}>Email address confirmed.</div>
                            <div className={styles["message-description"]}>Please continue in your browser.</div>
                        </div>
                        ) : (
                            <div className={styles["message-content"]}>
                                <div className={styles["message-header"]}>Your email confirmation link has expired</div>
                                <div className={styles["message-description"]}>Please request a new code.</div>
                            </div>
                        )
                    }
                </div>
            </div>
            </Fade>
        ) : (<></>)
    )
}

export default DeepLinkEmail;

import React from 'react'

const MobileThreatHistory = () => {
    return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.54317 5.91606C3.80316 2.4651 7.11613 0.00012207 11.0001 0.00012207C15.967 0.00012207 20 4.03308 20 9.00003C20 13.967 15.967 17.9999 11.0001 17.9999C7.67212 17.9999 4.76316 16.19 3.20618 13.501C2.93018 13.023 3.09317 12.411 3.57116 12.134C4.04816 11.858 4.66016 12.021 4.93716 12.499C6.14815 14.591 8.41111 16 11.0001 16C14.8631 16 18 12.863 18 9.00003C18 5.13607 14.8631 2.0001 11.0001 2.0001C7.78312 2.0001 5.07015 4.17408 4.25215 7.13205L6.00415 6.13206C6.48315 5.85807 7.09414 6.02406 7.36814 6.50406C7.64213 6.98305 7.47513 7.59405 6.99613 7.86805L3.49617 9.86803C3.25917 10.004 2.97718 10.036 2.71518 9.95802C2.45319 9.88002 2.23518 9.69903 2.11018 9.45603L0.110197 5.55307C-0.1418 5.06207 0.0532037 4.45908 0.544199 4.20708C1.03519 3.95508 1.63818 4.15008 1.89018 4.64107L2.54317 5.91606ZM12.0001 8.58603L14.7071 11.293C15.097 11.683 15.097 12.317 14.7071 12.707C14.3171 13.097 13.6831 13.097 13.2931 12.707L10.2931 9.70703C10.1051 9.51903 10.0001 9.26503 10.0001 9.00003V5.00007C10.0001 4.44808 10.4481 4.00008 11.0001 4.00008C11.5521 4.00008 12.0001 4.44808 12.0001 5.00007V8.58603Z" fill="#FFC300"/>
    </svg>
    )
}

export default MobileThreatHistory;

import React, {useEffect, useState} from "react";
import {useAuth} from "../contexts/authContext";

// This hook is used to fetch the billing status from the server.
const useBilling = () => {
    const { authState, isAuthenticated, client, isReady } = useAuth();
    const [isLoading, setIsIsLoading] = useState(true);
    const [billingItems, setBillingItems] = useState(null);

    useEffect(() => {
        if (isReady && isAuthenticated) {
            client.billing(authState.context.userId, authState.context.emailId).then((response) => {
                transformBillingResponse(response, setBillingItems, setIsIsLoading);
                setIsIsLoading(false);
            }).catch((error) => {
                console.log("there was an error: ", error);
            });
        }
    }, [isReady, isAuthenticated]);

    return {billingItems, isLoading};
}

export const transformBillingResponse = (response, setBillingItems, setIsIsLoading) => {
    // using safe navigation operator (?.)
    if (response) {
        setBillingItems(response);
    } else {
        setBillingItems([]);
    }
    setIsIsLoading(false);
};

export default useBilling;

export const BaseClientParams = {
    baseURL: process.env.REACT_APP_BASE_URL || "",
    headers: {
        "app-version": "1",
        "app-identifier": "1",
        "build": "522",
        "device-type": "windows 10",
        "is-browser": window.webkit === undefined ? "True" : "False",
    },
    language: "en",
}

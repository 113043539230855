import { useEffect, useState } from "react";


const useResizeWithScrollbar = () => {
    const [withScrollbarWidth, setWithScrollbarWidth] = useState(document.documentElement.clientWidth);


    //force-set it just once on page load, before resize, because the scrollbar came up
    useEffect(() => {
        setWithScrollbarWidth(document.documentElement.clientWidth);
    });
    
    useEffect(() => {
            const handleResize = () => {
                setWithScrollbarWidth(document.documentElement.clientWidth);
            };
    
            window.addEventListener('resize', handleResize);
    
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);
    
    return withScrollbarWidth;
    }
export default useResizeWithScrollbar;

import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useInView} from 'react-intersection-observer';
import {useStorage} from "../contexts/storageContext";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../contexts/authContext";
import {useFetch} from "../hooks/useFetch";
import {useModal} from "../contexts/ModalContext";
import Details from "./Details";
import '../styles/scss/Card.scss';
import useTrackImpression from './Seen';
import Modal from "@mui/material/Modal";
import useClearModals from "../hooks/useClearModals";
import {track} from "../util/analytics";
import useObserveVisibility from "../hooks/useObserveVisibility";
import {isHighDpi} from "./DetectHighDPI";
import ErrorModal from "../modals/ErrorModal";
import useWebKitDetector from "../hooks/useWebKitDetector";

const Card = ({ id, title, exposedDataTitle, description, bannerImageUrl, cardData, actions, feedCard, history, riskType, riskCategoryType, completedType, entityId, feedPosition, status, setRefreshNeeded, windowWidth, setShowErrorModal, isLastRisk, setLastCardDismissed, setSafeTransition, safeTransition }) => {
    const {modalCount, setModalCount} = useModal();
    const navigate = useNavigate();
    const [showDetails, setShowDetails] = useState(false);
    const {state, fetch, ready, reset} = useFetch();
    const {state: state2, fetch: fetch2, ready: ready2} = useFetch();
    const {isAuthenticated, authState, isReady} = useAuth();
    const [cardDismissed, setCardDismissed] = useState(false);
    const [delayedCardDismissed, setDelayedCardDismissed] = useState(false);
    const [isPasswordCard, setIsPasswordCard] = useState(false);
    const [isNew, setIsNew] = useState(false);
    //const MIN_TIME_IN_VIEW = 3000;  // Define a constant for minimum view time (3 seconds in this case).
    const MIN_TIME_IN_VIEW = 0;  // Define a constant for minimum view time (3 seconds in this case).
    //const options = { threshold: .5 }
    const options = {rootMargin: '20px'}
    const isFirstRender = useRef(true);
    const [clearModals, setClearModals] = useState(false);
    const decider = history ? "feedcard-history" : (!cardDismissed ? "feedcard" : (windowWidth > 839 ? "feedcard-moved" : (isLastRisk ? "feedcard" : "feedcard-moved")))
    const isWebKitBrowser = useWebKitDetector();
    const [isMobile, setIsMobile] = useState(false);

    const trackCallback = (cardId) => {
        console.log(`Card ${cardId} shown`);
        authState.context.seenIds.push(id);
        if (!feedCard?.seenAt) {
            fetch2('seen', [id]);
            console.log("FETCH2ing ID: ", id);
        }
        console.log("THE ARRAY IS: ", authState.context.seenIds);
    };


    const ref = useTrackImpression(MIN_TIME_IN_VIEW, trackCallback, id, options, feedCard?.seenAt, riskType, riskCategoryType, history, completedType, entityId, feedPosition, status);


    useEffect(() => {
        const userAgent = window.navigator.userAgent;

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setIsMobile(true);
        } else if (/android/i.test(userAgent)) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);

    useEffect(() => {
        //#99
        track('Card Loaded', {
            cardType: 'risk',
            cardId: id,
            riskType: riskType,
            riskCategoryType: riskCategoryType,
            feedType: history ? 'history' : 'feed',
            completedType: completedType,
            entityId: entityId,
            feedPosition: feedPosition, // integer
            status: status,
        });

    }, [id]);

    useClearModals(clearModals);

    useEffect(() => {
        if (feedCard.state === "new") {
            setIsNew(true)
        }
    });

    useEffect(() => {
        setIsPasswordCard(exposedDataTitle.startsWith('Your Exposed Password'));
    }, []);

    useEffect(() => {
        if (!authState.matches('authenticated')) {
            navigate('/login');
            setClearModals(true);
        }
    }, [isReady, isAuthenticated, state, authState, ready]);

    const handleClick = () => {
        //#131
        track('Card Button Tapped', {
            buttonType: 'action',
            cardId: id,
            cardType: 'risk',
            feedType: history ? 'history' : 'feed',
            riskType: riskType,
            entityId: entityId,
            feedPosition: feedPosition, // integer
            status: status,
        })
        setShowDetails(!showDetails)
    }

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        } else if (showDetails) {
            setModalCount(prevCount => prevCount + 1);
        } else {
            setModalCount(prevCount => prevCount - 1);
        }
    }, [showDetails]);

    useEffect(() => {
        console.log("STATUS: ", modalCount);
        if (modalCount > 0) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [modalCount]);


    const handleDismiss = () => {

        console.log("DISMISSI ID: ", id)
        fetch('dismiss', [id])

    }

    useEffect(() => {
        if (cardDismissed && !isLastRisk) {
            setTimeout(() => {
                setDelayedCardDismissed(true)
                setRefreshNeeded(true)
                reset()
            }, 300);
        } else if (cardDismissed && isLastRisk && windowWidth > 839 ) {
            setTimeout(() => {
                setDelayedCardDismissed(true)
                //setRefreshNeeded(true)
                setSafeTransition(true)
                reset()
            }, 300);
        }
    }, [cardDismissed]);

    useEffect(() => {
        if(state.matches('success')) {
            //dismissed
            //#131
            track('Card Button Tapped', {
                buttonType: 'dismiss',
                cardId: id,
                cardType: 'risk',
                feedType: 'feed',
                riskType: riskType,
                entityId: entityId,
                feedPosition: feedPosition, // integer
                status: status,
            })

            track('Risk Completed', {
                eventType: 'riskCompleted',
                feedType: 'feed',
                riskType: riskType,
                completedType: 'skipped',
                cardId: id,

            })
            if (isLastRisk) {
                setLastCardDismissed(true)
                setCardDismissed(true)
                //setRefreshNeeded(true)
            } else {
                setCardDismissed(true)
            }
        }

        if(state.matches('failure')) {
            setShowErrorModal(true)
            reset()

        }
    }, [state]);

    /*
    const [cardSeen, setCardSeen] = useState(false)
    const onBreachesVisible = useCallback(() => {
        if(feed && !cardSeen) {
            console.log(cardSeen)
            console.log('Card Seen Target card is now in view');

/*            track('Card Viewed', {
                cardType: 'dataBreaches',
                feedType: 'feed',
                feedSubType: feed?.feed?.existingRisks?.cards?.length > 0 ? 'risk' : 'safe', // or safe
                feedPosition: (feed.feed?.existingRisks?.cards?.length || 0) + 1, // integer
            });

 */
    //          setCardSeen(true)

    //    }
    /*
        }, [cardSeen]);

        const cardSeenRef = useObserveVisibility(onBreachesVisible, { threshold: 0.8 })

    */
    const getResizedImager = (bannerImageUrl, width) => {
        const parts = bannerImageUrl.split('/images/')
        if (parts.length > 1) {
            //we currently get it from the api.
            return  parts[0] + '/cdn-cgi/image/width=' + width + '/images/' + parts[1]
        } else {

            //const resizedImageUrl = `${src}?width=${width}&height=${height}`;
            //fallback
            console.warn("Could not resize image: ", bannerImageUrl)
            return bannerImageUrl
        }
    }
    let resizedImageUrl = getResizedImager(bannerImageUrl, 1000)
    if (isHighDpi()) {
        resizedImageUrl = getResizedImager(bannerImageUrl, 2000)
    }

    const checkPassword = (data) => data.startsWith('⦁') && data.endsWith('⦁');

    const analyzeString = (str) => {
        const hasBulletPoints = str.includes('⦁');
        const onlyBulletsAndDashes = /^[⦁-]+$/.test(str);
        const bulletsAndOtherChars = hasBulletPoints && /[^⦁-]/.test(str);

        let result = {
            bulletClass: null,
            dashClass: null,
            nonBulletClass: null,
            defaultClass: 'defaultText', // Set a default class
        };
        
        
        if (hasBulletPoints) {
            result.bulletClass = 'feedcard-value-heavy';
            if (onlyBulletsAndDashes) {
                result.dashClass = 'feedcard-value-dashes';
                result.defaultClass = null;
            } else if (bulletsAndOtherChars) {
                result.nonBulletClass = 'feedcard-value-char';
                result.bulletClass = 'feedcard-value-mixed';
                result.defaultClass = null;
            }
        }
        
        return result;
    };

    return (
	<>
        {!delayedCardDismissed && 
            <div className={decider} ref={ref} style={safeTransition ? {display: "none"} : null} >
	        <img src={resizedImageUrl} className="feedcard-image" loading="lazy" />
	        {isNew ? 
                        <div className="feedcard-status-container"><div className="feedcard-status" style={isMobile ? {padding: "5px 16px 3px 16px"} : {}}>NEW</div></div> 
                    : 
                        <div className="feedcard-spacer" />
                }
                <h3 className="feedcard-title">{title}</h3>
                <div className="feedcard-description-container"><p className="feedcard-description">{description}</p></div>
                <p className="feedcard-header">{exposedDataTitle}</p>
	        <div className="feedcard-display">
	        {cardData.map((cardData) => {
                        const { bulletClass, dashClass, nonBulletClass, defaultClass } = analyzeString(cardData?.value);

                        return (
	                <li key={cardData.id} className="feedcard-row">
	            	<div className="feedcard-label-container"><div className="feedcard-label">{cardData.label}</div></div>
	            	<div className="feedcard-value-container"><div className="feedcard-value">
                            {bulletClass ? <>
                                {(<div className="feedcard-value-row">
                                    {cardData?.value.split('').map((char, index) => {
                                        if (char === '⦁') {
                                            return <span className={bulletClass}>{char}</span>
                                        } else if (char === ' ') {
                                            return <span className={nonBulletClass}>&nbsp;</span>
                                        } else if (char === '-' && dashClass) {
                                            return <span className={isWebKitBrowser ? "feedcard-webkit-dashes" : dashClass}>{char}</span>
                                        } else {
                                            return <span className={nonBulletClass}>{char}</span>
                                        }
                                    })}
                                  </div>)}</> : (cardData?.value && <>{cardData?.value}<br /></>)}

                                {cardData?.subtitle && <div className="feedcard-lite">{cardData?.subtitle}<br /></div>}
                                {cardData?.subtitle2 && <div className="feedcard-lite">{cardData?.subtitle2}<br /></div>}
                                {cardData?.message && <div className="feedcard-message">{cardData?.message}<br /></div>}
                        </div></div>
	                </li>)
	            })}
	        </div>
	        {showDetails && <Modal open={showDetails} >
                    <Details
                        data={feedCard}
                        details={actions}
                        parentState={showDetails}
                        changeState={setShowDetails}
                        dismissState={cardDismissed}
                        setDismiss={setCardDismissed}
                        history={history}
                        riskType={riskType}
                        riskCategoryType={riskCategoryType}
                        entityId={entityId}
                        cardId={id}
                        numActions={actions?.length}
                        numOutboundActions= {actions?.filter(item => item.deepLinkUrl !== null).length}
                        setRefreshNeeded={setRefreshNeeded}
                        windowWidth={windowWidth}
                        isLastRisk={isLastRisk}
                        setLastCardDismissed={setLastCardDismissed}
                    /></Modal>}
	        <div className="card-button-container"><button className="card-button" onClick={handleClick}>What should I do?</button></div>
	        {history ? <div className="feedcard-bottom-spacer" /> : <div className="dismiss-button" onClick={handleDismiss}>Dismiss</div>}
	    </div>
        }</>
    );
};

export default Card;

import {useEffect, useMemo, useState, useRef} from 'react';
import {useAuth} from "../contexts/authContext";
import {useMachine} from "@xstate/react";
import {createFetchMachine} from "../machines/fetchMachine";
import {createDataFetcher} from "../util/fetchFactory";
import {retryableError} from "../util/retryableError";

const maxRetries = 3; //make sure we don't end up in a loop
const maxWaitRetries = 3; //make sure we don't end up in a loop

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function isExpired(xpTime) {
    //we should set an expired window of several seconds before the actual expiration time to minimize 400s
    if(xpTime === null) {
        return false; // don't want to be stuck in a loop
    }
    if(isNaN(xpTime)) {
        return true;
    }
    return Math.floor(Date.now()/1000) > parseInt(xpTime);
}

function refreshKeyExpired(context, event) {
    if(context.refreshTokenExp === 0 && context.refreshToken === null) {
        return false;
    }
    return isExpired(context.refreshTokenExp);
}


function accessKeyExpired(context, event) {
    if (!context.accessToken && context.accessTokenExp === 0) {
        return false;
    }
    return isExpired(context.accessTokenExp);
}


//check for secret existing too
function csrfExpired(context, event) {
    if((context?.csrf && isExpired(context?.csrfExp)) || !context?.csrf) {
        return true;
    }
    return false;
}

function expCheck(context, event) {
    let type = "";
    let expired = false;
    if (accessKeyExpired(context, event)) {
        type = 'access';
        expired = true;
    }

    if (refreshKeyExpired(context, event)) {
        type = 'refresh';
        expired = true;
    }

    if (csrfExpired(context, event)) {
        type = 'csrf';
        expired = true;
    }
    return {type, expired};
}

export function useFetch() {
    const {authService, authState, ready: isReady} = useAuth()
    const [aStateUpdated, setAStateUpdated] = useState(false)
    const [ready, setReady] = useState(false);
    //might not need the useMemos anymore since we reworked the UPDATE event.
    const fetchData = useMemo( () => createDataFetcher(authState.context.apiClient, authService), [authState.context.apiClient])
    const fetchMachine = useMemo(() => createFetchMachine(fetchData), [fetchData, authState.context, authState.context.accessToken]);
    const previousEventRef = useRef(null);
    const [preEv, setPrevEv] = useState(null);
    const [errorCleared, setErrorCleared] = useState(false);
    const [inError, setInError] = useState(false);
    const [state, send, service] = useMachine(fetchMachine.withContext({...fetchMachine.context, apiClient: authState.context.apiClient}));
    const [uiErrorMsg, setUiErrorMsg] = useState(null);
    const [retries, setRetries] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [waitRetries, setWaitRetries] = useState(0);
    const [waitRetry, setWaitRetry] = useState(false);


    //manual handling of error 4 - try update client// backoff timer and then display unresolveable error modal or something, logout and redirect to login?
    const [error4Retries, setError4Retries] = useState(0);
    const [error6Retried, setError6Retried] = useState(false); //for now, we only retry once. if it fails, we need to log out

    const [error4WaitRetry, setError4WaitRetry] = useState(false);






    useEffect(() => {

        if(authState.context.apiClient && fetchData && fetchMachine) {
            //console.log("setting ready")
            if(authState.matches('authenticated.idle')) {
                setReady(true)
            } else {
                setReady(false)
            }
        }

    }, [authState.context.apiClient, fetchData, fetchMachine])

    //watch if authState context changes, update the fetchMachine
    useEffect(() => {
        if(ready) {
            send({type: 'UPDATE', apiClient: authState.context.apiClient})
        }
    }, [authState.context.apiClient, authState.context.ptk, ready])

    //not clear this helped but it appears to....
    useEffect(() => {

        //TODO: if error code 6 -> try and trigger authMachine to refresh. if that fails, we need to log out
        //do same for 5,34,44 -> tell authMachine to refresh
        //if these don't match... tell authMachine to update?
        if (authState.context.accessToken) {
            if (authState.context.accessToken != state.context.apiClient?.accessToken) {
                console.log("in accessToken useEffect")
                send({type: 'UPDATE', apiClient: authState.context.apiClient})
            }
        }
    }, [authState.context.accessToken]);

    useEffect(() => {
        //if csrf or accessToken changes, we need our client updated.
        if(inError) {
            if(!errorCleared) {
                console.log("cleared error")
                setErrorCleared(true)
                setInError(false)
            }
        }

    }, [inError, errorCleared, authState]);

    useEffect(() =>  {
        let timeout;
        if (waitRetry && waitRetries < maxWaitRetries) {  //prevent endless loops
            console.log(waitRetries);
            timeout = setTimeout(() => {
                if (!authState.matches('authenticated')) {
                    clearTimeout(timeout);  //just for housekeeping, timer is over
                    console.log('not authed, clearing waitRetry timer');
                } else if (authState.matches('authenticated.idle')) {
                    if (preEv) {
                        send(preEv)
                    }
                    console.log("clearing waitRetry timer cause successful idle transition");
                    clearTimeout(timeout); //just for housekeeping, timer is over
                } else {
                    console.log("still in auth state, but still not in auth.idle state, let's go again");
                    setWaitRetries(prevRetries => prevRetries + 1);
                }
            }, 1000);
        }

        // remove timer when unmounted
        return () => {
            if (timeout) {
                clearTimeout(timeout);  //needed to avoid memory leaks if parent unmounted before timer over
                console.log("clearing cause return");
            }
        };
    }, [waitRetry,waitRetries]);

    //what if we watch authState.context.csrf and authState.context.accessKey for changes and if error,
    //handle error (update csrf) and retry... but we need to watch those and then set error cleared to true
    useEffect(() => {
        //need to store the event from the failed fetch
        if (state && state.event) {
            if (previousEventRef.current === 'RESET' && state.matches('idle')){
                console.log("BEFORE CLEARED: ", authState)
                if(!inError && errorCleared && authState.matches('authenticated')) {
                    console.log("we cleared the error and can try again")
                    if (authState.matches('authenticated.idle')) {
                        if (preEv) {
                            send(preEv)
                        }
                    } else {
                        if (preEv) {
                            setWaitRetry(true);
                        }
                    }
                }
            }
            previousEventRef.current = state.event.type;
        }
        if(state.matches('success') && !aStateUpdated) {
            setAStateUpdated((current) => !current)
        }
    }, [state, fetchMachine, preEv, inError, errorCleared]);


    //We need to watch for authState... if token refreshing, we should wait then try again after updataiting here
    useEffect(() => {
        //console.log(authState)
        setAStateUpdated((current) => !current)
    }, [authState]);

    //autoretry on error if we can
    useEffect(() => {
        if (state.matches('failure')) {
            let {canRetry, errMsg} = retryableError({errorBody: state.context.error, authService});
            setInError(true)

            /* //i don't think we want to retry on 400s?
            if(state.context.error?.errorCode === 4 && error4Retries < maxRetries) {
                if(error4Retries === 0) {
                    send({type: 'UPDATE', apiClient: authState.context.apiClient})
                    //Maybe we want to tell authMachine to refresh/reload everything before sending UPDATE to state (fetch) machine?
                    setError4Retries(error4Retries => error4Retries + 1);
                    send('RESET');
                    return;
                }
                if(error4Retries > 0) {
                    //if this works, maybe move to exponential backoff
                    sleep(1000).then(() => {
                        send({type: 'UPDATE', apiClient: authState.context.apiClient})
                        //Maybe we want to tell authMachine to refresh/reload everything before sending UPDATE to state (fetch) machine?
                        setError4Retries(error4Retries => error4Retries + 1);
                        send('RESET');
                    })
                }

             */
                /*
                //try and force an update
                send({type: 'UPDATE', apiClient: authState.context.apiClient})
                //Maybe we want to tell authMachine to refresh/reload everything before sending UPDATE to state (fetch) machine?
                setError4Retries(error4Retries => error4Retries + 1);
                send('RESET');

            }
                */
            //Fix for error 6 when session cookies get cleared
            if(state.context.error?.errorCode === 6 && !error6Retried) {
                authService.send('FORCE_REFRESH_TOKEN') // if refresh fails, it'll autologout
                setError6Retried(true)
                setInError(false);
                setErrorCleared(true);
                send('RESET');
            }

            //prevent infinite loop
            if (canRetry && retries < maxRetries) {
                console.log("RETRIES: ", retries)
                setPrevEv({type: state.history.event.type, method: state.history.event.method, params: state.history.event.params})
                setRetries(retries + 1);
                send('RESET');
                setInError(false);
                setErrorCleared(true);
            } else {
                setIsLoading(false)
                setUiErrorMsg(errMsg);
            }

        }
        if(state.matches('loading')) {
            setIsLoading(true)
        }
        if(state.matches('success')) {
            setError4Retries(0)
            setRetries(0)
            setIsLoading(false)
            if (error6Retried) {
                setError6Retried(false)
            }
        }
        if(state.matches('idle')) {
            //console.log(state)
            //console.log(authState)
            setIsLoading(false)
            setUiErrorMsg(null)
        }

    }, [state])



    const fetch = (method, params) => service.send({type: 'FETCH', method: method, params: params});
    const reset = () => (state.matches('failure')||state.matches('success')) && service.send('RESET');


    return {state, send, service, fetch, reset, uiErrorMsg, isLoading, ready};
}

export const parseMirkatAuthData = (data) => {
    let authData = {
        accessKey: null,
        accessKeyExp: null,
        refreshKey: null,
        refreshKeyExp: null,
        userId: null,
        emailId: null,
        installationId: null,
        ptk: null,
        settings: {'notifyEmail': false, 'notifyIOS': false, 'notifyAndroid': false, 'notifyWindows': false},
        primaryUser: null,
        emailAddress: null,
        isNewUser: false,
        cohorts: null,
    }

    if (data.hasOwnProperty('accessKey') && data.accessKey !== "undefined") {
        authData.accessKey = data.accessKey;
        authData.accessKeyExp = Math.floor(Date.now()/1000) + parseInt(process.env.REACT_APP_ACCESS_TOKEN_INTERVAL);
    }

    if (data.hasOwnProperty('refreshKey')) {
        authData.refreshKey = data.refreshKey;
        authData.refreshKeyExp = Math.floor(Date.now()/1000) + (parseInt(process.env.REACT_APP_REFRESH_TOKEN_INTERVAL));
    }

    if (data.hasOwnProperty('userId')) {
        authData.userId = data.userId;
    }

    if (data.hasOwnProperty('emailId')) {
        authData.emailId = data.emailId;
    }

    if (data.hasOwnProperty('installationId')) {
        authData.installationId = data.installationId;
    }

    if (data.hasOwnProperty('ptk')) {
        authData.ptk = data.ptk;
    }

    if (data.hasOwnProperty('settings')) {
        if(data.settings.hasOwnProperty('notifyEmail')) {
            authData.settings.notifyEmail = data.settings.notifyEmail;
        }
        if(data.settings.hasOwnProperty('notifyIOS')) {
            authData.settings.notifyIOS = data.settings.notifyIOS;
        }
        if(data.settings.hasOwnProperty('notifyAndroid')) {
            authData.settings.notifyAndroid = data.settings.notifyAndroid;
        }
        if(data.settings.hasOwnProperty('notifyWindows')) {
            authData.settings.notifyWindows = data.settings.notifyWindows;
        }
    }

    if (data.hasOwnProperty('primaryUser')) {
        authData.primaryUser = data.primaryUser;
    }

    if (data.hasOwnProperty('emailAddress')) {
        authData.emailAddress = data.emailAddress;
    }

    if (data.hasOwnProperty('isNewUser')) {
        authData.isNewUser = data.isNewUser;
    }

    if (data?.cohorts) {
        authData.cohorts = data.cohorts
    }

    return authData;
}

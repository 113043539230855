import React, {useEffect, useState} from "react";

import EmailLinkModal from "../modals/EmailLinkModal";
import CodeResentModal from "../modals/CodeResentModal";
import RemoveEmailModal from "../modals/RemoveEmailModal";
import AddEmailModal from "../modals/AddEmailModal";
import IntroAddEmailModal from "../modals/IntroAddEmailModal";
import ConfirmEmailModal from "../modals/ConfirmEmailModal";
import CancelMirkatPlusModal from "../modals/CancelMirkatPlusModal";
import DeleteAccount from "../modals/DeleteAccountModal";
import ChangeOldPasswordModal from "../modals/ChangePasswordOldPwModal";
import PasswordUpdatedModal from "../modals/PasswordUpdatedModal";

//ToDo: need to rename object since modalType.modalType is redundant
const DisplayModal = ({modalType}) => {
    let mType = "";
    if(typeof(modalType) === "object") {
        mType = modalType.modalType;
    } else {
        mType = modalType;
    }

    switch (mType) {
        case 'emailLink':
            return (
                <EmailLinkModal />
            );
        case 'codeResent':
            return (
                <CodeResentModal props={modalType} />
            );
        case 'removeEmail':
            return (
                <RemoveEmailModal props={modalType} />
            );
        case "addEmail":
            return (
                <AddEmailModal callback={modalType.callback} backEmail={modalType?.backEmail} exitCallback={modalType.exitCallback} props={modalType} />
            );
        case 'introAddEmail':
            return (
                <IntroAddEmailModal backEmail={modalType?.backEmail} props={modalType} />
            );
         case 'confirmEmail':
             return (
                 <ConfirmEmailModal props={modalType}/>
             );

          case 'cancelSubscription':
                return (
                    <CancelMirkatPlusModal callback={modalType.callback}/>
                );
          case 'deleteAccount':
                return (
                    <DeleteAccount callback={modalType.callback}/>
                );
          case 'passwordUpdatedModal':
                return (
                    <PasswordUpdatedModal callback={modalType.callback}/>
                );
        case 'changeOldPasswordModal':
            console.log("click registered")
            return (
                <ChangeOldPasswordModal setOldPw={modalType.setOldPw} setModalType={modalType.setModalType} toggleModal={modalType.toggleModal}/>
            );
        default:
            return (<></>);

    }
}

export default DisplayModal;

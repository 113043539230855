import {useState, useEffect} from  'react';
import {useAuth} from "../contexts/authContext";
import {useFetch} from "./useFetch";




//incase the order flips again we only need to change these two lines

const ios = "1";
const stripe = "2";

function getExpDate(data) {
    try {
        const [year, month, day] = data?.expiresAt.split(' ')[0].split('-').map(part => parseInt(part, 10))

        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const date = new Date(year, month - 1, day);
        const monthName = monthNames[date.getMonth()];

        // Add suffix for the day
        const daySuffixes = ['th', 'st', 'nd', 'rd'];
        const daySuffix = (day % 10 < 4 && Math.floor(day / 10) !== 1) ? daySuffixes[day % 10] : 'th';

        return `${monthName} ${day}${daySuffix}, ${date.getFullYear()}`;
    } catch (e) {
        return ""
    }
}


const useIsPlus = () => {
    const [isPlus, setIsPlus] = useState(null);
    //const {authState, authService} = useAuth();
    const { state, fetch, isLoading, uiError, ready, reset} = useFetch()
    const [billingRequestError, setBillingRequestError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false);
    const [billingRequestStatus, setBillingRequestStatus] = useState(null)
    const [iosManaged, setIosManaged] = useState(false)
    const [expDate, setExpDate] = useState(null)
    const [formattedExpDate, setFormattedExpDate] = useState(null)
    const [autoRenewStatus, setAutoRenewStatus] = useState(false)
    const [cancelCount, setCancelCount] = useState(null)
    const [upgradeCount, setUpgradeCount] = useState(null)
    const [hasHadTrial, setHasHadTrial] = useState(false)
    const [price, setPrice] = useState('')
    const [duration, setDuration] = useState('')
    const [isInTrial, setIsInTrial] = useState(false)



    useEffect(() => {
        setIsLoaded(true)
    }, []);

    const doReset = () => {
        reset()
        setBillingRequestError(null)
        setBillingRequestStatus(null)
        setIsPlus(null)
        setCancelCount(null)
        setUpgradeCount(null)
        setHasHadTrial(false)
    }

    useEffect(() => {
        if (isLoaded && ready && state.matches('idle')) {
            fetch('getWebBillingStatus')
        }

        if(state.matches('success')) {
            //Check if IOS managed. If so, get date from here and set iosManaged to true
            if(state.context.data?.providerDetails?.[ios]?.length > 0) {
                const data = state.context.data
                
                if (Array.isArray(data.providerDetails[ios]) && data.providerDetails[ios].length > 0) {
                    data.providerDetails[ios].forEach(subObject => {
                        for (let subKey in subObject) {
                            //if (subKey.startsWith("sub_")) { // don't care what the key name actually is
                            setExpDate(subObject[subKey].expiresAt); //
                            setFormattedExpDate(getExpDate(subObject[subKey]))
                            if (subObject[subKey].isActive === 1) {
                                setIosManaged(true)
                            }
                            break; //stop after first match
                            //}
                        }
                    });
                }
            }

            //Getting subscription expiration date
            //This only get's the latest stripe subscription expiration date
            if(state.context.data?.providerDetails?.[stripe]?.length > 0) {
                const data = state.context.data
                if (Array.isArray(data.providerDetails[stripe]) && data.providerDetails[stripe].length > 0) {
                    data.providerDetails[stripe].forEach(subObject => {
                        for (let subKey in subObject) {
                            //if (subKey.startsWith("sub_")) {
                            setExpDate(subObject[subKey].expiresAt); //
                            setFormattedExpDate(getExpDate(subObject[subKey]))

                            break; //stop after first match
                            //}
                        }
                    });
                }
            }

            if (state.context.data?.effectiveIsActive === 1) {
                setIsPlus(true)
            } else {
                setIsPlus(false)
            }

            if(state.context.data?.hasHadTrial) {
                setHasHadTrial(true)
            }

            if(state.context.data?.inTrial) {
                setIsInTrial(true)
            }

            if(state.context.data?.wPrice) {
                setPrice(state.context.data?.wPrice)
            }

            if(state.context.data?.wDuration) {
                setDuration(state.context.data?.wDuration)
            }

            setBillingRequestStatus("success")
            //check subscription for #2

        }
        if (state.matches('failure')) {
            //Todo -> error modal?
            setBillingRequestError("Error getting subscription status")
            setBillingRequestStatus("failure")

        }

        if(state.matches('success')) {
            let someObj = state.context.data?.providerDetails?.[stripe]?.["0"]
            if(someObj) {
                let key = Object.keys(someObj)
                let firstKey = key[0]
                if (someObj[firstKey]?.autoRenewStatus === 1) {
                    setAutoRenewStatus(true)

                }
            }
            setCancelCount(state.context.data?.cancelCount)
            setUpgradeCount(state.context.data?.upgradeCount)
        }


        //for testing purposes - manage subscription popup apple modal
        //setIosManaged(true)

    }, [ready, state, fetch, isLoaded])

    return {isPlus, billingRequestStatus, billingRequestError, iosManaged, expDate, formattedExpDate, autoRenewStatus, doReset, state, cancelCount, upgradeCount, hasHadTrial, price, duration, isInTrial}
}
export default useIsPlus;


/*
{
"success": 1,
"effectiveIsActive": 1,
"hasHadTrial": false,
"providerDetails": {
"1": [], //managed by IOS
"2": [ //managed by stripe
{
    "sub_1OFGsyKznqykFAAaYSu1KNgK": {
        "isActive": 1,
        "autoRenewStatus": 1,
        "billingIssue": 0,
        "expiresAt": "2023-12-22T13:51:59"
    }
}
]
}
}
*/

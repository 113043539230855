import React from "react";
//import SecondaryEmailsForm from "../components/SecondaryEmailsForm";
//import BillingList from "../components/BillingList";
import useBilling from "../hooks/useBilling";

const BillingPage = () => {
    const {billingItems, isLoading} = useBilling();
//    const navigate = useNavigate();

//    const onSuccess = () => {
//        navigate("/confirmEmails");
//    }

	    //<BillingList data={billingItems} isLoading={isLoading} />
    return (
        <div className="emails">
	        <h1>Billing Status:</h1>
	        {!isLoading && JSON.stringify(billingItems)}
        </div>
    );
}



export default BillingPage;

import {assign, createMachine, send} from "xstate";

export const addOrDeleteEmailUpdate = createMachine({
    id: 'addOrDeleteEmailUpdate',
    initial: 'initialfetch',
    context: {

    },
    states: {
        initialfetch: {
            on: {
                FETCHED: {
                    target: 'emailsFetched',
                },

                RESET: {target: 'initialfetch'},
            },
        },
        idle: {
            on: {
                REFETCH: {target: 'initialfetch'},
                RESET: {target: 'initialfetch'},
                EMAILADDED: {
                    target: 'emailAdded',
                },
                EMAILDELETED: {
                    target: 'emailDeleted',
                    assign: {
                        emailId: (context, event) => event.emailId,
                    }
                },
                PURGATORY: {target: 'purgatory'}
            },
        },
        emailAdded: {
            on: {
                REFETCH: {target: 'initialfetch'},
                FETCHED: {target: 'emailsFetched'},
                RESET: {target: 'idle'},
                PURGATORY: {target: 'purgatory'}
            },
        },
        emailDeleted: {
            on: {
                REFETCH: {target: 'initialfetch'},
                FETCHED: {target: 'emailsFetched'},
                RESET: {target: 'idle'},
                NEXT: {target: 'emailDeletedNextStep'},
                PURGATORY: {target: 'purgatory'}
            },
        },
        emailDeletedNextStep: {
            on: {
                REFETCH: {target: 'initialfetch'},
                FETCHED: {target: 'emailsFetched'},
                RESET: {target: 'idle'},
                PURGATORY: {target: 'purgatory'}
            },
        },
        emailsFetched: {
                on: {
                    RESET: {target: 'idle'},
                }
        },
        purgatory:{
            after: {
                // this timeout should be smaller than your token expiry time
                // let's assume tokens last for 1 minutes, we would refresh after 55 seconds
                //TODO: make this configurable. I attempted using delays and it totally dossed itself
                2000: { actions: [ send('emailsFetched') ]},
            },
        }
    },
});
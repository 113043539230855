import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';

import { useMachine} from '@xstate/react';
import {track} from "../util/analytics";
import {useFetch} from "../hooks/useFetch";
import {addOrDeleteEmailUpdate} from "../machines/addOrDeleteEmailMachine";
import useIsPlus from "../hooks/useIsPlus";
import useDetectNetwork from "../hooks/useDetectNetwork";
import useProgressBar from "../hooks/useProgressBar";
import {useAuth} from "./authContext";
import {isEmail} from "../util/validation";
import AuthStorageService from "../services/authStorage";

export const SettingsContext = createContext({
    primaryEmail: null,
    emails: [],
    emailsLoaded: false,
    emailNotificationEnabled: false,
    iosNotificationEnabled: false,
    subscriptionType: null, //Monthly or Free?
    subscriptionEnd: null, //Date

});

export const useSettings = () => {
    return useContext(SettingsContext);
}

export const SettingsProvider = ({ children }) => {
    //probably need to synchronize tabs with storage?
    //settings object -> one tab writes changes that are successful to storage -> other tabs read and update settings context
    //Monitoring
    //neeed setPrimaryEmail address and emails array
    const { isAuthenticated, authState} = useAuth();
    const {state: monitoredEmailsState, fetch: fetchMonitoredEmails, ready: fetchEmailsReady, reset: resetMonitoringEmails} = useFetch();
    //For adding/deleting emails.
    const {state, fetch, ready, reset} = useFetch();

    const [deleteEmailId, setDeleteEmailId] = useState(null);
    const [emailAdded, setEmailAdded] = useState(false);


    //Notifications
    const [emailNotificationsEnabled, setEmailNotificationsEnabled] = useState(false);
    const [iosNotificationsEnabled, setIosNotificationsEnabled] = useState(false);

    const [updateNotifications, setUpdateNotifications] = useState(false);
    const [updateNotificationType, setUpdateNotificationType] = useState(null);

    const {state: notificationState, fetch: fetchNotification, ready: notificationReady, reset: resetNotification} = useFetch();


    //Subscription status
    const {isPlus, billingRequestStatus, billingRequestError, iosManaged, expDate, formattedExpDate, autoRenewStatus, doReset, price, duration} = useIsPlus();
    const { progressStarted, apiStarted, isComplete, startProgress, handleCompletion,  setLastApiCallStarted, setProgressOverride, progressOverride} = useProgressBar();


    const [needsRefetch, setNeedsRefetch] = useState(false)


    const [isPaid, setIsPaid] = useState(false);
    const [subStatus, setSubStatus] = useState(null);
    const [subExp, setSubExp] = useState(null);


    const [genericErrorModal, setGenericErrorModal] = useState(false)

    const isOnline = useDetectNetwork();
    useEffect(() => {
        if(!isOnline){
            setGenericErrorModal(true)
        }
        if(isOnline) {
            setNeedsRefetch(true)
            setGenericErrorModal(false)
        }
    }, [isOnline])

    useEffect(() => {
        //setNeedsRefetch(true) //fetch on component mount
    }, [])

    const [primaryEmail, setPrimaryEmail] = useState('');
    const [emails, setEmails] = useState([]);
    const [emailsLoaded, setEmailsLoaded] = useState(false);
    const [deleteRequestSent, setDeleteRequestSent] = useState(false);


    useEffect(() => {
        if(fetchEmailsReady && monitoredEmailsState.matches('idle') && needsRefetch) {
            fetchMonitoredEmails('emails')
            doReset() //should retrigger billing?
            setNeedsRefetch(false)
        }

        if(fetchEmailsReady && monitoredEmailsState.matches('success') && !needsRefetch ) {

            let emList = monitoredEmailsState.context.data.emails; //should be an array of email objects

            let ems = [];
            let pri = '';

            for (let e of emList) { // this can error f emList not iterable
                if(e.primaryUser) {
                    pri = e.emailAddress;
                    continue
                }
                ems = [...ems, e]
            }

            console.log("Settings response: ", monitoredEmailsState.context.data)
            setPrimaryEmail(pri)
            setEmails([...ems])

            setIosNotificationsEnabled(monitoredEmailsState.context.data?.settings?.notifyIOS || false)
            setEmailNotificationsEnabled(monitoredEmailsState.context.data?.settings?.notifyEmail || false)

           // emailsService.send({type: 'FETCHED'})
            setProgressOverride(100)
            setEmailsLoaded(true)
        }

        if(monitoredEmailsState.matches('success')) {
            resetMonitoringEmails()
        }

        if(monitoredEmailsState.matches('failure')) {
            console.log("here1")
            setGenericErrorModal(true)
            resetMonitoringEmails()
            //show oops modal
        }

    }, [monitoredEmailsState, fetchEmailsReady, fetchMonitoredEmails, resetMonitoringEmails, deleteEmailId, emails, setEmails, needsRefetch]);


    //Delete monitored email
    useEffect(() => {
        if(ready && deleteEmailId && state.matches('idle') && !deleteRequestSent) {
            console.log("WHAT's THE EMAIL ID: ", deleteEmailId)
            fetch('deleteEmail', [deleteEmailId]) // delete the email
            setDeleteRequestSent(true)
        }

        if(deleteEmailId && state.matches('success') && deleteRequestSent) {
            setGenericErrorModal(false)
            console.log("here2")

            setDeleteEmailId(null)

            //#347
            track('User Email Address Removed', {eventType: 'emailAddressRemoved', entryType: 'settings'})
            reset()
            //needs refetch
            setEmailsLoaded(false)
            setNeedsRefetch(true)
            setDeleteRequestSent(false)

        }

        //add one more check here incase a single rerender occurs here...
        if(deleteEmailId && state.matches('failure') && deleteRequestSent) {
            console.log("here3")
            setDeleteEmailId(null)
            setGenericErrorModal(true)
            reset()
            setDeleteRequestSent(false)
        }
        //issue here when coming back from offline -> state thinks it's in failure but the request succeeded
    }, [deleteEmailId, setDeleteEmailId, state, ready, fetch, reset, genericErrorModal, setGenericErrorModal, deleteRequestSent]);

    //emailAdded -> need refetch
    useEffect(() => {
        if(emailAdded){
            //needs refetch
            setEmailAdded(false)
            setNeedsRefetch(true)
        }
    }, [emailAdded, setEmailAdded]);



    //Notifications
    useEffect(() => {
        //if we need to refresh, setNeedsRefetch to true
        //All failures throw oops
        if(notificationReady && notificationState.matches('idle') && updateNotifications) {
            fetchNotification('emailSettings', [authState.context.emailId, emailNotificationsEnabled, iosNotificationsEnabled])
            setUpdateNotifications(false)
        }

        if(notificationReady && notificationState.matches('success') && !updateNotifications) {
            resetNotification()
        }

        if(notificationReady && notificationState.matches('failure') && !updateNotifications) {
            //if failure -> set ui toggle back
            if(updateNotificationType === "email") {
                setEmailNotificationsEnabled(!emailNotificationsEnabled)
            }
            if(updateNotificationType === "ios") {
                setIosNotificationsEnabled(!iosNotificationsEnabled)
            }
            console.log("here4")
            setGenericErrorModal(true)
            resetNotification()
            //todo: throw oops
        }

    }, [emailNotificationsEnabled, iosNotificationsEnabled, notificationReady, notificationState, fetchNotification, authState, setNeedsRefetch, authState?.context?.emailId]);


    //subscription status
    useEffect(() => {
        if (billingRequestStatus === "success") {
            setProgressOverride(100)
            if (isPlus) {
                setIsPaid(true)
                setSubStatus("Monthly") //used to be Free/Trialing/Monthly // what if billing issue? -> trigger modal
                setSubExp(formattedExpDate)
            }

        }

        if (billingRequestStatus === "failure" && !genericErrorModal) {
            setProgressOverride(100)
            setGenericErrorModal(true)
        }
        console.log("Billing Request Status: ", billingRequestStatus)
        console.log("IS PLUS: ", isPlus)
        console.log("Needs Refetch: ", needsRefetch)
    }, [billingRequestStatus, isPlus, isPaid]);




    //get monitored emails
    //get notifications
    //get subscription status


    return (
        <SettingsContext.Provider value={{
        primaryEmail,
        emails,
        emailsLoaded,
        emailAdded,
        setEmailAdded,
        deleteEmailId,
        setDeleteEmailId,
        emailNotificationsEnabled,
        setEmailNotificationsEnabled,
        iosNotificationsEnabled,
        setUpdateNotificationType,
        setIosNotificationsEnabled,
        setUpdateNotifications,
        isPlus,
        billingRequestStatus,
        billingRequestError,
        iosManaged,
        expDate,
        formattedExpDate,
        autoRenewStatus,
        genericErrorModal,
        setGenericErrorModal,
        progressStarted,
        apiStarted,
        isComplete,
        startProgress,
        handleCompletion,
        setLastApiCallStarted,
        setProgressOverride,
        progressOverride,
        isPaid,
        subStatus,
        subExp,
        setNeedsRefetch,
        price,
        duration,
        }}>
            {children}
        </SettingsContext.Provider>
    );
}

import React, {useState, useEffect} from "react";
import "../styles/scss/MainFooter.scss";

const MainFooter = () => {
    //need build variable
    //need conditional for build variable
    const [build, setBuild] = useState("");
    const env = process.env.REACT_APP_ENV;
    const commitHash = process.env.REACT_APP_COMMIT_HASH;


    useEffect(() =>{
        if(env !== 'prod') {
            setBuild(commitHash|| "dev");
        }
    },[env])
    const currentYear = new Date().getFullYear();

    return (
        <footer>
            <div className="footer">
                <div className="footer-inner-container">
                    <div className="footer-links-container">
                        <div className="footer-link">
                            <a target="_blank" href="mailto:hi@mirkat.com">Contact</a>
                        </div>
                        <div className="footer-link">
                            <a target="_blank" href="https://help.mirkat.com/">Help</a>
                        </div>
                        <div className="footer-link">
                            <a target="_blank" href="https://mirkat.com/privacy/">Privacy</a>
                        </div>
                        <div className="footer-link">
                            <a target="_blank" href="https://mirkat.com/terms/">Terms</a>
                        </div>
                    </div>
                    <div className="copyright-container">
                        <div className="copyright-text">
                            © Copyright {currentYear} Mirkat Inc. All rights reserved.
                        </div>
                        <div className="build-text">{build  && "Build:"} {build}</div>
                    </div>


                </div>
            </div>
            <div className="footer-line" />
        </footer>
    )
}


export default MainFooter;

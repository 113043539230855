import React, {useEffect} from "react";
import {screen,track} from "../util/analytics";
import {cacheFeedActionImages} from "../util/imageCache";


const useHomePageAnalytics = ({loaded, state, feed, setIsNew, screenEventFired, setScreenEventFired, showErrorModal, setShowErrorModal, authService, cardsLoadedEventFired, setCardsLoadedEventFired, setAddEmailFeedSubType, setAddEmailFeedPosition, showEmail, addEmailLoaded, setAddEmailLoaded, setBreachesFeedSubType, setBreachesFeedPosition, isPaid, isDismissed, setUpgradeFeedSubType, setUpgradeFeedPosition, upgradeCardLoaded, setUpgradeCardLoaded}) => {

    //feed event with totals and categories parsed from feed call
    useEffect(() => {
        if(loaded && state.matches('success')) {
            //feed.feed.existingRisks and feed.feed.newRisks ? && existsingRisks.length  && newRisks.length
            console.log("feed new: ", feed?.feed?.breaches?.new);

            if (feed?.feed?.breaches?.new) {
                setIsNew(true);
            }

            if (!screenEventFired) {
                //state is feed,  state5 is seen
                const numOfRisks = state.context.data?.feed?.existingRisks?.cards?.filter(card => card.dismissedAt === null && card.completedAt === null && card.inHistoryAt === null) || 0;
                const riskContainsPassword = state.context?.data?.feed?.existingRisks?.cards.some(card =>
                    card.cardData.some(data => data.dataType.includes("Password"))
                );
                const notContainsPassword = state.context?.data?.feed?.existingRisks?.cards.some(card =>
                    card.cardData.every(data => !data.dataType.includes("Password"))
                );

                //new
                const newRisks = state.context.data?.feed?.existingRisks?.cards?.filter(card => card.seenAt === null);
                const newRiskContainsPassword = state.context.data?.feed?.existingRisks?.cards?.filter(card => card.seenAt === null).some(card =>
                    card.cardData.some(data => data.dataType.includes("Password"))
                );
                //does not include password
                const notNewRisksContainsPassword = state.context?.data?.feed?.existingRisks?.cards.some(card =>
                    card.cardData.every(data => !data.dataType.includes("Password"))
                );

                screen('Feed', {
                    screenType: 'feed',
                    feedType: numOfRisks.length > 0 ? 'risk' : 'safe',
                    feedSubType: state.context.data?.feed?.feedSubType, //accounts, financial, accountsAndFinancial
                    numberOfRiskCards: numOfRisks.length || 0,
                    newRisks: !!newRisks,
                    risksNewType: (newRiskContainsPassword && !notNewRisksContainsPassword) ? 'passwordsOnly' : notNewRisksContainsPassword > 0 ? 'nonPasswordsGreaterZero' : 'none', //passwordsOnly, nonPasswordsGreaterZero, none //new in 1.6
                    risksExistingType: (riskContainsPassword && !notContainsPassword) ? 'passwordsOnly' : notContainsPassword > 0 ? 'nonPasswordsGreaterZero' : 'none', //passwordsOnly, nonPasswordsGreaterZero, none  //new in 1.6
                })
                setScreenEventFired(true)
            }
        }

        if(state.matches('failure') && !showErrorModal) {
            console.log("Error on feed retrieval: ", state.error)
            setShowErrorModal(true)
            setTimeout(() =>
                authService.send('LOGOUT')
            , 5000)
        }
        console.log("Feed state: ", state)
    }, [state, loaded, screenEventFired, showErrorModal]);

    //sets position feed TYPE and feed POSITION for Upgrade Card Loaded call
    useEffect(() => {
        if(feed && !isPaid && !isDismissed) {
            //need to add 1 if breaches, add one if scan for more emails card
            //#406
            setUpgradeFeedSubType(feed?.feed?.existingRisks?.cards?.length > 0 ? 'risk' : 'safe')
            setUpgradeFeedPosition((feed.feed?.existingRisks?.cards?.length || 0) + 1 + (showEmail ? 1 : 0) + ((feed?.feed?.breaches?.new || feed?.feed?.breaches?.existing)  ? 1 : 0 ))
        }
    }, [feed]);

    //sets position feed TYPE and feed POSITION for Add More Emails Card Loaded call
    useEffect(() => {
        if(feed) {
            setAddEmailFeedSubType(feed.feed?.existingRisks?.cards?.length > 0 ? 'risk' : 'safe')
            setAddEmailFeedPosition((feed.feed?.existingRisks?.cards?.length || 0) + 1 + ((feed?.feed?.breaches?.new || feed?.feed?.breaches?.existing)  ? 1 : 0 )) // always after breaches card
        }
    }, [feed]);

    //card loaded event for Add More Emails Card
    useEffect(() => {
        if(loaded && feed?.feed?.addEmail && showEmail && !addEmailLoaded) {
            //#287
            track('Card Loaded', {cardType: 'monitorMoreEmails', feedType: 'feed',
                feedSubType: feed.feed?.existingRisks?.cards?.length > 0 ? 'risk' : 'safe',
                feedPosition: (feed.feed?.existingRisks?.cards?.length || 0) + 1 + ((feed?.feed?.breaches?.new || feed?.feed?.breaches?.existing)  ? 1 : 0 ),});
            setAddEmailLoaded(true)
        }
    }, [loaded, feed?.feed?.addEmail, showEmail, addEmailLoaded]);

    //sets position feed TYPE and feed POSITION for Data Breaches Card Loaded call
    useEffect(() => {
        if(feed) {
            //console.log("Caching details icons")
            //console.log("Cache feed: ", feed)
            cacheFeedActionImages(feed)
            setBreachesFeedSubType(feed?.feed?.existingRisks?.cards?.length > 0 ? 'risk' : 'safe')
            setBreachesFeedPosition((feed.feed?.existingRisks?.cards?.length || 0) + 1)
        }
    }, [feed]);

    //dataBreaches card loaded event
    useEffect(() => {
        if(loaded && state.matches('success') && feed && feed.feed.breaches.detail !== "" && !cardsLoadedEventFired) {
            //#401
            track('Card Loaded', {cardType: 'dataBreaches', feedType: 'feed',
                feedSubType: feed.feed?.existingRisks?.cards?.length > 0 ? 'risk' : 'safe',
                feedPosition: (feed.feed?.existingRisks?.cards?.length || 0) + 1});
            setCardsLoadedEventFired(true);
        }
    }, [state, feed, loaded, screenEventFired, cardsLoadedEventFired])

    //Card Loaded event with feed POSITION and feed TYPE
    useEffect(() => {
            if(feed && !isPaid && !isDismissed && !upgradeCardLoaded) {
                track('Card Loaded', {cardType: 'upgradeMirkat+', feedType: 'feed', feedSubType: feed?.feed?.existingRisks?.cards?.length > 0 ? 'risk' : 'safe',
                    feedPosition: (feed.feed?.existingRisks?.cards?.length || 0) + 1 + (showEmail ? 1 : 0) + ((feed?.feed?.breaches?.new || feed?.feed?.breaches?.existing)  ? 1 : 0 )});
                setUpgradeCardLoaded(true)
            }
    }, [feed, isPaid, isDismissed, upgradeCardLoaded])

    //Feed End Reached event
    useEffect(() => {
        const handleScroll = () => {
            const totalPageHeight = document.documentElement.scrollHeight;
            const scrollPoint = window.scrollY + window.innerHeight;
            const distanceToBottom = totalPageHeight - scrollPoint;

            // Check if the distance to bottom is within your threshold
            if (distanceToBottom < 100) { // 100px as threshold
                //#128
                track('Feed End Reached', {eventType: 'feedEndReached', feedType: 'feed'})
                window.removeEventListener('scroll', handleScroll);
            }
        };
        // Add event listener
        window.addEventListener('scroll', handleScroll);
        // Remove event listener on cleanup
        return () => window.removeEventListener('scroll', handleScroll);
    }, [])
}
export default useHomePageAnalytics;

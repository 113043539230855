import React from 'react'

const MobileSidebarCheckmark = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="18" cy="18" r="18" fill="url(#paint0_linear_27238_93081)"/>
          <path d="M25.3466 11.0279L15.2203 21.5359L12.4962 18.2476C11.2954 17.196 9.40703 18.4215 10.2656 19.9975L13.5032 25.7383C14.0166 26.4394 15.2203 27.139 16.4212 25.7383C16.9347 25.0372 26.7186 12.6025 26.7186 12.6025C27.9209 11.2017 26.3763 9.97622 25.3466 11.0264V11.0279Z" fill="white"/>
          <defs>
            <linearGradient id="paint0_linear_27238_93081" x1="0" y1="36" x2="36" y2="36" gradientUnits="userSpaceOnUse">
              <stop stopColor="#FF3F29"/>
              <stop offset="1" stopColor="#FF8E00"/>
            </linearGradient>
          </defs>
        </svg>
    )
}

export default MobileSidebarCheckmark;

import React, { useEffect, useState, useRef, useCallback, useReducer } from "react";
import "../styles/scss/HomePage.scss";
import ReviewBreaches from "../components/ReviewBreaches";
import Card from '../components/Card';
import {useLocation, useNavigate} from "react-router-dom";
import { useAuth } from "../contexts/authContext";
import {useFetch} from "../hooks/useFetch";
import {useModal} from "../contexts/ModalContext";
import Upgrade from "../components/Upgrade";
import PaidSidebar from "../components/PaidSidebar";
import FreeSidebar from "../components/FreeSidebar";
import ThreatHistory from "../components/ThreatHistory";
import Settings from "../components/Settings";
import MobileKebabMenu from "../components/MobileKibabMenu";
import BottomBar from "../components/BottomBar";
import PaidBottomBar from "../components/PaidBottomBar";
import Fade from '@mui/material/Fade';
import useResizeHorizontal from "../hooks/useResizeHorizontal";
import useResizeWithScrollbar from "../hooks/useResizeWithScrollbar";
import useAppleFix from "../hooks/useAppleFix";
import useScrollFix from "../hooks/useScrollFix";
import {useSettings} from "../contexts/settingsContext";
import AlertIcon from "../components/AlertIcon";
import SafeIcon from "../components/SafeIcon";

import useIsPlus from "../hooks/useIsPlus";
import Modal from "@mui/material/Modal";
import useClearModals from "../hooks/useClearModals";
import ErrorModal from "../modals/ErrorModal";
import {screen,track} from "../util/analytics";
import useUpgradeOpacityFix from "../hooks/useUpgradeOpacityFix";
import IntroAddEmailModal from "../modals/IntroAddEmailModal";

import ProgressBar from "../components/ProgressBar";
import useProgressBar from "../hooks/useProgressBar";
import HomepageProgressReducer, {HomepageReducerInitialState} from "../components/HomepageProgressReducer";
import AuthStorageService from "../services/authStorage";

import useAnalyticsCardViewed from "../hooks/useAnalyticsCardViewed";
import useHomePageAnalytics from "../hooks/useHomePageAnalytics";
import ReactPixel from 'react-facebook-pixel';


const storage = new AuthStorageService();

const HomePage = () => {
    const { authState, authService, stateSteady, isReady: authReady} = useAuth();
    const navigate = useNavigate();
    const windowWidth = useResizeHorizontal();
    const withScrollbarWidth = useResizeWithScrollbar();

    const [feed, setFeed] = useState(null);
    const [fadeIn, setFadeIn] = useState(false); //Fade in={} requires a boolean so we don't want to use feed - throws warnings
    const [showUpgrade, setShowUpgrade] = useState(false);
    const [breaches, setBreaches] = useState(null);
    const [feedCards, setFeedCards] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [loaded2, setLoaded2] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const [clearModals, setClearModals] = useState(false);
    const [pullFeed, setPullFeed] = useState(false);

    const isFirstRun = useRef(true);
    const [showFeed, setShowFeed] = useState(false);
    //for deciding on Loading redirect
    const loadingCrumb = storage.getLoadingBreadCrumb();
    const resumedSession = sessionStorage.getItem('resumedSession');
    const welcomeCrumb = sessionStorage.getItem("welcomeReturnBreadCrumb");
    const upgradeCrumb = sessionStorage.getItem("upgradeReturnBreadCrumb");
    const scanningCrumb =  sessionStorage.getItem("scanningReturnBreadCrumb");
    const {setNeedsRefetch} = useSettings();


    //feed -> if we fail -> show error modal and log user out
    const {state, fetch, ready, reset} = useFetch();
    //breaches -> if we fail -> show error modal and log user out
    const {state: state2, fetch: fetch2, ready: ready2} = useFetch();

    //emailDismiss - silently fail/don't care if fail state.
    const {fetch: fetch4} = useFetch();
    //Seen calls -> silently fail/don't care if fail state.
    const {state: state5, fetch: fetch5} = useFetch();
    //emails (For introEmails?)
    const {state: state6, fetch: fetch6, ready: ready6} = useFetch();


    const [isDismissed, setIsDismissed] = useState(false);
    const [showEmail, setShowEmail] = useState(true);
    const [addEmailOpen, setAddEmailOpen] = useState(false);
    const ids = []  //permanently empty

    const {mobileKebab, setModalType,modalType,threatState,setThreatState,showSettings,setShowSettings,setHomePage,homePage,modalCount,setModalCount,hiddenSettings, setHiddenSettings} = useModal();

    const [position, setPosition] = useState('');
    const [emails, setEmails] = useState([]);
    const {isPlus, billingRequestStatus, billingRequestError, iosManaged, expDate, autoRenewStatus, cancelCount, upgradeCount, hasHadTrial} = useIsPlus();
    const [isPaid, setIsPaid] = useState(false);

    const { progressStarted, setNoDelay, noDelay, apiStarted, isComplete, startProgress, handleCompletion,  setLastApiCallStarted, setProgressOverride, progressOverride} = useProgressBar();
    const [fetchStarted, dispatchFetchStarted] = useReducer(HomepageProgressReducer, HomepageReducerInitialState);
    //need to watch various fetches...
    //for analytics
    const [upgradeClickFrom, setUpgradeClickFrom] = useState(null);

    const [addEmailSeen, setaddEmailSeen] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const [addEmailLoaded, setAddEmailLoaded] = useState(false);
    const [showLoad, setShowLoad] = useState(false);
    const [screenEventFired, setScreenEventFired] = useState(false);
    const [cardsLoadedEventFired, setCardsLoadedEventFired] = useState(false);
    const [refreshNeeded, setRefreshNeeded] = useState(false);
    const [settingsSubMenu, setSettingsSubMenu] = useState("");

    //Anayltics
    const [addEmailFeedSubType, setAddEmailFeedSubType] = useState(null);
    const [addEmailFeedPosition, setAddEmailFeedPosition] = useState(null);
    
    const homeEmailIcon = "https://img.mirkat.com/web/images/home/email-card-icon.svg";
    const illustration = "https://img.mirkat.com/web/images/home/illustration.png";
    const homeBreachesIcon = "https://img.mirkat.com/web/images/home/breaches-card-icon.svg";
    const [isNew, setIsNew] = useState(null);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');
    const [breachesFeedSubType, setBreachesFeedSubType] = useState(null);
    const [breachesFeedPosition, setBreachesFeedPosition] = useState(null);
    const [upgradeFeedSubType, setUpgradeFeedSubType] = useState(null);
    const [upgradeFeedPosition, setUpgradeFeedPosition] = useState(null);
    const [upgradeCardLoaded, setUpgradeCardLoaded] = useState(false);
    const style = useUpgradeOpacityFix({showUpgrade, windowWidth});
    const [playAnimation, setPlayAnimation] = useState(null);
    const [isLastRisk, setIsLastRisk] = useState(null);
    const [lastCardDismissed, setLastCardDismissed] = useState(null);
    const [safeTransition, setSafeTransition] = useState(null);

    useHomePageAnalytics({loaded, state, feed, setIsNew, screenEventFired, setScreenEventFired, showErrorModal, setShowErrorModal, authService, cardsLoadedEventFired, setCardsLoadedEventFired, setAddEmailFeedSubType, setAddEmailFeedPosition, showEmail, addEmailLoaded, setAddEmailLoaded, setBreachesFeedSubType, setBreachesFeedPosition, isPaid, isDismissed, setUpgradeFeedSubType, setUpgradeFeedPosition, upgradeCardLoaded, setUpgradeCardLoaded});

    //redirect from stripe -> go to /welcome
    if (sessionId) {
        navigate("/welcome?session_id=" + sessionId);
    }

    //if user has been to Upgrade and navigated Home without completing through onboarding
    useEffect(() => {
        if(storage) {
            if(storage.getOnboardingBreadCrumb() && stateSteady && authState.matches('authenticated')) {
                navigate("/upgrade")
            }
        }
    }, [storage, authState, stateSteady]);

    //controls whether resumed session should see loading animation
    useEffect(() => {
        if (resumedSession && authState.matches('authenticated')) {
            storage.setLoadingBreadCrumb("true");
        } else if (!loadingCrumb && authState.matches('authenticated')) {
            navigate("/loading");
        }

        if (scanningCrumb === "scanning") {
            navigate("/scanning")
        } else if (scanningCrumb === "plus") {
            navigate("/plus")
        }
        if (welcomeCrumb) {
            navigate("/welcome")
        }
        if (upgradeCrumb) {
            navigate("/upgrade")
        }
    }, [authState, stateSteady]);

    //Hook handles in view or not tracking call
    const addEmailCardRef = useAnalyticsCardViewed({cardType:'monitorMoreEmails', feedType: 'feed',  feedSubType: addEmailFeedSubType ,feedPosition: addEmailFeedPosition})
    const breachesCardRef = useAnalyticsCardViewed({cardType:'dataBreaches', feedType: 'feed',  feedSubType: breachesFeedSubType ,feedPosition: breachesFeedPosition})
    const upgradeCardVisibleRef = useAnalyticsCardViewed({cardType:'upgradeMirkat+', feedType: 'feed',  feedSubType: upgradeFeedSubType ,feedPosition: upgradeFeedPosition})

    //clear all open Modals
    useClearModals(clearModals);

    //initialize vars on first page load only
    useEffect(() => {
        ReactPixel.track('ViewContent', {page: 'Homepage'})
        setNeedsRefetch(true) //fetch on component mount
        setIsMounted(true);
        setHomePage(true);
        setProgressOverride(null)
        //setClearModals(true)
        setHiddenSettings(false)

        authService.send('FINISH_PROGRESS') // ensure progress bar restriction doesn't delay or break the ui
        //set isNweUser back to false incase the user bypassed welcome page.
        if(authState.context?.isNewUser) {
            authService.send('CLEAR_ISNEW_USER')
        }
        if(authState.context.disableProgressBar) {
          //  authService.send('ENABLE_PROGRESS_BAR')
        }

        return () => {
            setProgressOverride(null)
            authService.send('FINISH_PROGRESS')
        }
    }, []);

    //back to login if unauthed
    useEffect(() => {
        if(authReady) {
            //stateSteady prevents immediately redirecting to login before state machine has transitioned to a steady state
            if(isMounted && stateSteady && !authState.matches('authenticated')) {
               setProgressOverride(100)
               navigate("/login")
               setClearModals(true);
            }
        }

        if(authState.matches('authenticated.idle') && !authState.matches('authenticated.refreshing') && state2.context.apiClient !== null && ready2 && ready && !loaded2 && !loaded) {
            setNoDelay(true)
            !authState.context.disableProgressBar && startProgress()
            fetch('feed', [authState.context.sessionStamp])
            !authState.context.disableProgressBar && dispatchFetchStarted({ type: 'TOGGLE_FETCH1' })
            //console.log("firing fetch2: ", state)
            //why are we fetching breaches when we don't do anything with the data? we're only using breaches from feed
            fetch2('breaches', [authState.context.sessionStamp]) // this isn't updating if the access token updates.
            !authState.context.disableProgressBar && dispatchFetchStarted({ type: 'TOGGLE_FETCH2' })
        }

        //if we came from scanning/scanning plus, progressbar is disabled by setting the context. after loading home, reenable progress bar.
        if (authState.context.disableProgressBar && state.matches('success') && state2.matches('success')) {
            authService.send("ENABLE_PROGRESS_BAR")
        }
        console.log("AUTHSTATE!: ", authState)
    }, [authState, state, state2, authState, ready, ready2, authReady, isMounted, stateSteady]);

    //progress bar stuff
    useEffect(() => {
        console.log("AUTHSTATE PROGRESS BAR: ", authState.context.disableProgressBar)
        if(fetchStarted.fetch1 && fetchStarted.fetch2 && fetchStarted.fetch6 && !authState.context.disableProgressBar) {
            setLastApiCallStarted(true)
            dispatchFetchStarted({ type: 'TOGGLE_FETCH1' })
            dispatchFetchStarted({ type: 'TOGGLE_FETCH2' })
            dispatchFetchStarted({ type: 'TOGGLE_FETCH6' })
        }

        if((state.matches('success') || state.matches('failure')) &&
            (state2.matches('success') || state2.matches('failure')) &&
            (state6.matches('success') || state6.matches('failure'))) {
            if(!progressOverride) {
                setProgressOverride(100)
            }

            authService.send('FINISH_PROGRESS')
        }
    }, [fetchStarted, state, state2, state6, progressOverride]);

    //set feed object - only if not currently re-pulling feed
    useEffect(() => {
        if (!refreshNeeded) {
            if(state.matches('success')) {
                console.log("CONTEXT FEED DATA:", state.context.data);
                setFeed(state.context.data);
                setFadeIn(true)
                authService.send('UPDATE_USER_EMAILID', {userEmailId: state.context.data?.userEmailId})
                authService.send('UPDATE_PRICE', {price: state.context.data?.price})
                authService.send('UPDATE_DURATION', {duration: state.context.data?.duration})
                setLoaded(true)
            }
            if(state.matches('error')) {
                console.log("error on feed retrieval: ", state.error)
                setShowErrorModal(true)
                //Should sho modal and then fire this off so the user knows something unrecoverable happened
                authService.send('LOGOUT')
            
            }
        }
    }, [state]);

    //re-open Upgrade modal when returning from stripe
    useEffect(() => {
    	if(state2.matches('success')) {
    	    setBreaches(state2.context.data); // why do we set this when we don't use breaches state var?
    	    setLoaded2(true)
    	}

        if(billingRequestStatus === "success") {
            setIsPaid(isPlus);
            const prevPlan = storage.getBreadCrumb()
            const etype = storage.getEntryType()

            //if entry type was settings, set show settings
            if(prevPlan) {
                // check entry type: -> settings, footer, upgrade card, signUp(upgrade page)

                //if(prevPlan === 'f' && !autoRenewStatus) {

                //entry type -> signUp, footer, upgrade card, settings
                let entryType = etype === 's' ? 'settings' : (etype === 'f' ? 'footer' : (etype === 'c' ? 'upgradeCard' : 'signUp'))
                console.log("Cancel Count: ", cancelCount)
                console.log("Upgrade Count: ", upgradeCount)
                if(prevPlan === 'p' && !autoRenewStatus) {
                    //user cancelled
                    track('Plan Changed', {
                        eventType: 'planChanged',
                        eventSubType: 'cancelled',
                        planType: 'free',
                        entryType: entryType,
                        occurrence: (cancelCount > 1 ? 'repeat' : 'first'),
                    })
                }
                if(prevPlan === 'f' && autoRenewStatus) {
                    //user upgraded
                    track('Plan Changed', {
                        eventType: 'planChanged',
                        eventSubType: 'upgraded',
                        planType: 'paid',
                        entryType: entryType,
                        occurrence: (upgradeCount > 1 ? 'repeat' : 'first'),
                    })
                }
                //renewed
                if(prevPlan === 'p' && autoRenewStatus) {
                    //user upgraded
                    track('Plan Changed', {
                        eventType: 'planChanged',
                        eventSubType: 'upgraded',
                        planType: 'paid',
                        entryType: entryType,
                        occurrence: (upgradeCount > 1 ? 'repeat' : 'first'),
                    })

                }
                if (prevPlan === 'f' && !isPlus) {
                    if (entryType === 'footer') {
                        setShowUpgrade(true);
                    } else if (entryType === 'upgradeCard') {
                        setShowUpgrade(true);
                    }
                }

                if (prevPlan === 'p' && isPlus) {
                    if (entryType === 'settings') {
                        setShowSettings(true)
                        setSettingsSubMenu('subscription')
                    }
                }

                storage.removeEntryType()
                storage.removeBreadCrumb()
            }
        }

        if(billingRequestStatus === "failure") {
            //should notify user?
        }

    }, [state2,showUpgrade, isPlus, billingRequestStatus, billingRequestError, autoRenewStatus, showErrorModal, cancelCount, upgradeCount]);

    //set breadCrumbs for re-opening Upgrade modal on return from stripe
    useEffect(() => {
        if(showUpgrade && upgradeClickFrom) {
            screen('Upgrade', {screenType: 'upgrade', entryType: 'footer'})
            setUpgradeClickFrom(null)
            //we don't care if it's bottomBar or sidebar anymore
            if(upgradeClickFrom) {
                //#373 //not specified for sideBar
                track('Button Tapped', {buttonType: 'upgrade', screenType: 'feed'})
                storage.setBreadCrumb('f')
                storage.setEntryType('f')
            }
        }
    }, [showUpgrade, upgradeClickFrom]);

    //Review Breaches button clicked on
    const onClickReviewBreaches = () => {
        //state6.context.data.emails.map(item => item.emailAddress
        let newBreaches = false
        if(state2.matches('success')) {
            state2.context.data?.dataBreaches.forEach(item => {
                if(item?.new) {
                    newBreaches = true
                }

            })
        }
        if(state2.matches('failure')) {
            console.log("error on breaches retrieval: ", state2.error)
            setShowErrorModal(true)
            //Should sho modal and then fire this off so the user knows something unrecoverable happened
            setTimeout(() =>
                    authService.send('LOGOUT')
                , 5000)
            return
        }
        if (isPaid) {
            setShowFeed(!showFeed);
        } else {
            screen("Upgrade", {screenType: 'upgrade', entryType: 'card'})
            setShowUpgrade(!showUpgrade);
        }
        //#413
        track('Card Button Tapped', {buttonType: 'action', cardType: 'dataBreaches',
            numBreaches: state2.context.data?.dataBreaches?.length || 0,
            newBreaches: newBreaches,
            feedType: 'feed',
            feedSubType: feed.feed?.existingRisks?.cards?.length > 0 ? 'risk' : 'safe'})
    }

    //Upgrade card button clicked
    const onClickGettingStarted = () => {
        screen("Upgrade", {screenType: 'upgrade', entryType: 'card'})
        storage.setEntryType('c')
        //#391 or #398 now?
        track('Card Button Tapped', {buttonType: 'action', cardType: 'upgradeMirkat+', feedType: 'feed',
            feedSubType: feed.feed?.existingRisks?.cards?.length > 0 ? 'risk' : 'safe'})
        setShowUpgrade(!showUpgrade)
    }

    //addEmail button to open modal
    const addEmail = () => {
        //#292
        track('Card Button Tapped', {buttonType: 'action', feedType: 'feed',
            feedSubType: feed.feed?.existingRisks?.cards?.length > 0 ? 'risk' : 'safe', cardType: 'monitorMoreEmails'})
        setAddEmailOpen(true);
    }
    
    //Dismiss for the upgrade card - check API for resumed session state
    useEffect(() => {
        if (authState.matches('authenticated')) {
    	    if (authState.context.dismissed === "true") {
                setIsDismissed(true);
    	    }
        }
    }, [authState]);
    

    //Dismiss for the upgrade card
    const handleDismiss = () => {
        //#297
        track('Card Button Tapped', {buttonType: 'dismiss', cardType: 'upgradeMirkat+', feedType: 'feed', feedSubType: feed.feed?.existingRisks?.cards?.length > 0 ? 'risk' : 'safe'})
        authState.context.storage.setDismissed(true)
        setIsDismissed(true)
    }

    //Dismiss for Add Email
    const handleDismissEmail = () => {
        //#297
        track('Card Button Tapped', {buttonType: 'dismiss', cardType: 'monitorMoreEmails', feedType: 'feed', feedSubType: feed.feed?.existingRisks?.cards?.length > 0 ? 'risk' : 'safe'})
	fetch4('emailDismiss')
	setShowEmail(false)
    }

    //fetch emails
    useEffect(() => {
    	if (ready6 && state6.matches('idle')) {
            fetch6('emails');
            !authState.context.disableProgressBar && dispatchFetchStarted({ type: 'TOGGLE_FETCH6' })

        }
    }, [state6, ready6]);
    
    //fill emails object
    useEffect(() => {
    	if (state6.matches('success')) {
            setEmails(state6.context.data.emails.map(item => item.emailAddress));
        }
    }, [state6, ready6]);

    
    //makes /seen call with empty array to initialize new users, who have no cards yet
    const seenCard = () => {
	fetch5('seen',[ids])  
    }

    //hook to check if page loaded then call method above
    useEffect(() => {
	if (loaded) {
	    if (!state?.context?.data?.feed?.existingRisks?.cards?.length) {
	   	seenCard()
	    }
	}
    }, [feed]);

    useAppleFix({mobileKebab, threatState, showFeed, addEmailOpen});
    useScrollFix({showFeed, showUpgrade, showSettings, addEmailOpen, threatState, modalCount, setModalCount});

    //pull Feed when new email address added
    useEffect(() => {
	if (pullFeed) {
                setPullFeed(false);
                if (showSettings) {
                    setShowSettings(!showSettings);
                    if (hiddenSettings) {
                        setHiddenSettings(!hiddenSettings)
                    }
                    //setClearModals(true);
                    setShowLoad(true);
                } else {
                    //setClearModals(true);
                    setShowLoad(true);
                }
	    }
    }, [pullFeed]);
    
    //reset the sessionStamp to force New cards
    useEffect(() => {
        if (showLoad) {
            authService.send('UPDATE_SESSION_STAMP')
            if (isPlus) {
                storage.setPaidReplayBreadCrumb("false");
                navigate("/plus");
            } else {
                storage.setFreeReplayBreadCrumb("false");
                navigate("/scanning");
            }
        }
    }, [showLoad]);

    //for re-pulling feed when Card dismissed - pulled once on page load already
    useEffect(() => {
        if (refreshNeeded) {
            console.log("resetting and re-fetching");
            reset();
	    fetch('feed', [authState.context.sessionStamp]);
            if(state.matches('success')) {
    	        console.log("CONTEXT FEED DATA ON REFRESH FROM CARD DISMISS:", state.context.data);
    	        setFeed(state.context.data);
                setRefreshNeeded(false);
    	    }
            if(state.matches('failure')) {
                setShowErrorModal(true);
    	        console.log("error on feed retrieval: ", state.error)
                authService.send('LOGOUT')
            }
        }
    }, [refreshNeeded, state, showErrorModal]);

    //to prevent normal card behavior when last card dismissed on mobile
    useEffect(() => {
        if (feed?.feed?.existingRisks?.cards.length === 1) {
            setIsLastRisk(true);
        }
    }, [feed]);

    //play smooth animation on mobile when last card in feed dismissed
    useEffect(() => {
        let timerId = null;
        let timerId2 = null;

        if (windowWidth < 840) {
            if (lastCardDismissed) {
                setFadeIn(false);
                let timerId = setTimeout (() => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'auto' // for smoothly scrolling
                    });
                    setSafeTransition(true);
                    //setRefreshNeeded(true);
                    setPlayAnimation(true);
                }, 400);
                let timerId2 = setTimeout (() => {
                    setFadeIn(true);
                }, 600);
            }    
        }
            
        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
            if (timerId2) {
                clearTimeout(timerId2);
            }
        };
    }, [lastCardDismissed]);
        

    return (

         <>{progressStarted && <ProgressBar progressOverride={progressOverride} apiStarted={apiStarted} onCompleted={handleCompletion} setProgressOverride={setProgressOverride} noDelay={noDelay} />}
             {showErrorModal && <ErrorModal props={true} dim={true} callback={()=> {authService.send('LOGOUT')}} screenType='feed' screenSubType='feed' />}
            {addEmailOpen && <IntroAddEmailModal props={{homeCallback: setAddEmailOpen, refreshCallback: setPullFeed, fromFeed: true, windowWidth: windowWidth}} />}
                <Modal open={showFeed}>
                    <ReviewBreaches parentState={showFeed} changeState={setShowFeed} windowWidth={windowWidth} props={{'feedSubType' : feed?.feed?.existingRisks?.cards?.length > 0 ? 'risk' : 'safe', 'feedPosition': 1}}/>
                </Modal>
	 {windowWidth > 839 && <Modal open={showUpgrade}><div className="home-upgrade" style={style}><Upgrade parentState={showUpgrade} changeState={setShowUpgrade} setIsPlus={setIsPaid} hasHadTrial={hasHadTrial} props={{'feedSubType' : feed?.feed?.existingRisks?.cards?.length > 0 ? 'risk' : 'safe'}}/></div></Modal>}
	 {windowWidth < 840 && <Modal open={showUpgrade}><div className="home-upgrade" style={style}><Upgrade parentState={showUpgrade} changeState={setShowUpgrade} setIsPlus={setIsPaid} hasHadTrial={hasHadTrial} props={{'feedSubType' : feed?.feed?.existingRisks?.cards?.length > 0 ? 'risk' : 'safe'}}/></div></Modal>}
	 <Modal open={threatState} disableEscapeKeyDown={true}><ThreatHistory parentState={threatState} changeState={setThreatState} /></Modal>
	 <Modal open={showSettings}><Settings emails={emails} parentState={showSettings} changeState={setShowSettings} refreshCallback={setPullFeed} openSettingsMenu={settingsSubMenu} /></Modal>
         <Fade in={fadeIn} timeout={500}>
	  <div className="main-container" >
	    <div className="feed-container">

	      <div className={`feed-header ${playAnimation ? 'slide-in' : ''}`}>
	        <div className="home-inner-container">
	          <div className="top-container">
		    {feed ? (!safeTransition ? (feed.feed.threatSummary.header === "You're at risk!" ? <AlertIcon /> : <SafeIcon />) : <SafeIcon />) : null }
                    {windowWidth < 840 && <div className="home-kebab"><MobileKebabMenu /></div>}
		  </div>
		  <div className="title">{feed ? (!safeTransition ? feed.feed.threatSummary.header : feed.feed.safeTransition.title) : "Loading" }</div>
		  <div className="threat-status-detail">
		    <div className="threat-status-detail-inner-container">
		      {feed ? (!safeTransition ? <div dangerouslySetInnerHTML={{ __html: feed?.feed?.threatSummary?.detail }} /> : <div dangerouslySetInnerHTML={{ __html: feed?.feed?.safeTransition?.description }} />) : <span style={{ color: "#FFF" }}>Loading</span>}
		    </div>
		  </div>
	        </div>
	      </div>

	      <div className={`feed-container-cards ${playAnimation ? 'slide-up' : ''}`}>

	        {feed && feed.feed.existingRisks && feed.feed.existingRisks.cards.map((item,index) => (
	                    <Card
	                        key={item.id}
	                        id={item.id}
	                        title={item.title}
	                        exposedDataTitle={item.exposedDataTitle}
	                        description={item.description}
	                        bannerImageUrl={item.bannerImageUrl}
	                        cardData={item.cardData}
	                        actions={item.actions}
	                        feedCard={item}
                                riskType={item.rType}
                                riskCategoryType={item.rcType}
                                completedType={(item?.completedAt ? 'handled' : (item?.dismissedAt ? 'skipped' : '' )) }
                                entityId={item?.entityId || ''}
                                feedPosition={item?.order + 1}
                                status={item?.state}
                                setRefreshNeeded={setRefreshNeeded}
                                windowWidth={windowWidth}
                                setShowErrorModal={setShowErrorModal}
                                isLastRisk={isLastRisk}
                                setLastCardDismissed={setLastCardDismissed}
                                setSafeTransition={setSafeTransition}
                                safeTransition={safeTransition}
	                    />  : null
                ))}


		{feed && feed.feed.breaches.detail !== "" ? <div className="breaches-card" ref={breachesCardRef}>
	    	  <div className="breaches-card-header-container">
	    	    <img src={homeBreachesIcon} className="breaches-card-header-icon"></img>
	            {isNew ? <div className="feedcard-status-container" style={{padding: "unset"}}><div className="feedcard-status">NEW</div></div> : null }
	    	    <div className="breaches-card-header">{feed.feed.breaches.title}</div>
	    	  </div>
	    	  <div className="breaches-card-description-container">
	    	    <div className="breaches-card-description">{feed.feed.breaches.detail}</div>
	    	  </div>
	          <div className="breaches-card-button-container">
	            {isPaid ? <button onClick={onClickReviewBreaches} className="breaches-card-button">Review data breaches</button> :
                        <button onClick={onClickReviewBreaches} className="breaches-card-button">Review with Mirkat+</button>}
	    	  </div>
	        </div>: null
		}

		{feed && feed.feed.addEmail && showEmail &&
	        <div ref={addEmailCardRef} className="email-card">
	    	  <div className="email-card-header-container">
	    	    <img src={homeEmailIcon} className="email-card-header-icon"></img>
	    	    <div className="email-card-header">{feed.feed.addEmail.label}</div>
	    	  </div>
	    	  <div className="email-card-description-container">
	    	    <div className="email-card-description">{feed.feed.addEmail.description}</div>
	    	  </div>
	    	  <div className="email-card-button-container">
	    	    <button className="email-card-button noselect" onClick={addEmail}>Add email address</button>
	    	    <button className="email-card-dismiss noselect" onClick={handleDismissEmail}>Dismiss</button>
	    	  </div>
	        </div>
		}

		{feed && !isPaid && !isDismissed ?

	        <div className="upgrade-card" ref={upgradeCardVisibleRef}>
		  <img className="upgrade-card-img" src={illustration} />
		  <div className="upgrade-card-header-container"><span className="upgrade-card-header">Find more threats with <span className="upgrade-card-span">Mirkat+</span></span></div>
		  <div className="upgrade-card-description">Scan the dark web for more than just passwords, including account credentials, bank accounts, credit cards, and more.</div>
		  <div className="upgrade-card-buttons-container">
		    <div className="upgrade-card-button noSelect" id="gettingstartedfree" onClick={onClickGettingStarted}>Get started for free</div>
		    <div className="upgrade-card-dismiss noSelect" id="upgradeCardDismiss" onClick={handleDismiss}>Dismiss</div>
		  </div>
		</div> : null
		}

	      </div>

	    </div>
            {feed && <>
	        {windowWidth >= 840 && isPaid && <PaidSidebar />}
                {windowWidth >= 840 && !isPaid && <FreeSidebar parentState={showUpgrade} changeState={setShowUpgrade} upgradeClickFrom={setUpgradeClickFrom}
                                                               feedSubType={feed.feed?.existingRisks?.cards?.length > 0 ? 'risk' : 'safe'} feedPosition={(feed.feed?.breaches?.existing || 0) + 3 - (isDismissed ? 1 : 0) - (showEmail ? 1 : 0)} />}
                {windowWidth < 840 && !isPaid && <BottomBar animation={playAnimation} parentState={showUpgrade} changeState={setShowUpgrade} upgradeClickFrom={setUpgradeClickFrom}
                                                            feedSubType={feed.feed?.existingRisks?.cards?.length > 0 ? 'risk' : 'safe'} feedPosition={(feed.feed?.breaches?.existing || 0) + 3 - (isDismissed ? 1 : 0) - (showEmail ? 1 : 0)}/>}

                {windowWidth < 840 && isPaid && <PaidBottomBar animation={playAnimation} parentState={showUpgrade} changeState={setShowUpgrade} />}
            </>}
        </div>
      </Fade>
      </>
    );
}

export default HomePage;

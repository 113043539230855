import React, {useState, useEffect} from "react";
import styles from "../styles/scss/Scanning.module.scss";
import {useNavigate} from "react-router-dom";
import Lottie from 'lottie-react';
//import animationTwo from '../assets/animations/animationTwo.json';
import {useAuth} from "../contexts/authContext";
import AuthStorageService from "../services/authStorage";
import fetchWithRetry from "../util/simpleFetch.js";
import {preloadTestRiskImages} from "../util/imageCache";

const storage = new AuthStorageService();


const DeepLink = () => {
    const {authService, authState, stateSteady} = useAuth();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const dynamic = {height: windowWidth > 500 ? "unset" : windowHeight}
    const [isStopped, setIsStopped] = useState(false);
    const style = {width: windowWidth > 500 ? "330px" : "100%"}
    const navigate = useNavigate();
    const paidCrumb = storage.getPaidReplayBreadCrumb();
    const [animation, setAnimation] = useState(null);
    const url = 'https://img.mirkat.com/web/animations/animationTwo.json'

    useEffect(() => {
        preloadTestRiskImages()
        fetchWithRetry(url)
            .then(response => response.json())
            .then(data => {
                setAnimation(data);
            })
            .catch(error => {
                console.error('failed to get loading animation from bucket', error);
            });
    }, []);

    useEffect(() => {
        if(stateSteady && !authState.matches('authenticated')) {
            sessionStorage.removeItem("scanningReturnBreadCrumb");
            navigate("/login")
        }
    }, [authState, stateSteady]);

    useEffect(() => {
        storage.removeOnboardingBreadCrumb('t') //true
        sessionStorage.setItem("scanningReturnBreadCrumb", "plus");
    }, []);

    useEffect(() => {
        console.log("PAIDCRUMB: ", paidCrumb);
        if (paidCrumb === "true") {
            sessionStorage.removeItem("scanningReturnBreadCrumb");
            navigate("/");
        }
    });


    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    //useEffect(() => {
    //    //setTimeout(() => window.location.href = "/", 22500);
    //    setTimeout(() => setIsStopped(true), 22500);
    //    console.log("timeout starts NOW");
    //}, []);

    //useEffect(() => {
    //    if (isStopped) {
    //        authService.send("DISABLE_PROGRESS_BAR");
    //        navigate("/");
    //        storage.setPaidReplayBreadCrumb("true");
    //        console.log("NAVIGATING");
    //    }
    //}, [isStopped])
    const proceedHome = () => {
        setIsStopped(true);
        authService.send("DISABLE_PROGRESS_BAR");
        storage.setPaidReplayBreadCrumb("true");
        sessionStorage.removeItem("scanningReturnBreadCrumb");
        navigate("/");
    };

    return (
        <div className={styles["main-container"]} style={dynamic}>
            <Lottie 
                animationData={animation}
                isStopped={isStopped}
                style={style}
                loop={false}
                autoplay={true}
                rendererSettings={{
                    preserveAspectRatio: 'xMidYMid slice'
                }}
                onComplete={proceedHome}
            />
        </div>
    );
}

export default DeepLink;

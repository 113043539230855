import styles from "../styles/scss/Spinner.module.scss";
import React from "react";


const Spinner = () => {
    return (
        <div className={styles["spinner-center"]}>
            <div className={styles["spinner-blade"]}></div>
            <div className={styles["spinner-blade"]}></div>
            <div className={styles["spinner-blade"]}></div>
            <div className={styles["spinner-blade"]}></div>
            <div className={styles["spinner-blade"]}></div>
            <div className={styles["spinner-blade"]}></div>
            <div className={styles["spinner-blade"]}></div>
            <div className={styles["spinner-blade"]}></div>
            <div className={styles["spinner-blade"]}></div>
            <div className={styles["spinner-blade"]}></div>
            <div className={styles["spinner-blade"]}></div>
            <div className={styles["spinner-blade"]}></div>
        </div>
    );
}

export default Spinner;
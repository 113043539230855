import React, { useState } from 'react';
import '../styles/scss/CardList.scss';
import Details from './Details';
import {screen} from "../util/analytics";

const DetailsList = ({ emailId, data, details, isLoading }) => {


    return (
        <div className="feedcard-list">
            {details &&
                <Details
		    data={data}
		    details={details}
                />
            };
        </div>
    );
}

export default DetailsList;

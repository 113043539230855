import React, { useContext } from "react";
import AuthStorageService from "../services/authStorage";
export const StorageContext = React.createContext({
    storage: null,
});

export const useStorage = () => {
    return useContext(StorageContext);
}

export const StorageProvider = ({ children }) => {
    const storage = new AuthStorageService();

    return (
        <StorageContext.Provider value={{ storage }}>
            {children}
        </StorageContext.Provider>
    );
}

import React, {useEffect, useRef, useState} from "react";
import '../styles/scss/ConfirmForm.scss';
import {useAuth} from "../contexts/authContext";
import Button from "./Button";
import useFormHandler from "../hooks/useFormHandler";
import styles from "../styles/scss/Confirm.module.scss"
import {useModal} from "../contexts/ModalContext";
import {useFetch} from "../hooks/useFetch";
import {Controller, useForm, useWatch} from "react-hook-form";
import Input2 from "./Input2";
import {retryableError} from "../util/retryableError";
import {useLocation, useNavigate} from "react-router-dom";
import Fade from '@mui/material/Fade';
import {track,screen} from "../util/analytics";
import ProgressBar from "./ProgressBar";
import useProgressBar from "../hooks/useProgressBar";
import {useGetAllQueryStringParamsAsString, useGetConfirmationToken} from "../hooks/useQueryStringParams";
import useConfirmPoll from "../hooks/useConfirmPoll";
import DeepLinkEmail from "./DeepLinkEmail";
import ErrorModal from "../modals/ErrorModal";
import useDetectNetwork from "../hooks/useDetectNetwork";
import AuthStorageService from "../services/authStorage";

const storage = new AuthStorageService();


const ConfirmForm = ({prevForm, setStep, regSend, regState}) => {
    const { register, handleSubmit, formState,
        setValue,
        control, reset, formState: {errors}} = useForm({mode: "onChange", defaultValues: {code: ""}});
    const fieldState = useWatch({control, name: 'code'});
    const [resetKey, setResetKey] = useState(Date.now());
    const {authService, authState, isReady, stateSteady} = useAuth();
    const codeRef = useRef();
    const {isFormCompleted, handleInputChange} = useFormHandler({code: ""})
    const [code, setCode] = useState(null);
    const [error, setError] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const {toggleModal,setModalType} = useModal();
    const [email, setEmail] = useState("");
    const {state, uiErrorMsg, isLoading, fetch, reset: formReset} = useFetch();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const dynamic = {height: windowWidth > 839 ? "unset" : windowHeight}
    const [isLoaded, setIsLoaded] = useState(false);
    const { progressStarted, apiStarted, isComplete, startProgress, handleCompletion, setNoDelay, noDelay, setLastApiCallStarted, setProgressOverride, progressOverride, resetProgressBar} = useProgressBar();
    const navigate = useNavigate()
    const location = useLocation()
    const [verifySubmitted, setVerifySubmitted] = useState(false);
    //confirm email/device token
    const {state: state2, uiErrorMsg: uiErrorMsg2, isLoading: isLoading2, fetch: fetch2, ready} = useFetch();
    //secondary confirmation email
    const {state: state3, uiErrorMsg: uiErrorMsg3, isLoading: isLoading3, fetch: fetch3, ready: ready3} = useFetch();
    const {success, error: pollingError, resetPolling, startPolling, state: pollingState , pollRunning} = useConfirmPoll()
    const [deviceTypeId, setDeviceTypeId] = useState(null);
    const [errorCode, setErrorCode] = useState(null);
    //const [errorModalVisible, setErrorModalVisible] = useState(false);



    ////This is for the deeplink verification
    //if we hit here without /verify/sometoken
    const {qConfToken} = useGetConfirmationToken();
    const {qParams} = useGetAllQueryStringParamsAsString();

    const inputRef = useRef(null);
    const leftbutton = "https://img.mirkat.com/web/icons/leftbutton.svg";

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    });

    useEffect(() => {
        if(pollingError && !submitted) {
            setError(pollingError)
        }
    }, [pollingError, submitted]);

    const isOnline = useDetectNetwork();
    useEffect(() => {
        if(!isOnline){
            setError("No network connection")
        }
        if(isOnline && error === "No network connection") {
            setError("")
        }
    }, [isOnline])

    //Transitioning from login or register ends up here
    useEffect(() => {
        if(authState.matches('confirm') && stateSteady) {
            if(!location.pathname.startsWith("/verify") && !location.pathname.startsWith("/verifysec")) {
                if (pollingState.matches('idle') && !pollRunning && !success) {
                    startPolling()
                }
                if (pollingState.matches('success') && !success) {
                    //check data for accessKey - if no accessKey -> was a user signup not new device
                    if(!pollingState.context?.data?.accessKey && pollingState.context?.data?.emailAddress) {
                        track('User Sign Up', {
                            eventType: 'userSignUp',
                            eventSubType: 'pinAccepted',
                            entryType: 'deepLink',
                        })
                    }

                }
                if (pollingState.matches('failure') && !success) {
                    //Todo: what do we want to do if polling fails?
                    setErrorCode()
                }
            }

        }
        console.log("Polling state: ", pollingState)
        console.log("State steady: ", stateSteady)
        console.log("AuthState: ", authState)
    }, [authState, stateSteady, pollingState, pollRunning, qConfToken, qParams]);

    //verification - deeplink
    useEffect(() => {
        //check if location is /verify
        //if (location.pathname.startsWith("/verify") || location.pathname.startsWith("/confirm")) { // once dev is done use this
        if (location.pathname.startsWith("/verify")) {
            //if we are in verify, check it has a token, if not redirect to login.
            storage.setIsVerifyUri()
            if ((!qConfToken || !qParams) && isLoaded) {
                if (!prevForm || !regSend || !regState) {
                    if(!storage.getIsVerifyUri()) {
                        navigate("/login")
                    }
                }
            }
        }
    }, [qConfToken, qParams, isLoaded])


    useEffect(() => {
        if(location.pathname.startsWith("/verifysec/")) {
            storage.setIsVerifyUri()
            if(state3.matches("idle") && qConfToken && qParams && isReady && stateSteady && !verifySubmitted) {
                startProgress()
                setLastApiCallStarted(true)
                fetch3('confirmTokenSecondaryEmails', [qConfToken, qParams])
                setVerifySubmitted(true)
            }
        }

        if(location.pathname.startsWith("/verify/")) {
            storage.setIsVerifyUri()
            if(state2.matches("idle") && qConfToken && qParams && isReady && stateSteady && !verifySubmitted) {
                startProgress()
                setLastApiCallStarted(true)
                fetch2('confirmToken', [qConfToken, qParams])
                setVerifySubmitted(true)
            }
        }

        if(state2.matches("success") && verifySubmitted) {
            setProgressOverride(100)
            if(state2.context.data?.errorCode) {
                setErrorCode(state2.context?.error?.errorCode)
            } else {
                setDeviceTypeId(state2.context.data?.deviceTypeId)

            }
        }

        if(state2.matches("failure") && verifySubmitted) {
            setProgressOverride(100)
            if(state2.context.data?.errorCode) {
                setErrorCode(state2.context?.error?.errorCode)
            } else {
                setDeviceTypeId(state2.context.data?.deviceTypeId)
            }
        }


        //secondary email deeplink
        if(state3.matches("success") && verifySubmitted) {
            setProgressOverride(100)
            setDeviceTypeId(state3.context.data?.deviceTypeId)
        }
        //secondary email deeplink
        if(state3.matches("failure") && verifySubmitted) {
            setProgressOverride(100)
            setErrorCode(state3.context?.error?.errorCode)
        }

        if(!location.pathname.startsWith("/verifysec/")) {
            if (authState.matches('authenticated')) {
                if(!storage.getIsVerifyUri()) {
                    navigate("/")
                }
            }
        }

        if (authState.matches('unauthenticated') && stateSteady) {
           if (!location.pathname.startsWith("/verify")) {
              console.log("qConfToken: ", qConfToken)
              console.log("qParams: ", qParams)
              goBack()
          }
        }
        if(authState.matches('needPw')) {
            if(!storage.getIsVerifyUri()) {
                navigate("/password")
            }
        }
        if(authState.matches('confirm') && stateSteady && authState.context.errorData && !verifySubmitted) {
            if (authState.context.errorData.errorCode === 37) {
                authService.send("CLEAR_ERROR") //prevent loop.
                setTimeout(() => setError("Confirmation code expired. Please click Resend code."), 650);
            }
        }

        if(state2.matches("failure") && verifySubmitted) {
            setProgressOverride(100)
            let {errMsg} = retryableError({errorBody: state2.context.error})
            setTimeout(() => setError(errMsg), 650);
            if(state2.context.error?.errorCode) {
                setErrorCode(state2.context?.error?.errorCode)
            } else {
                setDeviceTypeId(state2.context.error?.deviceTypeId)

            }
            //setVerifySubmitted(false)
            // authService.send('CONFIRM_TOKEN_FAILURE', {data: state.context.data}) // maybe drop to confirm page with error message in modal?
        }

        if(state3.matches("failure") && verifySubmitted) {
            setProgressOverride(100)
            console.log("UI ERROR MSG: ", uiErrorMsg3)
            let {errMsg} = retryableError({errorBody: state3.context.error})
            setTimeout(() => setError(errMsg), 650);
            //setVerifySubmitted(false)
            // authService.send('CONFIRM_TOKEN_FAILURE', {data: state.context.data}) // maybe drop to confirm page with error message in modal?
        }

    }, [qConfToken, qParams, ready, ready3, state, isReady, authState, stateSteady, fetch, isLoaded, progressStarted])

    ///////////////////////////////////////////////////////////////////////////


    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useEffect(() => {
        //if we want screen to be run everytime, we can change this
        //spec is for Sign Up, but we also use this component for new device verification
        if(prevForm === "register") {
            screen("Sign-up: Confirmation", {screenType: "signUp", screenSubType: "emailConfirmation"})
        }

        setSubmitted(false); //ensure false
        setIsLoaded(true)
        authService.send('FINISH_PROGRESS')
        return () => {
            setIsLoaded(false)
            setSubmitted(false)
            setCode(null)
            authService.send('FINISH_PROGRESS')
            resetProgressBar()
        }
    }, []);

    useEffect(() => {
        if(state.matches('failure')) {
            setProgressOverride(100)
            let {canRetry, errMsg} = retryableError({errorBody: state.context.error})
            setError(errMsg);
            setTimeout(() => {
                setError(null)
            }, 5000)
            formReset();
        }

        //Resend email
        if(state.matches('success')) {
            setProgressOverride(100)
            setError("");
            setModalType({modalType: 'codeResent', email: email});
            toggleModal();
            resetPolling()
            startPolling()
            formReset();
        }


    }, [state]);

    useEffect(() => {

        //Need to refactor fetchMachine to be a hook, wrapping the error handling around it.
        if (code && !submitted) {
            setTimeout(() => {authService.send({ type: 'CONFIRM', code: code})}, 200);

            setSubmitted(true)
        }




        if(authState.matches('confirm') && authState.context.errorData && submitted && authState.context.progressWait) {
            setProgressOverride(100)
            let {errMsg} = retryableError({errorBody: authState.context.errorData})
            authService.send('CLEAR_ERROR_DATA')
            setError(errMsg);
            setResetKey(Date.now())
            if(code){
                //#60
                track("User Sign Up", {
                    eventType: "userSignUp",
                    eventSubType: "error",
                    stepType: "pin",
                    errorCode: authState.context.errorData?.errorCode||null,
                })
            }
            reset({
               code: '',
            })
            setSubmitted(false);
            setCode(null);
            authService.send('FINISH_PROGRESS')

        }

        if(authState.matches('authenticated') && submitted && authState.context.progressWait) {
            setProgressOverride(100)
            authService.send('FINISH_PROGRESS')
            if(!storage.getIsVerifyUri()) {
                navigate("/loading")
            }

        }

        if(authState.matches('needPw') && submitted && code && authState.context.progressWait) {
            setProgressOverride(100)
            authService.send('FINISH_PROGRESS')
            //#54
            track('User Sign Up', {
                eventType: 'userSignUp',
                eventSubType: 'pinAccepted',
                entryType: 'manual',
            })

            console.log("NEEDS PW")
            if(regSend) {
                regSend('PASSWORD')
                setSubmitted(false);
                setCode(null);
            }

        }
    }, [state, submitted, authState, regState, submitted, code, isComplete])

    useEffect(() => {
        if (!prevForm) {
            setEmail(authState?.context?.emailAddress)
        }
        if(prevForm === "register") {
            setEmail(regState.context.email)
        }
        if(prevForm === "login") {
            setEmail(authState.context.emailLogin)
        }
    }, [prevForm, regState, authState])

    useEffect(() => {
        if(authState.matches('ERROR')) {
            goBack();
        }

    }, [authState]);

    const onSubmit = (data) => {
        //#10
        track("Button Tapped", {
            buttonType: "next",
            screenType: "signUp",
            screenSubType: "emailConfirmation"
        })
        const code = data.code
        //error code 3 - send back to registration page if registration not complete (needs_confirm)
        // we have 2 scenerios... 1 confirm user, 2 confirm register... -> to passwordform
        console.log("CODE: ", code)
        setError("")
        setCode(code)
        startProgress()
        setLastApiCallStarted(true)
        authService.send('START_PROGRESS')

    }

    //resend code works but we don't currently give the user feedback that the resend was successful.
    const resendOnClick = (event) => {
        //#13
        track("Button Tapped", {
            buttonType: "resentCode",
            screenType: "signUp",
            screenSubType: "emailConfirmation"
        })
        event.preventDefault();
        fetch('resendConfirm', [authState.context.userId, authState.context.emailId])
    }

    const goBack = () => {
        setError(null)
        if(prevForm === "login" && regSend) {
            track("Button Tapped", {buttonType: 'back', screenType: 'signIn', screenSubType: 'emailConfirmation'})
            regSend('BACK')
        }
        if (prevForm === "register" && regSend) {
            track("Button Tapped", {buttonType: 'back', screenType: 'signUp', screenSubType: 'emailConfirmation'})
            regSend('BACK')
            //authService.send('REGISTER')
        }
        if (!prevForm) { //Handle if someone lands here from verify deeplink where props would be undefined
            navigate("/login")
        }
    }
    //const latestValue = useRef(fieldState)
    /* // we may want to not show any error when entering the code.
    useEffect(() => {
        latestValue.current = fieldState;
        if(formState.errors?.code?.type === "minLength" || formState.errors?.code?.type === "maxLength"|| formState.errors?.code?.type === "pattern") {
            setTimeout(() => {
                //if(formState.errors?.code && latestValue.current === fieldState) {
                //    setError("Code must be 6 digits")
                //}
            }, 650)
        }
        console.log("control: ", errors)
        //console.log("FieldState: ", fieldState)
        console.log("FormState, FieldState: ", formState, fieldState)
        console.log("FormState: ", formState?.errors?.type)
    }, [formState]);
     */
    //currently need to only enable button after 4 or more chars...
        
        //not showing modal at this time - this goes before Fade if modal is wanted
        //{errorModalVisible && <ErrorModal props={errorModalVisible} dim={true} callback={setErrorModalVisible} />}
    const validateCode = (code) => {
        if(error) {
            setError(null)
        }
        return true
    }
    return (
        stateSteady && !verifySubmitted && email ? (
        <>{progressStarted && <ProgressBar progressOverride={progressOverride} apiStarted={apiStarted} onCompleted={handleCompletion} setProgressOverride={setProgressOverride} />}
        <Fade in={true} timeout={500}>
        <div className={styles["confirm-row"]} >
                <div className={styles["confirm-account"]} >
                    <div className={styles["confirm-back"]}>
                        <img src={leftbutton} alt="back arrow" style={{cursor: "pointer"}} onClick={goBack} />
                    </div>
            <form method="post" onSubmit={handleSubmit(onSubmit)} >
                    <div className={styles["confirm-inner-container"]}>
                        <div className={styles["confirm-header"]}>
                            <div className={styles["confirm-header-text"]}>Please verify your email</div>
                            <div className={styles["confirm-header-text-two"]}>Enter the code we sent to <div className={styles["confirm-header-text-three"]}>{email}.</div>&nbsp;&nbsp;Please check both your inbox and spam folder.</div>
                        </div>
                        <div className={styles["confirm-input-fields"]}>
                            <div className={styles["confirm-input-field"]}>
                                <div className={styles["confirm-input-inner-container"]}>
                                    <Controller
                                        key={`code-${resetKey}`}  // Apply the key here
                                        control={control}
                                        name="code"
                                        defaultValue=""
                                        rules={{required: true, minLength: 6, maxLength: 6, pattern: /^\d{6}$/, validate: validateCode}}
                                        render={({ field, fieldState }) => <Input2 field={field} restrict="numeric" autoFocus="autofocus" autoComplete="off" maxLength={6} label="Code" type="code" id="code" inputRef={inputRef} />}
                                    />
                                </div>
                            </div>
                            <div className="error" id="error-message">{error}</div>
                        </div>
                        <Button isDisabled={!formState.isValid || progressStarted} buttonText="Verify my email" ></Button>
                    </div>
            </form>
                        <div className={styles["confirm-bottom"]}>
                            <div className={styles["confirm-bottom-inner-container"]}>
                                <div className={styles["confirm-bottom-text"]}>
                                    <a href="#" className={styles["link"]} onClick={resendOnClick}>Resend code</a>
                                </div>
                            </div>
                        </div>
                </div>
        </div>
        </Fade>
            </>
        ) : !verifySubmitted ? (
            <>{progressStarted && <ProgressBar progressOverride={progressOverride} apiStarted={apiStarted} onCompleted={handleCompletion} setProgressOverride={setProgressOverride} />}</>
        ) : (
            <>{progressStarted && <ProgressBar progressOverride={progressOverride} apiStarted={apiStarted} onCompleted={handleCompletion} setProgressOverride={setProgressOverride} />}
            <DeepLinkEmail deviceTypeId={deviceTypeId} errorCode={errorCode} isComplete={isComplete}/>
            </>
        )
    )
}

export default ConfirmForm;

import React, {createContext, useContext, useEffect, useState} from "react";
import MirkatClient from "../services/mirkatClient"
import { useStorage } from "./storageContext";
import {BaseClientParams} from "../util/clientParams";

// This is the context that will be used to provide the MirkatClient instance to the rest of the application.
const MirkatClientContext = createContext({
    client: null,
    ready: false,
});

// This is a custom hook that will be used to access the MirkatClient instance.
export const useMirkatClient = () => {
    return useContext(MirkatClientContext);
}

// This is the provider that will be used to wrap the application.
export const MirkatClientProvider = ({ children }) => {
    const [client, setClient] = useState(null);
    const [isReady, setIsReady] = useState(false);

    const { storage } = useStorage();

    // On mount, create a new MirkatClient instance and set it in state.
    useEffect(() => {
        if (storage) {
            // When the mirkatClientProvider is mounted this useEffect is called.
            // In the useEffect we create a new MirkatClient instance and set it in state.
            // Once the client is set, we fetch the CSRF token and secret.
            const client = new MirkatClient({
                baseURL: BaseClientParams.baseURL,
                headers: BaseClientParams.headers,
                language: BaseClientParams.language,
            });
            if (storage.getAccessToken()) {
                client.setAccessKey(storage.getAccessToken());
            }

            if (storage.getRefreshToken()) {
                client.setRefreshKey(storage.getRefreshToken());
            }
            if (storage.getCSRF()) {
                client.setCsrf(storage.getCSRF());
            }
            if (storage.getSessionSecret()) {
                client.setSecret(storage.getSessionSecret());
            }

            setClient(client);

        }
    }, [setClient, storage]);

    // When the client is set, fetch the CSRF token and secret.
    // Error handling isn't implemented here, but should be.
    useEffect(() => {
        if (client) {
                setIsReady(true);

        }
    }, [client]);

    return (
        <MirkatClientContext.Provider value={{ client: client, isReady: isReady }}>
            {children}
        </MirkatClientContext.Provider>
    )
}

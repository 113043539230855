import React, { useState, useEffect } from "react";
// import useWrapper from "../hooks/useWrapper";
import { useStorage } from "../contexts/storageContext";
import styles from "../styles/scss/Login.module.scss";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/authContext";


const NativeMigrationPage = () => {
    const { storage } = useStorage()
    // const { authState, authService, isReady, stateSteady}  = useAuth();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const dynamic = { height: windowWidth > 839 ? "unset" : windowHeight };
    const navigate = useNavigate();
    const { send } = useAuth();

    useEffect(() => {
        if (window.webkit === undefined) {
            return;
        }
        async function fetchWrappedAuth() {
            let session = await window.webkit.messageHandlers.ios.postMessage({
                action: "getAuthSession",
            });
            var refreshToken = session.refreshToken;
            var accessToken = session.accessToken;
            var emailId = session.emailId;
            var userId = session.userId;
            var installationId = session.installationId

            storage.setAccessToken(accessToken);
            storage.setAccessTokenExp(2283177319);
            storage.setRefreshToken(refreshToken);
            storage.setRefreshTokenExp(2283177319);
            storage.setEmailId(emailId);
            storage.setUserId(userId);
            storage.setInstallationId(installationId);

            storage.setEmail("marcio@mirkat.com");
            send("UPDATE_FROMSTORAGE");
            console.log("useWrapper: ");
            navigate("/loading");

        }
        fetchWrappedAuth();
    }, []);

    return (
        <div className="onBoarding">
            <div className="login-row">
                <div className="login">
                    {/* <div className={styles["login-container"]} style={dynamic}> */}
                     Migration page
                    {/* </div> */}
                </div>
            </div>
        </div>

    );
};

export default NativeMigrationPage;

import { useEffect, useRef, useState } from 'react';
import { useAuth } from '../contexts/authContext';
import SessionExpiredModal from "../modals/SessionExpired";
import sysend from "sysend";

const shouldLogoutTime = process.env.REACT_APP_SESSION_IDLE_TIMEOUT || 1800000

const sessionResumeTimeout = process.env.REACT_APP_SESSION_RESUME_TIMEOUT || 300000

const useInactivityLogout = (timeout = 1800000) => { // Default timeout of 30 minutes
    const lastActivityRef = useRef(Date.now());
    const {authService, authState, stateSteady} = useAuth();
    //const [timeOut, setTimeOut] = useState(timeout)
    const [sessionExpired, setSessionExpired] = useState(false)
    const [loggedOut, setLoggedOut] = useState(false)

    useEffect(() => {
        sysend.on('sessionExpired', (message) => { //csrf was updated on another tab
            if (authState.context.windowId !== message?.windowId) {
                setSessionExpired(true)
                authService.send('CLEAR_AND_RESET')
            }
            setSessionExpired(true)
            setTimeout(() => {
                setSessionExpired(false)
            }, 5000)
        })

        return () => {
            sysend.off('sessionExpired');
        }

    }, [setSessionExpired, sessionExpired]);
    useEffect(() => {
        let intervalId = null;
        if(authState.matches('authenticated') && stateSteady) {
            intervalId = setInterval(() => {
                checkInactivity()
            }, 5000)
        }
        return () => clearInterval(intervalId);

    }, [authState]);
    const logoutUser = () => {
        console.log('User is inactive. Logging out...');
        setSessionExpired(true)
        authService.send('LOGOUT')
        setTimeout(() => {
            setSessionExpired(false)
        }, 5000)
        sysend.broadcast('sessionExpired', {
            windowId: authState.context.windowId
        })
        // Perform logout operations
    };

    const checkInactivity = () => {
        if(authState.matches('authenticated')) {
            const now = Date.now();
            const lastActivity = parseInt(localStorage.getItem('lastActivity') || '0');
            console.log("Now: " + now + " Last activity: " + lastActivity)
            console.log("Timeout: ", timeout)
            if (((now - lastActivity) >= timeout && !sessionExpired) || (!lastActivity) ){
                logoutUser();
            }
        }
    };



    const updateActivity = () => {
        lastActivityRef.current = Date.now();
        localStorage.setItem('lastActivity', lastActivityRef.current.toString());
    };

    const handleUserActivity = () => {
        updateActivity();

    };

    const handleStorageEvent = (e) => {
        if (e.key === 'lastActivity') {
            lastActivityRef.current = parseInt(e.newValue);
        }
    };
    useEffect(() => {

        if (!parseInt(localStorage.getItem('lastActivity'))) {
            updateActivity();
        } else {
            const now = Date.now();
            const lastActivity = parseInt(localStorage.getItem('lastActivity') || 0);

            if (lastActivity) {
                if((now - lastActivity) <= parseInt(sessionResumeTimeout) && localStorage.getItem('access_token') !== null) {
                    sessionStorage.setItem('resumedSession', true)
                }
            }
        }
        //}
    }, []);

    useEffect(() => {
        // Event listeners for user activity
        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('click', handleUserActivity);

        // Listen for storage changes
        window.addEventListener('storage', handleStorageEvent);
        // Cleanup
        return () => {
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
            window.removeEventListener('click', handleUserActivity);
            window.removeEventListener('storage', handleStorageEvent);
        };
    }, [timeout]);


    return {sessionExpired};
};

export default useInactivityLogout;

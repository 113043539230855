import React from "react";
import ConfirmForm from "../components/ConfirmForm";

const ConfirmPage = () => {
    return (
        <div className="onBoarding">
            <div className="login-row">
                <div className="login" >
                    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                    <ConfirmForm />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfirmPage;

function fetchWithRetry(url, retries = 2, retryDelay = 100, options = {}) {
    return new Promise((resolve, reject) => {

        const fetchRetry = (x) => {
            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        resolve(response);
                    } else if (x > 0) {
                        setTimeout(() => fetchRetry(x - 1), retryDelay);
                    } else {
                        reject(new Error('Max retries reached'));
                    }
                })
                .catch(error => {
                    if (x > 0) {
                        setTimeout(() => fetchRetry(x - 1), retryDelay);
                    } else {
                        reject(error);
                    }
                });
        };

        fetchRetry(retries);
    });
}

export default fetchWithRetry;

import React, {useState, useEffect, useRef} from  'react';
import styles from '../styles/scss/KebabMenu.module.scss';
import { Link } from "react-router-dom";
import {useModal} from "../contexts/ModalContext";
import {screen, track} from "../util/analytics";

const KebabMenu = ({ }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef();
    const {threatState, setThreatState, showSettings, setShowSettings, homePage} = useModal();
    const settingsIcon = "https://img.mirkat.com/web/icons/settings.svg";
    const historyIcon = "https://img.mirkat.com/web/icons/history.svg";
    const menuDot = "https://img.mirkat.com/web/images/menu/dot.svg";

    useEffect(() => {
        if(isOpen) {
            screen('Menu', {screenType: 'menu'})
            track('Button Tapped', {eventType: 'menu', screenType: 'feed'})
        }
        const handleDocumentClick = (event) => {
            if (isOpen && menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [isOpen]);


    const onClick = () => {
        console.log("Logout clicked")
        track('Button Tapped', {eventType: 'logout', screenType: 'settings'})
        //#239
        track('User Signed Out', {eventType: 'logout'})
    }

    return (
        <>
        <div className={`${styles["menu-dots"]} noSelect`} ref={menuRef} onClick={() => setIsOpen(!isOpen)}>
            <div className={styles["kebab-menu"]} >
                <img src={menuDot} alt="menu dot" />
                <img src={menuDot} alt="menu dot" />
                <img src={menuDot} alt="menu dot" />
            </div>
                {isOpen && homePage && (
                    <div className={styles["header-menu-container"]} style={{cursor: "default"}}>
                        <div className={styles["menu-items"]} style={{cursor: "default"}}>
                            <div className={styles["buttons-container"]} style={{cursor: "default"}}>

                                <div className={styles["header-menu-button"]} style={{padding: "unset"}}><button onClick={() => setShowSettings(!showSettings)} className={styles["header-menu-button"]} style={{background: "unset", border: "none"}}>
                                    <div className={styles["icon-container"]}><img src={settingsIcon} alt="settings icon" /> </div>
                                    <div className={styles["label-container"]}>
                                        <div className={styles["button-label-text"]}>Settings</div>
                                    </div>
                                </button></div>

                                <div className={styles["menu-button"]} style={{padding: "unset"}}><button onClick={() => {setThreatState(!threatState);}} className={styles["menu-button"]} style={{background: "unset", border: "none"}}>
                                    <div className={styles["icon-container"]}><img src={historyIcon} alt="history icon" /> </div>
                                    <div className={styles["label-container"]}>
                                        <div className={styles["button-label-text"]} >Threat history</div>
                                    </div>
                                </button></div>

                            </div>
                            <div className={styles["header-menu-divider"]}></div>

                            <div className={styles["buttons-container"]}><Link key="settings" onClick={onClick} className={styles["menu-logout"]} to="/logout"><div className={styles["label-text"]} >Logout</div></Link></div>
                        </div>
                    </div>
                )}
                {isOpen && !homePage &&
                    <div className={styles["header-menu-container"]} style={{height: "80px"}}>
                        <div className={styles["menu-items"]} style={{height: "100%"}}>
                            <div className={styles["header-menu-divider"]} style={{height: "100%"}}></div>
                            <div className={styles["buttons-container"]} style={{height: "100%"}}><div className={styles["menu-logout"]}><Link key="settings" onClick={onClick} className={styles["label-text"]} to="/logout">Logout</Link></div></div>
                        </div>
                    </div>
                }

        </div>
            </>
    );
};

export default KebabMenu;

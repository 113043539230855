import React, {useState, useEffect, useRef} from "react";
import styles from "../styles/scss/Loading.module.scss";
import {useNavigate} from "react-router-dom";
import Lottie from 'lottie-react';
//import loading from '../assets/animations/Loading.json';
import AuthStorageService from "../services/authStorage";
import {useAuth} from "../contexts/authContext";
import fetchWithRetry from "../util/simpleFetch.js";
import {preloadCacheIconSvgs, preloadTestRiskImages} from "../util/imageCache";
const storage = new AuthStorageService();


const Loading = () => {
    const {authState, authService, stateSteady} = useAuth();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const dynamic = {height: windowWidth > 500 ? "unset" : windowHeight}
    const [isStopped, setIsStopped] = useState(false);
    const style = {width: windowWidth > 500 ? "550px" : windowWidth + 150}
    const navigate = useNavigate();
    const loadingCrumb = storage.getLoadingBreadCrumb();
    const [animation, setAnimation] = useState(null);
    const resumedSession = sessionStorage.getItem('resumedSession');
    const url = 'https://img.mirkat.com/web/animations/Loading.json'


    useEffect(() => {
        preloadCacheIconSvgs()
        preloadTestRiskImages()
        fetchWithRetry(url)
            .then(response => response.json())
            .then(data => {
                setAnimation(data);
            })
            .catch(error => {
                console.error('failed to get loading animation from bucket', error);
            });
    }, []);

    useEffect(() => {
        if(stateSteady && !authState.matches('authenticated')) {
            navigate("/login")
        }
    }, [authState, stateSteady]);

    useEffect(() => {
        //storage.removeOnboardingBreadCrumb('t') //true
        if (loadingCrumb || resumedSession) {
            navigate("/");
        }
    });

    useEffect(() => {
        if(authService && stateSteady) {
                authService.send('DISABLE_PROGRESS_BAR')
                authService.send('DISABLE_LOADING') // disable loading screen
        }
    }, [authService, stateSteady]);


    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useEffect(() => {
        //setTimeout(() => navigate("/"), 8500);
        setTimeout(() => setIsStopped(true), 6300);
        console.log("timeout starts NOW");
    }, []);

    useEffect(() => {
        if (isStopped) {
            navigate("/");
            storage.setLoadingBreadCrumb("true");
            console.log("NAVIGATING");
        }
    }, [isStopped]);

            //<Lottie isStopped={isStopped} width={windowWidth > 500 ? "330px" : "100%"} options={defaultOptions} onClick={doNothing}/>
    return (
        <div className={styles["main-container"]} style={dynamic}>
            <Lottie 
                animationData={animation}
                isStopped={isStopped}
                style={style}
                loop={false}
                autoplay={true}
                rendererSettings={{
                    preserveAspectRatio: 'xMidYMid slice'
                }}
            />
        </div>
    );
}

export default Loading;

import React from 'react'

const SidebarCheckmark = () => {
    return (
	<svg width="191" height="41" viewBox="0 0 191 41" fill="none" xmlns="http://www.w3.org/2000/svg">
	  <circle cx="95.5" cy="15" r="15" fill="url(#paint0_linear_25740_70050)"/>
	  <path d="M101.622 9.18991L93.1836 17.9466L90.9135 15.2064C89.9128 14.33 88.3392 15.3512 89.0547 16.6646L91.7526 21.4486C92.1805 22.0328 93.1836 22.6159 94.1844 21.4486C94.6122 20.8643 102.766 10.5021 102.766 10.5021C103.767 9.33477 102.48 8.31352 101.622 9.1887V9.18991Z" fill="white"/>
	  <defs>
	    <linearGradient id="paint0_linear_25740_70050" x1="80.5" y1="30" x2="110.5" y2="30" gradientUnits="userSpaceOnUse">
	      <stop stopColor="#FF3F29"/>
	      <stop offset="1" stopColor="#FF8E00"/>
	    </linearGradient>
	  </defs>
	</svg>
    )
}

export default SidebarCheckmark;

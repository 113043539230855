import {useEffect} from "react";

// This hook is used to fetch the billing status from the server.
const useClearErrorOnKeyPress = ({setError, error}) => {

    const handleKeyPress = (event) => {
        if(!error) return null;
        if(error && setError) return setError(null);
    };

    useEffect(() => {
        // Adding the event listener
        window.addEventListener('keydown', handleKeyPress);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [setError, error]);

}

export default useClearErrorOnKeyPress;
import React, {useEffect, useRef, useState} from "react";
import Button from "./Button";
import useFormHandler from "../hooks/useFormHandler";

import "../styles/scss/PasswordReset.scss";
import {useModal} from "../contexts/ModalContext";
import {useFetch} from "../hooks/useFetch";
import {Controller, useForm} from "react-hook-form";
import Input2 from "./Input2";
import {retryableError} from "../util/retryableError";
import {track, screen} from "../util/analytics";
import useProgressBar from "../hooks/useProgressBar";
import ProgressBar from "./ProgressBar";
import Fade from "@mui/material/Fade";
import useDetectNetwork from "../hooks/useDetectNetwork";

const PasswordResetRequest = () => {
    const { register, handleSubmit, formState,
        setValue,
        control, reset: formReset, getValues} = useForm({mode: "onChange", reValidateMode: "all"});
    //we need to redirect if authenticated to home
    //if error is 34 -> csrf need to unset cookies // make a call to the api
    const [isSent, setIsSent] = useState(false)
    const [resetKey, setResetKey] = useState(Date.now());
    const [error, setError] = useState("");
    const {toggleModal,setModalType} = useModal();
    const {state, fetch, isLoading, uiErrorMsg, reset} = useFetch();
    const { progressStarted, setNoDelay, apiStarted, isComplete, startProgress, handleCompletion,  setLastApiCallStarted, setProgressOverride, progressOverride} = useProgressBar();
    const inputRef = useRef(null);
    //for analytics
    const [emailEventFired, setEmailEventFired] = useState(false);
    const leftbutton = "https://img.mirkat.com/web/icons/leftbutton.svg";


    const isOnline = useDetectNetwork();
    useEffect(() => {
        if(!isOnline){
            setError("No network connection")
        }
        if(isOnline && error === "No network connection") {
            setError("")
        }
    }, [isOnline])

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    });


    const onSuccess = () => {
        // This all needs cleanup either to a hook or move to state machine flow
        setIsSent(true);
        setModalType({modalType: 'emailLink'});
        //toggleModal();

        //clear form?
    }

    useEffect(() => {
        screen('Sign-in: Forgot password', {
            screenType: 'signIn',
            screenSubType: 'forgotPassword'
        });
        setProgressOverride(null)
        return () => {
            setProgressOverride(null)
        }
    }, []);


    useEffect(() => {
        if(state.matches('success') && !emailEventFired) {
            setEmailEventFired(true)
            //#51
            track('User Password Reset Requested')
        }
        if(state.matches('success')) {
            setProgressOverride(100)
            onSuccess();
            formReset({'email': ''})
            reset()
        }

        if(state.matches('failure')) {
            setProgressOverride(100)
            console.log("Test: ", uiErrorMsg)
            let {canRetry, errMsg} = retryableError({errorBody: state.context.error})
            console.log(errMsg)
            setTimeout(() => {
                setError(errMsg);
            }, 650)
            //formReset({'email': ''})
            reset()
           //setTimeout(() => {
            //  setError(null)
         //   }, 6000)
        }

        if(uiErrorMsg) {
            setProgressOverride(100)
        }

        console.log(state)
    }, [state, uiErrorMsg, isComplete, progressOverride]);

    const onSubmit = (data) => {
        setModalType({modalType: ''});
        //#48
        track('Button Tapped', {
            buttonType: 'next',
            screenType: 'signIn',
            screenSubType: 'forgotPassword'
        })
        const email = data.email;
        startProgress()
        setLastApiCallStarted()
        fetch('passwordResetRequest', [email])

    }
    useEffect( () => {
        console.log(isLoading);
        console.log(isSent);
    }, [isLoading, isSent]);

    const validateEmail = (email) => {
        // eslint-disable-next-line no-useless-escape
       // if(email.length > 0) {

        setError(null) //clears error on next tying event

        //}
       // const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        //return re.test(email);
    }

    //input type for email set to 1234 -> firefox relay plugin breaks the transform. onepassword still works
    return (
        <>{progressStarted && <ProgressBar progressOverride={progressOverride} apiStarted={apiStarted} onCompleted={handleCompletion} setProgressOverride={setProgressOverride} />}
        <Fade in={true} timeout={500}>
        <div className="passwordreset-row">
        <form className="passwordreset-form" noValidate method="post" onSubmit={handleSubmit(onSubmit)}>
            <div className="passwordreset-account">
                <div className="passwordreset-back"><a href="/login"><img src={leftbutton} alt="back arrow" /></a></div>
                <div className="passwordreset-inner-container">
                    <div className="passwordreset-header">
                        <div className="passwordreset-header-text" >Forgot your password?</div>
                        <div className="passwordreset-informational">Enter the email address used for Mirkat and we’ll email you a reset link.</div>
                    </div>
                    <div className="passwordreset-input-fields">
                        <Controller
                            key={`email-${resetKey}`}  // Apply the key here
                            control={control}
                            name="email"
                            defaultValue=""
                            rules={{ required: true, maxLength: 100, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, validate: validateEmail }}
                            render={({ field, fieldState }) => <Input2 field={field} maxLength={100} label="Email address" type="email" id="email" autoComplete="email" inputRef={inputRef} />}
                        />
                        <div className="error" id="error-message">{error}</div>

                    </div>
                    <Button isDisabled={!formState.isValid || progressStarted} buttonText="Reset Password" />
                </div>
                </div>
        </form>
        </div>
        </Fade>
            </>
    )
}

export default PasswordResetRequest;

// emails that are sent out for /verify/<somebase64string>

//atob("gAAAAABlao_K98FiiJyINS5tsQe2uBDrDAeAdeSGxU4P5xINM2UPs6l1He2dRQ9geU-s4FUtIbMqN_KeFSrgDQNwvTHHtQ3LGh4epzDBU7pH3j6r6h3ll57y_Gm8LRiaQrV7bsJw79WsMhvDDytIlxmaqk-EughCuEmXgG3ZIeku4CN-808tPu4NgHV9eyRRfbQOcNCiQ67UCt6lGJBkYTvIENs2DFRcKWA-GIdCYOYCwoY3CrfALp-omIbqTW1l0BG8tIaoyZDIgwU2_ikOdOss1RMPIK0bA8tyyN8w5rwaten05zlWJ16UIa7-3PAuq0DtXvJK85RB5hfSGB8u39uy7jxBxgkxuT8cRho-YloLgFDIAmxz9Y-0Pq1SY3nmkBUs4Skm8FQsfAhUpTf0v2hRJXlbSEvJFenHxpReo_Aoj9RSIuO4ORpdHzsKiMkid8y726_vaHRreOjqgwE7A3bD88ucX43CDcf2mo_06Q76ncHm7LgPUzs=?r=email&tID=e4af54cb-877f-4b03-a524-049700ffe8b7&vID=1&nID=c_4_af761ce4-37cb-44b4-af22-c11e713341fc")

//get the confirmationToken from the url
export function getConfirmationToken(someUrl) {
    //if no string passed in, grab the current window url
    let url = "";
    if(!someUrl) {
        url = window.location.href;
    } else {
        url = someUrl
    }
    console.log("Here url: ", url)

    const baseUrl = url.split('?')[0];
    console.log(baseUrl)
    if (baseUrl) {
        const lastSlashIndex = baseUrl.lastIndexOf('/');
        const token = lastSlashIndex !== -1 ? baseUrl.substring(lastSlashIndex + 1) : '';

        if (token) {
            return token;
        } else {
            console.log('URL does not contain a token.')
            return null;
        }
    } else {
        console.error('URL does not contain a base path.');
        return null;
    }
}



export function decode(encodedString) {
    try {
        // Base64 decode
        const decodedString = atob(encodedString);
        console.log(decodedString);
        return decodedString
    } catch (e) {
        console.error("Error decoding base64 string: ", e.message);
        return new Error("Error decoding base64 string: " + e.message)
    }
}

//returns all query strings
export function getAllQueryStrings(url) {
    let urlOverride = ""
    if (url) {
        urlOverride = new URL(url)
    } else {
        urlOverride = new URL(window.location.href)
    }
    let urlParams = new URLSearchParams(urlOverride.search);
    let params = {}
    for (let [key, value] of urlParams) {
        params[key] = value
    }
    return params
}

export function getAllQueryStringsAsString(url) {
    let urlOverride = ""
    if (url) {
        urlOverride = new URL(url)
    } else {
        urlOverride = new URL(window.location.href)
    }
    let params =  new URLSearchParams(urlOverride.search);
    let queryString = '';

    for (let [key, value] of params) {
        if (queryString !== '') {
            queryString += '&';
        }
        queryString += `${key}=${value}`;
    }

    return queryString;
}

//will get current window location url if not provided
//queryString is the key we're looking for
export function getQueryString(key, url) {

    let urlOverride = ""
    if (url) {
        urlOverride = new URL(url)
    } else {
        urlOverride = new URL(window.location.href)
    }
    const queryParams = new URLSearchParams(urlOverride.search);
    if(!queryParams) {
        console.log("No url parameters found")
        return null
    }
    const hasQueryString = queryParams.has(key); //email
    if (!hasQueryString) {
        console.log("No query string found")
        return null
    }
    return queryParams.get(key);
}



import React, {useEffect, useState} from "react";
import "../styles/scss/UpgradePage.scss";
import UpgradeNew from "../components/UpgradeNew";
import UpgradeB from "../components/UpgradeB";
import {useAuth} from "../contexts/authContext";


const UpgradePage = () => {
    const {authState, stateSteady, authService} = useAuth()
    const [showUpgradeB, setShowUpgradeB] = useState(false);
    const [readyToRender, setReadyToRender] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        sessionStorage.setItem("upgradeReturnBreadCrumb", true);

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])


    useEffect(() => {
        if (stateSteady) {
            const plan = authState.context?.cohorts?.includes('planSelection');
            if (plan) {
                console.log("we're on: ", authState.context.cohorts)
                setReadyToRender(true);
            } else {
                setShowUpgradeB(true);
                setReadyToRender(true);
            }
        }
    }, [authState, stateSteady])

    return (
        readyToRender ?
            <>{showUpgradeB ?
                <UpgradeB page={true} />
            :
                <UpgradeNew page={true} />
            }</>

        :
            <>{windowWidth > 839 ?
                <div style={{height: "calc(100vh - 130px)"}}></div>
            :
                <></>
            }</>
    );
}

export default UpgradePage;

import React, { useEffect, useState } from 'react'
import {useNavigate} from "react-router-dom";
import { useAuth } from "../contexts/authContext";
import AuthStorageService from "../services/authStorage";
import { Link } from "react-router-dom";
import {useModal} from "../contexts/ModalContext";
import Fade from '@mui/material/Fade';
import {track, screen} from "../util/analytics";
import DeleteAccountModal from "../modals/DeleteAccountModal";
import useClearModals from "../hooks/useClearModals";
import ChangePasswordModal from "../modals/ChangePasswordModal";
import passwordUpdatedModal from "../modals/PasswordUpdatedModal";
import PasswordUpdatedModal from "../modals/PasswordUpdatedModal";

const SetttingsAccount = ({ windowWidth }) => {
    const { isAuthenticated, authState, isReady} = useAuth();
    const [showUser, setShowUser] = useState('');
    const navigate = useNavigate();
    const storage = new AuthStorageService();
	const {setModalType, toggleModal, setHiddenSettings} = useModal();
    const [deletedSuccess, setDeletedSuccess] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [accountDelete, setAccountDelete] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [clearModals, setClearModals] = useState(false);
	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);


    useClearModals(clearModals);

    const deleteAccount = () => {
		//#256
		track('Button Tapped', {buttonType: 'deleteAccount', screenType: 'settings'});
		setShowDeleteModal(true);
		console.log("account delete clicked")
		console.log(accountDelete)
    }
	useEffect(() => {
		setIsLoaded(true)
	}, []);

	useEffect(() => {
		if(isLoaded) {
			if (!authState.matches('authenticated')) {
				navigate('/login');
                                setClearModals(true);
                                console.log("earlier DELETED ACCOUNT navigating to /login");
			}
		}
        if(authState.matches('authenticated') && isReady) {
            console.log("authenticated and Ready")
	    console.log(authState.context.emailAddress)
        }
        if(authState.context.emailAddress) {
            setShowUser(authState.context.emailAddress);
        }
        //`````````````````````setShowUser(storage.getEmail());
		console.log("Account Delete: ", accountDelete)
    }, [ isReady, isAuthenticated, authState, accountDelete, showDeleteModal ]);

    const changePassword = () => {

		//#248
		track('Button Tapped', {
			buttonType: 'changePassword',
			screenType: 'settings',

		});

		screen('Change Password', {screenType: 'changePassword', entryType: 'settings'});
		//navigate('/change');
		setShowChangePasswordModal(true);
		setHiddenSettings(true)

	}

	const onLogout = () => {
		//#237
		track('Button Tapped', {buttonType: 'logout', screenType: 'settings'});
		//#239
		track('User Signed Out', {eventType: 'logout'})
	};

	const [pwUpdateModal, setPwUpdateModal] = useState(false);
    return (
<>		{showDeleteModal && <DeleteAccountModal setShowDeleteModal={setShowDeleteModal} props={showDeleteModal}/>}
		{showChangePasswordModal && !pwUpdateModal && <ChangePasswordModal setShowChangePasswordModal={setShowChangePasswordModal} setPwUpdateModal={setPwUpdateModal} props={showChangePasswordModal} windowWidth={windowWidth} />}
		{pwUpdateModal && <PasswordUpdatedModal  setPwUpdateModal={setPwUpdateModal} setHiddenSettings={setHiddenSettings} />}
	   <div className="settings-account">
	        <div className="settings-header-container"><h2 className="settings-header">Account</h2></div>
	        <div className="account-body">


	    	    <div className="settings-row-container"><div className="settings-row">
	    	        <div className="settings-row-left-text-container"><div style={{ color: '#000000', fontSize: '17px' }} className="settings-row-left-text">Email</div></div>
	    	        <div className="settings-row-right-text-container"><div className="settings-row-right-text">{showUser}</div></div>
	    	    </div></div>


	    	    <div className="settings-row-container noSelect"><div className="settings-row">
	    		<div className="settings-row-icon-container">
			   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			     <path className="settings-row-icon" d="M8 11V7C8 5.93913 8.42143 4.92172 9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7V11M5 13C5 12.4696 5.21071 11.9609 5.58579 11.5858C5.96086 11.2107 6.46957 11 7 11H17C17.5304 11 18.0391 11.2107 18.4142 11.5858C18.7893 11.9609 19 12.4696 19 13V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V13ZM11 16C11 16.2652 11.1054 16.5196 11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16C13 15.7348 12.8946 15.4804 12.7071 15.2929C12.5196 15.1054 12.2652 15 12 15C11.7348 15 11.4804 15.1054 11.2929 15.2929C11.1054 15.4804 11 15.7348 11 16Z" stroke="#2F364B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			   </svg>
	    		</div>
					<div className="settings-row-text-container"  style={{ cursor: 'pointer', textDecoration: 'none' }} onClick={changePassword}><div className="settings-row-text">Change password</div></div>
	    		<div className="settings-row-right-container">
			    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			      <path d="M2 13.75L7.5 8.25L2 2.25" stroke="#2F364B" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
			    </svg>
	    		</div>
	    	    </div></div>


	    	    <div className="settings-row-container noSelect"><div className="settings-row">
	    		<div className="settings-row-icon-container">
			    <svg className="settings-row-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			      <path d="M3 6.44444H20.7778M9.66667 10.8889V17.5556M14.1111 10.8889V17.5556M4.11111 6.44444L5.22222 19.7778C5.22222 20.3671 5.45635 20.9324 5.8731 21.3491C6.28984 21.7659 6.85507 22 7.44444 22H16.3333C16.9227 22 17.4879 21.7659 17.9047 21.3491C18.3214 20.9324 18.5556 20.3671 18.5556 19.7778L19.6667 6.44444M8.55556 6.44444V3.11111C8.55556 2.81643 8.67262 2.53381 8.88099 2.32544C9.08937 2.11706 9.37198 2 9.66667 2H14.1111C14.4058 2 14.6884 2.11706 14.8968 2.32544C15.1052 2.53381 15.2222 2.81643 15.2222 3.11111V6.44444" stroke="#2F364B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			    </svg>
	    		</div>
	    		<div className="settings-row-text-container" style={{ cursor: "pointer" }} onClick={deleteAccount} id="delete-account"><div  className="settings-row-text">Delete account</div></div>
	    		<div className="settings-row-right-container">
			   <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			     <path d="M2 13.75L7.5 8.25L2 2.25" stroke="#2F364B" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
			   </svg>
	    		</div>
	    	    </div></div>

	    	    <div className="settings-row-container"><div className="settings-row">
	    		<div className="settings-row-icon-container">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.5 5.93782C9.56413 5.32344 10.7712 5 12 5C13.2288 5 14.4359 5.32344 15.5 5.93782C15.9783 6.21396 16.5899 6.05009 16.866 5.5718C17.1422 5.0935 16.9783 4.48191 16.5 4.20577C15.1318 3.41586 13.5798 3 12 3C10.4202 3 8.86817 3.41586 7.5 4.20577C6.13183 4.99569 4.99569 6.13183 4.20577 7.5C3.41586 8.86817 3 10.4202 3 12C3 13.5798 3.41586 15.1318 4.20577 16.5C4.99569 17.8682 6.13183 19.0043 7.5 19.7942C8.86817 20.5841 10.4202 21 12 21C13.5798 21 15.1318 20.5841 16.5 19.7942C16.9783 19.5181 17.1422 18.9065 16.866 18.4282C16.5899 17.9499 15.9783 17.786 15.5 18.0622C14.4359 18.6766 13.2288 19 12 19C10.7712 19 9.56414 18.6766 8.5 18.0622C7.43587 17.4478 6.5522 16.5641 5.93782 15.5C5.32344 14.4359 5 13.2288 5 12C5 10.7712 5.32344 9.56414 5.93782 8.5C6.5522 7.43587 7.43586 6.5522 8.5 5.93782ZM17.7071 8.29289C17.3166 7.90237 16.6834 7.90237 16.2929 8.29289C15.9024 8.68342 15.9024 9.31658 16.2929 9.70711L17.5858 11H11C10.4477 11 10 11.4477 10 12C10 12.5523 10.4477 13 11 13H17.5858L16.2929 14.2929C15.9024 14.6834 15.9024 15.3166 16.2929 15.7071C16.6834 16.0976 17.3166 16.0976 17.7071 15.7071L20.7071 12.7071C21.0976 12.3166 21.0976 11.6834 20.7071 11.2929L17.7071 8.29289Z" fill="#2F364B"/>
                            </svg>
	    		</div>
	    		<Link className="settings-row-text-container" id="settings" to="/logout" style={{ cursor: "pointer", textDecoration: "none" }} onClick={onLogout}><div className="settings-row-text">Log out</div></Link>
	    	    </div></div>
	        </div>
	   </div>
	</>
    )
}

export default SetttingsAccount;

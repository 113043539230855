import MirkatClient from "../services/mirkatClient";
import {assign, createMachine, sendParent} from "xstate";
import {BaseClientParams} from "../util/clientParams";
import {createDataFetcher} from "../util/fetchFactory";
import {parseMirkatAuthData} from "../util/mirkatAuth";
import {ErrorMachine} from "./errorMachine";
import {updateClient} from "../util/updateClient";

//This handles the confirm endpoint

const maxRetries = 3;
const client = new MirkatClient(BaseClientParams);

const confirmService = (context) => (callback) => {
    const event = {method: 'confirm'}
    const dataFetcher = createDataFetcher(context.apiClient);
    dataFetcher(context, event).then((data) => {
        //data returned is a string. we need to parse it
        let parsedData = parseMirkatAuthData(data);
        if (data?.errorCode) {
            if(data.errorCode !== 26) {
                //if we get errorCode 26, move to retry call
                callback({type: 'ERROR', data: data});
            }

        }


        if(data.hasOwnProperty('accessKey') && data.accessKey !== null) {
            parsedData = { ...data, 'isAuthenticated': true};
        } else {
            parsedData = { ...data, 'isAuthenticated': false};
        }
        callback({type: 'DONE', data: parsedData});

    }, (err) => {
        if(err.hasOwnProperty('errorCode')) {
            if(err.errorCode === 26) {
                console.log("error 26: confirmed already or zero results...")
                err = {...err, 'isAuthenticated': false};
                callback({type: 'DONE', data: err});
            }
            if(err?.errorCode === 5 || err?.errorCode === 44) {
                //update csrf and/or session
                err = {...err, 'isAuthenticated': false};
                callback({type: 'UPDATECSRF', data: err});
            }
            if(err?.errorCode === 8) {
                //inform users code invalid
                err = {...err, 'isAuthenticated': false};
                callback({type: 'INVALIDCODE', data: err});
            }
            if(err?.errorCode === 49) {
                //same as 8 - message to users and setup to go again
                err = {...err, 'isAuthenticated': false};
                callback({type: 'EXPIREDCODE', data: err});
            }
        }


        console.log("err: ", err)
        if (err?.errorCode === 8) {
            console.log("invalid code")
            console.log("wtf")
        }


        console.log("in confirm error state")
        callback({type: 'ERROR', data: err});
    });
}

export const ConfirmMachine = createMachine({
    id: 'confirmMachine',
    initial: 'confirm',
    context: {
        apiClient: client,
        authData: null,
        secret: null,
        error: false,
        errorData: null,
        retries: 0,
    },

    states: {
        confirm: {
            invoke: {
                id: 'confirm',
                src: (context) => confirmService(context),
                onError: {
                    target: 'error',
                    actions: [ 'logOutput', assign({
                        error: true,
                        retries: (context, event) => context.retries + 1,
                    })
                    ]
                }
            },
            on: {
                DONE: [{
                    target: 'done',
                    cond: (context, event) => event.data.isAuthenticated,
                    actions: ['logOutput',
                        assign({
                            data: (context, event) => event.data,
                            csrf: (context, event) => event.data.__farsce,
                            secret: (context, event) => event.data._gi_ase,
                            error: false,
                            retries: 0,
                        }),
                        sendParent((context, event) => ({type: 'AUTHENTICATED', data: context.data}))
                    ],

                },
                    {
                        target: 'done',
                        actions: [ 'logOutput', assign({
                            data: (context, event) => event.data,
                            retries: 0,
                        }),
                            sendParent((context, event) => ({type: 'DONE', data: context.data}))
                        ],
                    }
                ],

                INVALIDCODE: {
                    target: 'done',
                    actions: [(context, event) => console.log("EVENT: ", event), assign({
                        error: (_, event) => event.data,
                        retries: (context, event) => context.retries + 1,
                        data: (context, event) => event.data,
                    }), sendParent((context, event) => ({type: 'INVALID_CODE', data: context.data}))],
                },

                EXPIREDCODE: {
                    target: 'done',
                    actions: [(context, event) => console.log("EXPIREDCODE: ", event), assign({
                        error: (_, event) => event.data,
                        retries: (context, event) => context.retries + 1,
                        data: (context, event) => event.data,
                    }), sendParent((context, event) => ({type: 'EXPIRED_CODE', data: context.data}))],
                },

                ERROR: {
                    target: 'fixerror',
                    actions: [assign({
                        errorData: (context, event) => event.data,
                        error: true,
                        retries: (context) => context.retries + 1,
                    })],
                }
            },
        },
        fixerror: {
            entry: 'logWtfEntry',
            invoke: {
                src: (context) => ErrorMachine.withContext(context),
                data: {
                    apiClient: (context) => context.apiClient,
                    errorData: (context) => context.errorData,
                    retries: (context) => context.retries,
                },
                onDone: {
                    actions: [(event) => console.log("event: ", event), assign({
                        csrf: (context, event) => event.csrf,
                        secret: (context, event) => event.secret,
                    }), sendParent((context) => ({type: 'CSRF', csrf: context.csrf, secret: context.secret})),
                        (context) => console.log("context: ", context)],
                },
                onError: {
                    actions: 'logWtfEntry'
                }
            },
            on: {
                UPDATEDCSRF: {
                    actions: [(event) => console.log("event2: ", event), assign({
                        csrf: (context, event) => event.csrf,
                        secret: (context, event) => event.secret,
                    }), sendParent((context) => ({type: 'UPDATEDCSRF', csrf: context.csrf, secret: context.secret})),
                        (context) => updateClient(context),
                        (context) => console.log("context2: ", context)],
                    target: 'confirm',
                },
                ERROR: {
                    target: 'error',
                    actions: [assign({
                        error: true,
                        errorData: (context, event) => event?.errorData || event?.data || event?.error,
                    }), sendParent((context, event) => ({type: 'ERROR', errorData: context.errorData}))],
                },
            },

        },
        done: {},
        error: {}
    },
}, {
    guards: {
        shouldRetry: (context, event) => context.retries < maxRetries
    },
    delays: {
        RETRY_DELAY: (context) => {
            const exponent = context.retries ** 2;
            return exponent * 200;
        }
    },
    actions: {
        logOutput: (context, event) => console.log(context, event)
    },
    strict: true,
    history: false,
});

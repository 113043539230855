import secureLocalStorage from 'react-secure-storage';



class StorageService {
    // Store new data in session storage
    setSessionData(key, value) {
        sessionStorage.setItem(key, value);
    }

    // Get data from session storage
    getSessionData(key) {
        return sessionStorage.getItem(key);
    }

    removeSessionData(key) {
        sessionStorage.removeItem(key);
    }

    // Store new data in local storage
    setLocalData(key, value) {
        localStorage.setItem(key, value);
    }

    // Get data from local storage
    getLocalData(key) {
        return localStorage.getItem(key);
    }

    removeLocalData(key) {
        localStorage.removeItem(key);
    }
}

//Currently causing issues with multitab likely due to eventHandler context not being able to handle the secureLocalStorage
//or secureLocalstorage has an issue with different tabs - note -> comment out in AuthMachine as well.
/*
//Todo: Need to move/configure this in the build process not runtime for prod.
if ((process.env.REACT_APP_ENV === 'prod') || (process.env.REACT_APP_ENV !== 'dev' && !localStorage.getItem('debug'))) {
    // Store new data in local storage
    StorageService.prototype.setLocalData = function(key, value) {
        secureLocalStorage.setItem(key, value);
    }

    // Get data from local storage
    StorageService.prototype.getLocalData = function(key) {
        return secureLocalStorage.getItem(key);
    }

    StorageService.prototype.removeLocalData = function(key) {
        secureLocalStorage.removeItem(key);
    }
}

 */


export default StorageService;

import ClientErrors from "../services/errors";
import AuthStorageService from "../services/authStorage";

const errorLookup = new ClientErrors();
const storage = new AuthStorageService();


export function retryableError({errorBody, authService}) {
    console.log("ERROR BODY: ", errorBody)
    if (errorBody && errorBody.hasOwnProperty('errorCode')) {
        let err = errorLookup.getError(errorBody.errorCode)
        console.log("wtf error LOOKUP: ", err)
        let errMsg = err.uiMsg || ""
        switch (errorBody.errorCode) {
            case 1: case 27:
                //likely need to resignup
                return {canRetry: false, errMsg: "Too many confirmation codes have been requested. Please try again later."}
            case 3:
                //likely need to resignup
                if(authService) {
                    authService.send({type: 'LOGOUT'}); // nuke cookies and local stuff....
                }
                return {canRetry: true, errMsg: errMsg}
            case 5:
                if(authService) {
                    console.log("refresh calls: ", authService.state.context.refreshCalls)
                    if (authService.state.context.refreshCalls < 5) {
                        authService.send({type: 'REFRESH'});
                    } else {
                        console.log("too amy refresh calls. logging out")
                        authService.send({type: 'LOGOUT'});
                    }
                }
                //Login again
                return {canRetry: true, errMsg: errMsg}

            case 6:
                //we can get a 403 && a case 6,

                // logout as we might have an invalid access key/authorization header is wrong
                //if(authService) {
                //    authService.send({type: 'LOGOUT'}); //clear context
                //}
                return {canRetry: false, errMsg: 'User does not exist, try creating an account instead.'}
            case 8:
                return {canRetry: false, errMsg: errMsg}
            case 9:
                //remove installation id and start over signin/signup
                //storage event should trigger client rerender in authprovider
                if(authService) {
                    storage.removeInstallationId();
                    authService.send({type: 'LOGOUT'});
                }
                return {canRetry: false, errMsg: errMsg}
            case 34: case 44:
                if(authService) {
                    authService.send({type: 'UPDATECSRF'});
                }
                //then retry request again - client needs to be updated/rerendered...
                return {canRetry: true, errMsg: errMsg}
            case 36:
                return {canRetry: false, errMsg: errMsg}
            case 40: case 41:
                return {canRetry: false, errMsg: errMsg}
            case 43:
                return {canRetry: false, errMsg: errMsg}
            case 48:
                return {canRetry: false, errMsg: errMsg}
            case 49:
                return {canRetry: false, errMsg: errMsg}
            case 50:
                //email failed to send, autoretry
                return {canRetry: true, errMsg: errMsg}
            case 58:
                return {canRetry: false, errMsg: errMsg}
            default:
                break;
        }
        return {canRetry: false, errMsg: errMsg};
    }
    return {canRetry: false, errMsg: "An unexpected error occurred. Please try again later."};
}


export function retryableErrorGuard({retries, errorBody}) {
    if (errorBody.hasOwnProperty('errorCode')) {
        let err = errorLookup.getError(errorBody.errorCode)
        let errMsg = err.uiMsg || ""
        switch (errorBody.errorCode) {
            case 3:
                //likely need to resignup
                //task LOGOUT
                return {canRetry: true, task: "LOGOUT"}
            case 5:

                //task if refresh calls < 5, REFRESH else Logout
                if (retries < 3) {
                    return {canRetry: true, task: "REFRESH"}
                }
                //Login again
                return {canRetry: true, task: "LOGOUT"}

            case 6:
                //unauthorized ... refresh token or logout?
                return {canRetry: false, errMsg: errMsg}
            case 9:
                //remove installation id and start over signin/signup
                //storage event should trigger client rerender in authprovider

                //task remove installationId and logout
                return {canRetry: false, task: "REMOVEINSTALLATIONID"}
            case 34: case 44:
                console.log("Update csrf and try it all again?")
                //task UPDATECSRF and retry if retries < max_retries
                //then retry request again - client needs to be updated/rerendered...
                if (retries < 3) {
                    return {canRetry: true, task: "UPDATECSRF"}
                }
                return {canRetry: false, task: "LOGOUT"}
            case 36:
                //wrong password when logging in -> goto login
                return {canRetry: false, errMsg: errMsg}
            case 43:
                return {canRetry: false, errMsg: errMsg}
            default:
                break;
        }
        return {canRetry: false, errMsg: errMsg};
    }
    return {canRetry: false, errMsg: "An unexpected error occurred. Please try again later"};
}

import React, {useEffect, useState} from "react";
import {useAuth} from "../contexts/authContext";
import {useNavigate} from "react-router-dom";
import {useModal} from "../contexts/ModalContext";
import useClearModals from "../hooks/useClearModals";

const LogoutPage = () => {
    const { authService } = useAuth();
    const navigate = useNavigate();
    const {setModalType,setShowSettings,setModalCount} = useModal();
    const [clearModals, setClearModals] = useState(false);

    useClearModals(clearModals);

    useEffect(() => {
        authService.send("LOGOUT");

        navigate("/login");
        setClearModals(true);
    }, []);

    return (
        <div>
            <h1>Logging Out</h1>
        </div>
    )
}

export default LogoutPage;

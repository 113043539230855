import React from 'react'

const ToggleEnabled = () => {
    return (
			<svg width="51" height="31" viewBox="0 0 41 26" fill="none" xmlns="http://www.w3.org/2000/svg">
			  <path fillRule="evenodd" clipRule="evenodd" d="M12.7478 0.574316C14.1466 0.493671 15.5438 0.500123 16.9426 0.500123C16.9523 0.500123 24.0308 0.500123 24.0308 0.500123C25.457 0.500123 26.8542 0.493671 28.2522 0.574316C29.5232 0.646896 30.7613 0.801734 31.9937 1.1477C34.5879 1.87512 36.8534 3.39446 38.491 5.54848C40.1189 7.6888 41 10.3089 41 12.9993C41 15.6928 40.1189 18.3113 38.491 20.4516C36.8534 22.6049 34.5879 24.125 31.9937 24.8524C30.7613 25.1984 29.5232 25.3524 28.2522 25.4258C26.8542 25.5065 25.457 25.4992 24.0582 25.4992C24.0485 25.4992 16.9684 25.5 16.9684 25.5C15.5438 25.4992 14.1466 25.5065 12.7478 25.4258C11.4776 25.3524 10.2395 25.1984 9.00714 24.8524C6.41288 24.125 4.14743 22.6049 2.50984 20.4516C0.881902 18.3113 0 15.6928 0 13.0001C0 10.3089 0.881902 7.6888 2.50984 5.54848C4.14743 3.39446 6.41288 1.87512 9.00714 1.1477C10.2395 0.801734 11.4776 0.646896 12.7478 0.574316Z" fill="#009AE6"/>
			  <mask id="mask0_24884_11144" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="26">
			    <path fillRule="evenodd" clipRule="evenodd" d="M12.7478 0.574316C14.1466 0.493671 15.5438 0.500123 16.9426 0.500123C16.9523 0.500123 24.0308 0.500123 24.0308 0.500123C25.457 0.500123 26.8542 0.493671 28.2522 0.574316C29.5232 0.646896 30.7613 0.801734 31.9937 1.1477C34.5879 1.87512 36.8534 3.39446 38.491 5.54848C40.1189 7.6888 41 10.3089 41 12.9993C41 15.6928 40.1189 18.3113 38.491 20.4516C36.8534 22.6049 34.5879 24.125 31.9937 24.8524C30.7613 25.1984 29.5232 25.3524 28.2522 25.4258C26.8542 25.5065 25.457 25.4992 24.0582 25.4992C24.0485 25.4992 16.9684 25.5 16.9684 25.5C15.5438 25.4992 14.1466 25.5065 12.7478 25.4258C11.4776 25.3524 10.2395 25.1984 9.00714 24.8524C6.41288 24.125 4.14743 22.6049 2.50984 20.4516C0.881902 18.3113 0 15.6928 0 13.0001C0 10.3089 0.881902 7.6888 2.50984 5.54848C4.14743 3.39446 6.41288 1.87512 9.00714 1.1477C10.2395 0.801734 11.4776 0.646896 12.7478 0.574316Z" fill="white"/>
			  </mask>
			  <g mask="url(#mask0_24884_11144)">
			    <g filter="url(#filter0_dd_24884_11144)">
			      <path fillRule="evenodd" clipRule="evenodd" d="M28.5 23.5C34.299 23.5 39 18.799 39 13C39 7.20101 34.299 2.5 28.5 2.5C22.701 2.5 18 7.20101 18 13C18 18.799 22.701 23.5 28.5 23.5Z" fill="white"/>
			    </g>
			  </g>
			  <defs>
			    <filter id="filter0_dd_24884_11144" x="10" y="-2.5" width="37" height="37" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
			      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
			      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			      <feOffset dy="3"/>
			      <feGaussianBlur stdDeviation="0.5"/>
			      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"/>
			      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_24884_11144"/>
			      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			      <feOffset dy="3"/>
			      <feGaussianBlur stdDeviation="4"/>
			      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
			      <feBlend mode="normal" in2="effect1_dropShadow_24884_11144" result="effect2_dropShadow_24884_11144"/>
			      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_24884_11144" result="shape"/>
			    </filter>
			  </defs>
			</svg>
    )
}

export default ToggleEnabled;

import React from 'react';
//import '../styles/scss/Email.css';

const Email = ({ title}) => {
    return (
        <div className="email" >
            <h3 className="email-title">{title}</h3>
        </div>
    );
};

export default Email;


function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


export function createDataFetcher(apiClient, authService) {
    return function getData(context, event) { //generic call to apiClient
        return new Promise((resolve, reject) => { // pass the service in via event context?
            apiClient = context.apiClient
            //email id, installation id?
            if (typeof apiClient[event.method] === "function") {

                if(event.method === 'confirm') {
                    event.params = [context.eventData.code]
                }
                apiClient[event.method](...(event.params ?? [])).then((data) => {
                    if(data.ok) { // this is if we return the entire response instead of the json body
                        data.json().then((d) => {
                            if (d.hasOwnProperty('errorCode') && d?.errorCode !== 0) {
                                reject(d);
                            } else {
                                //console.log("api says okay", d)
                                resolve(d);
                            }
                        }).catch((error) => {
                            reject(error);
                        });
                    } else {//handle the response body
                        try { //crappy wrapper incase this fails
                            if (data.hasOwnProperty('errorCode') && data?.errorCode !== 0) {
                                reject(data);
                                /*
                                if (data.errorCode === 44) {
                                    console.log("WE'RE HANDLING CODE 44");
                                    const csrf = interpret(CsrfMachine.withContext(context)).onTransition((state) => {
                                        console.log('New state:', state.value);
                                        console.log('New context:', state.context);
                                    }).start();
                                }

                                 */
                            }
                            let d = JSON.stringify(data)
                            if (d.hasOwnProperty('errorCode') && d?.errorCode !== 0) {
                                reject(data);
                            }
                            if (isJson(d)) {
                                //console.log(d)
                                //console.log(typeof(d))
                                resolve(data)
                            } else {
                                reject(data);
                            }
                        } catch (e) {
                            reject(e);
                        }
                    }
                }).catch((error) => {
                    reject(error);
                });
            } else {
                reject(`Provided client does not have a ${event.method} method`)
            }
        });
    }
}

import React from 'react';
import SidebarCheckmark from "../svg/SidebarCheckmark";
import useIsWindows from "../hooks/useIsWindows";

const PaidSidebar = () => {
    const passwords = "https://img.mirkat.com/web/images/home/passwords.svg";
    const account = "https://img.mirkat.com/web/images/home/account.svg";
    const accountPrivate = "https://img.mirkat.com/web/images/home/private.svg";
    const identity = "https://img.mirkat.com/web/images/home/identity.svg";
    const card = "https://img.mirkat.com/web/images/home/card.svg";
    const banking = "https://img.mirkat.com/web/images/home/banking.svg";
   
    const [isWindows, isMac] = useIsWindows();
    return (
        <div className="sidebar-container">
	    <div className="sidebar-header-container"><div className="sidebar-header">You have
	        {isWindows ? 
                    <div className="sidebar-header-status" style={{padding: "7px 14px 7px 14px"}}>Mirkat+</div>
                : isMac ?
                    <div className="sidebar-header-status" style={{padding: "8.5px 14px 5.5px 14px"}}>Mirkat+</div>
                  :
                    <div className="sidebar-header-status" >Mirkat+</div>
                }
	    </div></div>
	    <div className="sidebar-items-container">
	    	<div className="sidebar-row">
	    	    <div className="sidebar-left">
	    	        <img className="sidebar-image" src={passwords} />
	    	        <div className="sidebar-description sidebar-description-paid">Exposed Passwords</div>
	    	    </div>
	    	    <div className="sidebar-right">
	    	        <img className="sidebar-image" src={account} />
	    	        <div className="sidebar-description sidebar-description-paid">Account Credentials</div>
	    	    </div>
	        </div>
	    	<div className="sidebar-row">
	    	    <div className="sidebar-left">
	    	        <img className="sidebar-image" src={accountPrivate} />
	    	        <div className="sidebar-description sidebar-description-paid">Private<br />Accounts</div>
	    	    </div>
	    	    <div className="sidebar-right">
	    	        <img className="sidebar-image" src={identity} />
	    	        <div className="sidebar-description sidebar-description-paid">Identity<br />Theft</div>
	    	    </div>
	        </div>
	    	<div className="sidebar-row">
	    	    <div className="sidebar-left">
	    	        <img className="sidebar-image" src={card} />
	    	        <div className="sidebar-description sidebar-description-paid">Credit &<br />Debit Cards</div>
	    	    </div>
	    	    <div className="sidebar-right">
	    	        <img className="sidebar-image" src={banking} />
	    	        <div className="sidebar-description sidebar-description-paid">Banking Accounts</div>
	    	    </div>
	        </div>
	    </div>
	    <div className="sidebar-footer-container">
	    	<div className="sidebar-checkmark"><SidebarCheckmark /></div>
	    	<div className="sidebar-footer">
	    	    <div className="sidebar-footer-text">
	    	    	You have maximum protection
	    	    	<span><span className="sidebar-footer-text-mirkat">Mirkat+</span><span className="sidebar-footer-text-bottom"> will continuously scan the dark web and notify you of any threats.</span></span>
	    	    </div>
	        </div>
	    </div>
        </div>
    );
};

export default PaidSidebar;

import React, {useEffect, useState} from 'react';
import {useModal} from "../contexts/ModalContext";

import styles from "../styles/scss/CancelMirkatPlusModal.module.scss";

import {useFetch} from "../hooks/useFetch";
import { useAuth } from "../contexts/authContext";

const CancelMirkatPlusModal = ({callback}) => {
    // const [showModal, setShowModal] = useState(true);
    //const [emailToRemove, setEmailToRemove] = useState(email);
    const {authState, authService} = useAuth();
    const {modalVisible, toggleModal} = useModal();
    const { state, fetch, isLoading, uiError, ready, reset} = useFetch()
    const [cancel, setCancel] = useState(false);
    const onClickCancel = () => {
        toggleModal();
    }

    const onClickCancelSubscription = () => {
        //remove email
        setCancel(true);
    }
    useEffect(() => {
        reset()
    }, []);

    useEffect(() => {
        if(ready && cancel && state.matches('idle')) {
            fetch('cancel')
            setCancel(false)
        }
        if(state.matches('success')) {
            //setEmailToRemove(null)
            //callback(true)
            authService.send('CANCELED_SUBSCRIPTION')
            toggleModal()
            reset()
        }
        if(state.matches('failure')) {
            //setEmailToRemove(null)
            //callback(true)
        }
        console.log("state: ", state)
        console.log("cancel: ", cancel)
    }, [state, ready, cancel]);


    useEffect(() => {
        console.log(modalVisible);
    }, [modalVisible]);

    return (
        <div id="cancel-subscription-overlay">
            { modalVisible && (
                <div className={styles["modal-container"]}>
                    <div className={styles["m-container"]}>
                        <div className={styles["m-content-container"]}>
                            <div className={styles["m-header-text"]}>Cancel Mirkat+ subscription</div>
                            <div className={styles["m-content-text"]}>{"If you cancel, you'll retain data web monitoring until {DATE}. You can resume your subscription before it expires."}</div>
                        </div>
                        <div className={styles["m-action-container"]}>
                            <div className={styles["red-cancel-button"]} onClick={onClickCancelSubscription}>
                                <div className={styles["red-cancel-button-text"]}>Cancel subscription</div>
                            </div>
                            <div className={styles["white-keep-button-container"]} onClick={onClickCancel}>
                                <div className={styles["white-keep-button"]}>
                                <div className={styles["white-keep-button-primary-default"]}>Keep Subscription</div>
                                </div>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            )}
        </div>
    );
}

export default CancelMirkatPlusModal;

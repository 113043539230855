import React, {useState,useEffect} from 'react';
import '../styles/scss/Breach.scss';
import useWebKitDetector from "../hooks/useWebKitDetector";


const Breach = ({ card }) => {
    const [knownFields, setKnownFields] = useState(true);
    const isWebKitBrowser = useWebKitDetector();

    useEffect( () => {
	if (card.datapoints.known.length === 0) setKnownFields(false);
    },[]);

    const analyzeString = (str) => {
        let result = {
            bulletClass: null,
            dashClass: null,
            nonBulletClass: null,
            defaultClass: 'defaultText', // Set a default class
        }
        if (str) {
        const hasBulletPoints = str.includes('⦁');
        const onlyBulletsAndDashes = /^[⦁-]+$/.test(str);
        const bulletsAndOtherChars = hasBulletPoints && /[^⦁-]/.test(str);

        if (hasBulletPoints) {
            result.bulletClass = 'feedcard-value-heavy';
            if (onlyBulletsAndDashes) {
                result.dashClass = 'feedcard-value-dashes';
                result.defaultClass = null;
            } else if (bulletsAndOtherChars) {
                result.nonBulletClass = 'feedcard-value-char';
                result.bulletClass = 'feedcard-value-mixed';
                result.defaultClass = null;
            }
        }
        }
        
        return result;
    };

    return (
        <div className="breach-feed-cards-container">
	    <div className="breach-feed-card">
	    	<div className="breach-feed-card-header">
	    	    <div className="breach-feed-card-title-container">
	    		<div className="breach-feed-card-title">{card.name}</div>
	    	    </div>
	    	</div>
	    	<div className="breach-feed-card-description">{card.description}</div>
	        {knownFields && <div className="breach-feed-card-datalist">
	        	{card.datapoints.known.map((cardData,index) => {
                        const { bulletClass, dashClass, nonBulletClass, defaultClass } = analyzeString(cardData?.value);

                        return (
	                <li key={cardData.index} className="breach-feed-card-row">
	            	<div className="breach-feed-card-row-left-container">
			    <div className="breach-feed-card-row-left">
			        <div className="breach-feed-card-row-left-internal">{cardData.label}</div>
			    </div>
			</div>
	            	<div className="breach-feed-card-row-right-container">
			    <div className="breach-feed-card-row-right">
			        <div className="breach-feed-card-row-right-internal">
                            {bulletClass ? <>
                                {(<div className="feedcard-value-row">
                                    {cardData?.value.split('').map((char, index) => {
                                        if (char === '⦁') {
                                            return <span className={bulletClass}>{char}</span>
                                        } else if (char === ' ') {
                                            return <span className={nonBulletClass}>&nbsp;</span>
                                        } else if (char === '-' && dashClass) {
                                            return <span className={isWebKitBrowser ? "feedcard-webkit-dashes" : dashClass}>{char}</span>
                                        } else {
                                            return <span className={nonBulletClass}>{char}</span>
                                        }
                                    })}
                                  </div>)}</> : (cardData?.value && <>{cardData?.value}<br /></>)}
                                {cardData?.subtitle && <div className="feedcard-lite">{cardData?.subtitle}<br /></div>}
                                {cardData?.subtitle2 && <div className="feedcard-lite">{cardData?.subtitle2}<br /></div>}
                                {cardData?.message && <div className="feedcard-message">{cardData?.message}<br /></div>}
                                </div>
			    </div>
			</div>
	                </li>
                        )
	            })}
	        </div>}
	        {!knownFields && <div className="breach-feed-card-datalist">
		    {card.datapoints.unknown.slice(0, 6).map((data,index,array) => (
			index % 2 === 0 ? (
	                <li key={index} className="breach-feed-card-row">
	            	    <div className="breach-feed-card-row-left-container">
			        <div className="breach-feed-card-row-left-unknown">
			            <div className="breach-feed-card-row-left-internal-unknown">{data}</div>
			        </div>
			    </div>
	            	    <div className="breach-feed-card-row-right-container">
			        <div className="breach-feed-card-row-right-unknown">
			            <div className="breach-feed-card-row-right-internal-unknown">{index < array.length - 1 ? array[index + 1] : ''}</div>
			        </div>
			    </div>
	                </li>
			) : null
	            ))}
		</div>}
	    </div>
        </div>
    );
};

export default Breach;

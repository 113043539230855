import React, {useCallback, useEffect} from 'react';
import {useState} from "react";
import {track} from "../util/analytics";
import useObserveVisibility from "../hooks/useObserveVisibility";
import AuthStorage from "../services/authStorage";
import useIsWindows from "../hooks/useIsWindows";

           //<div className="bbar-indicator-container">
           //    <div className="bbar-indicator" />
           //</div>
const storage = new AuthStorage();

const BottomBar = ({ animation, changeState, parentState, upgradeClickFrom, feedSubType, feedPosition }) => {
    const [bottomBarSeen, setBottomBarSeen] = useState(false)
    const [bottomBarButtonSeen, setBottomBarButtonSeen] = useState(false)
    const [isWindows, isMac] = useIsWindows();

    const passwordd = "https://img.mirkat.com/web/images/home/passwordd.svg";
    const accounte = "https://img.mirkat.com/web/images/home/accounte.svg";
    const privatee = "https://img.mirkat.com/web/images/home/privatee.svg";
    const credite = "https://img.mirkat.com/web/images/home/credite.svg";
    const bankinge = "https://img.mirkat.com/web/images/home/bankinge.svg";
    const identitye = "https://img.mirkat.com/web/images/home/identitye.svg";
    const illustrationBimage = "https://img.mirkat.com/web/images/home/illustrationb.png";
    const monitoringSpinner= "https://img.mirkat.com/web/images/home/spinner.png";

    const onBottomBarVisible = useCallback(() => {
        if(!bottomBarSeen) {
            console.log(bottomBarSeen)
            console.log('Bottombar Target card is now in view');
            setBottomBarSeen(true)
            if(feedPosition > 3) {
                console.log("RISK")
            } else {
                console.log("SAFE")
            }

            //#386
            track('Card Viewed', {
                cardType: 'upgradeMirkat+',
                feedType: 'feed',
                feedSubType: feedPosition> 0 ? 'risk' : 'safe', // or safe
                feedPosition: feedPosition, // integer
            });
        }

    }, [feedPosition, bottomBarSeen]);

    const bottomBarCardRef = useObserveVisibility(onBottomBarVisible, { threshold: 0.8 })

    const onBottomBarButtonVisible = useCallback(() => {
        if(!bottomBarButtonSeen) {
            console.log(bottomBarSeen)
            console.log('Bottombar button Target is now in view');
            setBottomBarButtonSeen(true)
            //#371
            track('Button Viewed', {
                cardType: 'upgrade',
                feedType: 'feed',
            });
        }

    }, [feedPosition, bottomBarSeen]);

    const bottomBarButtonRef = useObserveVisibility(onBottomBarButtonVisible, { threshold: 0.5 })
    /*
    useEffect(() => {
        if(bottomBarCardRef.current) {
            //#287
            track('Card Loaded', {cardType: 'monitorMoreEmails', feedType: 'feed',
                feedSubType: feed.feed?.existingRisks?.cards?.length > 0 ? 'risk' : 'safe',
                feedPosition: (feed.feed?.breaches?.existing || 0) + 2,});
        }
    }, [addEmailCardRef])
     */
    useEffect(() => {
        //#381
        track('Card Loaded', {
            cardType: 'upgradeMirkat+', feedType: 'feed', feedSubType: feedSubType,
            feedPosition: feedPosition
        })
    }, [])

               //<div className="bbar-illustration-container"><img className="bbar-illustration" src={illustrationBimage} width="414" height="205" /></div>
    return (
       <div className={`bbar-container ${animation ? 'slide-up' : ''}`} ref={bottomBarCardRef} style={{ position: 'relative'}}>
           <div className="bbar-top">
        <section className="monitoring content dark">
          <div className="container">
            <div className="visual">
              <div className="particles">
                {[...Array(120)].map((x, i) =>
                  <div className="particle" key={i}></div>
                )}
              </div>
              <div className="spinner">
                <img src={monitoringSpinner} alt="Dark scan scanner"></img>
              </div>
            </div>
          </div>
        </section>

               <div className="bbar-header">
                   <div className="bbar-header-text">
                       <div className="bbar-header-text-left">You have</div>
                        {isWindows ?
                            <div className="bbar-header-text-right" style={{padding: "7px 14px 7px 14px"}}>limited protection</div>
                        : isMac ?
                            <div className="bbar-header-text-right" style={{padding: "8.5px 14px 5.5px 14px"}}>limited protection</div>
                          :
                            <div className="bbar-header-text-right" >limited protection</div>
                        }
                   </div>
               </div>
           </div>
           <div className="bbar-items-container">
                   <div className="bbar-items-row">
                       <div className="bbar-item-container">
                           <img src={passwordd} className="bbar-item" />
                           <div className="bbar-item-header">Exposed Passwords</div>
                       </div>
                       <div className="bbar-item-container">
                           <img src={accounte} className="bbar-item" />
                           <div className="bbar-item-header">Account Credentials</div>
                       </div>
                       <div className="bbar-item-container">
                           <img src={privatee} className="bbar-item" />
                           <div className="bbar-item-header">Private Accounts</div>
                       </div>
                   </div>
                   <div className="bbar-items-row">
                       <div className="bbar-item-container">
                           <img src={credite} className="bbar-item" />
                           <div className="bbar-item-header">Credit & Debit Cards</div>
                       </div>
                       <div className="bbar-item-container">
                           <img src={bankinge} className="bbar-item" />
                           <div className="bbar-item-header">Banking Accounts</div>
                       </div>
                       <div className="bbar-item-container">
                           <img src={identitye} className="bbar-item" />
                           <div className="bbar-item-header" style={{ justifyContent: "center" }}>Identity<br />Theft</div>
                       </div>
                   </div>
           </div>
           <div className="bbar-button-container">
               <div className="bbar-button" ref={bottomBarButtonRef} onClick={() => {changeState(!parentState); upgradeClickFrom("bottomBar");}} style={{ cursor: "pointer" }} >Unlock full protection</div>
           </div>
       </div>
    );
};

export default BottomBar;

import { useState, useCallback } from 'react';


//example usage - basically what's in LoginForm2
//Button Click calls startProgress onClick
//ProgressBar is displayed - 10% after 500ms
//20% after auth sent (or reaches 1000ms timeout)
/*


    const { progressStarted, apiStarted, isComplete, startProgress, handleCompletion,  } = useProgressBar();
    //example




    const onSubmit = (data) => {
        authService.send({ type: 'LOGIN'})
        setLastApiCallStarted(true)
        setSubmitted(true); // should change button to disabled...
    }
    useEffect( () => {
        //isComplete is set once progress bar reaches 100% (set by onComplete() in ProgressBar)
        if(submitted && authState.matches('confirm') && !authState.context.errorData && isComplete) {
            //wait for progress bar to finish or set to 100?
            regSend('CONFIRM')
        }

        // or if state in failure.....
        if(authState.context.errorData) {
            setProgressOverride(100)
        }

        if(authState.matches('authenticated')) {
            navigate('/')
        }

        //if(authState.context.emailAddress) {
        if(authState.context.emailLogin) {
            setValue("email", authState.context.emailLogin)
        }
    }, [authState.value, authState.context.errorData, register, isComplete]);



    return (
        <>{progressStarted && <ProgressBar apiStarted={apiStarted} onCompleted={handleCompletion} />}
            <Fade in={true} timeout={1000}>
                <form style={{ maxWidth: "500px", width: "100%" }} noValidate method="post" onSubmit={handleSubmit(onSubmit)}>
                    MORE CODE
                     <Button isDisabled={!formState.isValid || progressStarted} buttonText={bText} onClick={startProgress} />
                </form>
            </Fade>
        </>
        )

 */


const useProgressBar = (apiDelay = 1000) => {
    const [progressStarted, setProgressStarted] = useState(false);
    const [apiStarted, setApiStarted] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [progressOverride, setProgressOverride] = useState(null);
    const [lastApiCallStarted, setLastApiCallStarted] = useState(false);
    const [noDelay, setNoDelay] = useState(false);

    const startProgress = useCallback(() => {
        // Reset states to initial values
        setProgressStarted(true);
        setIsComplete(false);
        setApiStarted(false);

        // Simulate an API call or any intermediate step after a delay
       // setTimeout(() => {
            setApiStarted(true);
      //  }, apiDelay);
    }, [apiDelay]);

    const resetProgressBar = useCallback(() => {
        setProgressStarted(false);
        setIsComplete(false);
        setApiStarted(false);
        setProgressOverride(null);
        setLastApiCallStarted(false);
    })

    const handleCompletion = useCallback(() => {
        setProgressStarted(false);
        setIsComplete(true); // Set completion state
        setIsComplete(true); // Set completion state
        return () => {
            setIsComplete(false); // Reset completion state
            setProgressStarted(false);
        }
        //setTimeout()
        // Additional actions after completion can be placed here
    }, []);

    return { progressStarted, setNoDelay, noDelay, apiStarted, isComplete, startProgress, progressOverride, setProgressOverride, lastApiCallStarted, setLastApiCallStarted, handleCompletion, resetProgressBar };
};

export default useProgressBar;
